import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

type TypographyProps = {
  isTablet: boolean;
  isMobile: boolean;
};
export const StTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<TypographyProps>(({ theme, isMobile, isTablet }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 600,
  color: theme.palette.themeColor.main,
  textAlign: isTablet || isMobile ? 'center' : 'unset',
  marginInline: isTablet ? '8rem' : isMobile ? '3rem' : 0,
  '& a': {
    color: theme.palette.themeColor.main,
  },
}));

export const StDesc = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<TypographyProps>(({ theme, isMobile, isTablet }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '16px',
  fontWeight: 400,
  color: theme.palette.neutral8.main,
  textAlign: isTablet || isMobile ? 'center' : 'unset',
  marginInline: isTablet ? '8rem' : isMobile ? '3rem' : 0,
  // '& a': {
  //   color: theme.palette.neutral8.main,
  // },
}));

export const StCardText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  fontWeight: 600,
  color: theme.palette.black.main,
}));

export const StCardTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '16px',
  fontWeight: 600,
  color: theme.palette.black.main,
}));

export const StNoSites = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<TypographyProps>(({}) => ({
  color: '#6E6E6E',
  textAlign: 'center',
  fontFamily: ' Proxima Nova',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  background: '#F2F2F2',
  margin: '20px',
}));
