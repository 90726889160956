import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

type InvertColorProps = {
  invert: boolean;
};

export const StContainer = styled(Grid)(({ theme }) => ({
  marginBlock: '1.5rem',
  marginLeft: '6.5rem',
  marginRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    marginLeft: '4rem',
    marginRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    marginLeft: '3rem',
    marginRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    marginInline: '2rem',
  },
}));

export const StTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'invert',
})<InvertColorProps>(({ theme, invert }) => ({
  color: invert ? theme.palette.white.main : theme.palette.themeColor.main,
  textAlign: 'center',
  fontSize: '18px',
  lineHeight: '1.5rem',
  fontWeight: 700,
}));

export const StDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.themeColorSecondary.main,
  margin: '1rem 0',
  marginInline: 'auto',
  width: '130px',
  height: '4px',
  borderRadius: '8px',
}));

export const StInformationContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'invert',
})<InvertColorProps>(({ theme, invert }) => ({
  background: invert ? theme.palette.themeColor.main : theme.palette.white.main,
  paddingBlock: invert ? '1.5rem' : 0,
}));
