import Box from '@mui/material/Box';
import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { OneColumnGrid } from 'components/shared/OneColumnGrid';
import { TwoColumnGrid } from 'components/shared/TwoColumnGrid';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';

const OneAndTwoColumnServerSide = (props: any): JSX.Element => {
  const { show } = props;
  if (!props?.fields) return <></>;

  let { fields } = props;

  if (!show) {
    return <></>;
  }

  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }

  const isSingleColumn = props?.fields?.contentList.length > 1 ? false : true;

  return (
    <Box sx={{ paddingBlock: '2rem', marginBlock: '1rem' }}>
      {isSingleColumn ? (
        <OneColumnGrid
          {...props.fields?.contentList[0]}
          isSingleColumn={isSingleColumn}
          mergeTagData={props?.mergeTagData}
        />
      ) : (
        <TwoColumnGrid
          fields={props.fields}
          isSingleColumn={isSingleColumn}
          mergeTagData={props?.mergeTagData}
        />
      )}
    </Box>
  );
};

export default OneAndTwoColumnServerSide;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'OneAndTwoColumnServerSide'
  );

  return {
    ...componentProps,
    confirmedsitesData: cdpData.confirmedsitesData,
    mergeTagData: cdpData,
  };
};
