import SupportSubpage from '../SupportSubpage/SupportSubpage';
import { ISubPageProps } from '../Support.types';
import getBreadcrumbsStaticProps from '../getBreadcrumbsStaticProps';

const TechnicalSupport: React.FC<ISubPageProps> = (props) => {
  if (!props?.fields) return <></>;
  return <SupportSubpage {...props} formType="technical-support-form" />;
};

export const getStaticProps = getBreadcrumbsStaticProps;

export default TechnicalSupport;
