import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { CR_SITE } from '../../lib/constant';
import { RootState } from '../../store/store';
import { resetLastVisitedComponentId } from '../../features/globalConfig';

const useLastVisitedComponent = () => {
  const globalConfig = useSelector((state: RootState) => state?.globalConfig);
  const dispatch = useDispatch();
  const { sitecoreContext } = useSitecoreContext();
  const siteName = sitecoreContext?.site?.name;
  const baseUrl = (sitecoreContext?.siteSettings as any)?.baseUrl?.value as string;

  const scrollToElement = (id: string) => {
    const itemOffset = document.getElementById(id)?.offsetTop;
    if (itemOffset) {
      window.scroll({
        top: itemOffset,
        left: 0,
        behavior: 'smooth',
      });
      dispatch(resetLastVisitedComponentId());
    }
  };

  const eventListnerFunction = (event: any) => {
    if (
      event.state &&
      globalConfig?.lastVisitedComponentId &&
      siteName === CR_SITE &&
      baseUrl === window?.location?.href
    ) {
      setTimeout(() => {
        scrollToElement(globalConfig?.lastVisitedComponentId);
      }, 1000);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', eventListnerFunction, false);
    }
    return () => {
      window.removeEventListener('popstate', eventListnerFunction, false);
    };
  }, [baseUrl, dispatch, globalConfig?.lastVisitedComponentId, siteName]);
};
export default useLastVisitedComponent;
