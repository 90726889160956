import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import StThankYouWrapperContainer from './ThankYouWrapper.style';

const ThankYouWrapper = (props: any) => {
  return (
    <StThankYouWrapperContainer>
      <Placeholder name="jss-main-thankyou-body" rendering={props.rendering} />
    </StThankYouWrapperContainer>
  );
};

export default ThankYouWrapper;
