import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { StBackDrop } from './Loader.style';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface ILoaderProps {
  message: string;
}
const LoaderWithText = (props: ILoaderProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isSubHeader: any =
    (sitecoreContext?.route?.placeholders['jss-header'] as any)?.findIndex(
      (item: any) =>
        item?.componentName === 'HeaderNavigation' && item?.fields?.isSubHeader?.value === true
    ) > -1
      ? true
      : false;

  return (
    <StBackDrop open={true} sx={{ padding: '1rem', top: isSubHeader ? '114px' : 0 }}>
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.primary.main,
          marginBottom: '0.5rem',
        }}
        size={25}
        thickness={6}
        {...props}
        value={50}
      />
      <Box sx={{ color: '#000' }}>{props?.message || ''}</Box>
    </StBackDrop>
  );
};

export default LoaderWithText;
