import { setCookie } from 'cookies-next';
import { getCookieExpiryTime } from '../../../src/lib/utils/enrollmentUtil';

export const resetCookies = () => {
  setCookie('enrollmentID', null, {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('journeyStatus', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('journeyStep', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('journeyType', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('bx_guest_ref', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  setCookie(cookieName, '', {
    secure: true,
    expires: getCookieExpiryTime(0),
  });
};
