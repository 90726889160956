/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import StudyEnrollmentContent from './StudyEnrollmentContent';
import { StDTPWrapper } from '../shared/Container/Container.styles';
import { JOURNEYS } from '../../constants/index';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { StudyDTPGridWithoutBackground } from './StudyEnrollment.styles';

export enum ProgressBarPositionOptions {
  TOP = 'Top',
  MIDDLE = 'Middle',
}
const StudyEnrollment: React.FC<ComponentProps> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  // @ts-ignore
  const isHomePage = sitecoreContext?.route?.fields?.isHomePage?.value || false;
  const journeyType = useSelector((state: RootState) => state.cdpData.journeyType);
  const isJourneyType3: boolean = journeyType === JOURNEYS.JOURNEY_03;
  const siteSettings: any = sitecoreContext?.siteSettings;
  const isScreenerLandingPageEnabled = siteSettings?.enableScreenerLandingPage?.value;

  const progressBarPosition: ProgressBarPositionOptions =
    (props.params?.progressBarPosition as ProgressBarPositionOptions) ||
    ProgressBarPositionOptions.TOP;

  if (isHomePage && !isJourneyType3) return null;
  if (isScreenerLandingPageEnabled && isHomePage) return <></>;
  return (
    <StDTPWrapper>
      <StudyDTPGridWithoutBackground>
        <StudyEnrollmentContent
          rendering={props.rendering}
          progressBarPosition={progressBarPosition}
        />
      </StudyDTPGridWithoutBackground>
    </StDTPWrapper>
  );
};

export default StudyEnrollment;
