import { UILink } from '../../shared/Links/UILink.styles';
import { UIButton } from '../../shared/UIButton.styles';
import { styled } from '@mui/material/styles';
import LinkWithModal from '../../shared/LinkWithModal';
import { Theme } from '@mui/material/styles';
import { useRouter } from 'next/router';

export interface ModalCTAButton {
  name: string;
  fields: {
    buttonVariant: {
      value: string;
    };
    link: {
      value: {
        text: string;
        anchor: string;
      };
    };
  };
}

enum ActionType {
  SignUp = 'SignUp',
  Back = 'Back',
  Continue = 'Continue',
  Cancel = 'Cancel',
  Done = 'Done',
  Resend = 'Resend',
  SignOut = 'Sign out',
  Ok = 'Ok',
}

interface FooterModalProps {
  modalCTAButtons: ModalCTAButton[];
  onClose: () => void;
  isFormValid?: boolean;
  onCloseModals?: () => void;
  onResend?: (modal: string) => void;
  emailResendCount?: number;
  onSignOut?: () => void;
}

const getmModalCTAButtonByName = (buttons: ModalCTAButton[], name: string) => {
  const item = buttons.find((item) => item.name === name);
  if (item) {
    return {
      buttonVariant: item?.fields?.buttonVariant?.value,
      value: item?.fields?.link?.value?.text,
      anchor: item?.fields?.link,
    };
  } else {
    return null;
  }
};

export const replaceValue = (originaValue = '', lookupObj: Record<string, string>): string => {
  const tags = Object.keys(lookupObj);
  let replacedValue = originaValue;
  tags.forEach((tag) => {
    const tagValue = lookupObj[tag] || '';
    replacedValue = replacedValue.replace(new RegExp(`\\[${tag}\\]`, 'g'), tagValue as string);
  });
  return replacedValue;
};

const StFooterBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: '1',
  a: {
    marginRight: '25px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
}));

export const StFooterbuttons = styled('div')(() => ({
  display: 'flex',
  marginTop: '25px',
  a: {
    padding: '12px 0px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    '&:hover': {
      border: 'none',
      opacity: '0.9',
    },
  },
  '.signUpBtn': {
    '&:hover': {
      border: 'none',
      opacity: '0.9',
    },
  },
}));

export const AppBarLink = styled(LinkWithModal)(
  ({ theme, variant }: { variant?: string; theme?: Theme }) => ({
    textDecoration: 'none',
    fontFamily: 'Proxima Nova SemiBold',
    color: variant === 'contained' ? theme?.palette.white.main : theme?.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  })
);

export const ProfileFooterModal: React.FC<FooterModalProps> = ({
  modalCTAButtons,
  onClose,
  isFormValid,
}) => {
  const router = useRouter();
  const signUpBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.SignUp)?.value;
  const signUpBtnText = signUpBtn ? signUpBtn.replace(/([A-Z])/g, ' $1').trim() : '';
  const backBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Cancel);
  const continueBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Continue);

  const signUpBtnClick = () => {
    router.push('/#cr-registration');
    onClose();
  };

  return (
    <StFooterbuttons>
      <UIButton id={signUpBtnText} className="signUpBtn" onClick={signUpBtnClick}>
        {signUpBtnText}
      </UIButton>
      <StFooterBox>
        <UILink id={backBtn?.value} href={'#'} variant={'outline'} onClick={onClose}>
          {backBtn?.value}
        </UILink>
        <UIButton
          id={continueBtn?.value}
          variant="contained"
          disabled={!isFormValid}
          sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
          type="submit"
        >
          {continueBtn?.value}
        </UIButton>
      </StFooterBox>
    </StFooterbuttons>
  );
};

export const SendFooterModal: React.FC<FooterModalProps> = ({
  modalCTAButtons,
  onClose,
  onCloseModals,
  onResend,
}) => {
  const backBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Back);
  const resendBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Resend);
  const doneBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Done);
  return (
    <StFooterbuttons>
      <UILink href={'#'} variant={'outline'} onClick={onClose}>
        {backBtn?.value}
      </UILink>
      <StFooterBox>
        <UILink href={'#'} variant={'outline'} onClick={() => onResend?.('emailSendModal')}>
          {resendBtn?.value}
        </UILink>
        <UIButton
          variant="contained"
          sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
          onClick={onCloseModals}
        >
          {doneBtn?.value}
        </UIButton>
      </StFooterBox>
    </StFooterbuttons>
  );
};

export const ReSendFooterModal: React.FC<FooterModalProps> = ({
  modalCTAButtons,
  onClose,
  onResend,
  onCloseModals,
  emailResendCount,
}: any) => {
  const backBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Back);
  const resendBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Resend);
  const doneBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Done);
  return (
    <StFooterbuttons>
      <UILink href={'#'} variant={'outline'} onClick={onClose}>
        {backBtn?.value}
      </UILink>
      <StFooterBox>
        {emailResendCount < 3 && (
          <UILink href={'#'} variant={'outline'} onClick={() => onResend?.('emailSendModal')}>
            {resendBtn?.value}
          </UILink>
        )}
        <UIButton
          variant="contained"
          sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
          onClick={onCloseModals}
        >
          {doneBtn?.value}
        </UIButton>
      </StFooterBox>
    </StFooterbuttons>
  );
};

export const SignOutFooterModal: React.FC<FooterModalProps> = ({
  modalCTAButtons,
  onClose,
  onSignOut,
}: any) => {
  const backBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Cancel);
  const signOutBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.SignOut);
  return (
    <StFooterbuttons>
      <StFooterBox>
        <UILink href={'#'} variant={'outline'} onClick={onClose}>
          {backBtn?.value}
        </UILink>
        <UIButton
          variant="contained"
          sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
          onClick={onSignOut}
        >
          {signOutBtn?.value}
        </UIButton>
      </StFooterBox>
    </StFooterbuttons>
  );
};

export const IdleSignOutFooterModal: React.FC<FooterModalProps> = ({
  modalCTAButtons,
  onClose,
}: any) => {
  const backBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Ok);
  return (
    <StFooterbuttons>
      <StFooterBox>
        <UIButton
          variant="contained"
          sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
          onClick={onClose}
        >
          {backBtn?.value || 'Ok'}
        </UIButton>
      </StFooterBox>
    </StFooterbuttons>
  );
};

export const PromptIdleTimeouFooterModal: React.FC<FooterModalProps> = ({
  modalCTAButtons,
  onClose,
  onSignOut,
}: any) => {
  const continueBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.Continue);
  const signOutBtn = getmModalCTAButtonByName(modalCTAButtons, ActionType.SignOut);
  return (
    <StFooterbuttons>
      <StFooterBox>
        <UILink href={'#'} variant={'outline'} onClick={onSignOut}>
          {signOutBtn?.value}
        </UILink>
        <UIButton
          variant="contained"
          sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
          onClick={onClose}
        >
          {continueBtn?.value}
        </UIButton>
      </StFooterBox>
    </StFooterbuttons>
  );
};
