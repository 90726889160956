import gql from 'graphql-tag';
import graphQLClient from '../graphQLClient';

const ConditionOfInterestQuery = gql`
  query ConditionOfInterest($pageSize: Int!, $language: String!, $rootItemId: String!) {
    pageOne: search(
      where: {
        AND: [
          { name: "_path", value: $rootItemId, operator: CONTAINS }
          { name: "_templates", value: "{3D27DB68-043C-48D0-9667-5DBA8B9CADE7}" }
          { name: "_language", value: $language }
        ]
      }

      first: $pageSize
    ) {
      total

      pageInfo {
        endCursor

        hasNext
      }

      ConditionOfInterest: results {
        name
        id
        category: field(name: "category") {
          ... on ItemField {
            jsonValue
          }
        }

        description: field(name: "description") {
          ... on TextField {
            value
          }
        }

        key: field(name: "key") {
          ... on TextField {
            value
          }
        }

        value: field(name: "value") {
          ... on TextField {
            value
          }
        }

        abbreviation: field(name: "abbreviation") {
          ... on TextField {
            value
          }
        }

        communities: field(name: "communities") {
          ... on ItemField {
            id
            jsonValue
          }
        }

        iconImage: field(name: "icon") {
          ... on ImageField {
            jsonValue
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchConditionOfInterestQueryParameters = (
  language = 'en',
  rootpath = '{8ABC0FE8-331F-401F-95BC-BAB80811FCE4}'
) => ({
  pageSize: 300,
  language,
  rootItemId: rootpath,
});

export const fetchConditionOfInterest = async (language: string, rootpath?: string) => {
  let ConditionOfInterestList: any = [];
  let shouldQueryMoreItems = true;

  while (shouldQueryMoreItems) {
    const fetchResponse = await graphQLClient.request<any>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ConditionOfInterestQuery as any,
      fetchConditionOfInterestQueryParameters(language, rootpath)
    );
    ConditionOfInterestList = ConditionOfInterestList.concat(
      fetchResponse?.pageOne?.ConditionOfInterest
    );
    shouldQueryMoreItems = fetchResponse.pageOne.pageInfo.hasNext;
  }
  return ConditionOfInterestList;
};

export default ConditionOfInterestQuery;
