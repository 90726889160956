import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const SLMainBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

export const SLBannerWrapper = styled(Box)(() => ({
  height: 177,
  background:
    'linear-gradient(285.78deg, rgba(0, 85, 135, 0.8) -23.78%, rgba(0, 142, 159, 0.8) 31.57%, rgba(0, 191, 179, 0) 87.78%)',
}));

export const SLTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingInline: theme.spacing(2),
  height: '48px',
  width: '100%',
  maxWidth: '391px',
  border: 'transperent',
  justifyContent: 'center',
  '& .MuiInput-root': {
    height: '48px',
  },
  [theme.breakpoints.down(500)]: {
    maxWidth: '368px',
  },
}));

export const SLBannerHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  marginBottom: theme.spacing(1),
  lineHeight: '45px',
  color: theme.palette.neutral8.main,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    fontSize: '22px',
  },
  [theme.breakpoints.down(350)]: {
    fontSize: '18px',
  },
}));
