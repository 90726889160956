import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Text,
  RichText,
  TextField,
  RichTextField,
  ImageField,
  NextImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export type ContentWithMediaProps = {
  fields?: {
    heading?: TextField;
    subHeading?: TextField;
    content?: RichTextField;
    imageContentDesktop?: ImageField;
    imageContentTablet?: ImageField;
    imageContentMobile?: ImageField;
  };
  OptionButtons?: () => React.ReactElement;
};

const ContentWithMedia = (props: ContentWithMediaProps): JSX.Element => {
  const { fields } = props;
  const heading = fields?.heading;
  const subHeading = fields?.subHeading;
  const content = fields?.content;
  const image = fields?.imageContentDesktop;
  const tabImage = fields?.imageContentTablet;
  const mobImage = fields?.imageContentMobile;
  const headingValue = heading?.value;
  const subHeadingValue = subHeading?.value;
  const contentValue = content?.value;
  const imageValue = image?.value?.src;
  const theme = useTheme();
  const neutral8 = theme.palette.neutral8.main;
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {headingValue || contentValue ? (
        <Box>
          {headingValue && (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingTop={'1rem'}
            >
              <Typography variant="h3" fontWeight={'bold'} color={neutral8}>
                <Text field={heading} />
              </Typography>
              {props.OptionButtons && props.OptionButtons()}
            </Box>
          )}
          {subHeadingValue && (
            <Typography variant="h5" fontWeight={'bold'} color={neutral8} paddingTop={'0.5rem'}>
              <Text field={subHeading} />
            </Typography>
          )}
          {contentValue && (
            <Box paddingTop={'1rem'} color={neutral8} sx={{ '& p': { color: neutral8 } }}>
              <RichText field={content} />
            </Box>
          )}
          {imageValue && (
            <Box width="100%" display="flex" justifyContent="center" paddingTop={'1rem'}>
              <NextImage
                field={isDesktop ? image : isTablet ? tabImage : isMobile ? mobImage : image}
              />
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default ContentWithMedia;
