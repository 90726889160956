import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

const SearchResultRendering = (props: any): JSX.Element => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          backgroundColor: '#F7F8FA',
          width: '1365px',
          display: 'flex',
          flexDirection: isTablet ? 'column' : 'row',
        }}
      >
        <Box sx={{ width: { xs: '100%', lg: '509px' }, minWidth: { xs: '100%', md: '509px' } }}>
          <Placeholder name="jss-main-search-left" rendering={props.rendering} />
        </Box>
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Placeholder name="jss-main-search-right" rendering={props.rendering} />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchResultRendering;
