import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const StCommunityWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
}));

export const StCommunityContainer = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  padding: '4rem 8rem',
  color: theme.palette.neutral8.main,
  [theme.breakpoints.down('lg')]: {
    padding: '3rem 4rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 2rem',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1300px',
    margin: '0 auto !important',
    padding: '4rem 2rem',
  },
}));
