import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LinkwithModal from '../LinkWithModal';
import Button from '@mui/material/Button';

export const StPrimaryLinks = styled(LinkwithModal, {
  shouldForwardProp: (prop) => prop !== 'linkFontColor',
})<{ linkFontColor?: string }>(({ theme, linkFontColor }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  marginRight: '1.5rem',
  marginBottom: '1rem',
  textDecoration: 'none',
  color: linkFontColor ? linkFontColor : theme.palette.white.main,
  '&:hover': {
    textDecoration: 'none',
    borderBottom: '1px dashed',
    color: linkFontColor ? linkFontColor : theme.palette.white.main,
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

export const AppBarButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  textAlign: 'center',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  marginRight: '1.5rem',
  marginBottom: '1rem',
  textDecoration: 'none',
  color: theme.palette.white.main,
  margin: 0,
  padding: 0,
  lineHeight: 'normal !important',
  '&:hover': {
    textDecoration: 'none',
    borderBottom: '1px dashed',
    color: theme.palette.white.main,
    borderRadius: 0,
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));

export const StPrimaryLinkItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: '0rem',
    },
  },
}));
