import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Text, TextField, RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { UIButton } from '../../../components/shared/UIButton.styles';
import { AntSwitch } from '../Cookie.styles';
import CookieTable from './CookieTable';
import { ManageCookieCategoryHeading } from '../Cookie.styles';
import { useState } from 'react';

interface ICookieCategoryProps {
  title: RichTextField;
  description: RichTextField;
  viewCookiesButton: TextField;
  alwaysActiveButton: TextField;
  isEssential: boolean;
  cookieList: any[];
  isEnabled: boolean;
  // eslint-disable-next-line
  updateCategoryConsent: Function;
}
interface IItemActionProps {
  alwaysActiveButton: TextField;
  isEssential: boolean;
  isEnabled: boolean;
  // eslint-disable-next-line
  onChange: Function;
}

// eslint-disable-next-line
const AcceptSwitch = (props: { isEnabled: boolean; onChange: Function }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <AntSwitch
        checked={props?.isEnabled}
        inputProps={{ 'aria-label': 'toggle' }}
        onChange={(event: any) => {
          props.onChange(!!event?.target?.checked);
        }}
      />
    </Stack>
  );
};

const ItemAction: React.FC<IItemActionProps> = (props) => {
  return (
    <>
      {props.isEssential ? (
        <Typography
          sx={{
            color: (theme) => theme.palette.hintText.main,
          }}
        >
          <Text field={props.alwaysActiveButton} />
        </Typography>
      ) : (
        <AcceptSwitch
          isEnabled={props?.isEnabled}
          onChange={(value: boolean) => props.onChange(value)}
        />
      )}
    </>
  );
};

const CookieCategory: React.FC<ICookieCategoryProps> = (props) => {
  const [isViewCookies, setViewCookies] = useState(false);
  const cookieList = Array.isArray(props.cookieList) ? props.cookieList : [];

  return (
    <Box
      sx={{
        marginTop: '1rem',
        padding: '2rem 1.2rem',
        border: (theme) => `1px solid ${theme.palette.neutral4.main}`,
        borderRadius: '10px',
      }}
    >
      <ManageCookieCategoryHeading>
        <Box
          sx={{
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            fontWeight: '500',
            flexBasis: '70%',
          }}
        >
          <RichText field={props.title} />
        </Box>
        <ItemAction
          isEssential={props.isEssential}
          alwaysActiveButton={props.alwaysActiveButton}
          isEnabled={props?.isEnabled}
          onChange={(value: boolean) => props.updateCategoryConsent(value)}
        />
      </ManageCookieCategoryHeading>
      <Box sx={{ fontSize: '1rem', lineHeight: '1.4rem', mt: '1rem' }}>
        <RichText field={props.description} />
      </Box>
      {!isViewCookies && cookieList.length > 0 && (
        <UIButton
          className="btn"
          variant="text"
          sx={{
            mt: '1rem',
            textAlign: 'center',
            alignSelf: 'center',
            minWidth: 'auto',
          }}
          onClick={() => {
            setViewCookies(true);
          }}
        >
          <Text field={props.viewCookiesButton} />
        </UIButton>
      )}
      {isViewCookies && cookieList.length > 0 && (
        <Box mt="0.5rem">
          <CookieTable cookieList={cookieList} />
        </Box>
      )}
    </Box>
  );
};

export default CookieCategory;
