import { styled } from '@mui/material/styles';

type StyledLinkProps = {
  callCenterNoColor?: string;
  isSubHeader?: any;
};

export const StyledLink = styled('a', {
  shouldForwardProp: (prop) => prop !== 'callCenterNoColor' && prop !== 'isSubHeader',
})<StyledLinkProps>(({ theme, callCenterNoColor, isSubHeader }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: callCenterNoColor || `${theme.palette.themeColor.main}`,
  '&:hover': {
    textDecoration: 'none',
    borderBottom: isSubHeader ? 'none' : `1px dashed ${theme.palette.themeColor.main}`,
  },
}));

export const StyledLinkMobile = styled(StyledLink, {
  shouldForwardProp: (prop) => prop !== 'hoverColor' && prop !== 'callCenterNoColor',
})<{ hoverColor?: string; callCenterNoColor?: string }>(
  ({ theme, hoverColor, callCenterNoColor }) => ({
    color: callCenterNoColor || theme.palette.themeColor.main,
    fontWeight: 'bold',
    '&:hover': {
      borderBottom: `1px dashed ${hoverColor || theme.palette.themeColor.main}`,
    },
  })
);
