import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const StCarouselWrapper = styled(Grid)(() => ({
  position: 'relative',
}));

export const StTypographyTitle = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontFamily: 'Proxima Nova',
  fontSize: '72px',
  fontStyle: 'normal',
  fontWeight: '600',
  margin: '0px',
  lineHeight: 'normal',
  [theme.breakpoints.between(500, 'lg')]: {
    fontSize: '48px',
  },
  [theme.breakpoints.down(500)]: {
    fontSize: '24px',
  },
}));

export const StTypographyDescription = styled('div')(({ theme }) => ({
  color: '#FFF',
  fontFamily: 'Proxima Nova',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  marginBottom: '40px',
  lineHeight: '39px',
  [theme.breakpoints.between(500, 'lg')]: {
    fontSize: '24px',
    fontWeight: '500',
  },
  [theme.breakpoints.down(500)]: {
    fontSize: '16px',
    fontWeight: '500',
  },
}));

export const StStack = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: '24%',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '50px',
  [theme.breakpoints.between(500, 'lg')]: {
    top: '22%',
    fontSize: '48px',
    '& .text': {
      marginTop: '20px',
    },
  },
  [theme.breakpoints.down(500)]: {
    top: '22%',
    left: '0px',
    '& .text': {
      marginTop: '40px',
    },
  },
}));
