import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { NextImage, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import SupportForm from '../SupportForm/SupportForm';
import { ISubPageProps } from '../Support.types';
import Breadcrumbs from '../../shared/Breadcrumbs/Breadcrumbs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { hideBanner } from '../../../features/crBannerSlice';

const AlertInfoBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  position: 'fixed',
  top: '89px',
  left: 0,
  width: '100%',
  zIndex: 5,
}));

const SupportSubpage: React.FC<ISubPageProps> = (props) => {
  const theme = useTheme();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const title = props.fields?.title;
  const description = props.fields?.description;
  const ErrorMessage = props.fields?.emailContentInfo?.fields?.ErrorMessage;
  const isBannerShown = useSelector((state: RootState) => state.crBanner.isBannerShown);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        marginTop: '50px',
        marginInline: '5rem',
        maxWidth: '100%',
        [theme.breakpoints.down('lg')]: {
          marginInline: '2.5rem',
        },
        [theme.breakpoints.up('xl')]: {
          width: '1300px',
          marginInline: 'auto !important',
          paddingLeft: '1rem',
        },
      }}
    >
      {isBannerShown && (
        <AlertInfoBox>
          <Alert
            icon={<LockOpenIcon fontSize="inherit" />}
            variant="filled"
            severity="error"
            onClose={() => {
              dispatch(hideBanner());
            }}
            sx={{ color: '#fff !important' }}
          >
            {ErrorMessage?.value}
          </Alert>
        </AlertInfoBox>
      )}
      {props?.breadcrumbsData?.item && <Breadcrumbs {...props?.breadcrumbsData} />}
      {!isFormSubmitted ? (
        <>
          <Typography
            variant="h2"
            fontWeight="600"
            color={(theme) => theme.palette.black.main}
            marginTop={'2rem'}
          >
            <Text field={title} />
          </Typography>
          <Box
            sx={{
              color: (theme) => theme.palette.black.main,
              marginTop: '1rem',
              fontSize: '1.2rem',
              lineHeight: '2rem',
              [theme.breakpoints.down('md')]: {
                fontSize: '1rem',
              },
              a: {
                textDecoration: 'none',
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            <RichText field={description} />
          </Box>
          <Box
            sx={{
              width: '50%',
              marginTop: '2rem',
              [theme.breakpoints.down('lg')]: {
                width: '100%',
              },
            }}
          >
            <SupportForm {...props} setIsFormSubmitted={setIsFormSubmitted} />
          </Box>
        </>
      ) : (
        <div style={{ marginTop: '3rem' }}>
          <NextImage
            field={props.fields?.emailContentInfo?.fields?.successIcon}
            data-testid="image-content-image"
            width={isMobile ? 86 : 101}
            height={isMobile ? 77 : 91}
          />
          <Typography
            variant="h1"
            color={'#000000'}
            sx={{ marginTop: '1.5rem', fontSize: isMobile ? '32px' : '48px', fontWeight: '600' }}
          >
            <Text field={props.fields?.emailContentInfo?.fields?.successTitle}></Text>
          </Typography>
          <Typography
            variant="h4"
            color={'#000000'}
            sx={{ marginTop: '1rem', fontSize: '22px', fontWeight: '400' }}
          >
            <Text field={props.fields?.emailContentInfo?.fields?.successDescription}></Text>
          </Typography>
        </div>
      )}
    </Box>
  );
};
export default SupportSubpage;
