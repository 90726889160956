import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type HamburgerIconProps = {
  color?: string;
};

const BurgerDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<HamburgerIconProps>(({ theme, color }) => ({
  width: '22px',
  height: '2px',
  margin: '5px 0',
  backgroundColor: color || theme.palette.themeColor.main,
}));

const HamburgerIcon = (props: HamburgerIconProps): JSX.Element => {
  return (
    <div>
      <BurgerDiv color={props?.color} />
      <BurgerDiv color={props?.color} />
      <BurgerDiv color={props?.color} />
    </div>
  );
};

export default HamburgerIcon;
