import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormDropDown from '../../shared/FormElements/DropDown';
import FormToolTip from './FormToolTip';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import moment from 'moment';
import { StFormDiv } from './FormElements.styles';
import { useEffect, useState } from 'react';
import { TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { pad, MONTH_MAPPER } from '../../../transformers/contactInfo.transform';
import { useSelector, useDispatch } from 'react-redux';
import { updatePrepopulateConfig } from '../../../features/contactInfoSlice';
import { RootState } from '../../../store/store';

const currentYear = new Date().getFullYear();
const maxYear = 1923;
const maxAllowedAge = Math.min(110, currentYear - maxYear);

const fallback = {
  minAge: 18,
  maxAge: maxAllowedAge,
  ageRangeError: 'Age range error',
};
const minorFallback = {
  minAge: 0,
  maxAge: 17,
};
const days = () => {
  const daysArray = Array(31)
    .fill(0)
    .map((_d, index) => {
      return {
        value: String(index + 1),
      };
    });
  return daysArray;
};

const months = ({ fields }: any) => {
  const months = fields.monthList.map((monthInfo: any) => {
    return {
      label: monthInfo?.fields?.label?.value,
      value: monthInfo?.fields?.value?.value,
    };
  });
  return months;
};

/**
 * Calculates the least Date of Birth (DOB) for a given maximum age.
 * Example if today is 2023-Dec-01 and maxAge is 10
 * then output will be 2013-Dec-02
 */
function getLeastDOBForMaxAge(maxAge: number) {
  const leastDob = moment().subtract(maxAge, 'years').add(1, 'days');
  return leastDob.format('YYYY-MM-DD');
}

const years = (minAge: number, maxAge: number, sortByAsc: boolean) => {
  const leastDob = getLeastDOBForMaxAge(maxAge);
  const startYear = moment(leastDob, 'YYYY-MM-DD').year();
  const endYear = moment().subtract(minAge, 'years').year();
  const yearList = [];

  for (let year = startYear; year <= endYear; year++) {
    yearList.push({
      value: year,
    });
  }

  if (sortByAsc) {
    return yearList;
  }
  return yearList.sort((a, b) => b.value - a.value);
};

const getDropdownOptions = (fieldName: any, fields: any, minAge: number, maxAge: number) => {
  if (fieldName === 'Day') {
    return days();
  } else if (fieldName === 'Month') {
    return months(fields);
  } else {
    const sortByAsc = fields?.fields?.sortByAscendingOrder?.value as boolean;
    return years(minAge, maxAge, sortByAsc);
  }
};

interface DOBProps {
  control?: any;
  errors: any;
  DOB: any;
  isRequired: any;
  toolTip: any;
  label: any;
  type: any;
  regexValidationMessage: any;
  requiredFieldMessage: any;
  getValues?: any;
  invalidInputMessage: any;
  clearErrors?: any;
  setError?: any;
  DisqualifiedAgeRangeErrorMessage?: TextField;
  DisqualifiedMaxAge?: TextField;
  DisqualifiedMinAge?: TextField;
  QualifiedAgeRangeErrorMessage?: TextField;
  QualifiedMaxAge?: TextField;
  QualifiedMinAge?: TextField;
  prePopulateFromScreener?: TextField;
  isReadOnly?: TextField;
}

const DOBErrorMessage = ({ errors, render }: any) => {
  const errorMessage = errors['Day'] || errors['Month'] || errors['Year'] || { message: '' };
  return <>{render({ message: errorMessage.message })}</>;
};

const DOB = (props: DOBProps): JSX.Element => {
  const {
    DOB,
    toolTip,
    label,
    isRequired,
    requiredFieldMessage,
    invalidInputMessage,
    clearErrors,
    DisqualifiedAgeRangeErrorMessage,
    DisqualifiedMinAge,
    DisqualifiedMaxAge,
    QualifiedAgeRangeErrorMessage,
    QualifiedMinAge,
    QualifiedMaxAge,
    prePopulateFromScreener,
    isReadOnly,
  } = props;
  const router = useRouter();
  const availableDobFields = DOB?.map((dobField: any) => dobField.name) || [];
  const theme = useTheme();
  const showpPadding = useMediaQuery(theme.breakpoints.down('md'));
  const prepopulateConfig = useSelector(
    (state: RootState) => state.contactInfoDetails.prepopulateConfig
  );
  const screenerOutput = useSelector((state: RootState) => state.screenerOutput);

  const dispatch = useDispatch();
  const pSec = useSelector((state: RootState) => state.screenerOutput.pSec);

  const [minAge, setMinAge] = useState<number>(fallback.minAge);
  const [maxAge, setMaxAge] = useState<number>(fallback.maxAge);
  const [ageRangeErrMsg, setAgeRangeErrMsg] = useState<string>(fallback.ageRangeError);

  useEffect(() => {
    const pathSegments = router?.query?.path || [];
    const isMinorPage = pathSegments.includes('contact-info') && pathSegments.includes('minor');

    let localMinAge = isMinorPage ? minorFallback.minAge : fallback.minAge;
    let localMaxAge = isMinorPage ? minorFallback.maxAge : fallback.maxAge;

    const qualifiedMinAge = QualifiedMinAge?.value;
    const qualifiedMaxAge = QualifiedMaxAge?.value;
    const disqualifiedMinAge = DisqualifiedMinAge?.value;
    const disqualifiedMaxAge = DisqualifiedMaxAge?.value;

    if (pSec) {
      // Qualified User
      if (typeof qualifiedMinAge === 'number' && typeof qualifiedMaxAge === 'number') {
        localMinAge = qualifiedMinAge;
        localMaxAge = qualifiedMaxAge;
        if (QualifiedAgeRangeErrorMessage?.value) {
          setAgeRangeErrMsg(String(QualifiedAgeRangeErrorMessage?.value));
        }
      }
    } else if (typeof disqualifiedMinAge === 'number' && typeof disqualifiedMaxAge === 'number') {
      // DisQualified User
      localMinAge = disqualifiedMinAge;
      localMaxAge = disqualifiedMaxAge;
      if (DisqualifiedAgeRangeErrorMessage?.value) {
        setAgeRangeErrMsg(String(DisqualifiedAgeRangeErrorMessage?.value));
      }
    } else {
    }

    setMinAge(localMinAge);
    setMaxAge(localMaxAge);
  }, [pSec]);

  const getFieldWidth = (fieldName: any) => {
    if (fieldName === 'Month') {
      return 5;
    } else if (fieldName === 'Day') {
      return 3;
    } else {
      return 4;
    }
  };

  const isValueFromScreener = (fieldName: any) => {
    if (fieldName === 'Month') {
      return screenerOutput.m ? true : false;
    } else if (fieldName === 'Day') {
      return screenerOutput.d ? true : false;
    } else {
      return screenerOutput.y ? true : false;
    }
  };

  useEffect(() => {
    dispatch(
      updatePrepopulateConfig({
        DOB: {
          readOnly: isReadOnly?.value,
          prePopulate: prePopulateFromScreener?.value,
        },
      })
    );
  }, []);

  return (
    <FormControl fullWidth>
      <StFormDiv>
        <Text field={label} />
        {toolTip?.value && <FormToolTip value={toolTip?.value} />}
      </StFormDiv>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={7}
        lg={6}
        spacing={2}
        paddingRight={showpPadding ? 0 : 1.6}
      >
        {DOB.map((dobFields: any, index: number) => {
          return (
            <Grid item key={index} xs={getFieldWidth(dobFields?.name)}>
              <FormDropDown
                regexValidationMessage={props.regexValidationMessage}
                requiredFieldMessage={props.requiredFieldMessage}
                type="string"
                isRequired={props.isRequired}
                placeholder={dobFields?.fields?.placeholderLabel}
                label={dobFields?.fields?.label}
                name={dobFields?.fields?.key}
                toolTip={props.toolTip}
                disabled={
                  (prepopulateConfig['DOB']?.prePopulate === true &&
                    prepopulateConfig['DOB']?.readOnly) === true &&
                  isValueFromScreener(dobFields?.name)
                }
                errors={props?.errors}
                control={props?.control}
                options={getDropdownOptions(dobFields?.name, dobFields, minAge, maxAge)}
                isError={props?.errors['Day'] || props?.errors['Month'] || props?.errors['Year']}
                showInputLabel={false}
                rules={{
                  required: isRequired.value ? requiredFieldMessage.value : false,
                  validate: () => {
                    const dayValue = props?.getValues('Day');
                    const monthValue = props?.getValues('Month');
                    const yearValue = props?.getValues('Year');
                    let isValidDate = true;
                    let enteredDOB;
                    let age: number | null = null;
                    let fromattedDate;
                    const dateNow = moment(Date.now());
                    if (dobFields?.fields?.key?.value === 'Day') {
                      clearErrors('Month');
                      clearErrors('Year');
                    }
                    if (dobFields?.fields?.key?.value === 'Month') {
                      clearErrors('Day');
                      clearErrors('Year');
                    }
                    if (dobFields?.fields?.key?.value === 'Year') {
                      clearErrors('Month');
                      clearErrors('Day');
                    }
                    if (availableDobFields.length === 3) {
                      // it has all fields Day, Month and Year
                      if (dayValue && monthValue && yearValue) {
                        const fromattedDate = `${yearValue}-${pad(
                          MONTH_MAPPER[monthValue as keyof typeof MONTH_MAPPER]
                        )}-${pad(dayValue)}`;
                        isValidDate = moment(fromattedDate, 'YYYY-MM-DD', true).isValid();
                        enteredDOB = moment(fromattedDate);
                        age = dateNow.diff(enteredDOB, 'years', false);
                      } else {
                        isValidDate = false;
                      }
                    } else if (availableDobFields.length === 2) {
                      // it has  Month and Year
                      if (monthValue && yearValue) {
                        fromattedDate = `${yearValue}-${pad(
                          MONTH_MAPPER[monthValue as keyof typeof MONTH_MAPPER]
                        )}`;
                        isValidDate = moment(fromattedDate, 'YYYY-MM', true).isValid();
                        enteredDOB = moment(fromattedDate);
                        age = dateNow.diff(enteredDOB, 'years', false);
                      } else {
                        isValidDate = false;
                      }
                    } else {
                      // it has Year only
                      if (yearValue) {
                        fromattedDate = `${yearValue}`;
                        isValidDate = moment(fromattedDate, 'YYYY', true).isValid();
                        enteredDOB = moment(fromattedDate);
                        enteredDOB = moment(fromattedDate);
                        age = dateNow.diff(enteredDOB, 'years', false);
                      } else {
                        isValidDate = false;
                      }
                    }
                    if (!isValidDate) {
                      return invalidInputMessage?.value;
                    }
                    if (age != null) {
                      if (age < minAge || age > maxAge) {
                        return ageRangeErrMsg;
                      }
                    }
                    clearErrors('Month');
                    clearErrors('Day');
                    clearErrors('Year');
                    return true;
                  },
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <DOBErrorMessage
        errors={props?.errors}
        render={({ message }: any) => (
          <Box sx={{ color: (theme) => theme.palette.error.main }}>{message}</Box>
        )}
      />
    </FormControl>
  );
};

export default DOB;
