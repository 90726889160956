import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { RichText, Text, TextField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { StContentHeadingBox, StContentHeadingSubBox } from './ContentWithHeading.styles';

interface IReferencesFields {
  heading?: TextField;
  content?: RichTextField;
}
interface IReferencesProps extends ComponentProps {
  fields?: IReferencesFields;
}
const ContentWithHeading: React.FC<IReferencesProps> = (props) => {
  if (!props?.fields) return <></>;
  return (
    <StContentHeadingBox>
      <StContentHeadingSubBox>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h3">
            <Text field={props.fields?.heading} />
          </Typography>
        </Box>
        <Box sx={{ color: (theme) => theme.palette.common.black }}>
          <RichText field={props.fields?.content} />
        </Box>
      </StContentHeadingSubBox>
    </StContentHeadingBox>
  );
};

export default ContentWithHeading;
