import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { UILink } from 'components/shared/Links/UILink.styles';
import { useEffect, useState } from 'react';

export const StRichTextGridItem = styled(Box)(() => ({
  p: {
    margin: '0px 0px',
  },
}));

export const HCPWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '0px',
  width: '100%',
  margin: '0px',
  marginInline: 'auto',
  gap: theme.spacing(0),
  display: 'flex',
  maxWidth: '1305px',
  justifyContent: 'center',
}));

export const HCPCRGridContainerWithoutBackground = styled(Grid)(({ theme }) => ({
  ul: {
    paddingLeft: '15px',
  },
  width: '100%',
  paddingBottom: '3rem',
  paddingInline: '4rem',
  marginInline: '0',
  opacity: 0.95,
  background: 'rgba(255, 255, 255, 0.95)',
  [theme.breakpoints.only('lg')]: {
    paddingInline: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.only('md')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down(620)]: {
    padding: '2rem',
  },
}));

const HcpContent = (props: any): JSX.Element => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down(1000));
  const [showMore, setShowMore] = useState<boolean>(false);
  const [lineLimit, setLineLimit] = useState<any>(props?.fields?.readMoreLineLimit?.value);

  useEffect(() => {
    if (isNotDesktop) {
      setShowMore(true);
      setLineLimit(props?.fields?.readMoreLineLimit?.value);
    } else {
      setShowMore(false);
      setLineLimit(props?.fields?.readMoreLineLimit?.value);
    }
  }, [isNotDesktop]);

  return (
    <HCPWrapper>
      <HCPCRGridContainerWithoutBackground>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Box
            sx={{
              fontFamily: `Proxima Nova`,
              fontWeight: 600,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              textAlign: 'start',
              wordBreak: 'break-word',
              color: theme.palette.themeColor.main,
            }}
          >
            <Text field={props?.fields?.heading} />
          </Box>
          <Box>
            <Box
              sx={{
                fontFamily: `Proxima Nova`,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '30px',
                textAlign: 'start',
                wordBreak: 'break-word',
                color: theme.palette.black.main,
                display: !showMore ? 'block' : '-webkit-box',
                WebkitLineClamp: isNotDesktop ? lineLimit : 'none',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'clip',
              }}
              id="content"
            >
              <StRichTextGridItem
                sx={{
                  '& div': {
                    ...(!showMore && { minHeight: '100%' }),
                  },
                  '& div > *': {
                    ...(!showMore && { minHeight: '100%' }),
                  },
                }}
              >
                <RichText field={props?.fields?.content} />
              </StRichTextGridItem>
            </Box>
            {showMore && isNotDesktop && (
              <UILink
                onClick={() => {
                  setLineLimit('none');
                  setShowMore(false);
                }}
              >
                {props?.fields?.readMoreLabel?.value}
              </UILink>
            )}
          </Box>
        </Box>
      </HCPCRGridContainerWithoutBackground>
    </HCPWrapper>
  );
};

export default HcpContent;
