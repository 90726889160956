import { NextImage, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Typography from '@mui/material/Typography';

import { StContentBox, StImageContentCardBox } from './ImageContentCard.style';
import { tilesListProps } from './ImageContentCard.type';

const ImageContentCard = (props: tilesListProps): JSX.Element => {
  if (!props) return <></>;
  const { icon, title, description } = props?.fields;
  const { onClickHandler = () => void 0, onKeyUp = () => void 0 } = props;
  return (
    <StImageContentCardBox
      bgcolor="white"
      data-testid="image-content-card"
      onClick={() => onClickHandler(props?.fields)}
      onKeyUp={(e: any) => {
        onKeyUp(e, props?.fields);
      }}
    >
      <div style={{ minWidth: '76px', minHeight: '75px' }}>
        <NextImage field={icon} data-testid="image-content-image" width={76} height={75} />
      </div>
      <StContentBox>
        <Typography
          data-testid="image-content-title"
          sx={{ fontSize: '1.375rem', lineHeight: '1.5rem', fontWeight: 600 }}
        >
          <Text field={title} />
        </Typography>
        <Typography data-testid="image-content-description" variant="subtitle1">
          <RichText field={description} />
        </Typography>
      </StContentBox>
    </StImageContentCardBox>
  );
};

export default ImageContentCard;
