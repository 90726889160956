import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { StDTPGridContainer, StDTPWrapper } from 'components/shared/Container/Container.styles';

const OutComePageContent: React.FC<any> = ({ hideRightPanel, renderingProps }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <StDTPWrapper>
      <StDTPGridContainer sx={{ marginBlock: '1rem' }}>
        <Grid container spacing={3}>
          {hideRightPanel ? (
            <Grid item xs={12} lg={12} sx={{ textAlign: 'center' }}>
              <Placeholder name="jss-main-left" rendering={renderingProps} />
            </Grid>
          ) : !isTablet ? (
            <>
              <Grid item xs={12} lg={8.7}>
                <Placeholder name="jss-main-left" rendering={renderingProps} />
              </Grid>
              <Grid item xs={12} lg={3.3}>
                <Placeholder name="jss-main-right" rendering={renderingProps} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} lg={8.7}>
                <Placeholder name="jss-main-right" rendering={renderingProps} />
              </Grid>
              <Grid item xs={12} lg={3.3}>
                <Placeholder name="jss-main-left" rendering={renderingProps} />
              </Grid>
            </>
          )}
        </Grid>
      </StDTPGridContainer>
    </StDTPWrapper>
  );
};

export default OutComePageContent;
