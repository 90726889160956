import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { IContactInfoHeader } from './ContactinfoHeader.types';
import RichTextWithStudyPnMergeTag from '../../shared/RichTextWithStudyPnMergeTag/RichTextWithStudyPnMergeTag';

const ContactInfoHeader = (props: IContactInfoHeader): JSX.Element => {
  const cdpState = useSelector((state: RootState) => state.cdpData);
  if (
    !cdpState?.isDnq &&
    !cdpState?.isNoSitesAvailable &&
    !cdpState?.isNoSitesConvenient &&
    !cdpState?.isNoSitesAvailableMaxCapacity &&
    !cdpState?.isQualified
  ) {
    return <></>;
  }
  if (!props?.fields) return <></>;
  const {
    dnqBlock,
    noSitesAvailableBlock,
    noSitesConvenientBlock,
    noSitesAvailableMaxCapBlock,
    qualifiedTextBlock,
  } = props?.fields;

  return (
    <Grid
      container
      flexDirection={'column'}
      sx={{
        padding: '1.5rem 2rem',
        background: (theme) => theme.palette.themeLightColor.main,
        borderRadius: '0.5rem',
        color: (theme) => theme.palette.themeColor.main,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '1.5rem',
      }}
    >
      {cdpState?.isDnq && (
        <RichTextWithStudyPnMergeTag
          inheritLinkColor={true}
          field={dnqBlock?.fields?.textBlock}
        ></RichTextWithStudyPnMergeTag>
      )}
      {cdpState?.isNoSitesAvailable && (
        <RichTextWithStudyPnMergeTag
          inheritLinkColor={true}
          field={noSitesAvailableBlock?.fields?.textBlock}
        ></RichTextWithStudyPnMergeTag>
      )}
      {cdpState?.isNoSitesConvenient && (
        <RichTextWithStudyPnMergeTag
          inheritLinkColor={true}
          field={noSitesConvenientBlock?.fields?.textBlock}
        ></RichTextWithStudyPnMergeTag>
      )}
      {cdpState?.isNoSitesAvailableMaxCapacity && (
        <RichTextWithStudyPnMergeTag
          inheritLinkColor={true}
          field={noSitesAvailableMaxCapBlock?.fields?.textBlock}
        ></RichTextWithStudyPnMergeTag>
      )}
      {cdpState?.isQualified && (
        <RichTextWithStudyPnMergeTag
          inheritLinkColor={true}
          field={qualifiedTextBlock?.fields?.textBlock}
        ></RichTextWithStudyPnMergeTag>
      )}
    </Grid>
  );
};

export default ContactInfoHeader;
