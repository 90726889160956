import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import LinkWithModal from '../shared/LinkWithModal';

const fontSpec = {
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: 'normal',
};

export const AppBarButton = styled(Button)(({ theme }) => ({
  ...fontSpec,
  textTransform: 'none',
  color: theme.palette.themeColor.main,
  margin: '0px',
  padding: '0px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.themeColor.main,
  },
}));

export const AppBarProfileButton = styled(Button)(({ theme }) => ({
  ...fontSpec,
  textTransform: 'none',
  color: theme.palette.themeColor.main,
  margin: '0px',
  padding: '0px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.themeColor.main,
  },
  '& .MuiAvatar-root': {
    color: theme.palette.themeColor.light,
    backgroundColor: theme.palette.themeColor.main,
  },
}));

export const AppBarLink = styled(LinkWithModal)(({ theme }) => ({
  ...fontSpec,
  textDecoration: 'none',
  color: theme.palette.themeColor.main,
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.themeColor.main,
  },
}));

export const AppBarProfileLink = styled(Button)(({ theme }) => ({
  ...fontSpec,
  textTransform: 'inherit',
  textDecoration: 'none',
  color: theme.palette.neutral8.main,
  padding: 0,
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const AppBarMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '10px 12px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.neutral8.main,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.neutral8.main,
      borderBottom: 'none',
    },
  },
}));

export const DrawerListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.themeColor.main,
  paddingLeft: '24px',
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.themeColor.main,
    backgroundColor: theme.palette.secondary.dark,
  },
  '&.child-link-list-item': {
    paddingLeft: '40px',
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.themeColor.main,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.themeColor.main,
      borderBottom: 'none',
    },
  },
}));
