import { RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

const replaceValue = (originaValue: string, lookupObj: Record<string, string>): string => {
  const tags = Object.keys(lookupObj);
  let replacedValue = originaValue;

  //Harcoding in UI lastName for 'DO', 'MD', 'PhD' and firstName for other prefix
  if (lookupObj?.suffix === 'DO' || lookupObj?.suffix === 'MD' || lookupObj?.suffix === 'PhD') {
    replacedValue = replacedValue.replace('[firstName]', '[lastName]');
  } else {
    replacedValue = replacedValue.replace('[lastName]', '[firstName]');
  }

  tags.forEach((tag) => {
    const tagValue = lookupObj[tag] || '';
    replacedValue = replacedValue.replace(new RegExp(`\\[${tag}\\]`, 'g'), tagValue as string);
  });

  return replacedValue;
};

export const ConnectUsMergeTag: React.FC<{ field: RichTextField; className?: string }> = (
  props
) => {
  const hcpReduxData = useSelector((state: RootState) => state.hcpRegistrationData);
  const lookupObj = {
    suffix: hcpReduxData?.hcpData?.suffix || '',
    lastName: hcpReduxData?.hcpData?.lastName || '',
    firstName: hcpReduxData?.hcpData?.firstName,
  };
  const newValue = replaceValue(String(props.field.value), lookupObj);
  return (
    <RichText
      field={{ value: newValue, editable: props.field.editable }}
      className={props.className}
    />
  );
};
