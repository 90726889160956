import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
export const StTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.black.main,
  fontFamily: 'Proxima Nova',
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: '39px',
  marginBottom: '14px',
  [theme.breakpoints.between(500, 'lg')]: {
    fontSize: '26px',
    lineHeight: '33px',
  },
  [theme.breakpoints.between('xs', 500)]: {
    fontSize: '18px',
    lineHeight: '27px',
  },
}));

export const StRichTextGridItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<{ isMobile: boolean; isTablet: boolean }>(({ theme, isMobile, isTablet }) => ({
  color: theme.palette.neutral8.main,
  div: {
    h4: {
      fontSize: '1.5rem',
      fontFamily: 'Proxima Nova',
      lineHeight: '2.25rem',
      fontWeight: 500,
      margin: isTablet || isMobile ? '0 0 0.5rem 0' : '0 0 3rem 0',
      padding: 0,
      [theme.breakpoints.between(500, 'lg')]: {
        fontSize: '22px',
        lineHeight: '33px',
      },
      [theme.breakpoints.between('xs', 500)]: {
        fontSize: '1rem',
        lineHeight: '24px',
      },
    },
    h5: {
      fontSize: '1.5rem',
      fontFamily: 'Proxima Nova',
      lineHeight: '2.25rem',
      fontWeight: 600,
      margin: '0 0 0.5rem 0',
      padding: 0,
      [theme.breakpoints.between(500, 'lg')]: {
        fontSize: '22px',
        lineHeight: '2.25rem',
      },
      [theme.breakpoints.between('xs', 500)]: {
        fontSize: '18px',
        lineHeight: '27px',
      },
    },
    h6: {
      fontSize: '1.25rem',
      fontFamily: 'Proxima Nova',
      lineHeight: '2.25rem',
      fontWeight: 500,
      color: theme.palette.neutral8.main,
      margin: '1rem 0 2rem 0',
      padding: 0,
      [theme.breakpoints.between(500, 'lg')]: {
        fontSize: '18px',
        textAlign: 'flex-start',
      },
      [theme.breakpoints.between('xs', 500)]: {
        fontSize: '18px',
        lineHeight: '27px',
        textAlign: 'center',
      },
    },
    ul: {
      margin: 0,
      paddingInline: '1.5rem',
      li: {
        fontSize: '1.25rem',
        fontFamily: 'Proxima Nova',
        lineHeight: '2.25rem',
        fontWeight: 500,
        color: theme.palette.neutral8.main,
        margin: 0,
        padding: 0,
        [theme.breakpoints.between(500, 'lg')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.between('xs', 500)]: {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      },
    },
  },
}));

export const StBannerWrapper = styled(Grid)(({}) => ({
  position: 'relative',
  minHeight: '542px',
  overflow: 'hidden',
}));

export const StBannerContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  backgroundSize: 'cover',
  minHeight: '475px',
  paddingBlock: '60px',
  paddingLeft: '6.5rem',
  paddingRight: '5.5rem',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    marginInline: 'auto',
  },
  [theme.breakpoints.down(1150)]: {
    paddingLeft: '4rem',
    paddingRight: '3rem',
  },
}));

export const StBannerContent = styled(Grid)(({ theme }) => ({
  marginBottom: '10px',
  zIndex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  color: theme.palette.white.main,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '480px',
    marginRight: '30px',
  },
}));

export const StBoxWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<{ isMobile: boolean; isTablet: boolean }>(({ theme, isMobile, isTablet }) => ({
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    maxWidth: isTablet ? '100%' : '700px',
    height: isTablet || isMobile ? '90%' : '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: isMobile ? '2rem' : isTablet ? '2.5rem 4.2rem 1rem 4.2rem' : '4rem 6rem 3rem 6rem',
  },
}));

export const StEllipseBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<{ isMobile: boolean; isTablet: boolean }>(({ isMobile, isTablet, theme }) => ({
  zIndex: isMobile || isTablet ? 1 : -1,
  background: '#ffffffe6',
  minWidth: isMobile || isTablet ? '200%' : '1100px',
  height: isMobile || isTablet ? '160%' : '180%',
  left: isMobile || isTablet ? '-50%' : '-450px',
  top: isMobile || isTablet ? '-70%' : '-50%',
  borderRadius: '50%',
  position: 'absolute',
  opacity: '0.9',
  [theme.breakpoints.up(1500)]: {
    minWidth: '350%',
    height: '200%',
    left: '-200%',
  },
}));
