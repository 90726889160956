import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  HCPCRGridContainerWithoutBackground,
  HCPWrapper,
} from '../HcpTrainingAccess/HcpTrainingAccess.style';
import { StContainer, StTrainingCard } from './HcpTrainingModules.style';

const HcpTrainingModules = (props: any) => {
  const { heading, content, listofItems } = props?.fields || {};

  return (
    <HCPWrapper id="hcp-wrapper">
      <HCPCRGridContainerWithoutBackground item container>
        <Grid item container>
          <Typography variant="h4">
            <Text field={heading} />
          </Typography>
          <div
            style={{ fontSize: '16px', lineHeight: '24px', color: '#000', marginBottom: '0.5rem' }}
          >
            {' '}
            <RichText field={content} />
          </div>
        </Grid>{' '}
        <Grid
          item
          container
          sx={{
            '& div .last': {
              marginRight: 0,
            },
          }}
        >
          {listofItems?.map((item: any, index: number) => (
            <Grid item xs={12} md={12} lg={3} key={index}>
              <StTrainingCard item className={(index + 1) % 4 === 0 ? 'last' : ''}>
                <Typography
                  variant="h5"
                  sx={{ marginBottom: '0.5rem', color: '#000', fontSize: '1rem', fontWeight: 600 }}
                >
                  <Text field={item?.fields?.heading} />
                </Typography>
                <StContainer isSummary={true}>
                  <RichText field={item?.fields?.Summary} />
                </StContainer>
                <StContainer>
                  <RichText field={item?.fields?.content} />
                </StContainer>
              </StTrainingCard>
            </Grid>
          ))}
        </Grid>
      </HCPCRGridContainerWithoutBackground>
    </HCPWrapper>
  );
};

export default HcpTrainingModules;
