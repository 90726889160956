import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
export const MyProfileBannerContainer = styled(Box)(() => ({
  background:
    'linear-gradient(283deg, rgba(0, 85, 135, 0.80) -13.55%, rgba(0, 142, 159, 0.80) 8.06%, rgba(0, 191, 179, 0.00) 60.54%)',
  padding: '62px',
  paddingTop: '71px',
}));

export const AlertInfoBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  position: 'fixed',
  top: '89px',
  left: 0,
  width: '100%',
  zIndex: 5,
}));

export const MyProfileBannerInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  '& .MuiTypography-body1': {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '45px',
    color: '#444',
  },
  '& .MuiTypography-body2': {
    color: '#2F2E41',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  '& .MuiButton-contained': {
    minWidth: 'unset',
    padding: '5px 24px',
  },
  '& .MuiButton-outlined': {
    minWidth: 'unset',
    padding: '5px 24px',
  },
}));

export const IconBannerInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '164px',
  height: '117px',
  padding: '8px 16px',
  border: '1px solid #D9D9D9',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  gap: theme.spacing(1),
  boxShadow: '0 2px  8px 0 rgba(0, 0, 0, 0.08)',
  cursor: 'pointer',
}));

export const MyProfileBoxWithoutBackground = styled(Grid)(({ theme }) => ({
  maxWidth: '1366px',
  width: '100%',
  marginInline: 'auto',
  paddingInline: '6rem',
  [theme.breakpoints.down('xl')]: {
    marginInline: 0,
    paddingInline: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    paddingInline: 0,
  },
}));
