import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: 'normal',
  fontStyle: 'normal',
  marginBottom: '30px',
  color: theme.palette.themeColor.main,
}));

export const StSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova SemiBold',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '1.5rem',
  fontStyle: 'normal',
  color: theme.palette.neutral8.main,
}));

export const StDescription = styled(Box)(({ theme }) => ({
  fontFamily: 'Proxima Nova Regular',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  fontStyle: 'normal',
  color: theme.palette.neutral8.main,
  p: {
    marginTop: 0,
  },
  div: {
    marginTop: 0,
  },
}));

export const StLink = styled(Box)(({ theme }) => ({
  fontFamily: 'Proxima Nova Regular',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '22px',
  fontStyle: 'normal',
  color: theme.palette.themeColor.main,
  cursor: 'pointer',
}));
