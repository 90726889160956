import { styled } from '@mui/material/styles';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';

export const StChip = styled(Chip)(({ theme }) => ({
  background: `${theme.palette.neutral0.main} !important`,
  fontSize: '1rem',
  border: `1px solid ${theme.palette.neutral4.main}`,
  '& .MuiChip-deleteIcon': {
    color: `${theme.palette.primary.main} !important`,
  },
  '& .MuiChip-avatar': {
    marginLeft: '8px',
    marginRight: '-8px',
    width: '24px',
    height: '24px',
    borderRadius: 0,
    padding: '2px',
  },
}));

export const StFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontFamily: `Proxima Nova`,
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
}));
