import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StImageContentCardBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: '15px 20px',
  flexDirection: 'row',
  display: 'flex',
  columnGap: '20px',
  minHeight: '110px',
  backgroundColor: theme.palette.white.main,
  borderRadius: '0.6rem',
  boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
}));

export const StContentBox = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));
