import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientDetails } from '../../../features/registrationSlice';
import { RootState } from '../../../store/store';
import { RegWorkflowContainer } from '../RegistrationWorkflow.style';
import RegWorkflowFactory from '../RegWorkflowFactory';
import PatientEnquiryQuestion from './PatientEnquiryQuestion';
import { getQuery } from '../../../lib/utils/common';

const PatientEnquiry = (props: any): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const storedAnswers = useSelector((state: RootState) => state.registrationDetails.patientDetails);

  const [enableNext, setEnableNext] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({ patientDetails: false });

  const getCheckedCount = () => {
    let count = 0;
    storedAnswers.map((ans: any) => {
      if (ans.checked) {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    if (props?.fields?.isRequired.value && getCheckedCount() < 1) {
      setEnableNext(false);
    } else {
      setEnableNext(true);
    }
  }, [storedAnswers]);

  const validateInputs = () => {
    let isValid = false;
    if (props?.fields?.isRequired.value && getCheckedCount() < 1) {
      isValid = false;
      setErrors({ ...errors, patienDetails: true });
    } else {
      isValid = true;
    }

    return isValid;
  };
  const handleChange = (event: any, name: any, index: number) => {
    const obj = {
      name,
      checked: event.target.checked,
      index,
    };
    dispatch(setPatientDetails(obj));
  };

  const handleClick = (event: any, url: any, action: any) => {
    event.preventDefault();
    if (action === 'back') {
      if (url != 'http://') {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      } else {
        history.back();
      }
    } else {
      const isValid = validateInputs();
      if (isValid) {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      }
    }
  };
  if (!props?.fields) return <></>;

  return (
    <RegWorkflowContainer>
      <RegWorkflowFactory
        title={props?.fields?.heading}
        subTitle={props?.fields?.content}
        currentPage={2}
        totalCount={5}
        buttonsItems={props?.fields?.ctaLinks}
        handleClick={handleClick}
        enableNext={enableNext}
      >
        <PatientEnquiryQuestion
          {...props.fields}
          handleChange={handleChange}
          errors={errors}
          storedAnswers={storedAnswers}
        />
      </RegWorkflowFactory>
    </RegWorkflowContainer>
  );
};

export default PatientEnquiry;
