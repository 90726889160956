import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getSearchBox } from '../../lib/utils/coveo/searchBoxes';
import { RootState } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { setZipCode } from '../../../src/features/coveoSlice';
import SearchFormInput from './SearchFormInput';
import { HeroAndSearchType } from './HeroAndSearch.types';
import {
  StSearchBox,
  StSearchBoxWrapper,
  StSearchButton,
  InputTextField,
} from './HeroAndSearch.styles';

const fallbackField = '';

const SearchForm = (props: HeroAndSearchType): JSX.Element => {
  const isHCP = props?.isHCP;
  const dispatch = useDispatch();
  const zipValue = useSelector((state: RootState) => state.coveoResult.zipCode);
  const userProfile = useSelector((state: RootState) => state.crProfile);
  const profileDetails = userProfile?.profileData?.basicDetails;
  const [localZipcode, setLocalZipcode] = useState<string>('');

  const searchBox = useMemo(
    () =>
      getSearchBox(
        props?.fields?.totalSuggestion?.value || 20,
        props?.fields?.searchlink?.value?.href || '/find-trials/search-result'
      ),
    []
  );

  const coiRequiredErrorMessage = props.fields?.coiRequiredErrorMessage?.value || fallbackField;
  const isCOIRequired = props.fields?.isCOIRequired?.value || fallbackField;
  const isZipcodeRequired = props.fields?.isZipcodeRequired?.value || fallbackField;
  const zipcodeRegex = props.fields?.zipcodeRegex?.value || fallbackField;
  const zipcodeRegexFailedErrorMessage =
    props.fields?.zipcodeRegexFailedErrorMessage?.value || fallbackField;
  const zipcodeRequiredErrorMessage =
    props.fields?.zipcodeRequiredErrorMessage?.value || fallbackField;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { 'hero-and-search-coi': '', 'hero-and-search-zipcode': '' },
    mode: 'all',
  });
  const onZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 5) return;
    dispatch(setZipCode(e.target.value));
    setLocalZipcode(e.target.value);
    setValue('hero-and-search-zipcode', e.target.value);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      searchBox.subscribe(() => {
        const { redirectTo, value, analytics } = searchBox.state;

        if (redirectTo) {
          const data = JSON.stringify({ value, analytics });
          localStorage.setItem('coveo_standalone_search_box_data', data);
          searchBox.afterRedirection();
          window.location.href = redirectTo;
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   dispatch(setZipCode(''));
  // }, [dispatch]);

  useEffect(() => {
    if (profileDetails?.zipCode && !zipValue) {
      localStorage.setItem('zipCode', profileDetails.zipCode);
    } else {
      localStorage.setItem('zipCode', zipValue);
    }
  }, [profileDetails?.zipCode, zipValue]);

  const coveoSearchSubmit = () => {
    if (errors['hero-and-search-coi'] || errors['hero-and-search-zipcode']) {
      return;
    }
    searchBox.submit();
  };

  return (
    <StSearchBoxWrapper
      sx={{ justifyContent: isHCP ? 'flex-start' : 'flex-end' }}
      item
      xs={12}
      sm={12}
      md={isHCP ? 12 : 6}
      lg={isHCP ? 12 : 6}
    >
      <StSearchBox>
        <form>
          <Controller
            name="hero-and-search-coi"
            control={control}
            defaultValue={''}
            rules={{
              required: {
                value: isCOIRequired,
                message: coiRequiredErrorMessage,
              },
            }}
            render={({ field }) => (
              <>
                <SearchFormInput
                  {...field}
                  searchBox={searchBox}
                  fieldID="hero-and-search-coi"
                  placeholder={props?.fields?.conditionOfInterestPlaceholder?.value}
                  coiInputTextLimit={props?.fields?.coiInputTextLimit?.value || 50}
                  lengthToSuggestion={props?.fields?.lengthToSuggestion?.value || 3}
                  errors={errors}
                  setValue={setValue}
                />
              </>
            )}
          />
          <Controller
            name="hero-and-search-zipcode"
            control={control}
            rules={{
              required: {
                value: isZipcodeRequired,
                message: zipcodeRequiredErrorMessage,
              },
              pattern: {
                value: new RegExp(zipcodeRegex),
                message: zipcodeRegexFailedErrorMessage,
              },
            }}
            render={({ field }) => (
              <InputTextField
                {...field}
                id="hero-and-search-zipcode"
                size="small"
                placeholder={props?.fields?.zipCodePlaceholder?.value}
                value={localZipcode}
                inputProps={{
                  'aria-label': 'zipcode',
                  'aria-required': isZipcodeRequired || false,
                }}
                onChange={onZipCodeChange}
                error={Boolean(errors?.['hero-and-search-zipcode'])}
                helperText={String((errors && errors?.['hero-and-search-zipcode']?.message) || '')}
              />
            )}
          />
          <StSearchButton
            variant={props?.fields?.buttonVariant?.value}
            field={props?.fields?.link}
            onClick={handleSubmit(coveoSearchSubmit)}
            role="button"
          ></StSearchButton>
        </form>
      </StSearchBox>
    </StSearchBoxWrapper>
  );
};

export default SearchForm;
