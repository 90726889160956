/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { post } from '../../lib/utils/axiosFetcher';
import { UIButton } from '../shared/UIButton.styles';
import GeneralModal from '../shared/CustomModal/GeneralModal';
import {
  HumanApiStatus,
  IUpdateHumanApi,
  IUpdateHumanApiOptional,
} from '../../types/HumanAPITypes';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgressWithHelpText from '../shared/CircularProgressWithHelpText/CircularProgressWithHelpText';
import getReferralId from '../../lib/utils/getReferralId';
import { Field, Text, isEditorActive, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import getBaseURL from '../../lib/utils/getBaseURL';
import { getEnrollmentId } from '../../lib/utils/enrollmentUtil';

interface IHumanConnectProps {
  buttonText?: Field<string>;
  sideCode?: string;
  modelHeadline: string;
  errorMessage?: string;
  serviceUnavailableHeadline: string;
  serviceUnavailableMessage?: string;
  closeButtonText?: string;
  waitMessage?: string;
  maxAttempts?: number;
  emailAddress?: string;
  potentialId?: string | number;
}

const fallback = {
  buttonText: { value: 'Share Your Data' },
  sideCode: '',
  modelHeadline: 'Share Your Data',
  serviceUnavailableHeadline: 'Share Your Data Unavailable',
  waitMessage: 'Please Wait',
  maxAttempts: 5,
  errorMessage: 'We are currently unable to connect',
  serviceUnavailableMessage: 'Sorry, service unavailable.',
  closeButtonText: 'Close',
};

const setHapiAttempts = (value: number): void => {
  sessionStorage.setItem('hapiAttempts', String(value));
};
const getHapiAttempts = (): number => {
  try {
    const hapiAttempts = sessionStorage.getItem('hapiAttempts');
    if (!hapiAttempts) {
      setHapiAttempts(0);
    }
    const count = hapiAttempts ? parseInt(hapiAttempts) : 0;
    return count;
  } catch (error) {
    return 0;
  }
};

const HumanConnect: React.FC<IHumanConnectProps> = (props) => {
  const DEFAULT_MAX_ATTEMPT = props.maxAttempts || fallback.maxAttempts;
  const buttonText = props.buttonText || fallback.buttonText;
  const modelHeadline = props.modelHeadline || fallback.modelHeadline;
  const errorMessage = props.errorMessage || fallback.errorMessage;
  const serviceUnavailableHeadline =
    props.serviceUnavailableHeadline || fallback.serviceUnavailableHeadline;
  const serviceUnavailableMessage =
    props.serviceUnavailableMessage || fallback.serviceUnavailableMessage;
  const closeButtonText = props.closeButtonText || fallback.closeButtonText;
  const waitMessage = props.waitMessage || fallback.waitMessage;
  const emailAddress = props.emailAddress || '';
  const potentialId = props.potentialId || '';
  const refSessionToken = useRef('');
  const refHumanId = useRef('');

  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMaxRetry, setErrorModalMaxRetry] = useState(false);
  const { sitecoreContext } = useSitecoreContext() as any;
  const siteSettings: any = sitecoreContext?.siteSettings;
  const tenantKey = siteSettings?.tenantKey?.value || '';

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const updateHumanAPI = async (optionalProperties: IUpdateHumanApiOptional) => {
    try {
      const reqBody: IUpdateHumanApi = {
        potentialId: potentialId,
        baseUrl: getBaseURL(),
        enrollmentId:
          (sessionStorage.getItem('enrollmentID_for_human_api') as string) ||
          (getEnrollmentId() as string),
        tenantKey: tenantKey,
        humanApiEnabled: true,
        recordLastModifiedDate: new Date().toISOString(),
        ...optionalProperties,
      };
      await post('/HumanApi/UpdateHumanApiResponse', reqBody);
    } catch (_error) {}
  };

  const modalStyles: React.CSSProperties = {
    top: '25%',
  };

  const openHumanConnect = (sessionToken: string) => {
    // @ts-ignore
    if (window.HumanConnect) {
      // @ts-ignore
      window.HumanConnect.open({
        token: sessionToken,
        onClose: function () {
          return 'closed';
        },
      });
    } else {
      const script = document.createElement('script');
      script.src = 'https://cdn.humanapi.co/humanapi-connect-client@2.4.3.js';
      script.async = true;

      script.onload = () => {
        // @ts-ignore
        window.HumanConnect.open({
          token: sessionToken,
          onClose: function () {
            return 'closed';
          },
        });
      };

      script.onerror = (error) => {
        console.log('Error loading HumanConnect script:', error);
      };

      document.head.appendChild(script);
    }
  };

  const makeAxiosCall = async (): Promise<{
    sessionToken: string;
    humanId: string;
  }> => {
    try {
      const sitecode = props.sideCode || '';
      const referralId = getReferralId(
        sessionStorage.getItem('enrollmentID_for_human_api') as string,
        sitecode
      );
      const requestData = {
        client_user_email: emailAddress,
        client_user_id: referralId,
        type: 'session',
        retryCount: 3,
      };
      setErrorModalOpen(false);
      const response = await axios.post('/api/humanapi', requestData);
      const sessionToken = response?.data?.session_token || '';
      const humanId = response?.data?.human_id || '';
      return { sessionToken, humanId };
    } catch (error: any) {
      setErrorModalOpen(true);
      // Log Error
      const errorMessage = error?.response?.data?.error || '';
      await updateHumanAPI({
        humanApiStatus: HumanApiStatus.BUTTON_CLICKED,
        exception: errorMessage,
      });
      return { sessionToken: '', humanId: '' };
    }
  };

  const handleStartProcess = async () => {
    if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
      return;
    }
    const hapiAttempts = getHapiAttempts();
    const isMaxReached = DEFAULT_MAX_ATTEMPT <= hapiAttempts;
    if (!isMaxReached) {
      setHapiAttempts(getHapiAttempts() + 1);
      setLoadingModalOpen(true);
      const { sessionToken, humanId } = await makeAxiosCall();
      setLoadingModalOpen(false);
      refSessionToken.current = sessionToken;
      refHumanId.current = humanId;
      try {
        updateHumanAPI({
          humanApiStatus: HumanApiStatus.HUMAN_ID_RECEIVED,
          humanId,
        });
      } catch (_error) {}
      if (sessionToken) {
        setTimeout(() => {
          openHumanConnect(sessionToken);
        }, 1000);
      }
    } else {
      // Show Max Retry Reached Modal and disable button
      setHapiAttempts(getHapiAttempts() + 1);
      setIsButtonDisabled(true);
      setErrorModalMaxRetry(true);
    }
  };

  const LoadingModal = () => {
    return (
      <GeneralModal open={loadingModalOpen} heading={modelHeadline} style={modalStyles}>
        <Box mt={'2rem'}>
          <CircularProgressWithHelpText text={waitMessage} />
        </Box>
      </GeneralModal>
    );
  };
  const ErrorModal = () => {
    return (
      <GeneralModal
        open={errorModalOpen}
        heading={modelHeadline}
        style={modalStyles}
        showActionButton
        onActionButtonClick={() => setErrorModalOpen(false)}
        actionButtonText={closeButtonText}
      >
        <Box mt={'1rem'}>
          <Typography variant="body">{errorMessage}</Typography>
        </Box>
      </GeneralModal>
    );
  };

  const MaxRetryErrorModal = () => {
    return (
      <GeneralModal
        open={errorModalMaxRetry}
        heading={serviceUnavailableHeadline}
        style={modalStyles}
        showActionButton
        onActionButtonClick={() => setErrorModalMaxRetry(false)}
        actionButtonText={closeButtonText}
      >
        <Box mt={'1rem'}>
          <Typography variant="body">{serviceUnavailableMessage}</Typography>
        </Box>
      </GeneralModal>
    );
  };

  useEffect(() => {
    const hapiAttempts = getHapiAttempts();
    if (!hapiAttempts) {
      try {
        updateHumanAPI({ humanApiStatus: HumanApiStatus.BUTTON_NOT_CLICKED });
      } catch (_error) {}
    }
  }, []);

  return (
    <>
      <LoadingModal />
      <ErrorModal />
      <MaxRetryErrorModal />
      <UIButton
        className="btn"
        variant="contained"
        sx={{ padding: '5px', width: '203px', textAlign: 'center', alignSelf: 'center' }}
        onClick={handleStartProcess}
        disabled={isButtonDisabled}
      >
        <Text field={buttonText} />
      </UIButton>
    </>
  );
};

export default HumanConnect;
