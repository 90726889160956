import {
  Text,
  RichText,
  NextImage,
  useSitecoreContext,
  LinkField,
  Item,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import lowerCase from 'lodash/lowerCase';
import isEmpty from 'lodash/isEmpty';

import { ContentBox, StyledDivider, QualifyLink } from './HowToApply.style';
import { HowToApplyProps } from './HowToApply.types';
import ButtonWithIcon from '../../components/shared/ButtonWithIcon/ButtonWithIcon';

const HowToApply = (props: HowToApplyProps): JSX.Element => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  if (!props.fields) {
    return <></>;
  }
  const isScreenerPresent = !isEmpty(
    (sitecoreContext?.route?.fields?.relatedTaEprLink as LinkField)?.value?.href
  );
  const trialContacts =
    sitecoreContext?.route?.fields?.trialContacts &&
    (sitecoreContext?.route?.fields?.trialContacts as Item[]).filter(
      (item: any) => lowerCase(item?.fields?.contactType?.value) === 'primary'
    )[0];
  return (
    <ContentBox id={'howToApply'}>
      <Typography
        data-testid="title"
        sx={{
          fontSize: '1.5em',
          textAlign: 'center',
          fontWeight: '600',
          color: theme.palette.white.main,
        }}
      >
        <Text field={props?.fields?.heading} />
      </Typography>
      <StyledDivider />
      {isScreenerPresent && (
        <>
          <NextImage field={props?.fields?.applyIcon} />
          <Typography
            variant="body"
            className="screener-content"
            sx={{
              fontSize: '1em',
              textAlign: 'center',
              color: theme.palette.white.main,
              fontWeight: 400,
              marginBottom: '8px',
              marginTop: '8px',
            }}
          >
            <RichText field={props?.fields?.content} />
          </Typography>
          <QualifyLink
            className={'epr-link btn'}
            variant="outlined"
            field={sitecoreContext?.route?.fields?.relatedTaEprLink as LinkField}
          />
        </>
      )}
      {!isScreenerPresent && (
        <>
          <Typography
            variant="body"
            sx={{
              fontSize: '1em',
              textAlign: 'center',
              color: theme.palette.white.main,
              fontWeight: 400,
              marginBottom: '11px',
              marginTop: '8px',
            }}
          >
            <RichText field={props?.fields?.content1} />
          </Typography>
          <Stack
            sx={{
              [theme.breakpoints.up('md')]: {
                minWidth: '515px',
              },
              [theme.breakpoints.up('lg')]: {
                minWidth: 'auto',
              },
            }}
            direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'column' }}
            spacing={2}
          >
            {trialContacts && !isEmpty(trialContacts) && !isEmpty(trialContacts?.fields) && (
              <>
                {(trialContacts.fields?.phone as Field)?.value && (
                  <ButtonWithIcon
                    href={`tel:${(trialContacts.fields?.phone as Field)?.value}` || '/'}
                    image={props?.fields?.callIcon}
                    buttonText={`${props?.fields?.callText?.value} ${
                      (trialContacts?.fields?.phone as Field)?.value
                    }`}
                    imageId={'call-icon'}
                  />
                )}
                {(trialContacts.fields?.email as Field)?.value && (
                  <ButtonWithIcon
                    href={`mailto:${(trialContacts.fields?.email as Field)?.value}` || '/'}
                    image={props?.fields?.emailIcon}
                    buttonText={`${props?.fields?.emailText?.value}`}
                    imageId={'email-icon'}
                    verticalAlignment={'text-bottom'}
                  />
                )}
              </>
            )}
          </Stack>
        </>
      )}
    </ContentBox>
  );
};

export default HowToApply;
