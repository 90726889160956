import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import TextBoxWithLabel from 'components/shared/TextBox/TextBoxWithLabel';
import { StFormLabel } from '../RegistrationWorkflow.style';

const ContactQuestion = (props: any): JSX.Element => {
  const theme = useTheme();
  const DropdownTypes = {
    NUMBER: 'number',
    STRING: 'string',
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '1rem',
      }}
    >
      <FormControl fullWidth>
        <StFormLabel
          htmlFor="messageConsentText"
          sx={{
            '&.Mui-focused': {
              color: `${theme.palette.neutral8.main}`,
            },
          }}
        >
          <Text field={props.messageConsentLabel} />
        </StFormLabel>
        <Select
          labelId="messageConsentText"
          onChange={props?.handleDropdownChange}
          value={
            props?.storedAnswers?.textMessaging != '' ? props?.storedAnswers?.textMessaging : 'none'
          }
          MenuProps={MenuProps}
          input={
            <InputBase
              sx={{
                border: (theme: Theme) => `1px solid ${theme.palette.neutral4.main}`,
                padding: '10px 8px 8px 8px !important',
                background: theme.palette.white.main,
                borderRadius: '4px',
                marginTop: '2px',
                fontFamily: 'Proxima Nova',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: 400,
                height: '40px',
                '&:hover': {
                  border: `1px solid ${theme.palette.neutral6.main}`,
                },
                '&.Mui-focused': {
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                '& ::placeholder': {
                  color: `${theme.palette.neutral7.main} !important`,
                  fontFamily: 'Proxima Nova',
                  fontSize: '16px',
                  lineHeight: '16px',
                  opacity: 100,
                },
              }}
            />
          }
        >
          <MenuItem value="none" disabled>
            <Typography
              sx={{
                color: theme.palette.neutral7.main,
                fontFamily: 'Proxima Nova',
                fontSize: '16px',
                lineHeight: '16px',
                padding: '4px 0 5px',
              }}
            >
              <Text field={props?.messagePlaceholder} />
            </Typography>
          </MenuItem>
          {props?.messageOptions?.map((item: any, index: any) => (
            <MenuItem
              key={index}
              value={props?.type === DropdownTypes.NUMBER ? Number(index) : item?.fields.name.value}
              sx={{
                fontFamily: 'Proxima Nova',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: 400,
              }}
            >
              {item?.fields.name.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextBoxWithLabel
        label={props?.phoneNumberLabel}
        handleChange={props?.handleChange}
        placeholder={props?.phoneNumberPlaceholder.value}
        required={props?.isPhoneNumberRequired.value}
        errors={props?.errors?.phoneNumber}
        requiredMessage={props?.errorMessage}
        value={props?.storedAnswers?.phoneNumber != '' ? props.storedAnswers.phoneNumber : ''}
        name="phoneNumber"
      />
    </Box>
  );
};

export default ContactQuestion;
