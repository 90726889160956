import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
// import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StDTPGridContainer, StDTPWrapper } from 'components/shared/Container/Container.styles';
import { StNextImage } from 'components/shared/NextImage.styles';
import { ConfirmationBannerProps } from './ConfirmationBanner.type';
import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import SuccessPageMergeTagText from '../SuccessPageMergeTag/SuccessPageMergeTagText';

const ConfirmationBanner = (props: ConfirmationBannerProps): JSX.Element => {
  const theme = useTheme();
  if (!props?.fields) return <></>;
  let { fields } = props;
  // Donot show component is show is false
  const { show } = props;
  if (!show) {
    return <></>;
  }

  // show default value if fields are empty
  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }
  return (
    <StDTPWrapper>
      <StDTPGridContainer>
        <Alert
          icon={false}
          sx={{
            width: '100%',
            padding: 0,
            borderRadius: '8px',
            background: '#028602',
            '& .MuiAlert-message': {
              textAlign: 'center',
              width: 'inherit',
              paddingBlock: '15px',
            },
            marginBlock: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
            {/* <StNextIcon
              alt={props?.fields?.bannerIcon?.value?.alt as string}
              src={props?.fields?.bannerIcon?.value?.src as string}
              width={props?.fields?.bannerIcon?.value?.width as number}
              height={props?.fields?.bannerIcon?.value?.height as number}
              overrideIconColor={theme.palette.white.main}
            /> */}
            <StNextImage
              suppressHydrationWarning
              field={props?.fields?.bannerIcon}
              overrideIconColor={theme.palette.white.main}
            />
            <Box
              sx={{
                fontFamily: 'Proxima Nova Regular',
                fontSize: '1rem',
                fontWeight: 600,
                lineHeight: '1rem',
                color: theme.palette.white.main,
              }}
            >
              <SuccessPageMergeTagText
                mergeTagField={fields.bannerText}
                mergeTagObj={props.mergeTagData}
                inheritLinkColor={true}
              />
            </Box>
          </Box>
        </Alert>
      </StDTPGridContainer>
    </StDTPWrapper>
  );
};

export default ConfirmationBanner;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'ConfirmationBanner'
  );

  return {
    ...componentProps,
    mergeTagData: cdpData,
  };
};
