/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { UIIframe } from '../shared/UIIframe';
import { SurveyDetails } from './Survey.types';
import { GetSurveyPostData } from './SurveyData.service';
import { JOURNEY_STEP } from '../../constants';
import { getOrCreateEnrollmentId } from '../../lib/utils/enrollmentUtil';
import { useSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { JOURNEYS } from '../../constants/index';
import {
  StDTPGridContainer,
  StDTPWrapper,
} from '../../components/shared/Container/Container.styles';
import { updateCurrentJourneyInCDP } from '../../services/CdpService';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { getQueryParams } from '../../lib/utils/common';
import axios from 'axios';
import { ROOT_PATH_ID } from '../../lib/constant';
import { setConditionItems, setLastQuestionSubmitted } from '../../features/multiScreenerSlice';
import LoaderWithText from '../../components/shared/Loader/LoaderWithText';
import { hideLoader } from '../../features/loaderSlice';
import { useRouter } from 'next/router';
import moment from 'moment';
import { PatientType } from '../../lib/type/userJourney.types';

const PreScreenerQuestionnaire: React.FC<SurveyDetails> = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;
  const [isShowLoader, setIsShowLoader] = useState(false);
  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );

  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const myProfileData = useSelector((state: RootState) => state.crProfile.profileData);
  const searchQueryData = useSelector((state: RootState) => state?.queryParams);
  const cdpState = useSelector((state: RootState) => state.cdpData);
  const multiScreenerData = useSelector((state: RootState) => state?.multiScreener);
  const journeyType = useSelector((state: RootState) => state.cdpData.journeyType);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const isLastQuestionSubmitted: any =
    useSelector((state: RootState) => state?.multiScreener?.isLastQuestionSubmitted) || false;

  const { potentialParticipant_ID, potentialId } = potentialParticipantDetailsFromStore;
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language || 'en';
  // @ts-ignore
  const isHomePage = sitecoreContext?.route?.fields?.isHomePage?.value || false;
  // @ts-ignore
  const isPrescreenerPage = sitecoreContext?.route?.fields?.isPreScreenerPage?.value || false;
  const postDataFromScreener =
    (!isEmpty(multiScreenerData?.postDataFromScreener) &&
      multiScreenerData?.postDataFromScreener) ||
    [];
  const genericAnswerObj =
    (!isEmpty(postDataFromScreener) && postDataFromScreener[postDataFromScreener.length - 1]) || {};
  const isJourneyType3: boolean = journeyType === JOURNEYS.JOURNEY_03;

  const siteSettings: any = sitecoreContext?.siteSettings;
  const enableMultiConditionRouting = props?.fields?.enableMultiConditionRouting?.value || false;
  const loaderTextMessage = props?.fields?.loaderText?.value || '';
  const isScreenerLandingPageEnabled = siteSettings?.enableScreenerLandingPage?.value;

  //Updating CDP Journey Step
  const updateCurrentJourneyStepinCDP = async () => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: JOURNEY_STEP.SCREENER,
      journeyStatus: '',
      isActiveEnrollment: '1',
    };

    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const fetchConditionItems = async () => {
    try {
      const response = await axios.post('/api/conditionItemIds', {
        language,
        rootpath: ROOT_PATH_ID,
      });
      if (
        response.status === 200 &&
        !isEmpty(response?.data) &&
        !isEmpty((response as any)?.data?.ConditionItemIdsList) &&
        (response as any)?.data?.ConditionItemIdsList.length > 0
      )
        dispatch(setConditionItems((response as any)?.data?.ConditionItemIdsList));
    } catch (error) {}
  };

  const checkStudiesAttemptOrNot = () => {
    const attemptStudies = multiScreenerData?.attemptStudies || [];
    const attemptStudiesQuery = attemptStudies?.map((a: any) => a?.toLowerCase())?.join(',');
    const path = Array.isArray(query?.path) ? query?.path?.join(',') : query?.path;
    if (attemptStudiesQuery.includes(path)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isPrescreenerPage && potentialParticipantDetailsFromStore?.potentialParticipant_ID) {
      updateCurrentJourneyStepinCDP();
    }
  }, [potentialParticipantDetailsFromStore]);

  useEffect(() => {
    if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
      return;
    }
    fetchConditionItems();
  }, [isPrescreenerPage]);

  useEffect(() => {
    dispatch(hideLoader());
    dispatch(setLastQuestionSubmitted(false));
  }, []);

  if (!props?.fields) return <></>;
  if ((isHomePage && isJourneyType3) || isLoading) return null;
  if (isScreenerLandingPageEnabled && isHomePage) return <></>;

  // const postData = GetSurveyPostData('http://localhost:3000/');
  // Send baseurl to survey to set it as targetOrigin for survey
  const postData = GetSurveyPostData(props?.fields?.baseUrl?.value?.href);

  // const surveyLink = 'https://survey.alchemer.com/s3/7297073/Re-write-Dev-CVD-Studies-screener/123';
  let surveyLink = props?.fields?.surveyUrl?.value?.href;
  if (!isEmpty(genericAnswerObj?.genericAnswers)) {
    const urlParams = Object.keys(genericAnswerObj?.genericAnswers)
      .map((key) => key + '=' + genericAnswerObj?.genericAnswers?.[key])
      .join('&');
    if (urlParams && !checkStudiesAttemptOrNot())
      surveyLink = surveyLink + `${surveyLink.includes('?') ? '&' : '/?'}` + urlParams;
  }

  const { screenerResponseID, screenerCompleted } = cdpState;
  if (screenerResponseID && screenerCompleted === 'false') {
    surveyLink = surveyLink + `/?snc=${screenerResponseID}`;
  }

  if (siteSettings?.baseUrl?.value || props?.fields?.baseUrl?.value?.href) {
    const baseURLVal = siteSettings?.baseUrl?.value || props?.fields?.baseUrl?.value?.href;
    if (baseURLVal)
      surveyLink =
        surveyLink + `${surveyLink.includes('?') ? '&' : '/?'}` + 'baseURL=' + baseURLVal;
  }

  if (!isEmpty(searchQueryData)) {
    const queryData = pickBy(searchQueryData, (value) => !!value?.length);
    const urlParams = getQueryParams(queryData);
    if (urlParams) surveyLink = surveyLink + `${surveyLink.includes('?') ? '&' : '/?'}` + urlParams;
  }

  const prepopulateScreener = (
    dob: string | null,
    zipcode: string | null,
    gender: string | null
  ) => {
    let dobDate: string | null = null;
    let dobYear: number | null = null;
    let dobDay: number | null = null;
    let dobMonth: number | null = null;
    let zipcodeVal: string | null = null;
    let genderVal: string | null = null;

    if (dob) {
      const dobFields = dob.split('-');

      if (dobFields.length === 3) {
        dobDate = moment.parseZone(dob).format();
        dobYear = moment.parseZone(dobDate).year();
        dobDay = moment.parseZone(dobDate).date();
        dobMonth = moment.parseZone(dobDate).month() + 1;
      } else if (dobFields.length === 2) {
        dobDate = moment.parseZone(dob).format();
        dobYear = moment.parseZone(dobDate).year();
        dobMonth = moment.parseZone(dobDate).month() + 1;
        dobDay = null;
      } else {
        dobDate = moment.parseZone(dob).format();
        dobYear = moment.parseZone(dobDate).year();
        dobDay = null;
        dobMonth = null;
      }
    } else {
      dobYear = null;
      dobDay = null;
      dobMonth = null;
    }
    if (zipcode) {
      zipcodeVal = zipcode;
    } else {
      zipcodeVal = null;
    }

    if (gender) {
      genderVal = gender;
    } else {
      genderVal = null;
    }

    return {
      Dyval: dobYear,
      Ddval: dobDay,
      Dmval: dobMonth,
      Zval: zipcodeVal,
      Gval: genderVal,
    };
  };

  const getSurveryLinkWithPrepopulate = (prepopulateDetails: any) => {
    const newObj = {};
    Object.keys(prepopulateDetails).forEach((item: any) => {
      if (prepopulateDetails[item]) {
        newObj[item] = prepopulateDetails[item];
      }
    });
    let prepopulatedSurveryLink = '';

    Object.keys(newObj).forEach((item: any, index: number) => {
      if (
        genericAnswerObj &&
        genericAnswerObj?.genericAnswers &&
        (!Object.keys(genericAnswerObj?.genericAnswers).includes(item) ||
          (Object.keys(genericAnswerObj?.genericAnswers).includes(item) &&
            (genericAnswerObj?.genericAnswers[item] === null ||
              genericAnswerObj?.genericAnswers[item] === undefined)))
      ) {
        if (index < Object.keys(newObj).length) {
          prepopulatedSurveryLink += `${item}=${newObj[item]}&`;
        } else {
          prepopulatedSurveryLink += `${item}=${newObj[item]}`;
        }
      } else if (
        genericAnswerObj?.genericAnswers === null ||
        genericAnswerObj?.genericAnswers === undefined ||
        Object.keys(genericAnswerObj?.genericAnswers).length === 0
      ) {
        if (index < Object.keys(newObj).length) {
          prepopulatedSurveryLink += `${item}=${newObj[item]}&`;
        } else {
          prepopulatedSurveryLink += `${item}=${newObj[item]}`;
        }
      }
    });
    return prepopulatedSurveryLink;
  };

  const getUIIframeComponent = () => {
    if ((potentialParticipant_ID === '' || potentialId === '') && enableMultiConditionRouting) {
      return <></>;
    }

    let prepopulateDetails: any = {};

    if (isAuthenticated && myProfileData && !isJourneyType3) {
      prepopulateDetails = prepopulateScreener(
        myProfileData.basicDetails?.dob,
        myProfileData.basicDetails?.zipCode,
        myProfileData.basicDetails?.gender
      );
    } else if (cdpState?.patientType === PatientType.ADULT) {
      prepopulateDetails = prepopulateScreener(
        cdpState?.participantDateOfBirth,
        cdpState?.participantPostalCode,
        cdpState?.participantGender
      );
    }

    if (surveyLink) {
      surveyLink =
        surveyLink +
        `${surveyLink.includes('?') ? '&' : '/?'}` +
        getSurveryLinkWithPrepopulate(prepopulateDetails);
      // `Gval=${prepopulateDetails?.genderVal}&Zval=${prepopulateDetails.zipcodeVal}&Dyval=${prepopulateDetails.dobYear}&Dmval=${prepopulateDetails.dobMonth}&Ddval=${prepopulateDetails.dobDay}`;
    }
    const srcUrl = decodeURIComponent(`${surveyLink}`);
    return (
      <UIIframe
        id="pre-screener-questionnaire-iframe"
        title="Iframe to display prescreener questionnaire"
        testId="pre-screener-questionnaire-iframe"
        src={srcUrl}
        messageToPost={postData}
        nextPageUrl={props?.fields?.contactInfoUrl}
        enableMultiConditionRouting={enableMultiConditionRouting}
        setIsShowLoader={setIsShowLoader}
      />
    );
  };

  return (
    <StDTPWrapper id="pre-screener-questionnaire">
      <StDTPGridContainer>{getUIIframeComponent()}</StDTPGridContainer>
      {isLastQuestionSubmitted && isShowLoader && <LoaderWithText message={loaderTextMessage} />}
    </StDTPWrapper>
  );
};

export default PreScreenerQuestionnaire;
