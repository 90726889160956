import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { CRHeaderNavigationProps } from './CRHeaderNavigation.types';
import CRDesktopNav from './CRDesktopNav';
import CRMobileNav from './CRMobileNav';
import { TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import ProfileModal from 'components/MyProfile/Modal/ProfileModal';
import { SendEmailCategories } from '../../lib/type/common';
import { AppDispatch, RootState } from '../../store/store';
import { updateMyProfileModal } from '../../features/crProfileSlice';
import { useDispatch, useSelector } from 'react-redux';

const CRHeaderNavigation: React.FC<CRHeaderNavigationProps> = (props) => {
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const isMyProfileModalOpen: boolean = useSelector(
    (state: RootState) => state.crProfile.isMyProfileModalOpen
  );
  const asPath = router?.asPath || '';
  const theme = useTheme();
  const [profileModal, setProfileModal] = React.useState({
    open: false,
    profileFields: {},
    signOutOpen: false,
  });
  const myProfileFields = useMemo(() => {
    const findMyProfile = props?.fields?.navigationLinks?.find((item) =>
      item.fields?.hasOwnProperty('myProfile')
    );
    return findMyProfile;
  }, [props?.fields?.navigationLinks]);

  useEffect(() => {
    if (isMyProfileModalOpen) {
      setProfileModal({
        ...profileModal,
        open: isMyProfileModalOpen,
        profileFields: myProfileFields?.fields || {},
      });
    }
  }, [isMyProfileModalOpen]);

  useEffect(() => {
    if (!profileModal.open && isMyProfileModalOpen) {
      dispatch(updateMyProfileModal(false));
    }
  }, [profileModal.open]);

  if (!props?.fields) return <></>;
  return (
    <>
      <Box
        sx={{
          // display: { xs: 'none', lg: 'block' },
          display: 'flex',
          [theme.breakpoints.up(1366)]: {
            width: '1366px',
            marginInline: 'auto',
          },
          [theme.breakpoints.down(1025)]: {
            display: 'none',
          },
        }}
      >
        <CRDesktopNav
          headerLogo={props.fields?.headerLogo}
          navigationLinks={props.fields?.navigationLinks}
          headerLogoAriaLabel={props?.fields?.headerLogoAriaLabel as TextField}
          openMenuAriaLabel={props?.fields?.openMenuAriaLabel as TextField}
          closeButtonAriaLabel={props?.fields?.closeButtonAriaLabel as TextField}
          openProfileModal={setProfileModal}
          isAuthenticated={isAuthenticated}
        />
      </Box>
      <Box
        sx={{
          // display: { xs: 'block', lg: 'none' },
          display: 'block',
          boxShadow: 'unset',
          [theme.breakpoints.up(1025)]: {
            display: 'none',
          },
        }}
      >
        <CRMobileNav
          headerLogo={props.fields?.headerLogo}
          navigationLinks={props.fields?.navigationLinks}
          languageList={props?.fields?.languageList}
          headerLogoAriaLabel={props?.fields?.headerLogoAriaLabel as TextField}
          openMenuAriaLabel={props?.fields?.openMenuAriaLabel as TextField}
          closeButtonAriaLabel={props?.fields?.closeButtonAriaLabel as TextField}
          openProfileModal={setProfileModal}
          isAuthenticated={isAuthenticated}
        />
      </Box>
      <ProfileModal
        modal={{ open: profileModal.open, setProfileModal, signOutOpen: profileModal.signOutOpen }}
        fieldProps={profileModal.profileFields}
        emailType={SendEmailCategories.MANAGE_PROFILE}
        communityPageLink={asPath}
      />
    </>
  );
};

export default CRHeaderNavigation;
