import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useRouter } from 'next/router';

import { ComponentProps } from '../../lib/component-props';
import ImageContentCard from '../shared/ImageContentCard/ImageContentCard';
import {
  SupportContentContainer,
  SupportContentItem,
  SupportHeader,
  SupportTitle,
} from './Support.style';
import {
  ImageContentCardProps,
  tilesListProps,
} from '../shared/ImageContentCard/ImageContentCard.type';
import { SupportHeaderProps } from './Support.types';
import { getQuery } from '../../lib/utils/common';

type SupportProps = ComponentProps & {
  fields: {
    title: Field<string>;
    subTitle: Field<string>;
    tilesList: tilesListProps[];
  };
};

const Header: React.FC<SupportHeaderProps> = ({ title, subTitle }) => {
  const theme = useTheme();
  const themeColor = theme.palette.themeColor.main;
  const themeColorSecondary = theme.palette.themeColorSecondary.main;
  return (
    <div
      style={{
        background: `linear-gradient(to top, ${themeColor}, ${themeColorSecondary})`,
      }}
    >
      <SupportHeader id="cr-support">
        <SupportTitle>
          <Typography data-testid="support-title" variant="title">
            <Text field={title} />
          </Typography>
          <Typography data-testid="support-content" variant="subtitle1">
            <RichText field={subTitle} />
          </Typography>
        </SupportTitle>
      </SupportHeader>
    </div>
  );
};

const Support = (props: SupportProps): JSX.Element => {
  const router = useRouter();
  if (!props?.fields) return <></>;
  const { query } = router;
  const title = props.fields?.title;
  const subTitle = props.fields?.subTitle;
  const onClickHandler = (tile: ImageContentCardProps) =>
    router.push(
      {
        pathname: tile?.link?.value?.href,
        query: getQuery(query),
      },
      tile?.link?.value?.href
    );

  return (
    <Box>
      <>
        <Header title={title} subTitle={subTitle} />
      </>
      <SupportContentContainer container spacing={{ xs: 0, lg: 2 }} rowGap={{ xs: 3, md: 4 }}>
        {props?.fields?.tilesList &&
          props?.fields?.tilesList.map((item: tilesListProps, i) => (
            <SupportContentItem item xs={12} sm={12} lg={6} key={i}>
              <ImageContentCard
                fields={item?.fields}
                onClickHandler={onClickHandler}
                onKeyUp={(e, item) => {
                  if (e.key === 'Enter') {
                    onClickHandler(item);
                  }
                }}
              />
            </SupportContentItem>
          ))}
      </SupportContentContainer>
    </Box>
  );
};

export default Support;
