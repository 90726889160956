import React from 'react';
import CRModal from '.';
import Box from '@mui/material/Box';
import { SendFooterModal, ModalCTAButton, replaceValue } from './FooterModal';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

interface ModalProps {
  modal: {
    open: boolean;
    onClose: () => void;
    onResendEmail?: () => void;
    onCloseModals?: () => void;
  };
  fieldProps: {
    modalCTAButtons: ModalCTAButton[];
    modalTitle?: { value: string };
    modalDescription?: { value: string };
  };
  emailResendCount?: number;
  email: string;
}

const SendModal: React.FC<ModalProps> = ({
  modal: { open, onClose, onResendEmail, onCloseModals },
  fieldProps,
  email,
}) => {
  const { modalCTAButtons = [], modalTitle, modalDescription } = fieldProps;
  return (
    <>
      <CRModal open={open} handleClose={onClose} handleCancel={onClose} title={modalTitle?.value}>
        <Box sx={{ color: '#444444' }}>
          <RichText field={{ value: replaceValue(modalDescription?.value, { email: email }) }} />
          <SendFooterModal
            modalCTAButtons={modalCTAButtons}
            onClose={onClose}
            onResend={onResendEmail}
            onCloseModals={onCloseModals}
          />
        </Box>
      </CRModal>
    </>
  );
};

export default SendModal;
