import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Typography from '@mui/material/Typography';
import { StThreeStepItemVar3 } from './ThreeStepInformation.style';
import { IThreeStepItemProps } from './ThreeStepInformation.types';
import Box from '@mui/material/Box';
import ThreeStepImage from './ThreeStepImage';

const ThreeStepItemVar3: React.FC<IThreeStepItemProps> = (props) => {
  return (
    <StThreeStepItemVar3>
      <ThreeStepImage field={props.fields?.icon} />
      <Typography variant="h3">
        <Text field={props.fields?.stepNumber} />
      </Typography>
      <Box>
        <RichText field={props.fields?.description} />
      </Box>
    </StThreeStepItemVar3>
  );
};

export default ThreeStepItemVar3;
