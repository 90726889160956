/* eslint-disable @next/next/no-img-element */
import Typography from '@mui/material/Typography';
import { RichTextField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { IImageProps, studySiteInfoProps } from '../shared/Carousel/Carousel.types';
import SiteLocationRichText from '../SiteLocationRichText/SiteLocationRichText';
import { ContainerBox, MainModalContainerBox } from './MyProfileQuestionnaire.style';
import { StyledLink } from '../DTP/HeaderNavigation/CallCenter/CallCenter.style';

interface IViewSiteProps {
  cardTitle: string;
  mapsLink: string;
  siteAddressFormat: RichTextField;
  siteInfo?: studySiteInfoProps;
  icons?: {
    hospitalIcon: IImageProps;
    locationPinIcon: IImageProps;
    phoneIcon: IImageProps;
  };
}

const ViewSiteDetails = (props: IViewSiteProps): JSX.Element => {
  const onAddressClick = () => {
    if (props?.siteInfo?.siteLatitude && props?.siteInfo?.siteLongitude) {
      const url = `${props?.mapsLink}${props?.siteInfo?.siteLatitude},${props?.siteInfo?.siteLongitude}`;
      window?.open(url);
    }
  };

  return (
    <MainModalContainerBox>
      {props?.siteInfo?.siteName && (
        <ContainerBox>
          <img src={props?.icons?.hospitalIcon?.src} alt={props?.icons?.hospitalIcon?.alt} />
          <Typography variant="body2" sx={{ color: 'neutral7.main' }}>
            <Text field={{ value: props?.siteInfo?.siteName }} />
          </Typography>
        </ContainerBox>
      )}
      {(props?.siteInfo?.siteAddress ||
        props?.siteInfo?.siteCity ||
        props?.siteInfo?.siteCountry ||
        props?.siteInfo?.siteZipCode) && (
        <ContainerBox onClick={onAddressClick} sx={{ cursor: 'pointer' }}>
          <img
            src={props?.icons?.locationPinIcon?.src}
            alt={props?.icons?.locationPinIcon?.alt}
            height={20}
          />
          <SiteLocationRichText
            siteAddressFormat={{ value: props?.siteAddressFormat as string }}
            siteInfo={props?.siteInfo as any}
          />
        </ContainerBox>
      )}
      {props?.siteInfo?.sitePhone && (
        <ContainerBox>
          <StyledLink
            className={'site-info-link-btn'}
            href={`tel:${props?.siteInfo?.sitePhone}`}
            target="_blank"
            sx={{ gap: '8px' }}
          >
            <img src={props?.icons?.phoneIcon?.src} alt={props?.icons?.phoneIcon?.alt} />
            <Typography variant="body2">{props?.siteInfo?.sitePhone}</Typography>
          </StyledLink>
        </ContainerBox>
      )}
    </MainModalContainerBox>
  );
};

export default ViewSiteDetails;
