import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const HomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11.0305 6.37955C11.5533 5.90722 12.3027 5.87573 12.8558 6.28508L12.9711 6.37955L19.5 11.5C19.8122 11.8011 19.9541 12.3529 19.9911 12.8266L20.0001 13L19.9999 19.3376C20.0077 20.1953 19.4001 20.9064 18.6164 20.9916L18.4764 21H14.2854C13.8929 20.9954 13.5722 20.678 13.5286 20.272L13.5236 20.1688V15.5973C13.5272 15.1783 13.2371 14.8293 12.8582 14.7738L12.7618 14.7662H11.2382C10.8459 14.7712 10.5255 15.0883 10.4815 15.4942L10.4764 15.5973V20.1688C10.4804 20.588 10.1902 20.9373 9.81114 20.9925L9.71464 21H5.52365C4.72075 20.9901 4.06812 20.3261 4.005 19.4874L4.00007 19.3376L3.99986 13C4.01299 12.5522 4.12085 11.9817 4.39097 11.6237L4.5 11.5L11.0305 6.37955ZM10.1626 1.63785C11.1751 0.829907 12.6147 0.789509 13.6698 1.51666L13.8331 1.63785L21.5358 7.80267C22.0735 8.23839 22.1557 9.00893 21.7214 9.5418C21.5161 9.79589 21.2134 9.95871 20.882 9.99317C20.598 10.0227 20.3138 9.95577 20.077 9.80626L19.9628 9.7247L12.26 3.55988C12.1297 3.45542 11.9493 3.4405 11.8047 3.51511L11.7357 3.55988L4.03299 9.7247C3.77671 9.92901 3.44618 10.0248 3.11606 9.99036C2.78594 9.95594 2.48407 9.79425 2.27862 9.5418C1.87324 9.04446 1.91784 8.34007 2.36274 7.89411L2.46419 7.80267L10.1626 1.63785Z" />
    </SvgIcon>
  );
};

export default HomeIcon;
