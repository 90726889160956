import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';

export const StAppBarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSubHeader',
})<any>(({ theme, isSubHeader }) => ({
  height: isSubHeader ? '114px' : 'auto',
  header: {
    '.MuiGrid-container': {
      [theme.breakpoints.up('xl')]: {
        width: '1300px',
        margin: '0 auto !important',
      },
    },
  },
}));

type AppBarNavItemProps = {
  hoverColor?: string;
};

export const AppBarNavItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hoverColor',
})<AppBarNavItemProps>(({ theme, hoverColor }) => ({
  marginInline: '1rem',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  fontWeight: 580,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    borderBottom: `1px dashed ${hoverColor || theme.palette.themeColor.main}`,
  },
  [theme.breakpoints.down(1220)]: {
    marginInline: '0.8rem',
  },
  [theme.breakpoints.down(1150)]: {
    marginInline: '0.5rem',
  },
}));

export const MobileNavListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'hoverColor',
})<AppBarNavItemProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  columnGap: '  10px',
  '&:hover': {
    backgroundColor: '#f4f4f4',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
    lineHeight: '1rem',
  },
}));

export const MobileNavIconWrap = styled(Box)(() => {
  return {
    width: '16px',
    height: '16px',
  };
});
