import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const StThankYouItemWrapper = styled(Grid)(({ theme }) => {
  return {
    padding: '2rem 1rem',
    borderRadius: '8px',
    backgroundColor: '#E6F9F8',
    margin: 'auto',
    marginTop: '5rem',
    width: '502px',
    height: 'fit-content',
    textAlign: 'center',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '350px',
      maxWidth: '100%',
      padding: '24px 21px',
    },
  };
});

export default StThankYouItemWrapper;
