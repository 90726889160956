import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import PersonalizedTrialCardItem from '../../CR/PersonalizedTrialCardItem';
import TrialCardItem from '../../CR/TrialCardItem';
import {
  ArticleListType,
  CRQuestionaaireListType,
  CarouselItemType,
  FeaturedTrialItemType,
  PersonalizedFeaturedTrialItemType,
  SavedTrialsListItem,
  studyQuestionaireType,
} from './Carousel.types';
import ArticleCard from './CarouselCard/ArticleCard';
import CRQuestionnairCard from './CarouselCard/CRQuestionaireCard';
import TrialCard from './CarouselCard/TrialCard';

const CarouselItem = (props: CarouselItemType): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      {props?.callerComponent === 'Articles'
        ? props?.data &&
          props?.data.map((item: ArticleListType, itemIndex: number) => {
            return (
              <Grid key={itemIndex} item margin="0.5rem">
                <ArticleCard
                  data={item}
                  isMobile={props.isMobile}
                  isTablet={props.isTablet}
                  totalCount={props.totalCount}
                  slideNumber={props.slideNumber}
                  descriptionWordLimit={props.descriptionWordLimit}
                  titleCharacterLimit={props.titleCharacterLimit}
                  descriptionLineLimit={props.descriptionLineLimit}
                  titleLineLimit={props.titleLineLimit}
                />
              </Grid>
            );
          })
        : props?.callerComponent === 'CR-Questionaaire'
        ? props?.data &&
          props?.data?.map((item: studyQuestionaireType, itemIndex: number) => {
            return (
              <Grid key={itemIndex} item margin="0.5rem">
                <CRQuestionnairCard
                  data={item}
                  dataProps={props?.dataProps as CRQuestionaaireListType}
                  isMobile={props.isMobile}
                  isTablet={props.isTablet}
                  totalCount={props.totalCount}
                  slideNumber={props.slideNumber}
                  descriptionWordLimit={props.descriptionWordLimit}
                  titleCharacterLimit={props.titleCharacterLimit}
                  descriptionLineLimit={props.descriptionLineLimit}
                  titleLineLimit={props.titleLineLimit}
                />
              </Grid>
            );
          })
        : props?.callerComponent === 'saved-trials'
        ? props?.data &&
          props?.data?.map((item: SavedTrialsListItem, itemIndex: number) => {
            return (
              <Grid
                key={itemIndex}
                item
                margin="0.5rem"
                sx={{
                  [theme.breakpoints.between(1200, 1237)]: {
                    width: '323px',
                  },
                  [theme.breakpoints.between(1237, 1277)]: {
                    width: '343px',
                  },
                  [theme.breakpoints.between(1277, 1367)]: {
                    width: '363px',
                  },
                  width: '383px',
                  height: '100%',
                  margin: '0.4rem',
                }}
              >
                <TrialCardItem
                  data={item}
                  style={{
                    height: '100%',
                    margin: '0.4rem',
                  }}
                  isMobile={props.isMobile}
                  isTablet={props.isTablet}
                  totalCount={props.totalCount}
                  slideNumber={props.slideNumber}
                  descriptionWordLimit={props.descriptionWordLimit}
                  titleCharacterLimit={props.titleCharacterLimit}
                  descriptionLineLimit={props.descriptionLineLimit}
                  titleLineLimit={props.titleLineLimit}
                  modalProps={props?.modalProps}
                  state={props?.state}
                />
              </Grid>
            );
          })
        : props?.callerComponent === 'personalized-featured-trials'
        ? props?.data &&
          props?.data?.map((item: PersonalizedFeaturedTrialItemType, itemIndex: number) => {
            return (
              <Grid key={itemIndex} item margin="0.5rem">
                <PersonalizedTrialCardItem
                  data={item}
                  isMobile={props.isMobile}
                  isTablet={props.isTablet}
                  totalCount={props.totalCount}
                  itemIndex={itemIndex}
                  carouselDataIndex={props.slideNumber}
                  showCount={props?.showCount}
                  descriptionWordLimit={props.descriptionWordLimit}
                  titleCharacterLimit={props.titleCharacterLimit}
                  descriptionLineLimit={props.descriptionLineLimit}
                  titleLineLimit={props.titleLineLimit}
                  findOutMoreText={props.findOutMoreText}
                  seeIfYouQualifyText={props.seeIfYouQualifyText}
                />
              </Grid>
            );
          })
        : props?.data &&
          props?.data?.map((item: FeaturedTrialItemType, itemIndex: number) => {
            return (
              <Box key={itemIndex} margin="0.5rem">
                <TrialCard
                  data={item}
                  isMobile={props.isMobile}
                  isTablet={props.isTablet}
                  totalCount={props.totalCount}
                  itemIndex={itemIndex}
                  carouselDataIndex={props.slideNumber}
                  showCount={props?.showCount}
                  descriptionWordLimit={props.descriptionWordLimit}
                  titleCharacterLimit={props.titleCharacterLimit}
                  descriptionLineLimit={props.descriptionLineLimit}
                  titleLineLimit={props.titleLineLimit}
                  id={'featured-trial'}
                />
              </Box>
            );
          })}
    </>
  );
};

export default CarouselItem;
