import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import MuiTypography from '@mui/material/Typography';

export const StCardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: `"Proxima Nova", sans-serif`,
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 400,
  color: theme.palette.neutral11.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  textTransform: 'uppercase',
}));

type LimitProps = {
  limit: number;
};

export const StCardSubTitle = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'limit',
})<LimitProps>(({ theme, limit }) => ({
  fontFamily: `"Proxima Nova", sans-serif`,
  fontSize: '18px',
  lineHeight: '27px',
  fontWeight: 700,
  color: theme.palette.black.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: limit || 2,
  height: `${limit * 25}px`,
  WebkitBoxOrient: 'vertical',
}));

export const StTrialCardSubTitle = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'limit',
})<LimitProps>(({ theme, limit }) => ({
  fontFamily: `"Proxima Nova SemiBold", sans-serif`,
  fontSize: '18px',
  lineHeight: '27px',
  fontWeight: 500,
  color: theme.palette.black.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: limit || 2,
  height: `${limit * 25}px`,
  WebkitBoxOrient: 'vertical',
}));

export const StCardDesc = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'limit',
})<LimitProps>(({ theme, limit }) => ({
  fontFamily: `"Proxima Nova", sans-serif`,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.black.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: limit || 3,
  height: `${limit * 24}px`,
  WebkitBoxOrient: 'vertical',
}));

export const StCountContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.neutral8.main,
  opacity: 0.9,
  width: '55px',
  height: '32px',
  borderRadius: '20px',
  position: 'absolute',
  top: 10,
  right: 10,
}));

export const StCountText = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const StCRQuestionnairTagText = styled(Box)(({ theme }) => ({
  color: theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '20px',
  width: 'auto',
  maxWidth: '130px',
  borderRadius: '5px',
}));

export const StTagText = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontFamily: 'Proxima Nova',
  height: '13px',
  fontSize: '13px',
  lineHeight: '13px',
  fontWeight: 500,
}));

export const StTagTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontFamily: 'Proxima Nova',
  fontSize: '22px',
  lineHeight: '33px',
  fontWeight: 500,
}));

export const StTagSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 500,
}));

export const StQuestionairContainer = styled(Card)(({ theme }) => ({
  borderRadius: '0.6rem',
  boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
  padding: '24px 0px',
  height: '217px',
}));
