import React from 'react';
import dynamic from 'next/dynamic';
import { CAROUSEL_TYPE } from '../../constants';
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface IVideoPlayer {
  type: string;
  url: string;
  playing: boolean;
  handleOnPause: () => void;
  handleOnPlay: () => void;
}

const VideoPlayer: React.FC<IVideoPlayer> = ({
  url,
  type,
  handleOnPause,
  playing,
  handleOnPlay,
}) => {
  return (
    <ReactPlayer
      data-testid="react-player"
      playing={playing}
      controls={
        CAROUSEL_TYPE.VIMEO === type || CAROUSEL_TYPE.YOUTUBE === type || playing ? true : false
      }
      url={url}
      width="100%"
      height="665px"
      onPause={handleOnPause}
      onPlay={handleOnPlay}
      style={{
        backgroundColor: 'black',
      }}
    />
  );
};

export default React.memo(VideoPlayer);
export type { IVideoPlayer };
