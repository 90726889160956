import Box from '@mui/material/Box';
import { ProgressBarPositionOptions } from './StudyEnrollment';
import {
  DesktopJssBarBox,
  MainContentBox,
  StudyEnrollmentContainer,
} from './StudyEnrollment.styles';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentRendering, RouteData } from '@sitecore-jss/sitecore-jss/layout';

interface StudyEnrollContentProps {
  progressBarPosition: ProgressBarPositionOptions;
  rendering: ComponentRendering | RouteData;
}

const StudyEnrollmentContent: React.FC<StudyEnrollContentProps> = ({
  progressBarPosition,
  rendering,
}) => {
  const JssBarPosition = progressBarPosition || ProgressBarPositionOptions.TOP;
  const isTopProgressBar = JssBarPosition === ProgressBarPositionOptions.TOP;
  return (
    <StudyEnrollmentContainer data-testid={'StudyEnrollmentContainer'}>
      {isTopProgressBar && (
        <Box className={'JssBarMobile top-progress-bar'}>
          <Placeholder name="jss-bar" rendering={rendering} />
        </Box>
      )}
      <DesktopJssBarBox>
        <Placeholder name="jss-bar" rendering={rendering} />
      </DesktopJssBarBox>
      <MainContentBox>
        <Box className={`${isTopProgressBar ? 'top-variant' : 'middle-variant'}`}>
          <Placeholder name="jss-main-top" rendering={rendering} />
        </Box>
        {!isTopProgressBar && (
          <Box className={'JssBarMobile middle-progress-bar'}>
            <Placeholder name="jss-bar" rendering={rendering} />
          </Box>
        )}
        <Box className={'JssMainBottom'}>
          <Placeholder name="jss-main-bottom" rendering={rendering} />
        </Box>
      </MainContentBox>
    </StudyEnrollmentContainer>
  );
};

export default StudyEnrollmentContent;
