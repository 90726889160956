import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ComponentProps } from '../../lib/component-props';
import ImageCtaCard from '../shared/ImageCtaCard/ImageCtaCard';
import { StImageCardContainer } from './ContentWithIconList.style';
import { ImageCtaCardProps } from '../../components/shared/ImageCtaCard/ImageCtaCard.type';
import { StCRGridContainer, STCRWrapper } from '../../components/shared/Container/Container.styles';
import { useDispatch } from 'react-redux';
import { updateLastVisitedComponentId } from '../../features/globalConfig';
import { useTheme } from '@mui/material/styles';

type IconListProps = {
  fields: ImageCtaCardProps;
};

type ContentWithIconListProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    content: Field<string>;
    iconList: IconListProps[];
  };
};

const ContentWithIconList = (props: ContentWithIconListProps): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  if (!props?.fields) return <></>;
  const onClickHandler = () => {
    dispatch(updateLastVisitedComponentId('content-with-icon-list'));
  };
  return (
    <div style={{ backgroundColor: '#F7F8FA' }}>
      <STCRWrapper id="content-with-icon-list">
        <StCRGridContainer>
          <>
            <Typography
              variant="h3"
              data-testid="title"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '8px',
                lineHeight: '1.5rem',
                fontWeight: 600,
                color: (theme) => theme.palette.neutral8.main,
              }}
            >
              <Text field={props?.fields?.heading} />
            </Typography>
            <RichText
              data-testid="content"
              style={{
                color: theme.palette.neutral8.main,
                marginTop: '1rem',
                fontSize: '1.125rem',
                fontWeight: 500,
                fontFamily: 'Proxima Nova',
              }}
              field={props?.fields?.content}
            />
          </>
          <StImageCardContainer>
            {props?.fields?.iconList &&
              props?.fields?.iconList.map((item, i) => (
                <Grid item key={i}>
                  <ImageCtaCard
                    icon={item?.fields?.icon}
                    link={item?.fields?.link}
                    buttonVariant={item?.fields?.buttonVariant}
                    onClick={onClickHandler}
                  />
                </Grid>
              ))}
          </StImageCardContainer>
        </StCRGridContainer>
      </STCRWrapper>
    </div>
  );
};

export default ContentWithIconList;
