import * as React from 'react';
import { IProgressBarFields, ProgressBarItemStatus } from '../ProgressBar.types';
import ProgressBarItem from '../ProgressBarItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowDropDown from '../../../../../public/ArrowDropDown.webp';
import { StNextImage } from '../../../shared/NextImage.styles';

const CollapsibleProgressBar: React.FC<IProgressBarFields> = (props) => {
  const [showAllItems, setShowAllItems] = React.useState(false);
  const progressBarItems = props.progressBarItems;
  const activeItem = progressBarItems
    ?.map((i, index) => ({ ...i, index: index + 1 }))
    .find((i) => i?.fields?.status?.value === ProgressBarItemStatus.active);

  const handleClick = () => {
    setShowAllItems(!showAllItems);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ' || event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <>
      {/* Active Item */}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-testid={'active-item-test-id'}
      >
        <Box flexGrow={1}>
          <ProgressBarItem
            index={activeItem?.index || 0}
            fields={activeItem?.fields || {}}
            isNoBackground={true}
          />
        </Box>
        <Box>
          <StNextImage suppressHydrationWarning field={ArrowDropDown} />
        </Box>
      </Box>
      {/* All items */}
      {showAllItems && (
        <Box sx={{ position: 'absolute', zIndex: 1000, width: 'calc(100vw - 2rem)' }}>
          <Paper sx={{ padding: '10px' }} elevation={3}>
            {progressBarItems?.map((item, index) => (
              <ProgressBarItem index={index + 1} key={index} fields={item.fields} />
            ))}
          </Paper>
        </Box>
      )}
    </>
  );
};
export default CollapsibleProgressBar;
