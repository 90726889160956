import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const StTabListContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.neutral0.main,
  paddingBlock: '2rem',
  paddingLeft: '6.5rem',
  paddingRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    paddingLeft: '4rem',
    paddingRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    paddingLeft: '3rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    paddingInline: '2rem',
  },
  // [theme.breakpoints.up('xl')]: {
  //   padding: '0 30px',
  //   width: '1366px',
  //   margin: '4rem auto !important',
  // },
}));

export const StDropDown = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: '8px 0px 24px 0px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '85vw !important',
  },
  '& .MuiSelect-select': {
    whiteSpace: 'normal !important',
  },
}));
export const StTabsContainer = styled(Grid)(({ theme }) => ({
  marginBottom: '2rem',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '560px !important',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '880px !important',
  },
  '@media screen and (max-width:600px)': {
    maxWidth: '400px !important',
  },
}));
