import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { UILink } from 'components/shared/Links/UILink.styles';
import { useEffect, useState } from 'react';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import HumanConnect from 'components/HumanConnect/HumanConnect';
import { ShareYourHealthDataProps } from './ShareYourHealthData.type';
import SuccessPageMergeTagText from '../SuccessPageMergeTag/SuccessPageMergeTagText';
import SuccessPageMergeTag from '../SuccessPageMergeTag/SuccessPageMergeTag';

const ShareYourHealthData = (props: ShareYourHealthDataProps): JSX.Element => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const [showMore, setShowMore] = useState<boolean>(false);

  const { show, confirmedsitesData } = props;
  let { fields } = props;
  const potentialId = props.potentialId || '';
  const emailAddress = props.emailAddress || '';

  useEffect(() => {
    setShowMore(isNotDesktop);
  }, [isNotDesktop]);

  if (!props?.fields) return <></>;

  if (!show) {
    return <></>;
  }

  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }

  const content = fields?.content;

  const minContent = {
    value: content?.value?.substring(0, 160),
  };

  const sideCode: string = confirmedsitesData?.sideCode || confirmedsitesData?.code || '';
  const humanApiButtonLabel = fields?.humanApiButtonLabel || { value: '' };
  const retryAttempt = fields?.retryAttempt?.value || 4;
  const modelHeadline = fields?.modelHeadline?.value || '';
  const errorMessage = fields?.errorMessage?.value || '';
  const serviceUnavailableHeadline = fields?.serviceUnavailableHeadline?.value || '';
  const serviceUnavailableMessage = fields?.serviceUnavailableMessage?.value || '';
  const closeButtonText = fields?.closeButtonText?.value || '';
  const waitMessage = fields?.waitMessage?.value || '';

  return (
    <Box
      sx={{
        border: ` 1px solid ${theme.palette.neutral4.main}`,
        borderRadius: '8px',
        padding: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        boxShadow: `0px 2px 8px 0px rgba(0, 0, 0, 0.08)`,
      }}
    >
      <Typography
        sx={{
          color: theme.palette.themeColor.main,
          fontFamily: `Proxima Nova`,
          fontWeight: 600,
          fontSize: '1.5rem',
          lineHeight: 'normal',
          textAlign: 'center',
          wordBreak: 'break-word',
        }}
      >
        <SuccessPageMergeTagText mergeTagField={fields.title} mergeTagObj={props?.mergeTagData} />
      </Typography>
      <Box
        sx={{
          color: theme.palette.backgroundType1.main,
          fontFamily: `Proxima Nova`,
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '22px',
          textAlign: 'start',
          wordBreak: 'break-word',
          paddingBottom: '1rem',
        }}
      >
        <SuccessPageMergeTag
          mergeTagField={showMore ? minContent : content}
          mergeTagObj={props?.mergeTagData}
        />
        {showMore && <UILink onClick={() => setShowMore(false)}>Show more</UILink>}
      </Box>
      <HumanConnect
        buttonText={humanApiButtonLabel || { value: 'Share Health Data' }}
        sideCode={sideCode}
        modelHeadline={modelHeadline}
        errorMessage={errorMessage}
        serviceUnavailableHeadline={serviceUnavailableHeadline}
        serviceUnavailableMessage={serviceUnavailableMessage}
        closeButtonText={closeButtonText}
        waitMessage={waitMessage}
        maxAttempts={typeof retryAttempt === 'number' ? retryAttempt : 5}
        potentialId={potentialId}
        emailAddress={emailAddress}
      />
      {/* <JSSLink variant={fields?.buttonVariant.value} field={fields?.link} /> */}
    </Box>
  );
};

export default ShareYourHealthData;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';
  const potentialId = cdpData.potentialId || '';
  const guardianEmail = cdpData.guardianEmail || '';
  const participantEmail = cdpData.participantEmail || '';
  const emailAddress = guardianEmail || participantEmail || '';
  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'ShareYourHealthData'
  );

  return {
    ...componentProps,
    confirmedsitesData: cdpData.confirmedsitesData,
    potentialId,
    emailAddress,
    mergeTagData: cdpData,
  };
};
