import { Text, ImageField, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { StTypography, StBox } from './IconWithTitle.style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomNextImage from '../CustomNextImage/CustomNextImage';

type IconWithTitleProps = {
  titleIcon?: ImageField;
  title: Field<string>;
  iconWithTitleVariant: string;
  invertColor?: boolean;
  overrideImageProps?: boolean;
  iconMobile?: ImageField;
  iconTablet?: ImageField;
  iconDesktop?: ImageField;
  id?: string;
};

const IconWithTitle = (props: IconWithTitleProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const singleLine = props?.iconWithTitleVariant === 'ImageUp' ? false : true;
  const invertColor = props?.invertColor ? true : false;
  const imageHeight = isMobile ? '36px' : props?.titleIcon?.value?.height;
  const imageWidth = isMobile ? '30px' : props?.titleIcon?.value?.width;
  return (
    <StBox singleline={singleLine}>
      <CustomNextImage
        mobileImg={props?.iconMobile}
        tabletImg={props?.iconTablet}
        desktopImg={props?.iconDesktop}
        field={props?.titleIcon}
        height={imageHeight as string}
        width={imageWidth as string}
        invertColor={invertColor}
      />
      <StTypography variant="title" invertColor={invertColor} singleline={singleLine}>
        <Text data-testid={props.title} field={props?.title} />
      </StTypography>
    </StBox>
  );
};

export default IconWithTitle;
