import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'; // add Box, Grid
import Grid from '@mui/material/Grid';
import { UIButton } from '../../components/shared/UIButton.styles';
import { StTextField } from '../../components/StudyLocation/StudyLocation.style';

export const TrialDetailsContainer = styled(Box)(({ theme }) => ({
  padding: '2rem',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    columnGap: '2rem',
    padding: '2rem 4.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1300px',
    margin: '0 auto !important',
  },
}));
export const SideBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    width: '324px',
  },
}));
export const MainContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexBasis: '70%',
  },
  [theme.breakpoints.down('md')]: {
    '& .top-variant #small-banner': {
      marginTop: '1.5rem',
    },
    '& .middle-progress-bar': {
      marginTop: '-24px',
    },
  },
}));

export const TrialTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '98%',
  maxWidth: '1300px',
  margin: '0 auto',
  padding: '1rem 5rem 1rem 4rem',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '1rem 2rem',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '1rem 2.5rem',
  },
}));

export const TrialConatiner = styled(Grid)(({ theme }) => {
  const neutral0 = theme.palette.neutral0.main;
  const themeColor = theme.palette.themeColor.main;
  const themeColorSecondary = theme.palette.themeColorSecondary.main;
  return {
    paddingTop: '1rem',
    minHeight: `calc(100vh - 7rem)`,
    [theme.breakpoints.down('lg')]: {
      backgroundImage: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `linear-gradient(to top, ${neutral0} 70%, ${themeColorSecondary} 80%, ${themeColor})`,
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `linear-gradient(to top, ${neutral0} 60%, ${themeColorSecondary} 80%, ${themeColor})`,
    },
  };
});

export const TrialWrapper = styled(Grid)(({ theme }) => ({
  width: '98%',
  backgroundColor: theme.palette.neutral0.main,
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    borderRadius: '16px',
    border: `1px solid ${theme.palette.neutral4.main}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

export const ZipCodeWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    gap: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    gap: '1rem',
  },
}));

export const SearchZipBtn = styled(UIButton)(({ theme }) => ({
  height: '42px',
  [theme.breakpoints.down('md')]: {
    marginTop: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem',
    width: '100%',
    height: 'auto',
  },
}));

export const ZipCodeTextField = styled(StTextField)(({ theme }) => ({
  '.css-yz9k0d-MuiInputBase-input': {
    color: theme.palette.common.black,
  },
}));
