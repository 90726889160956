import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHomeTrial,
  setTravelDistance,
  setZipCode,
  setLatAndLong,
} from '../../../features/registrationSlice';
import { RootState } from '../../../store/store';
import { RegWorkflowContainer } from '../RegistrationWorkflow.style';
import RegWorkflowFactory from '../RegWorkflowFactory';
import LocationDetailsQuestions from './LocationDetailsQuestion';
import { getQuery } from '../../../lib/utils/common';

const LocationDetails = (props: any): JSX.Element => {
  const locationFields = props?.fields;
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const storedAnswers = useSelector(
    (state: RootState) => state.registrationDetails.locationDetails
  );

  const [enableNext, setEnableNext] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<any>({});
  const regex = new RegExp(locationFields.zipcodeRegex?.fields?.value?.value);

  useEffect(() => {
    if (
      (locationFields.isDistanceRequired.value && storedAnswers.travelDistance === '') ||
      (locationFields.isHomeTrailsConsentRequired.value && storedAnswers.homeTrial === '') ||
      (locationFields.isZipcodeRequired.value && storedAnswers.zipcode === '') ||
      (storedAnswers.zipcode !== '' && !regex.test(storedAnswers?.zipcode))
    ) {
      setEnableNext(false);
    } else {
      setEnableNext(true);
    }

    if (storedAnswers.zipcode !== '' && !regex.test(storedAnswers?.zipcode)) {
      setErrors({ ...errors, zipcode: true });
      setErrorMessage(locationFields?.zipcodeRegexErrorMessage);
    } else {
      setErrors({});
    }
  }, [storedAnswers]);

  const handleChange = (event: any) => {
    dispatch(setZipCode(event.target.value));
  };

  const handleRadioBox = (event: any, id: string) => {
    if (id === 'distanceCheckbox') {
      dispatch(setTravelDistance(event.target.name));
    } else {
      dispatch(setHomeTrial(event.target.name));
    }
  };

  const validateInputs = () => {
    let isValid = false;
    if (locationFields?.isZipcodeRequired.value && storedAnswers.zipcode === '') {
      isValid = false;
      setErrors({ ...errors, zipcode: true });
      setErrorMessage(locationFields?.zipcodeRequiredErrorMessage);
    } else if (errors.zipcode) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  const getStateFromZipcode = async (zipCode: string) => {
    return new Promise((reslove, reject) => {
      const geoCoder = new window.google.maps.Geocoder();
      geoCoder.geocode(
        { address: zipCode, componentRestrictions: { country: 'US' } },
        (results: any, status: string) => {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            if (results[0]?.types?.includes('postal_code')) {
              reslove({
                latitude: results[0]?.geometry?.location.lat() || null,
                longitude: results[0]?.geometry?.location.lng() || null,
              });
            } else {
              reject(false);
            }
          } else {
            reject(false);
          }
        }
      );
    });
  };

  const handleClick = async (event: any, url: any, action: any) => {
    event.preventDefault();
    if (action === 'back') {
      if (url != 'http://') {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      } else {
        history.back();
      }
    } else {
      const isValid = validateInputs();
      try {
        const locationCordinates = await getStateFromZipcode(storedAnswers.zipcode);
        dispatch(setLatAndLong(locationCordinates));
      } catch (_e) {
        dispatch(setLatAndLong({ latitude: null, longitude: null }));
      }
      if (isValid) {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      }
    }
  };

  return (
    <RegWorkflowContainer>
      <RegWorkflowFactory
        title={props?.fields?.title}
        subTitle={props?.fields?.subTitle}
        currentPage={4}
        totalCount={5}
        buttonsItems={props?.fields?.ctaLinks}
        handleClick={handleClick}
        enableNext={enableNext}
      >
        <LocationDetailsQuestions
          {...props.fields}
          handleChange={handleChange}
          handleCheckBox={handleRadioBox}
          errors={errors}
          errorMessage={errorMessage}
          storedAnswers={storedAnswers}
        />
      </RegWorkflowFactory>
    </RegWorkflowContainer>
  );
};

export default LocationDetails;
