import gql from 'graphql-tag';

const BreadcrumbsGQLQuery = gql`
  fragment breadcrumbFields on Item {
    ... on _PageBaseTemplate {
      pageTitle {
        value
      }
    }

    url {
      path
    }
  }

  query GetBreadcrumbs($language: String!, $itemId: String!) {
    item(path: $itemId, language: $language) {
      ...breadcrumbFields
      ancestors(hasLayout: true, includeTemplateIDs: "{B36BA9FD-0DC0-49C8-BEA2-E55D70E6AF28}") {
        ...breadcrumbFields
      }
    }
  }
`;

export const breadcrumbsQueryParams = (language: string, itemId: string) => {
  return { language, itemId };
};
export default BreadcrumbsGQLQuery;
