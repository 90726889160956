import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { Field, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import Content from '../Content/Content';
import { CAROUSEL_TYPE } from '../../../constants';
import { ICarousalPanelFields } from '../CarousalList';
import React from 'react';
import CustomNextImage from '../../shared/CustomNextImage/CustomNextImage';

interface ISlide {
  item: { fields: ICarousalPanelFields };
  background: Field<string>;
  playIcon: Field<string> | undefined;
  index: number;
  variation: string | undefined;
  setPlaying: any;
  playing: boolean;
  type: string;
  handlePlay: (index: number) => void;
  handlePause: () => void;
  currentVideoIndex: any;
  ariaLabel: Field<string>;
  playIconAriaLabel?: Field<string>;
}

const Slide: React.FC<ISlide> = ({
  item,
  type,
  index,
  variation,
  playing,
  playIcon,
  handlePlay,
  handlePause,
  currentVideoIndex,
  ariaLabel,
  playIconAriaLabel,
}) => {
  const videoUrl = item?.fields?.VideoUrl;
  const handleOnPlay = () => {
    handlePlay(index);
  };

  if (type === CAROUSEL_TYPE.IMAGE) {
    return (
      <Grid container key={index}>
        <CustomNextImage
          priority={true} // When true, the image will be considered high priority and preload
          style={{ zIndex: 0 }}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          mobileImg={item?.fields?.backgroundMobile}
          tabletImg={item?.fields?.backgroundTablet}
          desktopImg={item?.fields?.backgroundDesktop}
        />
        <Content fields={item?.fields} variation={variation} />
      </Grid>
    );
  } else {
    return (
      <Grid container key={index}>
        <Grid className="content" style={{ display: playing ? 'none' : 'block' }}>
          <Grid
            className="playicon"
            style={{ marginTop: '5%', marginLeft: '3%', position: 'absolute' }}
          >
            <IconButton aria-label={playIconAriaLabel?.value}>
              <NextImage
                width={'100px'}
                height={'100px'}
                style={{ zIndex: 5 }}
                field={playIcon}
                onClick={async () => await handlePlay(index)}
                // priority={true}
              />
            </IconButton>
          </Grid>

          <Grid
            style={{
              background: `linear-gradient(187deg, #060606 10.36%, #00BEB1 10.36%, #00638C 63.15%)`,
            }}
          >
            <Content fields={item?.fields} variation={variation} />

            <CustomNextImage
              layout="fill"
              objectFit="cover"
              // priority={true}
              mobileImg={item?.fields?.backgroundMobile}
              tabletImg={item?.fields?.backgroundTablet}
              desktopImg={item?.fields?.backgroundDesktop}
              ariaLabel={ariaLabel?.value}
            />

            <Content fields={item?.fields} variation={variation} />
          </Grid>
        </Grid>

        {currentVideoIndex?.current === index && playing && (
          <VideoPlayer
            type={type}
            playing={currentVideoIndex?.current === index && playing ? true : false}
            handleOnPause={handlePause}
            handleOnPlay={handleOnPlay}
            url={videoUrl?.value}
          />
        )}
      </Grid>
    );
  }
};

export default React.memo(Slide);
