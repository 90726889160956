import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { JSSLink } from '../../components/shared/Links/JSSLink.styles';
import { StCardDesc, StCardSubTitle, StSectionTitle } from './SingleTrial.styles';
import { SingleTrialType } from './SingleTrial.types';

const SingleTrial = (props: SingleTrialType): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 961));
  if (!props?.fields) return <></>;
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StSectionTitle>
        <Text field={props?.fields?.sectionTitle}></Text>
      </StSectionTitle>
      <Card
        sx={{
          borderRadius: '0.6rem',
          boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
          margin: isTablet
            ? '1.5rem 6rem 2.5rem 6rem'
            : isMobile
            ? '1rem 3rem 2rem 3rem'
            : '3rem 5rem',
          [theme.breakpoints.up('xl')]: {
            maxWidth: '1366px',
            marginInline: 'auto',
            width: '100%',
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                minHeight: isTablet ? '279px' : isMobile ? '320px' : '250px',
                minWidth: '100%',
                position: 'relative',
              }}
            >
              <NextImage
                field={props.fields?.relatedTrial?.fields?.featuredTrialImage}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                rowGap: '0.5rem',
              }}
            >
              {/* 
              //TODO :  To BE UPDATED AFTER CONFIRMATION WITH BHARDWAJ
              {(isMobile || isTablet) && (
                <StCardTitle>
                  <Text field={{ value: 'NASH' }} /> 
                </StCardTitle>
              )} 
              */}
              <StCardSubTitle limit={props?.fields?.titleLineLimit.value}>
                <Text field={props.fields?.relatedTrial?.fields?.trialTitle} />
              </StCardSubTitle>
              <StCardDesc limit={props?.fields?.descriptionLineLimit.value}>
                <Text field={props.fields?.relatedTrial?.fields?.trialDescription} />
              </StCardDesc>
            </CardContent>
            <CardActions sx={{ padding: '1rem' }}>
              <JSSLink
                className={'single-trial-btn btn'}
                variant="outlined"
                sx={{ width: '100%' }}
                field={props.fields?.trialCTA}
              />
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default SingleTrial;
