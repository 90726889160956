import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StContainer = styled('div')(({ theme }) => ({
  margin: '1rem 2.5rem',
  [theme.breakpoints.up('lg')]: {
    margin: '1rem 8rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0 30px',
    width: '1300px',
    margin: '4rem auto !important',
  },
}));

export const StHeader = styled('h4')(() => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  color: '#000000',
}));

export const StDescription = styled('div')(() => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#444444',
  paddingBottom: '24px',
}));

export const StHeadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StHeadingText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '20px',
  color: '#000000',
}));

export const StDescriptionText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
  margin: '28px 0 32px',
}));

export const StContentListContainer = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
}));
export const StContentListContainerLaptop = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));
export const StContentListSubContainer = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '48%',
}));

export const StContentItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'applyBackground',
})<{ applyBackground?: boolean }>(({ applyBackground, theme }) => ({
  padding: 16,
  display: 'flex',
  alignItems: 'flex-start',
  //this value can't be configure from default theme as this hex code is not available
  background: applyBackground
    ? theme?.palette?.themeLightColor?.main
    : theme?.palette?.secondary?.main,
  border: '1px solid #005587',
  borderRadius: '16px',
  marginBottom: '24px',
}));
export const StContent = styled('div')(() => ({
  fontWeight: '400',
  fontize: '16px',
  lineHeight: '24px',
}));
export const StIcon = styled('span')(() => ({ minWidth: '24px' }));
