import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

interface IStNotificationBannerContainer {
  isNotificationOpen: boolean;
  hideActionButton: boolean;
}
const StNotificationBannerContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isNotificationOpen' && prop !== 'hideActionButton',
})<IStNotificationBannerContainer>(({ theme, isNotificationOpen, hideActionButton }) => {
  return {
    p: {
      color: theme?.palette?.neutral0.main,
      fontWeight: '600',
    },
    '.MuiAlert-action': {
      color: theme?.palette?.neutral0.main,
      display: !hideActionButton ? 'block' : 'none',
    },
    '.Error': {
      backgroundColor: theme?.palette?.error.main,
      '.icon': {
        marginRight: '0.5rem',
      },
    },
    '.Information': {
      backgroundColor: theme?.palette?.themeColor.main,
      '.icon': {
        marginRight: '0.5rem',
      },
    },
    '.Success': {
      backgroundColor: theme?.palette?.success.main,
      '.icon': {
        marginRight: '0.5rem',
      },
    },
    '.typo': {
      display: 'flex',
      justifyContent: 'center',
    },
    display: isNotificationOpen ? 'block' : 'none',
  };
});

export default StNotificationBannerContainer;
