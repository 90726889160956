import { ComponentProps } from 'lib/component-props';
import { TextField } from '@sitecore-jss/sitecore-jss-nextjs';

export enum ProgressBarItemStatus {
  disabled = 'Disabled',
  active = 'Active',
  completed = 'Completed',
}

export interface IProgressBarItem {
  fields: {
    name?: TextField;
    status?: {
      value: ProgressBarItemStatus;
    };
  };
}

export interface IProgressBarFields {
  [key: string]: IProgressBarItem[];
}

export interface IProgressBarProps extends ComponentProps {
  fields?: IProgressBarFields;
}

export interface IProgressBarItemProps extends IProgressBarItem {
  index: number;
  isNoBackground?: boolean;
}
