import Box from '@mui/material/Box';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import CircularProgressBar from '../../components/shared/ProgressBar/CircularProgressBar';
import { RegisterMergeText } from './RegistrationMergeFields';
import { StSubTitle, StTitle } from './RegistrationWorkflow.style';
import RegWorkflowButtons from './RegWorkflowButtons';

const RegWorkflowFactory = (props: any): JSX.Element => {
  const minHeight =
    (props?.view &&
      (props.view === 'device' ? '360px' : props?.view === 'tablet' ? '400px' : '475px')) ||
    '400px';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2rem',
        paddingBlock: '2rem',
      }}
    >
      <StTitle sx={{ alignSelf: 'center' }}>
        <RegisterMergeText field={props?.title} />
      </StTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flext-start',
          minHeight: minHeight,
          // maxHeight: '600px',
          maxWidth: '500px',
        }}
      >
        {props?.subTitle && (
          <StSubTitle sx={{ textAlign: 'center' }}>
            <Text field={props?.subTitle} />
          </StSubTitle>
        )}
        <Box sx={{ alignSelf: 'center', marginBlock: '4rem' }}>
          <CircularProgressBar currentPage={props?.currentPage} totalCount={props?.totalCount} />
        </Box>
        <Box>{props.children}</Box>
      </Box>
      <RegWorkflowButtons
        enableNext={props?.enableNext}
        buttonItems={props?.buttonsItems}
        handleClick={props.handleClick}
      />
    </Box>
  );
};

export default RegWorkflowFactory;
