import { Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import lowerCase from 'lodash/lowerCase';

export interface LinkwithModal {
  children?: React.ReactNode;
  field: LinkField;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (e: any) => void;
  id?: string;
  role?: string;
}

const LinkwithModal = (props: LinkwithModal): JSX.Element => {
  const linkTypeValue = props?.field?.value?.linktype as string;
  const classFromSitecore = props?.field?.value?.class || '';
  const propsClass = props?.className || '';
  const isExternalLink = lowerCase(linkTypeValue) === 'external';
  const isManageCookiesClassFound = classFromSitecore?.includes('manage-cookies');
  let isAddOpenDisclaimer: boolean = isExternalLink;
  const role = props?.role || 'link';
  if (isManageCookiesClassFound) {
    isAddOpenDisclaimer = false;
  }
  const classNames = `${propsClass} ${
    isAddOpenDisclaimer ? 'open-disclaimer' : ''
  } ${classFromSitecore}`;

  return (
    <Link {...props} className={classNames} rel="noopener noreferrer" role={role}>
      {props.children}
    </Link>
  );
};

export default LinkwithModal;
