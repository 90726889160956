import * as React from 'react';
import { TwoColumnGridProps } from './TwoColumnGrid.types';
import { useTheme } from '@mui/material/styles';
import Grid, { GridSize } from '@mui/material/Grid';
import OneColumnGrid from '../OneColumnGrid/OneColumnGrid';
import useMediaQuery from '@mui/material/useMediaQuery';

const TwoColumnGrid: React.FC<TwoColumnGridProps> = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  if (!props?.fields) return <></>;
  const screenRatio = props?.fields?.screenRatio.value;
  const maintainOrder = props?.maintainOrder;

  const calculateBreakpoints = (screenRatio: string) => {
    if (screenRatio === 'FiftyFifty') {
      return { one: 6, two: 6 };
    } else if (screenRatio === 'SeventyThirty') {
      return { one: 8, two: 4 };
    } else {
      return { one: 4, two: 8 };
    }
  };

  const { one, two }: { one: GridSize; two: GridSize } = calculateBreakpoints(screenRatio);

  const contentType = props?.fields?.contentList[0]?.templateName;

  return (
    <Grid
      container
      item
      columnSpacing={3}
      rowSpacing={3}
      flexDirection={
        matches
          ? (contentType === 'ImageContainer' || contentType === 'VideoContainer') && !maintainOrder
            ? 'column-reverse'
            : 'column'
          : 'row'
      }
    >
      <Grid item xs={12} sm={12} md={12} lg={one}>
        <OneColumnGrid
          {...props?.fields?.contentList[0]}
          isSingleColumn={props?.isSingleColumn as boolean}
          mergeTagData={props?.mergeTagData}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={two}>
        <OneColumnGrid
          {...props?.fields?.contentList[1]}
          isSingleColumn={props?.isSingleColumn as boolean}
          mergeTagData={props?.mergeTagData}
        />
      </Grid>
    </Grid>
  );
};

export default TwoColumnGrid;
