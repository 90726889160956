import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const StHcpTrainingContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    maxWidth: '1224px',
  },
}));

type IsSummaryProps = {
  isSummary?: boolean;
};

export const StContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSummary',
})<IsSummaryProps>(({ theme, isSummary }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#000',
  marginBottom: '0.5rem',
  '& p': {
    margin: 0,
  },
  [theme.breakpoints.up('lg')]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: isSummary ? 3 : 2,
    height: isSummary ? '75px' : '50px',
  },
}));

export const StTrainingCard = styled(Grid)(({ theme }) => ({
  background: '#F2F2F2',
  borderRadius: '8px',
  padding: '2rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  height: '220px',
  [theme.breakpoints.down('lg')]: {
    marginRight: 0,
    padding: '1.5rem',
    height: 'auto',
  },
  [theme.breakpoints.between(960, 1036)]: {
    padding: '2rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    height: '230px',
  },
}));
