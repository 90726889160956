import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

interface StepperProps {
  stepCount: number;
  stepNumber: number;
  goTo: (step: number) => void;
}
const Stepper = (props: StepperProps): JSX.Element => {
  const { stepCount, stepNumber, goTo } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container justifyContent="space-between">
      {Array(stepCount)
        .fill(0)
        .map((_stepItem, index) => (
          <Grid
            key={index}
            data-testid={`Stepper-${index}`}
            item
            sx={{
              height: isMobile ? '10px' : '12px',
              background:
                index <= stepNumber ? theme.palette.themeColor.main : theme.palette.neutral3.main,
              borderRadius: '6px',
              cursor: 'pointer',
            }}
            xs={12 / stepCount - 0.1}
            sm={12 / stepCount - 0.15}
            md={12 / stepCount - 0.05}
            onClick={() => goTo(index)}
          ></Grid>
        ))}
    </Grid>
  );
};

export default Stepper;
