import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from '@mui/material/styles';
import { ISurveyPostMessage } from './Survey.types';

export const GetSurveyPostData = (targetOriginForSurvey: string): ISurveyPostMessage => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const currentWebsiteLanguage = sitecoreContext?.language || 'en';
  const postData: ISurveyPostMessage = {
    theme: {
      themeColor: theme.palette.themeColor.main.replace('#', ''),
      answerColor: theme.palette.text.primary.replace('#', ''),
      btnColor: theme.palette.primary.main.replace('#', ''),
      btnHoverColor: theme.palette.primary.dark.replace('#', ''),
      btnTextColor: theme.palette.white.main.replace('#', ''),
      btnSecondaryColor: theme.palette.white.main.replace('#', ''),
      btnSecondaryHoverColor: theme.palette.secondary.dark.replace('#', ''),
      btnSecondaryBorderColor: theme.palette.neutral4.main.replace('#', ''),
      btnSecondaryTextColor: theme.palette.primary.main.replace('#', ''),
      errorTextColor: theme.palette.surveyErrorTextColor.main.replace('#', ''),
      errorBackgroundColor: theme.palette.surveyErrorBackgroundColor.main.replace('#', ''),
      errorIconColor: theme.palette.surveyErrorIconColor.main.replace('#', ''),
    },
    targetOriginForSurvey,
    currentWebsiteLanguage,
  };

  return postData;
};
