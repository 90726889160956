import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ImageField } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Logo from '../DTP/HeaderNavigation/Logo';
import CloseIcon from '../shared/CloseIcon/CloseIcon';
import HamburgerIcon from '../shared/HamburgerIcon/HamburgerIcon';
import { CRNavFields } from './CRHeaderNavigation.types';
import CRMobileDrawConent from './CRMobileDrawConent';

const drawerWidth = '256px';
const fallbackImg: ImageField = { value: { src: '' } };
const CRMobileNav: React.FC<CRNavFields> = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  if (!props.navigationLinks) return <></>;
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box sx={{ marginInline: '2rem' }} id="cr-mobile-header-navigation">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Logo
            id={'cr-mobile-logo'}
            imageField={props.headerLogo || fallbackImg}
            ariaLabel={props?.headerLogoAriaLabel?.value as string}
          />
        </Box>
        <IconButton
          color="inherit"
          aria-label={
            isDrawerOpen
              ? (props?.closeButtonAriaLabel?.value as string) || 'close menu'
              : (props?.openMenuAriaLabel?.value as string) || 'open menu'
          }
          edge="start"
          onClick={handleDrawerToggle}
        >
          {isDrawerOpen ? <CloseIcon /> : <HamburgerIcon />}
        </IconButton>
      </Box>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        elevation={0}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <CRMobileDrawConent
          navigationLinks={props.navigationLinks}
          openProfileModal={props?.openProfileModal}
          isAuthenticated={props?.isAuthenticated}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Drawer>
    </Box>
  );
};
export default CRMobileNav;
