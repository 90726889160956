import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCOIList } from '../../../features/registrationSlice';
import { RootState } from '../../../store/store';
import RegWorkflowFactory from '../RegWorkflowFactory';
import AutocompleteMultiSelect from '../../../components/shared/AutocompleteMultiSelect/AutocompleteMultiSelect';
import { RegWorkflowContainer } from '../RegistrationWorkflow.style';
import { COIItemInterface } from '../../shared/AutocompleteMultiSelect/AutocompleteMultiSelect.types';
import { ConditionOfInterestsProps } from './ConditionsOfInterest.types';
import { formateCOIList, getQuery } from '../../../lib/utils/common';
import { GetStaticComponentProps, constants } from '@sitecore-jss/sitecore-jss-nextjs';
import { ExtenedLayoutServiceContextInterface } from '../../../lib/type/Site';
import { fetchConditionOfInterest } from '../../../GraphQL/queries/fetchConditionOfInterest.query';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

const ConditionsOfInterest = (props: ConditionOfInterestsProps): JSX.Element => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down(960));
  const isTablet = useMediaQuery(theme.breakpoints.down(786));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const view = isMobile ? 'device' : isTablet ? 'tablet' : 'desktop';
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const selectedCOIList = useSelector(
    (state: RootState) => state.registrationDetails.selectedCOIList
  );
  const [enableNext, setEnableNext] = useState<boolean>(false);

  useEffect(() => {
    if (props?.fields?.isRequired.value && selectedCOIList.length === 0) {
      setEnableNext(false);
    } else {
      setEnableNext(true);
    }
  }, [selectedCOIList]);

  const handleClick = (event: any, url: any, action: any) => {
    event.preventDefault();
    if (action === 'back') {
      if (url !== '') {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      } else {
        history.back();
      }
    } else {
      router.push(
        {
          pathname: url,
          query: getQuery(query),
        },
        url
      );
    }
  };

  const conditionsOfInterestList: any = formateCOIList(props?.COIList) || [];

  if (!props?.fields) return <></>;

  return (
    <RegWorkflowContainer>
      <RegWorkflowFactory
        title={props?.fields?.title}
        subTitle={props?.fields?.subTitle}
        currentPage={3}
        totalCount={5}
        buttonsItems={props?.fields?.ctaLinks}
        handleClick={handleClick}
        enableNext={enableNext}
        view={view}
      >
        <Grid style={isMobile ? { textAlign: 'center' } : { width: '450px' }}>
          <AutocompleteMultiSelect
            id={'conditions-of-interest'}
            options={conditionsOfInterestList}
            noOptionText={props?.fields?.noOptionText?.value}
            label={props?.fields?.label?.value}
            placeholder={props?.fields?.placeholder?.value}
            value={selectedCOIList}
            limitTags={2}
            onChangeHandler={(newValue: COIItemInterface[]) => {
              dispatch(setSelectedCOIList(newValue));
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: isTabletOrMobile ? '16px' : '6px',
              '& MuiFormLabel-root': { fontWeight: 600 },
              '& div.MuiAutocomplete-inputRoot': { padding: '2.25px 9px' },
            }}
          />
        </Grid>
      </RegWorkflowFactory>
    </RegWorkflowContainer>
  );
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  const context = layoutData?.sitecore?.context as ExtenedLayoutServiceContextInterface;
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const COIList = await fetchConditionOfInterest(language);

  return {
    COIList,
  };
};

export default ConditionsOfInterest;
