/**
 * Replaces the [pn] merge tag in the inputString with the call center number, formatted as an HTML anchor tag with a tel href.
 * @param inputString - The input string that may contain the [pn] merge tag.
 * @param phoneNumber - The phone number to be inserted into the [pn] merge tag.
 * @returns - The modified string with the [pn] merge tag replaced by the call center number formatted as an HTML anchor tag.
 */
const studyConditionMergeTagReplacer = (
  inputString = '',
  studyName = '',
  conditionName = ''
): string => {
  let newString = '';
  if (!inputString) {
    return '';
  }
  if (!studyName && !conditionName) {
    return inputString;
  }
  newString = inputString;
  if (studyName) {
    newString = newString?.replace(/\[primaryStudyName\]/g, `${studyName}`);
  }
  if (conditionName) {
    newString = newString?.replace(/\[primaryConditionName\]/g, `${conditionName}`);
  }
  return newString;
};
export default studyConditionMergeTagReplacer;
