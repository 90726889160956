import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { NextImage, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import ExploreProfile from '../RegistrationWorkflows/ExploreProfile/ExploreProfile';
import { RegisterMergeText } from '../RegistrationWorkflows/RegistrationMergeFields';
import StThankYouItemWrapper from '../RegistrationWorkflows/ThankYou/ThankYou.style';
import { UIButton } from '../shared/UIButton.styles';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { EXPLORE_PROFILE, PROFILE_VERIFY_TOKEN_KEY } from '../../constants';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { post as cdpPost } from '../../lib/utils/axiosCDPFetcher';
import { StDTPWrapper } from '../../components/shared/Container/Container.styles';
import UpdateEmail from '../shared/UpdateEmail/UpdateEmail';
import { resetContactInfo } from '../../features/contactInfoSlice';
interface ITQPageComponents {
  fields: {
    listofItems: {
      name: string;
      fields: {
        icon: any;
        heading: TextField;
        ctaButton?: {
          value?: {
            href: string;
            text: string;
          };
        };
      };
    }[];
  };
}

const TQPageComponents: React.FC<ITQPageComponents> = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const listofItems = props?.fields?.listofItems;
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  let emailAddress;
  const adultEmailAddress = useSelector(
    (state: RootState) => state?.contactInfoDetails?.contactInfo?.patient?.emailAddress
  );
  const delegateEmailAddress = useSelector(
    (state: RootState) => state?.contactInfoDetails?.contactInfo?.delegate?.emailAddress
  );
  const patientType = useSelector(
    (state: RootState) => state?.contactInfoDetails?.contactInfo?.patient?.patientType
  );
  if (patientType && patientType?.toLowerCase() === 'adult') {
    emailAddress = adultEmailAddress;
  } else {
    emailAddress = delegateEmailAddress;
  }
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );

  const getProfileToken = async (email: string) => {
    try {
      const response = await cdpPost(
        `/api/contacts/CreateProfileToken?patientEmailAdress=${email}`,
        {}
      );
      const myProfileVerificationToken = response?.data?.myProfileVerificationToken;
      if (myProfileVerificationToken) {
        sessionStorage.setItem(PROFILE_VERIFY_TOKEN_KEY, myProfileVerificationToken);
      }
      return response;
    } catch (error) {}
  };
  useEffect(() => {
    if (window) {
      const myProfileVerificationToken = sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
      if (!!myProfileVerificationToken == false && !!emailAddress) {
        getProfileToken(emailAddress);
      }
    }
  }, [emailAddress]);

  useEffect(() => {
    // This function will run when the page changes
    const handleRouteChange = () => {
      dispatch(resetContactInfo());
    };

    // Set up a listener for route changes
    router.events.on('routeChangeStart', handleRouteChange);
    // Clean up the listener when the component unmounts
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [dispatch, router]);

  const handleExploreYourProfile = (item: any) => {
    router.push(item?.fields?.ctaButton?.value?.href);
  };

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <>
      {isDesktop ? (
        <StDTPWrapper style={{ justifyContent: 'center', display: 'flex', marginBottom: '2rem' }}>
          <Grid container spacing={2} style={{ maxWidth: '800px' }}>
            {listofItems?.map((item: any, index: number) => {
              return (
                <Grid xs={6} key={index} item sx={{ width: '457px', height: '466px' }}>
                  <Box
                    sx={{
                      boxShadow: `0px 4px 4px 0px #00000040`,
                      borderRadius: '8px',
                      padding: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      background: item.name === EXPLORE_PROFILE ? 'none' : '#E6F9F8',
                    }}
                  >
                    {item.name === EXPLORE_PROFILE ? (
                      <>
                        <Stack alignItems="center">
                          <Box>
                            <NextImage field={item.fields.icon} height={237} width={182} />
                          </Box>
                          <Grid sx={{ color: theme.palette.neutral8.main, height: '50px' }}>
                            <RegisterMergeText
                              field={item?.fields?.heading}
                              data-testid="explore-profile-title"
                            />
                          </Grid>
                          <UIButton
                            data-testid="explore-profile-button-id"
                            variant="contained"
                            sx={{
                              height: '40px',
                              width: '322px',
                            }}
                            onClick={() => handleExploreYourProfile(item)}
                          >
                            <Text field={{ value: item?.fields?.ctaButton?.value?.text }} />
                          </UIButton>
                        </Stack>
                      </>
                    ) : (
                      <UpdateEmail {...item} />
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </StDTPWrapper>
      ) : (
        <div style={{ marginBottom: '2rem' }}>
          {listofItems?.map((item: any, index: number) => {
            if (item.name === EXPLORE_PROFILE) {
              return <ExploreProfile key={index} {...item} />;
            } else {
              return (
                <Grid key={index}>
                  <StThankYouItemWrapper className="mail-box" xs={isMobile ? 12 : 12}>
                    <UpdateEmail {...item} />
                  </StThankYouItemWrapper>
                </Grid>
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default TQPageComponents;
