import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import { HeadingMessageProps } from './HeadingMessage.type';
import SuccessPageMergeTagText from '../SuccessPageMergeTag/SuccessPageMergeTagText';
import SuccessPageMergeTag from '../SuccessPageMergeTag/SuccessPageMergeTag';

const HeadingMessage: React.FC = (props: HeadingMessageProps): JSX.Element => {
  let { fields } = props;
  const theme = useTheme();
  if (!props?.fields) return <></>;
  const message = fields?.message;
  const heading = fields?.heading;

  // Donot show component is show is false
  const { show } = props;
  if (!show) {
    return <></>;
  }

  // show default value if fields are empty
  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }
  return (
    <>
      <Stack spacing={0.5} width="100%" sx={{ marginBlock: '1rem' }}>
        <Typography
          style={{
            color: theme.palette.themeColor.main,
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            textAlign: 'start',
          }}
        >
          <SuccessPageMergeTagText
            mergeTagField={heading}
            mergeTagObj={props.mergeTagData}
            inheritLinkColor={true}
          />
        </Typography>
        <div
          style={{
            color: theme.palette.text.primary,
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'start',
          }}
        >
          <SuccessPageMergeTag
            mergeTagField={message}
            inheritLinkColor={false}
            mergeTagObj={props?.mergeTagData}
          />
        </div>
      </Stack>
    </>
  );
};

export default HeadingMessage;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'HeadingMessage'
  );
  return {
    ...componentProps,
    mergeTagData: cdpData,
  };
};
