import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

export const MainModalContainerBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  fontSize: '14px',
}));

export const ContainerBox = styled(Box)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  display: 'flex',
  gap: '16px',
}));

export const CardContentBox = styled(CardContent)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1rem 1rem 1rem',
}));

export const ExploreOpportunitiesBox = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: theme.spacing(3),
  gap: theme.spacing(1),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  '& .MuiButton-text': {
    fontSize: '18px',
    lineHeight: theme.spacing(3),
    fontWeight: 600,
  },
}));

export const NoQuestionnaireBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SQLableText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  textAlign: 'center',
  marginBlock: '10px',
  color: theme.palette.neutral6.main,
}));
