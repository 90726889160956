import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  StModalContainerWithModal,
  StmodalContainer,
  StHeadingContainer,
  StHeadingText,
  StFooterbuttons,
} from './CustomModal.styles';
import { UIButton } from '../UIButton.styles';

interface IGeneralModalProps {
  children: React.ReactNode;
  open: boolean;
  heading?: string;
  showActionButton?: boolean;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
}

const GeneralModal: React.FC<IGeneralModalProps> = (props) => {
  const heading = props.heading || '';
  const actionButtonText = props.actionButtonText || 'Close';
  const style = props.style || {};
  const onClose = props.onClose;
  return (
    <Modal
      open={props.open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StModalContainerWithModal sx={style}>
        <StmodalContainer
          sx={{
            width: '500px',
            height: '246px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <StHeadingContainer>
              <StHeadingText id="modal-modal-title" variant="h6">
                {heading}
              </StHeadingText>
            </StHeadingContainer>
            {props.children}
          </Box>
          {props.showActionButton && (
            <StFooterbuttons>
              <UIButton
                className="btn"
                variant="contained"
                onClick={props.onActionButtonClick}
                sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
              >
                {actionButtonText}
              </UIButton>
            </StFooterbuttons>
          )}
        </StmodalContainer>
      </StModalContainerWithModal>
    </Modal>
  );
};

export default GeneralModal;
