import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { JSSLink } from '../../shared/Links/JSSLink.styles';

export const StBannerWrapper = styled(Grid)(({}) => ({
  position: 'relative',
  minHeight: '542px',
}));

export const StBannerContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  // justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  backgroundSize: 'cover',
  minHeight: '475px',
  paddingBlock: '60px',
  paddingLeft: '6.5rem',
  paddingRight: '5.5rem',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // alignItems: 'center',
    // textAlign: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    marginInline: 'auto',
  },
  [theme.breakpoints.down(1150)]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));

export const StBannerContent = styled(Grid)(({ theme }) => ({
  maxWidth: '450px',
  marginBottom: '10px',
  zIndex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  color: theme.palette.white.main,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '450px',
    marginRight: '30px',
  },
}));

export const StRichTextGridItem = styled(Grid)(() => ({
  margin: '0px 0px',
  marginBottom: '0',
  fontSize: '20px',
  lineHeight: '35px',
  p: {
    margin: '0px',
    display: 'inline',
  },
}));

export const StSearchBoxWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    marginTop: '22px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export const StSearchBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  padding: '30px',
  minHeight: '226px',
  width: '442px',
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    a: {
      marginTop: '6px',
      fontWeight: 500,
    },
  },
  [theme.breakpoints.down('lg')]: {
    form: {
      minHeight: '176px',
    },
  },
  '@media screen and (max-width:500px)': {
    width: '100%',
  },
}));

export const StSearchButton = styled(JSSLink)(({}) => ({
  minWidth: 'auto !important',
  // padding: '5px 17px',
  width: '100%',
}));
