import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { JSSLink } from '../shared/Links/JSSLink.styles';

export const StButtonStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'spacing',
})<{ variant?: string; spacing: number }>(({ theme, spacing }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    'a:nth-of-type(2)': {
      marginLeft: theme.spacing(spacing),
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    'a:nth-of-type(2)': {
      marginLeft: 0,
      marginTop: '16px',
    },
  },
}));

export const StLinkButton = styled(JSSLink)(({ theme }) => ({
  height: 40,
  minWidth: 159,
  [theme.breakpoints.down('md')]: {
    height: 40,
    minWidth: 300,
  },
}));
