import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StudyDTPGridWithoutBackground = styled(Grid)(({ theme }) => ({
  marginLeft: '6.5rem',
  marginRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    marginLeft: '4rem',
    marginRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    marginLeft: '3rem',
    marginRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down(960)]: {
    marginInline: '0',
  },
  [theme.breakpoints.down(500)]: {
    marginInline: '0',
  },
}));

export const StudyEnrollmentContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    columnGap: '2rem',
    paddingTop: '2rem',
    '& .JssBarMobile': {
      display: 'none',
    },
  },
  '& .JssBarMobile': {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  '& .top-progress-bar': {
    paddingTop: '1.5rem',
  },
  '& .JssMainBottom': {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1361px',
    margin: '0 auto !important',
  },
}));
export const DesktopJssBarBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    flexBasis: '20%',
  },
}));
export const MainContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexBasis: '80%',
  },
  [theme.breakpoints.down('md')]: {
    '& .top-variant #small-banner': {
      marginTop: '1.5rem',
    },
    '& .middle-progress-bar': {
      marginTop: '-24px',
    },
  },
}));
