import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { IItemWithString } from 'lib/type/common';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

interface IEmailAutocompleteProps {
  emailTitle: Field<string>;
  emailDomains: IItemWithString[];
  fieldProps: ControllerRenderProps<FieldValues, 'email'>;
}

const EmailAutocomplete: React.FC<IEmailAutocompleteProps> = (props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [domainList, setDomainList] = useState<string[]>([]);

  useEffect(() => {
    const doamins: string[] = props?.emailDomains?.map((item) => item?.fields?.name?.value);
    const newValue = props?.fieldProps?.value ? props?.fieldProps?.value : '';
    if (props?.fieldProps?.value !== undefined) {
      const atIndex: number | string = newValue.indexOf('@');
      if (atIndex !== -1) {
        const prefix = props?.fieldProps?.value?.slice(0, (atIndex as number) + 1);
        setDomainList(doamins.map((item: any) => `${prefix}${item}`));
      }
    } else {
      setDomainList([]);
    }
  }, [props, props?.emailDomains, props?.fieldProps?.value]);

  const handleInputChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    props?.fieldProps?.onChange(value);
  };

  const handleOnChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    props?.fieldProps.onChange(value);
  };

  const filterOptions = (options: string[], { inputValue }: { inputValue: string }) => {
    const input = inputValue.trim().toLowerCase();
    return options?.filter((option: string) => option?.toLowerCase().startsWith(input));
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel id="email_label" htmlFor="email">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ color: theme.palette.secondary.main, fontSize: '16px' }}
        >
          <Typography>{props?.emailTitle?.value}</Typography>
          <Tooltip title={props.emailTitle?.value}>
            <IconButton sx={{ color: theme.palette.secondary.main, paddingLeft: '0px' }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </FormLabel>
      <Autocomplete
        freeSolo
        {...props.fieldProps}
        id="email"
        sx={{
          background: theme.palette.white.main,
          borderRadius: 3,
          '.MuiOutlinedInput-root': { padding: '3px' },
          '.MuiOutlinedInput-root .MuiAutocomplete-input': { color: theme.palette.black.main },
          '.MuiAutocomplete-popper': { background: 'red' },
        }}
        disableClearable
        options={domainList}
        filterOptions={filterOptions}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onInputChange={handleInputChange}
        onChange={handleOnChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              sx={{
                border: 'none',
                '& fieldset': { border: 'none' },
              }}
              InputProps={{
                ...params.InputProps,
                autoComplete: 'off',
                inputProps: {
                  ...params.inputProps,
                  'aria-label': 'email',
                  'aria-required': true,
                  role: 'textbox',
                },
              }}
            />
          );
        }}
        renderOption={(params, option) => (
          <span {...params} style={{ color: theme.palette.black.main }}>
            {option}
          </span>
        )}
        PopperComponent={({ ...props }) => (
          <Popper
            {...props}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ]}
          />
        )}
        noOptionsText=""
      />
    </FormControl>
  );
};

export default EmailAutocomplete;
