import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import { SelectedSitesContainerProps } from './SelectedSitesContainer.type';
import SiteInfoBox from './SiteInfoBox';
import SuccessPageMap from './SuccessPageMap';

const SelectedSitesContainer = (props: SelectedSitesContainerProps): JSX.Element => {
  const theme = useTheme();
  if (!props?.fields) return <></>;
  const { show, mergeTagData } = props;
  let { fields } = props;

  if (!show) {
    return <></>;
  }

  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }

  return (
    <Grid
      container
      sx={{ background: theme.palette.neutral2.main, borderRadius: '0.5rem', marginBlock: '1rem' }}
    >
      <Grid item sm={12} md={6} lg={6}>
        <SiteInfoBox {...props} mergeTagData={mergeTagData} />
      </Grid>
      <Grid item sm={0} md={6} lg={6}>
        <SuccessPageMap
          markerUrl={props?.fields?.selectedSiteMapMarker?.value?.src as string}
          confirmedsitesData={mergeTagData?.confirmedsitesData}
        />
      </Grid>
    </Grid>
  );
};

export default SelectedSitesContainer;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'SelectedSitesContainer'
  );

  return {
    ...componentProps,
    confirmedsitesData: cdpData.confirmedsitesData,
    mergeTagData: cdpData,
  };
};
