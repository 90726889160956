import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const StMenuBox = styled(Menu)(({ theme }) => ({
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1,
  '& .social-media-link': {
    paddingInline: theme.spacing(1),
  },
  '& .social-media-link:hover': {
    backgroundColor: theme.palette.neutral7.main,
  },
}));
