import {
  buildStandaloneSearchBox,
  buildSearchBox,
  SearchBox,
  StandaloneSearchBox,
  SearchBoxOptions,
} from '@coveo/headless';
import headlessEngine from './headlessEngine';

//this is used in facetInput but facetInput component is not used in the project
const conditionOptions: SearchBoxOptions = {
  numberOfSuggestions: 20,
};

export const getSearchBox = (
  noOfSuggestion = 20,
  redirectionUrl = '/find-trials/search-result'
) => {
  const searchBox: StandaloneSearchBox = buildStandaloneSearchBox(headlessEngine, {
    options: {
      numberOfSuggestions: noOfSuggestion,
      redirectionUrl: redirectionUrl,
    },
  });
  return searchBox;
};

export const getConditionSearchBox = (noOfSuggestion = 20) => {
  const conditionSearchBox = buildSearchBox(headlessEngine, {
    options: {
      numberOfSuggestions: noOfSuggestion,
    },
  });
  return conditionSearchBox;
};
export const conditionSearchBox: SearchBox = buildSearchBox(headlessEngine, {
  options: conditionOptions,
});
