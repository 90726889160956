import { RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ISiteInfo } from '../SiteInfoCard/SiteInfoCard';
interface IProps {
  siteAddressFormat: RichTextField;
  siteInfo: ISiteInfo | undefined;
  style?: React.CSSProperties;
}
const SiteLocationRichText: React.FC<IProps> = (props) => {
  const style = props.style || {};
  const RTFromSiteCore: RichTextField = props.siteAddressFormat || { value: '' };
  const siteInfo: ISiteInfo | undefined = props?.siteInfo;
  let newValue = '';

  if (siteInfo && Object.keys(siteInfo).length > 0) {
    // Get the mergeTags out from the RTFromSiteCore.value and store it in an array
    const originaValue: string = RTFromSiteCore.value || '';
    const mergeTagsInText: string[] = (originaValue.match(/\[(.*?)\]/g) || []).map((tag) =>
      tag.slice(1, -1)
    );

    const replaceMergeTags = (): string => {
      let replacedValue = originaValue;
      mergeTagsInText.forEach((tag) => {
        const tagValue = siteInfo[tag as keyof ISiteInfo] || '';
        replacedValue = replacedValue.replace(new RegExp(`\\[${tag}\\]`, 'g'), tagValue as string);
      });
      return replacedValue;
    };

    newValue = replaceMergeTags();
  } else {
    newValue = props.siteAddressFormat.value as string;
  }

  return <RichText data-testid="siteDetails" style={style} field={{ value: newValue }} />;
};

export default SiteLocationRichText;
