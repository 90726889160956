import * as React from 'react';
import { StMenuBox } from '../../../components/SitesSuccessPage/SelectedSitesContainer/SelectedSitesContainer.style';
import { useTheme } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { StNextImage } from '../NextImage.styles';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  ISocialMediaFieldProps,
  ISocialMediaProps,
} from '../../../components/SitesSuccessPage/SelectedSitesContainer/SelectedSitesContainer.type';

interface ShareToSocialMediaProps {
  socialEl: HTMLElement | null;
  setSocialEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  sharingMediaList: any;
}

const ShareToSocialMedia: React.FC<ShareToSocialMediaProps> = ({
  socialEl,
  setSocialEl,
  sharingMediaList,
}) => {
  const theme = useTheme();
  const open = Boolean(socialEl);

  const socialMediaIconList = {
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    LinkedIn: 'LinkedIn',
    Email: 'Email',
    Instagram: 'Instagram',
  };

  const MenuIconButton: React.FC<{
    tooltipText: string;
    IconImage: ImageField | any;
    onClickEvent: (e?: any) => void;
  }> = ({ tooltipText, IconImage, onClickEvent }) => {
    const theme = useTheme();
    return (
      <Tooltip title={tooltipText} placement="left" arrow>
        <IconButton onClick={onClickEvent}>
          <StNextImage
            suppressHydrationWarning
            field={IconImage}
            width={24}
            height={24}
            overrideIconColor={(theme as any).palette.white.main}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const handleClose = () => {
    setSocialEl(null);
  };

  const handleIconClickClose = (fields: ISocialMediaFieldProps, isEmailIcon: boolean) => {
    let url =
      (!isEmailIcon && fields?.shareMediaUrl?.value) ||
      (`mailto:?subject=${fields?.subject?.value}&body=${fields?.content?.value}%0D%0A${window.location.href}` as string);
    handleClose();
    if (url) {
      if (!isEmailIcon) url = url + encodeURIComponent(window?.location?.href);
      window.open(url);
    }
  };

  return (
    <>
      <StMenuBox
        anchorEl={socialEl}
        id="social-media-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: (theme as any).palette.neutral8.main,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {sharingMediaList.map((social: ISocialMediaProps) => {
          const isEmailIcon = social?.displayName === socialMediaIconList.Email;
          return (
            <MenuItem
              key={social.id}
              className={'social-media-link btn'}
              onClick={() => handleIconClickClose(social.fields, isEmailIcon)}
            >
              <MenuIconButton
                tooltipText={social.displayName}
                IconImage={social.fields.shareIcon}
                onClickEvent={(e) => e?.preventDefault()}
              />
            </MenuItem>
          );
        })}
      </StMenuBox>
    </>
  );
};

export default ShareToSocialMedia;
