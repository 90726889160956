import { useEffect } from 'react';
import { AppDispatch } from '../../store/store';
import { useDispatch } from 'react-redux';
import {
  updateStateList,
  updateTimeZones,
  updateConditionOfIntrestList,
} from '../../features/contactInfoSlice';

interface StateField {
  value: string;
}
function useStoreUpdateWithStateAndTimeZoneList(
  stateList: any,
  timeZoneList: any,
  conditionOfIntrestList: any
) {
  const dispatch = useDispatch<AppDispatch>();

  const sortCallback = (a: StateField, b: StateField) => {
    const x = a.value.toLowerCase();
    const y = b.value.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    const stateListData = stateList?.map((state: any) => ({
      value: state?.key?.value,
      label: state?.label?.value,
      isterritoryState: state?.isterritoryState?.value,
      countryCodeTerritory: state?.countryCodeTerritory?.value,
    }));
    const timeZoneListData = timeZoneList?.map((state: any) => ({
      value: state?.key?.value,
      label: state?.labelText?.value,
    }));
    dispatch(updateStateList(stateListData?.sort(sortCallback)));
    dispatch(updateTimeZones(timeZoneListData));
    dispatch(updateConditionOfIntrestList(conditionOfIntrestList));
  }, []);
  return null;
}

export default useStoreUpdateWithStateAndTimeZoneList;
