export enum HumanApiStatus {
  BUTTON_CLICKED = 'Clicked',
  BUTTON_NOT_CLICKED = 'CTA Button not clicked',
  HUMAN_ID_RECEIVED = 'HUMAN_ID_RECEIVED',
}

export interface IUpdateHumanApiRequired {
  potentialId: string | number;
  baseUrl: string;
  enrollmentId: string;
  tenantKey: string | number;
  humanApiEnabled: boolean;
}
export interface IUpdateHumanApiOptional {
  humanId?: string;
  humanApiStatus?: HumanApiStatus;
  exception?: string;
  recordLastModifiedDate?: string;
}
export interface IUpdateHumanApi extends IUpdateHumanApiRequired, IUpdateHumanApiOptional {}
