import Alert from '@mui/material/Alert';
import StNoScriptBannerContainer from './NoScriptBanner.style';
import Typography from '@mui/material/Typography';
import { RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';

export interface INoScriptBanner {
  noScriptRichText: RichTextField;
}

const NoScriptBanner = (props: INoScriptBanner): JSX.Element => {
  const { noScriptRichText } = props;

  return (
    <>
      <StNoScriptBannerContainer>
        <Alert
          sx={{
            background: (theme) => theme.palette.error.main,
            width: '100%',
            borderRadius: 0,
            '& .MuiAlert-message': { textAlign: 'center', width: 'inherit', padding: '0' },
            '& .MuiAlert-icon': { display: 'none' },
            '& .noscript-contents': { my: 2, '& p': { display: 'inline' } },
          }}
        >
          <Typography className="typo" component="div">
            <RichText field={noScriptRichText} className="noscript-contents" />
          </Typography>
        </Alert>
      </StNoScriptBannerContainer>
    </>
  );
};

export default NoScriptBanner;
