import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { GetServerSideComponentProps, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNextImage } from '../../../components/shared/NextImage.styles';
import moment from 'moment';
import React from 'react';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import { AppointmentDateTimeProps } from './AppointmentDateTime.type';

export const StTextItem = styled(Box)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontFamily: 'Proxima Nova',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  alignSelf: 'center',
}));

const AppointmentDateTime: React.FC = (props: AppointmentDateTimeProps) => {
  const theme = useTheme();
  if (!props?.fields) return <></>;

  // Donot show component is show is false
  const { show, appointmentDateTime } = props;
  if (!show) {
    return <></>;
  }

  let { fields } = props;

  // show default value if fields are empty
  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }

  // Display current date by Default
  let defaultData = {
    dateIconLabel: { value: '-' },
    timeIconLabel: { value: '-' },
  };

  if (appointmentDateTime) {
    const apptDate = appointmentDateTime.split(',')[0] + appointmentDateTime.split(',')[1];
    const day = moment(apptDate).format('dddd');
    const constructedApptDate = day + ',' + `<b> ${appointmentDateTime.split(',')[0]} </b>`;
    defaultData = {
      dateIconLabel: {
        value: constructedApptDate,
      },
      timeIconLabel: { value: `<b>${appointmentDateTime.split(',')[2]}</b>` },
    };
  }

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1rem', marginBlock: '1rem' }}>
        <Box
          sx={{
            background: theme.palette.neutral2.main,
            borderRadius: '0.5rem',
            display: 'flex',
            padding: '12px 1rem',
            gap: '14px',
            alignSelf: 'center',
          }}
        >
          <StNextImage
            suppressHydrationWarning
            field={props.fields.dateIcon}
            width={24}
            height={22}
          />
          <StTextItem>
            <RichText field={defaultData.dateIconLabel} />
          </StTextItem>
        </Box>
        <Box
          sx={{
            background: theme.palette.neutral2.main,
            borderRadius: '0.5rem',
            display: 'flex',
            padding: '12px 1rem',
            gap: '14px',
            alignSelf: 'center',
          }}
        >
          <StNextImage
            suppressHydrationWarning
            field={props.fields.timeIcon}
            width={24}
            height={24}
          />
          <StTextItem>
            <RichText field={defaultData.timeIconLabel} />
          </StTextItem>
        </Box>
      </Box>
    </>
  );
};

export default AppointmentDateTime;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: false };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'AppointmentDateTime'
  );
  return { ...componentProps, appointmentDateTime: cdpData.appointmentDateTime };
};
