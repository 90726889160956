import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { ContentBox, StyledDivider } from './UnderstandTrials.style';
import { UnderstandTrialsProps } from './UnderstandTrials.types';
import { JSSLink } from 'components/shared/Links/JSSLink.styles';

const UnderstandTrials = (props: UnderstandTrialsProps): JSX.Element => {
  const theme = useTheme();
  if (!props.fields) {
    return <></>;
  }
  return (
    <ContentBox>
      <Typography
        variant="h4"
        data-testid="title"
        sx={{
          width: '83%',
          textAlign: 'center',
          fontWeight: '600',
          color: theme.palette.themeColor.main,
        }}
      >
        <Text field={props?.fields?.title} />
      </Typography>
      <StyledDivider />
      <Typography
        variant="body"
        sx={{ color: theme.palette.backgroundType1.main, fontWeight: 600, marginBottom: '8px' }}
      >
        <Text field={props?.fields?.subTitle} />
      </Typography>
      {props?.fields?.ctaLinks?.length > 0 &&
        props?.fields?.ctaLinks.map((item) => (
          <JSSLink
            className={'understand-trial-btn btn'}
            sx={{
              lineHeight: '18px',
              fontSize: '0.875rem',
              fontWeight: 500,
              textAlign: 'left !important',
              alignSelf: 'stretch',
              color: theme.palette.primary.main,
            }}
            key={item?.id}
            field={item?.fields?.link}
          />
        ))}
    </ContentBox>
  );
};

export default UnderstandTrials;
