import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const STCRWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    marginInline: 'auto',
  },
}));

export const StCRGridContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.backgroundColorType3.main,
  // marginBlock: '2rem',
  padding: '50px 90px 15px 127px',
  [theme.breakpoints.down(1150)]: {
    padding: '50px 40px 10px 60px',
  },
  [theme.breakpoints.down('md')]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    marginLeft: '0px !important',
    marginRight: '0px !important',
    marginInline: '2rem',
  },
}));

export const StCRGridContainerWithoutBackground = styled(Grid)(({ theme }) => ({
  // marginBlock: '2rem',
  marginLeft: '6.5rem',
  marginRight: '5.5rem',
  [theme.breakpoints.down(1150)]: {
    marginLeft: '4rem',
    marginRight: '3rem',
  },
  [theme.breakpoints.down('md')]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    marginInline: '2rem',
  },
}));

type StDTPGridContainerProps = {
  bgColor?: string;
};

export const StDTPWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<StDTPGridContainerProps>(({ theme, bgColor }) => ({
  background: bgColor || theme.palette.neutral0.main,
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    marginInline: 'auto',
  },
}));

export const StDTPGridContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<StDTPGridContainerProps>(({ theme, bgColor }) => ({
  background: bgColor || theme.palette.neutral0.main,
  // marginBlock: '2rem',
  marginLeft: '6.5rem',
  marginRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    marginLeft: '4rem',
    marginRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    marginLeft: '3rem',
    marginRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    marginInline: '2rem',
  },
}));

export const StDTPGridWithoutBackground = styled(Grid)(({ theme }) => ({
  marginLeft: '6.5rem',
  marginRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    marginLeft: '4rem',
    marginRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    marginLeft: '3rem',
    marginRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    marginInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    marginInline: '2rem',
  },
}));

export const CommunityContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1366px',
  width: '100%',
  marginInline: 'auto',
  [theme.breakpoints.down('xl')]: {
    paddingInline: '48px',
  },
  [theme.breakpoints.down(1080)]: {
    paddingInline: '35px',
  },
  [theme.breakpoints.down(500)]: {
    paddingInline: 0,
  },
}));

export const StCommonTypography = styled(Typography)(({}) => ({
  fontFamily: 'Proxima Nova',
}));
