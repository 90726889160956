export const featureHighlightsProps = {
  rendering: {
    componentName: 'FeatureHighlights',
  },
  fields: {
    description: {
      value:
        '<div>&nbsp;</div>\r\nRsit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu Rsit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu Rsit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.<br />\r\n<br />\r\nSeveral tests and assessments will be performed to monitor your health. These will include:',
    },
    title: {
      value: 'What to expect?',
    },
    applyBackground: {
      value: false,
    },
    contentList: [
      {
        id: 'b59edcf6-bf3c-4785-9129-e03099796daa',
        url: '/Page-Components/Feature-Highlights/Section-Content-1',
        name: 'Section Content-1',
        displayName: 'Section Content-1',
        fields: {
          content: {
            value:
              'Electrocaiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
          },
        },
      },
      {
        id: '1d42b00d-eaf3-4558-b4ea-a452d1b5d5da',
        url: '/Page-Components/Feature-Highlights/Section-Content-2',
        name: 'Section Content-2',
        displayName: 'Section Content-2',
        fields: {
          content: {
            value:
              'Fe et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
          },
        },
      },
      {
        id: 'c1d0c058-85c9-405e-bcdd-4058289314d6',
        url: '/Page-Components/Feature-Highlights/Section-Content-3',
        name: 'Section Content-3',
        displayName: 'Section Content-3',
        fields: {
          content: {
            value:
              'Fe et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
          },
        },
      },
      {
        id: '03b598b7-27ae-43c6-95df-ddb5ad2dc607',
        url: '/Page-Components/Feature-Highlights/Section-Content-4',
        name: 'Section Content-4',
        displayName: 'Section Content-4',
        fields: {
          content: {
            value:
              'Questionaire olore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati',
          },
        },
      },
      {
        id: '2c72d8a0-f604-477e-a268-21df2329c607',
        url: '/Page-Components/Feature-Highlights/Section-Content-5',
        name: 'Section Content-5',
        displayName: 'Section Content-5',
        fields: {
          content: {
            value: 'Physical a aliqua. Ut enim ad minim veniam, quis nostrud exercitat.',
          },
        },
      },
      {
        id: '7d5acb63-c0b8-45e3-b9f3-571eff37f523',
        url: '/Page-Components/Feature-Highlights/Section-Content-6',
        name: 'Section Content-6',
        displayName: 'Section Content-6',
        fields: {
          content: {
            value: 'Golore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati',
          },
        },
      },
    ],
    sectionIcon: {
      value: {
        src: '/-/media/DAP/DTP/Images/tick-box-16.png?h=16&iar=0&w=16&hash=C25CC691BEB58B5EE230D30A89F5A0EA',
        alt: 'tick-box-16',
        width: '16',
        height: '16',
      },
    },
  },
};
