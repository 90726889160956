/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Icon from '@mui/material/Icon';
import { AlertInfoBox, AlertMessageBox, LinkTextBox } from './VerificationAlert.style';

interface Iprops {
  severity?: AlertColor;
  link?: string;
  linkText?: string;
  text?: string;
  icon: React.ReactElement<SvgIconProps>;
  closeAlert: () => void;
}

const VerificationAlert = (props: Iprops): JSX.Element => {
  const { severity, link, linkText, icon, text, closeAlert } = props;

  return (
    <>
      <AlertInfoBox>
        <Alert
          icon={<Icon>{icon}</Icon>}
          variant="filled"
          severity={severity || 'warning'}
          onClose={() => closeAlert()}
          sx={{ color: '#fff !important' }}
        >
          <AlertMessageBox>
            {text}
            {link && (
              <LinkTextBox underline="hover" href={link}>
                {linkText}
              </LinkTextBox>
            )}
          </AlertMessageBox>
        </Alert>
      </AlertInfoBox>
    </>
  );
};

export default VerificationAlert;
