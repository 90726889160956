import { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { StStack, StTypographyDescription, StTypographyTitle } from '../CarousalList.style';
import { useTheme } from '@mui/material/styles';
import { CAROUSEL_VARIATION } from '../../../constants';
import startsWith from 'lodash/startsWith';
import { setScrollId } from '../../../features/scrollIdSlice';
import { useDispatch, useSelector } from 'react-redux';
import useScrollToElement from '../../../lib/hooks/useScrollToElement';
import { RootState } from '../../../store/store';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { JSSLink } from '../../shared/Links/JSSLink.styles';

export interface IContent {
  fields: any;
  variation: string | undefined;
}
const Content: React.FC<IContent> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const ctaButtonsList = props?.fields?.ctaButtons;
  const variation = props?.variation;
  const title = props?.fields?.title;
  const description = props?.fields?.description;
  const dispatch = useDispatch();

  const scrollId = useSelector((state: RootState) => state.scrollId.scrollId);
  const scrollToElement = useScrollToElement();

  useEffect(() => {
    if (scrollId) {
      scrollToElement(scrollId);
      dispatch(setScrollId(null));
    }
  }, [scrollId]);

  const ctaButtons =
    variation === CAROUSEL_VARIATION.VARIATION3
      ? ctaButtonsList.filter((button: any) => {
          if (button?.name === 'PrimaryCTAButton') return button;
        })
      : ctaButtonsList;

  return (
    <StStack>
      <Grid className="carousallist-slide">
        <StTypographyTitle variant="h1">
          <Text field={title} />
        </StTypographyTitle>
        <StTypographyDescription>
          <RichText field={description} />
        </StTypographyDescription>
      </Grid>

      {ctaButtons?.length > 0 && (
        <Stack
          direction={isMobile ? 'column' : 'row'}
          className="ctabuttons"
          spacing={2}
          alignItems={'center'}
        >
          {ctaButtons &&
            ctaButtons.map((ctaButton: any, index: number) => {
              const ctaVariant = ctaButton.fields.buttonVariant.value;
              const ctaButtonLink = ctaButton.fields.link?.value?.href;
              const startsWithHash = startsWith(ctaButtonLink, '#');

              const handleOnClick = (e: any) => {
                e.preventDefault();
                dispatch(setScrollId(ctaButtonLink.replace(/^#/, '')));
              };

              const buttonProps = {
                ...(!startsWithHash && { target: '_blank' }),
                ...(startsWithHash && { onClick: handleOnClick }),
              };

              return (
                <JSSLink
                  className={ctaButton?.name}
                  variant={ctaVariant}
                  key={index}
                  field={ctaButton?.fields?.link}
                  {...buttonProps}
                />
              );
            })}
        </Stack>
      )}
    </StStack>
  );
};

export default Content;
