import { NextImage, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

import { JSSLink } from '../Links/JSSLink.styles';
import { StImageCtaCardBox } from './ImageCtaCard.style';
import { ImageCtaCardProps } from './ImageCtaCard.type';

const ImageCtaCard = (props: ImageCtaCardProps): JSX.Element => {
  const { icon, link, buttonVariant } = props;
  const fallbackLink: LinkField = { value: { href: '', text: '' } };
  return (
    <StImageCtaCardBox data-testid="image-cta-card" onClick={props?.onClick}>
      <NextImage field={icon} data-testid="image" />
      <JSSLink
        className={'image-card-btn btn'}
        data-testid="cta"
        field={link || fallbackLink}
        variant={buttonVariant?.value}
      />
    </StImageCtaCardBox>
  );
};

export default ImageCtaCard;
