import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { GetServerSideComponentProps, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import { StDescription, StHeading, StLink, StSubHeading } from './NextStep.styles';
import { NextStepType, NextStepSectionsType } from './NextStep.type';

const NextStep = (props: NextStepType): JSX.Element => {
  const { show } = props;
  if (!props?.fields) return <></>;
  let { fields } = props;

  if (!show) {
    return <></>;
  }

  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }

  const stepCount = props.fields?.nextStepSectionsList.length;
  return (
    <Box sx={{ marginBlock: '1rem' }}>
      <StHeading>
        <Text field={props.fields.nextStepHead} />
      </StHeading>
      <Grid container>
        {props.fields?.nextStepSectionsList?.map((data: NextStepSectionsType, index: number) => {
          return (
            <Grid item key={index}>
              <StSubHeading>
                <Text field={data?.fields?.nextStepSubHeading} />
              </StSubHeading>
              <StDescription>
                <RichText field={data?.fields?.nextStepDescription} />
              </StDescription>
              <StLink>
                <RichText field={data?.fields?.nextStepLinks} />
              </StLink>
              {index !== stepCount - 1 && (
                <Box sx={{ marginBlock: '2rem' }}>
                  <Divider sx={{ background: `rgba(255, 255, 255, 0.00)` }} />
                </Box>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default NextStep;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'NextStep'
  );

  return { ...componentProps, confirmedsitesData: cdpData.confirmedsitesData };
};
