import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { UILink } from 'components/shared/Links/UILink.styles';
import { useEffect, useState } from 'react';
import { HCPCRGridContainerWithoutBackground, HCPWrapper } from './HcpContent';

export const StRichTextGridItem = styled(Box)(() => ({
  p: {
    margin: '0px 0px',
  },
}));

const HcpContentBlockList = (props: any): JSX.Element => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down(1000));
  const [showMoreIndex, setShowMoreIndex] = useState<any>([]);

  const handleShowMore = (index: number) => {
    const showMoreIndexes =
      showMoreIndex && showMoreIndex?.includes(index)
        ? showMoreIndex?.filter((id: number) => id !== index)
        : [...showMoreIndex, index];
    setShowMoreIndex(showMoreIndexes);
  };

  useEffect(() => {
    setShowMoreIndex([]);
  }, [isNotDesktop]);

  return (
    <HCPWrapper>
      <HCPCRGridContainerWithoutBackground>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <Box
            sx={{
              fontFamily: `Proxima Nova`,
              fontWeight: 600,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              textAlign: 'start',
              wordBreak: 'break-word',
              color: theme.palette.themeColor.main,
            }}
          >
            <Text field={props?.fields?.heading} />
          </Box>
          <Box
            sx={{
              fontFamily: `Proxima Nova`,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '1.5rem',
              textAlign: 'start',
              wordBreak: 'break-word',
              color: theme.palette.black.main,
            }}
          >
            <Text field={props?.fields?.subHeading} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
            {props?.fields?.contentBlockList?.map((contentBlock: any, index: number) => {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }} key={index}>
                  <Box
                    sx={{
                      fontFamily: `Proxima Nova`,
                      fontWeight: 600,
                      fontSize: '1rem',
                      lineHeight: '30px',
                      textAlign: 'start',
                      wordBreak: 'break-word',
                      color: theme.palette.black.main,
                    }}
                  >
                    <Text field={contentBlock?.fields?.heading} />
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontFamily: `Proxima Nova`,
                        fontWeight: 400,
                        fontSize: '1rem',
                        lineHeight: '30px',
                        textAlign: 'start',
                        wordBreak: 'break-word',
                        color: theme.palette.black.main,
                        display:
                          !showMoreIndex || showMoreIndex?.includes(index)
                            ? 'block'
                            : '-webkit-box',
                        WebkitLineClamp: !isNotDesktop
                          ? 'none'
                          : showMoreIndex && showMoreIndex?.includes(index) && isNotDesktop
                          ? 'none'
                          : props?.fields?.readMoreLineLimit?.value,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'clip',
                      }}
                    >
                      <StRichTextGridItem
                        sx={{
                          '& div': {
                            ...((!showMoreIndex || showMoreIndex?.includes(index)) && {
                              minHeight: '100%',
                            }),
                          },
                          '& div > *': {
                            ...((!showMoreIndex || showMoreIndex?.includes(index)) && {
                              minHeight: '100%',
                            }),
                          },
                        }}
                      >
                        <RichText field={contentBlock?.fields?.content} />
                      </StRichTextGridItem>
                    </Box>
                    {showMoreIndex && !showMoreIndex?.includes(index) && isNotDesktop && (
                      <UILink onClick={() => handleShowMore(index)}>
                        {props?.fields?.readMoreLabel?.value}
                      </UILink>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </HCPCRGridContainerWithoutBackground>
    </HCPWrapper>
  );
};

export default HcpContentBlockList;
