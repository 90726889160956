import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import MuiButton from '@mui/material/Button';

export const HCPWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '0px',
  width: '100%',
  margin: '0px',
  marginInline: 'auto',
  gap: theme.spacing(0),
  display: 'flex',
  maxWidth: '1305px',
  justifyContent: 'center',
}));

export const HCPCRGridContainerWithoutBackground = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingBottom: '3rem',
  paddingInline: '4rem',
  marginInline: '0',
  opacity: 0.95,
  background: 'rgba(255, 255, 255, 0.95)',
  [theme.breakpoints.only('lg')]: {
    paddingInline: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.only('md')]: {
    paddingInline: '2rem',
    paddingBottom: '1rem',
  },
  [theme.breakpoints.down(620)]: {
    paddingInline: '2rem',
    paddingBottom: '0.5rem',
  },
}));

export const AccessTrainingButton = styled(MuiButton)(({ theme }) => ({
  fontSize: '1rem',
  fontStyle: 'normal',
  fontFamily: 'Proxima Nova SemiBold',
  textTransform: 'none',
  borderRadius: '50px',
  height: 'auto',
  minWidth: '205px',
  padding: '8px 20px',
  background: theme.palette.primary.main,
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

export const StContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    maxWidth: '1224px',
  },
}));
