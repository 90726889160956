import { createContext } from 'react';

export const ContactInfoFormContext = createContext(null);

const ContactInfoContextProvider = (props: any) => {
  return (
    <ContactInfoFormContext.Provider value={props?.formHandlerObject}>
      {props.children}
    </ContactInfoFormContext.Provider>
  );
};
export default ContactInfoContextProvider;
