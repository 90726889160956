import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const StContentWithIconListContainer = styled(Box)(({ theme }) => ({
  margin: '4rem 8rem',
  alignItems: 'center',
  gap: '0.6rem',
  [theme.breakpoints.down('lg')]: {
    margin: '4rem 6rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '4rem 4rem',
  },
  [theme.breakpoints.down(450)]: {
    margin: '4rem 2rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0px 30px',
    width: '1300px',
    margin: '4rem auto !important',
  },
}));

export const StImageCardContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  overflowX: 'scroll',
  margin: '51px auto',
  padding: '0 30px',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '75px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 19px',
    margin: '1rem auto !important',
  },
  [theme.breakpoints.up('xl')]: {
    justifyContent: 'space-around',
    gap: '75px',
    padding: '0 30px',
    width: '950px',
    margin: '1rem auto !important',
  },
}));
