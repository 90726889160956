import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RichTextField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { StCardTitleText } from '../SitesSelection/SiteSelection.styles';
import { StudyLocationMergeTag } from './StudyLocationMergeTag';

interface IStudyLocationCardProps {
  enrollingText: TextField;
  notYetEnrollingText: TextField;
  siteAddressFormat: RichTextField;
  studyDetails: any;
}

export const StTagInfo = styled(Box)(() => ({
  borderRadius: '5px',
  color: '#FFF',
  fontFamily: 'Proxima Nova',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '500',
  padding: '0.25rem 0.5rem',
}));

const StudyLocationCard: React.FC<any> = (props: IStudyLocationCardProps) => {
  const theme = useTheme();
  const studyTitle = props?.studyDetails?.raw?.locationsitename || '';
  return (
    <Card
      tabIndex={0}
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        height: '100%',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '0.5rem',
        }}
      >
        {props.studyDetails?.raw?.locationstatus === 'Enrolling' ? (
          <StTagInfo sx={{ background: '#077D86' }}>
            {props?.studyDetails?.raw?.locationstatus}
          </StTagInfo>
        ) : (
          <StTagInfo sx={{ background: '#595959' }}>
            {props?.studyDetails?.raw?.locationstatus}
          </StTagInfo>
        )}
        <StCardTitleText gutterBottom>{studyTitle}</StCardTitleText>
        <Divider flexItem={true} />
        <Typography
          sx={{
            margin: 0,
            fontFamily: 'Proxima Nova',
            fontSize: '0.9rem',
            fontWeight: 400,
            color: theme.palette.black.main,
          }}
        >
          <StudyLocationMergeTag
            field={props?.siteAddressFormat}
            studyDetails={props.studyDetails}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StudyLocationCard;
