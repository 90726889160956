/* eslint-disable prettier/prettier */
import { useTheme } from '@mui/material/styles';
import { UILink } from '../Links/UILink.styles';
import { StNextImage } from '../NextImage.styles';
import { ButtonWithIconProps } from './ButtonWithIcon.type';

const ButtonWithIcon = (props: ButtonWithIconProps): JSX.Element => {
  const theme = useTheme();
  const { href, image, buttonText, imageId, verticalAlignment, buttonVariant = 'outlined' } = props;
  return (
    <UILink
      sx={{
        width: '100%',
        padding: '6px 18px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        fontSize: '1em',
        lineHeight: '1.5rem',
      }}
      variant={buttonVariant}
      href={href}
    >
      <StNextImage
        suppressHydrationWarning
        id={imageId}
        field={image}
        height="20"
        width="20"
        overrideIconColor={`${theme.palette.primary.main}`}
      />
      <span style={{ marginLeft: '7px', verticalAlign: verticalAlignment }}>{buttonText}</span>
    </UILink>
  );
};

export default ButtonWithIcon;
