import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';

type ToolTipProps = {
  value: string;
};

const ToolTipStyle = {
  height: '18px',
  width: '18px',
  marginLeft: '0.2rem',
  paddingBottom: '0.2rem',
  alignSelf: 'center',
  color: (theme: Theme) => `${theme.palette.neutral8.main}`,
};

const FormToolTip = (props: ToolTipProps): JSX.Element => {
  return (
    <Tooltip title={props?.value} arrow placement="right" sx={ToolTipStyle}>
      <InfoIcon />
    </Tooltip>
  );
};

export default FormToolTip;
