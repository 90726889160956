import React, { useState } from 'react';
import { OptionIconButton } from './OptionIconButton';
import { saveAs } from 'file-saver';
import { Field, ImageField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import ShareToSocialMedia from '../../components/shared/ShareToSocialMedia/ShareToSocialMedia';
import { ShareButton } from './ShareAndDownload.styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { StNextImage } from '../../components/shared/NextImage.styles';

interface ShareAndDownloadProps {
  params: {
    pageType: string;
  };
  fields: {
    shareIcon: ImageField;
    shareText: Field<string>;
    showShareOption: Field<string>;
    sharingMedia: any;
    downloadText: Field<string>;
    downloadIcon: ImageField;
    downloadFile: ImageField;
    showDownloadOption: Field<string>;
    fileName?: donwloadItems;
  };
}

type donwloadItems = {
  value: {
    description: string;
    displayName: string;
    extension: string;
    keywords: string;
    mimeType: string;
    name: string;
    size: string;
    src: string;
    title: string;
  };
};

const fallbackIcon: ImageField = {
  value: {
    src: '',
  },
};

const ShareAndDownload = (props: ShareAndDownloadProps): JSX.Element => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const route: any = sitecoreContext?.route;
  const isShowDownloadOption = props.fields?.showDownloadOption?.value;
  const isShowShareOption = props.fields?.showShareOption?.value;
  const downloadIcon = props.fields?.downloadIcon || fallbackIcon;
  const downloadText = props.fields?.downloadText?.value || '';
  const shareIcon = props.fields?.shareIcon || fallbackIcon;
  const shareText = props.fields?.shareText?.value || '';
  const sharingMediaList = props?.fields?.sharingMedia;
  const downloadFile = props.fields?.downloadFile?.value?.src || '';
  const fileName = props.fields?.downloadFile?.value?.displayName || '';
  const renderPageName = props?.params?.pageType;
  const downloadFilePath = route?.fields?.downloadFile?.value?.src;
  const [socialEl, setSocialEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const style = {
    padding: '5px',
    width: '112px',
    marginTop: isMobile ? '2.5rem' : '2rem',
  };

  const onShareHandler = (event: React.MouseEvent<HTMLElement>) => {
    setSocialEl(event.currentTarget);
  };

  const onDownloadHandler = () => {
    const file = renderPageName === 'trialPage' ? downloadFilePath : downloadFile;
    saveAs(file, fileName);
  };

  return (
    <>
      {renderPageName !== 'articlePage' ? (
        <Box
          sx={{
            marginTop: {
              lg: renderPageName === 'trialPage' ? '40px' : '0px',
              xs: '0px',
            },
          }}
        >
          <div style={{ display: 'flex' }}>
            {isShowDownloadOption && (downloadFile || downloadFilePath) && (
              <OptionIconButton
                tooltipText={String(downloadText)}
                iconImage={downloadIcon}
                clickHandler={onDownloadHandler}
              />
            )}
            {isShowShareOption && (
              <OptionIconButton
                tooltipText={String(shareText)}
                iconImage={shareIcon}
                clickHandler={onShareHandler}
              />
            )}
            {socialEl && (
              <ShareToSocialMedia
                socialEl={socialEl}
                setSocialEl={setSocialEl}
                sharingMediaList={sharingMediaList}
              />
            )}
          </div>
        </Box>
      ) : (
        <div>
          <div style={{ display: 'flex' }}>
            {renderPageName !== 'articlePage' && downloadFile && isShowDownloadOption && (
              <OptionIconButton
                tooltipText={String(downloadText)}
                iconImage={downloadIcon}
                clickHandler={onDownloadHandler}
              />
            )}
            {isShowShareOption && (
              <ShareButton
                className={'share-btn btn'}
                type="submit"
                variant="outlined"
                tabIndex={0}
                sx={style}
                onClick={onShareHandler}
                endIcon={
                  <>
                    <IconButton
                      sx={{
                        '&: hover': {
                          backgroundColor: (theme: Theme) => `${theme.palette.secondary.dark}`,
                        },
                      }}
                    >
                      <StNextImage
                        suppressHydrationWarning
                        field={shareIcon}
                        width={'20px'}
                        height={'25px'}
                        overrideIconColor={`${theme.palette.primary.main}`}
                      />
                    </IconButton>
                  </>
                }
              >
                Share
              </ShareButton>
            )}
            {socialEl && (
              <ShareToSocialMedia
                socialEl={socialEl}
                setSocialEl={setSocialEl}
                sharingMediaList={sharingMediaList}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ShareAndDownload;
