/* eslint-disable react/jsx-key */
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import Divider from '@mui/material/Divider';
import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNextImage } from 'components/shared/NextImage.styles';
import SuccessPageMergeTag from '../SuccessPageMergeTag/SuccessPageMergeTag';
import {
  ISocialMediaFieldProps,
  ISocialMediaProps,
  SelectedSitesContainerProps,
} from './SelectedSitesContainer.type';
import { StMenuBox } from './SelectedSitesContainer.style';
import { useTheme } from '@emotion/react';

const socialMediaIconList = {
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  LinkedIn: 'LinkedIn',
  Email: 'Email',
};

const OptionIconButton: React.FC<{
  tooltipText: string;
  IconImage: ImageField | any;
  onClickEvent: (e?: any) => void;
}> = ({ tooltipText, IconImage, onClickEvent }) => {
  return (
    <Tooltip title={tooltipText} placement="top" arrow>
      <IconButton onClick={onClickEvent}>
        <StNextImage
          suppressHydrationWarning
          field={IconImage}
          width={24}
          height={24}
          sx={{
            color: (theme: any) => theme.palette.themeColor.main,
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

const MenuIconButton: React.FC<{
  tooltipText: string;
  IconImage: ImageField | any;
  onClickEvent: (e?: any) => void;
}> = ({ tooltipText, IconImage, onClickEvent }) => {
  const theme = useTheme();
  return (
    <Tooltip title={tooltipText} placement="top" arrow>
      <IconButton onClick={onClickEvent}>
        <StNextImage
          suppressHydrationWarning
          field={IconImage}
          width={24}
          height={24}
          overrideIconColor={(theme as any).palette.white.main}
        />
      </IconButton>
    </Tooltip>
  );
};

const SiteInfoBox = (props: SelectedSitesContainerProps): JSX.Element => {
  const theme = useTheme();
  const [socialEl, setSocialEl] = useState<null | HTMLElement>(null);
  const open = Boolean(socialEl);
  const sharingMedia = props?.fields?.sharingMedia || [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setSocialEl(event.currentTarget);
  };

  const handleClose = () => {
    setSocialEl(null);
  };

  const handleIconClickClose = (fields: ISocialMediaFieldProps, isEmailIcon: boolean) => {
    const sharedLink =
      fields?.shareLink?.value?.href != ''
        ? ((window.location.origin + fields?.shareLink?.value?.href) as string)
        : window.location.href;
    let url =
      (!isEmailIcon && fields?.shareMediaUrl?.value) ||
      (`mailto:?subject=${fields?.subject?.value}&body=${fields?.content?.value}%0D%0A${sharedLink}` as string);
    handleClose();
    if (url) {
      if (!isEmailIcon) url = url + encodeURIComponent(window?.location?.origin);
      window.open(url);
    }
  };

  const onGlobeButtonClick = (fields: any) => {
    const url = fields?.browseUrl?.value?.href as string;
    if (url) {
      window.open(url);
    }
    handleClose();
  };

  const onCopyButtonClick = () => {
    const siteDetails = props?.mergeTagData?.confirmedsitesData;
    const siteName = siteDetails?.siteName;
    const phoneNumber = siteDetails?.contactPhoneNumber;
    const siteAddress = `${siteDetails?.siteAddress},\n${siteDetails?.siteCity}, ${siteDetails?.siteState},\n${siteDetails?.siteCountry}, ${siteDetails?.siteZipCode};`;
    const copyText = `${siteName}\n${siteAddress}\n${phoneNumber}`;
    navigator.clipboard.writeText(copyText);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1.5rem' }}>
      <Box
        sx={{
          fontFamily: `"Proxima Nova", sans-serif`,
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: 'normal',
          textAlign: 'start',
          color: (theme) => theme.palette.neutral9.main,
        }}
      >
        <SuccessPageMergeTag
          mergeTagField={props?.fields?.selectedSiteMergeTagHeader}
          mergeTagObj={props?.mergeTagData}
        />
      </Box>
      <Divider sx={{ height: '1px', background: `rgba(255, 255, 255, 0.00)` }} />
      <Box sx={{ paddingBlock: '1rem', textAlign: 'start' }}>
        <SuccessPageMergeTag
          mergeTagField={props?.fields?.selectedSiteMergeTagDetails}
          mergeTagObj={props?.mergeTagData}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            fontWeight: 600,
          }}
        >
          <SuccessPageMergeTag
            mergeTagField={props?.fields?.selectedSiteMergeTagFootNote}
            mergeTagObj={props?.mergeTagData}
          />
        </Box>
        <Box display={'flex'} alignItems="center">
          <OptionIconButton
            tooltipText="Copy"
            IconImage={props.fields?.copyIcon}
            onClickEvent={onCopyButtonClick}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <OptionIconButton
              tooltipText="Share"
              IconImage={props.fields?.shareIcon}
              onClickEvent={handleClick}
            />
          </Box>
          <StMenuBox
            anchorEl={socialEl}
            id="social-media-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                backgroundColor: (theme as any).palette.neutral8.main,
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {sharingMedia?.map((social: ISocialMediaProps) => {
              const isEmailIcon = social?.displayName === socialMediaIconList.Email;
              return (
                <MenuItem
                  // href={url}
                  onClick={() => handleIconClickClose(social?.fields, isEmailIcon)}
                  className={'social-media-link btn'}
                >
                  <MenuIconButton
                    tooltipText={social?.displayName as string}
                    IconImage={social.fields?.shareIcon}
                    onClickEvent={(e) => e?.preventDefault()}
                  />
                </MenuItem>
              );
            })}
          </StMenuBox>
          <OptionIconButton
            tooltipText="Globe"
            IconImage={props.fields?.webIcon}
            onClickEvent={() => onGlobeButtonClick(props.fields)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SiteInfoBox;
