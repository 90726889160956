import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StErrorPageContainer = styled(Box)(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '655px',
    backgroundColor: theme.palette.neutral2.main,
    marginBottom: '-8px!important',

    '.content': {
      maxWidth: '556px',
      maxHeight: '355px',
      padding: '0px 24px',
    },
    '.title': {
      color: theme.palette.themeColor.main,
      fontFamily: 'Proxima Nova',
      fontSize: isMobile ? '26px' : '32px',
      fontWeight: 600,
      lineHeight: '35px',
      letterSpacing: '0em',
      textAlign: 'center',
    },
    '.sub-content': {
      color: theme.palette.themeColor.main,
      textAlign: 'center',
      fontFamily: 'Proxima Nova',
      fontSize: isMobile ? '16px' : '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    '.back-btn': {
      textAlign: 'center',
      fontFamily: 'Proxima Nova',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    '.back-link': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '8px 22px',
      lineHeight: '2rem',
    },
  };
});

export default StErrorPageContainer;
