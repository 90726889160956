import { useDispatch } from 'react-redux';
import { TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { setModalData, showModal } from '../../features/modalSlice';

export interface IJourneyModalProps {
  title?: TextField;
  description?: TextField;
  customButtons?: any;
}

const useJourneyModal = (props: IJourneyModalProps) => {
  const dispatch = useDispatch();

  const title = props?.title || { value: 'Resume Your Journey' };
  const description = props?.description || {
    value:
      'We see that you have already started your journey. Please click on Resume my journey if you would like to pick up from where you left',
  };

  const data: any = {
    title,
    description,
    customButtons: props?.customButtons,
    hideCloseIcon: true,
    cancelButtonCallback:
      props?.customButtons && props?.customButtons.length !== 1
        ? props?.customButtons && props?.customButtons[0]?.callback
        : {},
    continueButtonCallback:
      props?.customButtons && props?.customButtons.length !== 1
        ? props?.customButtons && props?.customButtons[1]?.callback
        : props?.customButtons && props?.customButtons[0]?.callback,
  };

  const setJourneyModal = () => {
    dispatch(setModalData(data));
  };
  const showJourneyModal = () => {
    dispatch(showModal());
  };

  return {
    setJourneyModal,
    showJourneyModal,
  };
};

export default useJourneyModal;
