/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { StSupportFormBox } from '../Support.style';
import { UIButton } from '../../shared/UIButton.styles';
import { ISubPageProps, IIssueListItem } from '../Support.types';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { post } from '../../../lib/utils/axiosFetcher';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { hideLoader, showLoader } from '../../../features/loaderSlice';
import { useDispatch } from 'react-redux';
//import { color } from '@mui/system';

interface FormValues {
  name: string;
  email: string;
  issue: string;
  message: string;
}

export const getIssueList = (listFromSitecore: Array<IIssueListItem>): string[] => {
  if (Array.isArray(listFromSitecore)) {
    return listFromSitecore.map((i) => {
      const value = String(i.fields?.name?.value || '');
      return value;
    });
  }
  return [];
};

const SupportForm: React.FC<ISubPageProps> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const namePlaceholder = String(props?.fields?.namePlaceholder?.value || '');
  const emailPlaceholder = String(props?.fields?.emailPlaceholder?.value || '');
  const issuePlaceholder = String(props?.fields?.issuePlaceholder?.value || '');
  const messagePlaceholder = String(props?.fields?.messagePlaceholder?.value || '');
  const fixedIssueValue = String(props?.fields?.issueText?.value || '');
  const buttonText = props?.fields?.buttonText;
  const setIsFormSubmitted = props?.setIsFormSubmitted;
  const isNameRequired = props?.fields?.isNameRequired?.value || false;
  const nameRequiredErrorText = String(props?.fields?.nameRequiredErrorText?.value || '');

  const isEmailRequired = props?.fields?.isEmailRequired?.value || false;
  const emailRequiredErrorText = String(props?.fields?.emailRequiredErrorText?.value || '');
  const emailValidationMessage = String(props?.fields?.emailValidationMessage?.value || '');
  const emailRegex = String(
    props?.fields?.emailRegex?.fields?.value?.value || '/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i'
  );

  const issueRequired = props?.fields?.issueRequired?.value || false;
  const issueRequiredErrorText = String(props?.fields?.issueRequiredErrorText?.value || '');

  const isMessageRequired = props?.fields?.isMessageRequired?.value || false;
  const messageRequiredErrorText = String(props?.fields?.messageRequiredErrorText?.value || '');

  const fromEmailAddress =
    props?.fields?.emailContentInfo?.fields?.fromEmailAddress?.value ||
    'clinicalresearch@iqvia.com';

  const toEmailAddress =
    props?.fields?.emailContentInfo?.fields?.toEmailAddress?.value ||
    'studies@clinicalresearch.com';
  const subjectPrefix = (props?.fields as any)?.subjectPrefix?.value;
  const smtpPort = siteSettings?.smtpPort?.value || 0;
  const smtpHostAddress = siteSettings?.smtpHostAddress?.value || 0;
  const emailTemplateContent =
    props?.fields?.emailContentInfo?.fields?.emailTemplateContent?.value || '';
  const nameRules = { required: isNameRequired ? nameRequiredErrorText : undefined };
  const issueRules = { required: issueRequired ? issueRequiredErrorText : undefined };
  const messageRules = { required: isMessageRequired ? messageRequiredErrorText : undefined };
  const emailRules = {
    required: isEmailRequired ? emailRequiredErrorText : undefined,
    pattern: {
      value: new RegExp(emailRegex, 'i'),
      message: emailValidationMessage,
    },
  };
  const dispatch = useDispatch();
  const issueList = (): Array<string> => {
    const options = props?.fields?.issueList || [];
    return getIssueList(options);
  };
  const issueOptions = issueList();

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm<FormValues>();

  const replaceMergeTagWithData = (data: any) => {
    let newString = emailTemplateContent || '';
    newString = newString.replace(/\[subject\]/g, data.issue);
    newString = newString.replace(/\[name\]/g, data.name);
    newString = newString.replace(/\[mailId\]/g, data.email);
    newString = newString.replace(/\[Message\]/g, data.message);
    return newString;
  };
  const getPayload = (data: any) => {
    const subject = subjectPrefix + data.issue;
    const payload = {
      toAddress: toEmailAddress,
      fromAddress: fromEmailAddress,
      emailBody: replaceMergeTagWithData(data),
      subject: subject,
      smtpClientAddress: smtpHostAddress,
      port: smtpPort,
    };
    return payload;
  };
  // Form submission handler
  const onSubmit = async (data: FormValues) => {
    if (fixedIssueValue) {
      data.issue = fixedIssueValue;
    }
    const payload = getPayload(data);
    try {
      dispatch(showLoader());
      const { data: response } = await post('/Send/EmailSupport', {
        ...payload,
      });
      dispatch(hideLoader());
      setIsFormSubmitted(true);
      return response;
    } catch (_e) {
      dispatch(hideLoader());
    }
  };

  const onNameBlur = async () => {
    await trigger('name');
  };
  const onEmailBlur = async () => {
    await trigger('email');
  };
  const onIssueBlur = async () => {
    await trigger('issue');
  };
  const onMessageBlur = async () => {
    await trigger('message');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="support-form">
      <StSupportFormBox>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={nameRules}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{
                '& .MuiInputBase-input': namePlaceholder
                  ? {
                      content: `"${namePlaceholder}"`,
                      color: '#757575',
                    }
                  : {},
                '& input::placeholder': {
                  color: '#000',
                },
              }}
              placeholder={namePlaceholder}
              variant="outlined"
              fullWidth
              error={Boolean(errors.name?.message)}
              helperText={errors.name?.message}
              onBlur={onNameBlur}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={emailRules}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{
                '.MuiInputBase-input': emailPlaceholder
                  ? {
                      content: `"${emailPlaceholder}"`,
                      color: '#757575',
                    }
                  : {},
                '& input::placeholder': {
                  color: '#000',
                },
              }}
              placeholder={emailPlaceholder}
              variant="outlined"
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              onBlur={onEmailBlur}
            />
          )}
        />

        {fixedIssueValue && (
          <Controller
            name="issue"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                fullWidth
                value={fixedIssueValue}
                disabled
                data-testid="fixed-issue-input"
              />
            )}
          />
        )}
        {!fixedIssueValue && Array.isArray(issueOptions) && issueOptions.length > 0 && (
          <FormControl variant="outlined" fullWidth error={Boolean(errors.issue)}>
            <Controller
              name="issue"
              control={control}
              defaultValue=""
              rules={issueRules}
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{
                    '& .MuiSelect-select .notranslate::after': issuePlaceholder
                      ? {
                          content: `"${issuePlaceholder}"`,
                          color: '#757575',
                          opacity: '0.7',
                        }
                      : {},
                  }}
                  error={Boolean(errors.issue?.message)}
                  // @ts-ignore
                  helpertext={errors.issue?.message}
                  onBlur={onIssueBlur}
                  data-testid="select-issue"
                >
                  {issueOptions.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        color: '#444',
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{errors.issue?.message}</FormHelperText>
          </FormControl>
        )}

        <Controller
          name="message"
          control={control}
          defaultValue=""
          rules={messageRules}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{
                '.MuiInputBase-input': messagePlaceholder
                  ? {
                      content: `"${messagePlaceholder}"`,
                      color: '#757575',
                    }
                  : {},
                '& input::placeholder': {
                  color: '#000',
                  opacity: '1',
                },
              }}
              placeholder={messagePlaceholder}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              error={Boolean(errors.message?.message)}
              helperText={errors.message?.message}
              onBlur={onMessageBlur}
            />
          )}
        />

        <UIButton className="submit-button btn" type="submit" variant="contained" color="primary">
          <Text field={buttonText} />
        </UIButton>
      </StSupportFormBox>
    </form>
  );
};

export default SupportForm;
