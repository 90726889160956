import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  RichTextField,
  TextField,
  ImageField,
  Text,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { ComponentProps } from 'lib/component-props';
import { StDTPGridWithoutBackground } from '../Container/Container.styles';
import { StNextImage } from '../NextImage.styles';

interface ISimplePageProps extends ComponentProps {
  fields?: {
    content: RichTextField;
    heading: TextField;
    icon: ImageField;
  };
}

const fallback = { text: { value: '' }, image: { value: { src: '' } } };

const SimplePage: React.FC<ISimplePageProps> = (props) => {
  const theme = useTheme();
  if (!props.fields) return <></>;
  const heading: TextField = props.fields?.heading || fallback.text;
  const content: RichTextField = props.fields?.content || fallback.text;
  const icon: ImageField = props.fields?.icon || fallback.image;

  return (
    <Box paddingY="32px">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <StNextImage
          suppressHydrationWarning
          field={icon}
          height={'48px'}
          width={'44px'}
        ></StNextImage>
        <Typography
          variant="h1"
          sx={{
            paddingTop: '0.25rem',
            color: (theme) => theme.palette.themeColor.main,
            fontWeight: '600',
            lineHeight: '38.4px',
            [theme.breakpoints.up('md')]: {
              fontSize: '2rem',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '1.5rem',
            },
          }}
        >
          <Text field={heading} />
        </Typography>
      </Box>
      <StDTPGridWithoutBackground>
        <Box
          sx={{
            lineHeight: 'normal',
            fontWeight: '500',
            ['h1, h2, h3']: {
              color: (theme) => theme.palette.themeColor.main,
            },
            h3: {
              fontSize: '22px',
              paddingBottom: '0px !important',
              marginBottom: '0px !important',
              marginTop: '1rem !important',
            },
            p: {
              fontSize: '16px',
              lineHeight: '24px',
            },
            a: {
              textDecoration: 'none',
              color: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <RichText field={content} />
        </Box>
      </StDTPGridWithoutBackground>
    </Box>
  );
};

export default SimplePage;
