import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const StRegistrationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginInline: '5rem',
  rowGap: '1rem',
  [theme.breakpoints.down('lg')]: {
    marginInline: '3rem',
  },
  [theme.breakpoints.down(500)]: {
    marginInline: '2rem',
  },
}));

const StRegistrationContainer = styled(Grid)(({ theme }) => {
  return {
    background: 'linear-gradient(1deg, #005587 0%, #15968f 100%)',
    paddingBlock: '5rem',
    '.checkboxLabel': {
      color: theme.palette.secondary.main,
    },
    '.checkbox': {
      color: theme.palette.secondary.main,
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
    },
    '.description': {
      a: {
        color: theme.palette.secondary.main,
        textDecorationLine: 'underline',
        textDecorationThickness: '1px',
      },
    },
  };
});

export default StRegistrationContainer;
