import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  SITECORE_ADULT_FORM_KEYS,
  SITECORE_CAREGIVER_FORM_KEYS,
  SITECORE_MINOR_FORM_KEYS,
  PATIENT_KEY_ADDRESS,
  DELEGATEKEYADDRESS,
  USER_TYPE,
  SITECORE_CR_REGISTRATION_FORM_KEYS,
} from '../../../ContactInfo/constants';
import { ChangeEvent, useState } from 'react';
import { Input } from './Input';
import { InputProps } from '../FormElements.types';
import { updateAutoCompleteLocationInfo } from '../../../../features/contactInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { JOURNEYS } from '../../../../constants';
import { RootState } from '../../../../store/store';
import {
  getStateFromZipcode,
  validateAddresWithState,
  validateAddresWithZipcode,
} from '../../../../lib/utils/contactForm';

type AutoCompleteType = google.maps.places.Autocomplete;
type PlaceResultType = google.maps.places.PlaceResult;
type AddressComponentType = google.maps.GeocoderAddressComponent;

const AutocompleteInput = (props: InputProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const [searchResult, setSearchResult] = useState<AutoCompleteType>();
  const geolocation = useSelector((state: RootState) => state.geolocation);
  const stateList = useSelector((state: RootState) => state.contactInfoDetails.stateList);
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const cdpData = useSelector((state: RootState) => state?.cdpData);

  const siteSettings: any = sitecoreContext?.siteSettings;
  const [libraries] = useState<
    ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]
  >(['places']);

  let isLoaded = false;
  const lng =
    sitecoreContext?.site?.name === 'ClinicalResearch' ? 'en' : sitecoreContext?.language || 'en';

  try {
    const loaderObj = useLoadScript({
      googleMapsApiKey: atob(siteSettings?.googleMapsAPIKey?.value || ''),
      libraries: libraries,
      language: lng,
    });

    isLoaded = loaderObj.isLoaded;
  } catch (_error) {}

  //Territory changes in autocomplete UI
  const countryList: any[] = [];
  countryList.push(props?.countryCode ? props?.countryCode : '');
  if (props?.countryCode === 'US') {
    stateList?.map((state: any) => {
      if (state?.isterritoryState === '1') {
        countryList.push(state?.countryCodeTerritory);
      }
    });
  }

  const defaultCountry = countryList;

  const sitecoreFieldsForContactInfo = useSelector(
    (state: RootState) => state.contactInfoDetails.sitecoreFieldsForContactInfo
  );

  const isCaregiverPatientZipcodeFieldAvailable =
    sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode'];

  const dispatch = useDispatch();
  const onLoad = (autocomplete: AutoCompleteType) => {
    setSearchResult(autocomplete);
  };

  const getUserType = (key: string) => {
    if (PATIENT_KEY_ADDRESS.indexOf(key) > -1) {
      return USER_TYPE.PATIENT;
    } else if (DELEGATEKEYADDRESS.indexOf(key) > -1) {
      return USER_TYPE.DELEGATE;
    }
    return USER_TYPE.DELEGATE;
  };
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place: PlaceResultType = searchResult.getPlace();
      const addressComponents: AddressComponentType[] = place.address_components
        ? place.address_components
        : [];
      const siteSettings: any = sitecoreContext?.siteSettings;
      const isStateDisable =
        JOURNEYS.JOURNEY_01 === journeyType &&
        siteSettings?.enableGeoLocation?.value &&
        geolocation?.location?.state !== null &&
        geolocation?.location?.state !== '';

      const isZipcodeDisable =
        JOURNEYS.JOURNEY_01 === journeyType &&
        siteSettings?.enableGeoLocation?.value &&
        geolocation.location.zipcode !== null &&
        geolocation.location.zipcode !== '';

      let address = '';
      let state = '';
      let city = '';
      let zipcode = '';
      let cityPopulated = false;
      let statePopulated = false;

      if (
        addressComponents.length === 0 ||
        addressComponents === null ||
        addressComponents === undefined
      ) {
        address = place?.name || '';
      } else {
        if (siteSettings.addressType && siteSettings.addressType.length > 0) {
          siteSettings.addressType.map((addressType: any, addressTypeIndex: number) => {
            addressComponents.map((item: AddressComponentType) => {
              if (item.types.includes(addressType.fields.name.value)) {
                address += item.long_name;
              }
            });
            if (addressTypeIndex < siteSettings.addressType?.length - 2 && address != '') {
              address += '  ';
            }
          });
        } else {
          address = place.formatted_address ? place.formatted_address : ' ';
        }

        siteSettings.cityType?.map((cityType: any) => {
          if (cityPopulated) {
            return;
          } else {
            addressComponents.map((item: AddressComponentType) => {
              if (item.types.includes(cityType.fields.name.value)) {
                city = item.long_name;
                cityPopulated = true;
                return;
              }
            });
          }
        });

        siteSettings.stateType?.map((stateType: any) => {
          if (statePopulated) {
            return;
          } else {
            addressComponents.map((item: AddressComponentType) => {
              if (item.types.includes(stateType.fields.name.value)) {
                state = item.long_name;
                statePopulated = true;
                return;
              }
            });
          }
        });

        addressComponents.map((item: AddressComponentType) => {
          if (item.types.includes(siteSettings.zipCodeType?.value)) {
            zipcode = item.long_name;
            return;
          }
        });
      }
      // now that we have lat and long seprate fields for patient and delegate we need to find out
      // userType could be -> patient or delegate
      const userType = getUserType(props.name);
      dispatch(
        updateAutoCompleteLocationInfo({
          [userType]: {
            lat: place.geometry?.location?.lat(),
            lng: place.geometry?.location?.lng(),
            address,
            city,
            state,
            zipcode,
          },
        })
      );
      switch (props.name) {
        case SITECORE_CR_REGISTRATION_FORM_KEYS.ADDRESS:
          props.setValue(SITECORE_CR_REGISTRATION_FORM_KEYS.ADDRESS, address);
          props.setValue(SITECORE_CR_REGISTRATION_FORM_KEYS.CITY, city);
          props.setValue(SITECORE_CR_REGISTRATION_FORM_KEYS.STATE, state);
          props.setValue(SITECORE_CR_REGISTRATION_FORM_KEYS.ZIPCODE, zipcode);

          props.trigger([
            `${SITECORE_CR_REGISTRATION_FORM_KEYS.ADDRESS}`,
            `${SITECORE_CR_REGISTRATION_FORM_KEYS.CITY}`,
            `${SITECORE_CR_REGISTRATION_FORM_KEYS.STATE}`,
            `${SITECORE_CR_REGISTRATION_FORM_KEYS.ZIPCODE}`,
          ]);
          break;
        case SITECORE_ADULT_FORM_KEYS.ADDRESS:
          const { City, State, Zipcode } = sitecoreFieldsForContactInfo['adult'];
          props.setValue(SITECORE_ADULT_FORM_KEYS.ADDRESS, address);

          if (isZipcodeDisable) {
            validateAddresWithZipcode(
              address,
              cdpData?.patientZipCode || geolocation.location.zipcode,
              siteSettings.zipCodeType.value
            ).then((response) => {
              if (!response) {
                let isValidAddress = false;

                getStateFromZipcode(geolocation.location.zipcode).then((stateRes: string) => {
                  if (stateRes) {
                    validateAddresWithState(address, stateRes, siteSettings.stateType).then(
                      (res) => {
                        if (res) {
                          isValidAddress = true;
                        } else {
                          isValidAddress = false;
                        }

                        if (!isValidAddress) {
                          props?.setError(SITECORE_ADULT_FORM_KEYS.ADDRESS, {
                            type: 'manual',
                            message: props.addressZipcodeValidationErrorMessage?.value,
                          });
                        }
                      }
                    );
                  }
                });
              }
            });
          }

          City && props.setValue(SITECORE_ADULT_FORM_KEYS.CITY, city);
          if (!isStateDisable) {
            State && props.setValue(SITECORE_ADULT_FORM_KEYS.STATE, state);
          }
          if (!isZipcodeDisable) {
            Zipcode && props.setValue(SITECORE_ADULT_FORM_KEYS.ZIPCODE, zipcode);
          }

          props.trigger([
            `${SITECORE_ADULT_FORM_KEYS.ADDRESS}`,
            `${SITECORE_ADULT_FORM_KEYS.CITY}`,
            `${SITECORE_ADULT_FORM_KEYS.STATE}`,
            `${SITECORE_ADULT_FORM_KEYS.ZIPCODE}`,
          ]);
          break;
        case SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ADDRESS:
          const { CaregiverZipCode, CaregiverCity, CaregiverState } =
            sitecoreFieldsForContactInfo['caregiver'];
          props.setValue(SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ADDRESS, address);
          CaregiverCity && props.setValue(SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_CITY, city);

          if (!(!isCaregiverPatientZipcodeFieldAvailable && isStateDisable)) {
            CaregiverState && props.setValue(SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_STATE, state);
          }

          if (!(!isCaregiverPatientZipcodeFieldAvailable && isZipcodeDisable)) {
            CaregiverZipCode &&
              props.setValue(SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ZIPCODE, zipcode);
          }
          props.trigger([
            `${SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ADDRESS}`,
            `${SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_CITY}`,
            `${SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_STATE}`,
            `${SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ZIPCODE}`,
          ]);
          break;
        case SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_ADDRESS:
          const { ParticipantZipcode, ParticipantCity, ParticipantState } =
            sitecoreFieldsForContactInfo['caregiver'];
          props.setValue(SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_ADDRESS, address);
          ParticipantCity && props.setValue(SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_CITY, city);

          if (!isStateDisable) {
            ParticipantState &&
              props.setValue(SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_STATE, state);
          }

          if (!isZipcodeDisable) {
            ParticipantZipcode &&
              props.setValue(SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_ZIPCODE, zipcode);
          }

          props.trigger([
            `${SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_ADDRESS}`,
            `${SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_CITY}`,
            `${SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_STATE}`,
            `${SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_ZIPCODE}`,
          ]);
          break;
        case SITECORE_MINOR_FORM_KEYS.PARENT_ADDRESS:
          const { ParentState, ParentCity, ZipCode } = sitecoreFieldsForContactInfo['minor'];
          props.setValue(SITECORE_MINOR_FORM_KEYS.PARENT_ADDRESS, address);
          ParentCity && props.setValue(SITECORE_MINOR_FORM_KEYS.PARENT_CITY, city);

          if (!isStateDisable) {
            ParentState && props.setValue(SITECORE_MINOR_FORM_KEYS.PARENT_STATE, state);
          }

          if (!isZipcodeDisable) {
            ZipCode && props.setValue(SITECORE_MINOR_FORM_KEYS.PARENT_ZIPCODE, zipcode);
          }
          props.trigger([
            `${SITECORE_MINOR_FORM_KEYS.PARENT_ADDRESS}`,
            `${SITECORE_MINOR_FORM_KEYS.PARENT_CITY}`,
            `${SITECORE_MINOR_FORM_KEYS.PARENT_STATE}`,
            `${SITECORE_MINOR_FORM_KEYS.PARENT_ZIPCODE}`,
          ]);
          break;
      }
    }
  };
  return (
    <>
      {isLoaded && (
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
          restrictions={{ country: defaultCountry }}
        >
          <Input
            onChangeHandler={(e: ChangeEvent<HTMLInputElement>) => {
              const userType = getUserType(props.name);
              dispatch(
                updateAutoCompleteLocationInfo({
                  [userType]: {
                    lat: null,
                    lng: null,
                    address: '',
                    city: '',
                    state: '',
                    zipcode: '',
                  },
                })
              );
              props.onChangeHandler && props.onChangeHandler(e);
            }}
            value={props.value}
            label={props.label}
            name={props.name}
            onBlur={props.onBlur}
            setError={props.setError}
            toolTip={props.toolTip}
            placeholder={props.placeholder}
            errors={props.errors}
            countryFlag={props.countryFlag}
            countryCode={props.countryCode}
            maxLength={props.maxLength}
            isMobileLabel={props.isMobileLabel}
            showIsMobileOption={props.showIsMobileOption}
            enableAutoComplete={props.enableAutoComplete}
            control={props.control}
          />
        </Autocomplete>
      )}
    </>
  );
};

export default AutocompleteInput;
