import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { StNextIcon } from '../NextImage.styles';
import ExpandedIcon from '../../../../public/expand-icon.webp';
import CollapseIcon from '../../../../public/collapse-icon.webp';

type StcollapseExpandIconProps = {
  direction: boolean;
};

export const StcollapseExpandIcon = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'direction',
})<StcollapseExpandIconProps>(({ theme, direction }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  alignSelf: direction ? 'center' : 'flex-end',
  margin: '1rem',
  // Add it if we need border when accessing via keyboard
  // '&:focus': {
  //   borderBottom: '1.5px dashed',
  //   borderColor: theme.palette.primary.main,
  // },
  '&:hover': {
    borderBottom: '1.5px dashed',
    borderColor: theme.palette.primary.main,
  },
}));

export const StTypography = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'direction',
})<StcollapseExpandIconProps>(({ theme, direction }) => ({
  color: theme.palette.themeColor.main,
  textAlign: 'center',
  margin: 0,
  lineHeight: '24px',
  alignItems: 'flex-end',
  fontFamily: 'Proxima Nova SemiBold',
  [direction ? theme.breakpoints.down(540) : theme.breakpoints.down(478)]: {
    fontFamily: 'Proxima Nova SemiBold',
    fontSize: '1.125rem',
    lineHeight: '1.69rem',
    margin: 0,
  },
}));

type CollapseExpandIconProps = {
  showExpandIcon: boolean;
  showExpandIconText: string;
  singleLineHeading: boolean;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  ariaLabel: string;
};

const CollapseExpandIcon = (props: CollapseExpandIconProps): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <StcollapseExpandIcon
      data-testid="Collapse-expand-button"
      aria-label={props?.ariaLabel}
      onClick={props.handleClick}
      direction={props?.singleLineHeading}
    >
      {props.showExpandIcon ? (
        <StTypography direction={props?.singleLineHeading}>
          <Tooltip title={props?.showExpandIconText} placement="top" arrow>
            <div>
              <StNextIcon
                suppressHydrationWarning
                alt="expand-all"
                src={ExpandedIcon}
                width={24}
                height={24}
                overrideIconColor={theme.palette.primary.main}
              />
            </div>
          </Tooltip>
        </StTypography>
      ) : (
        <StTypography direction={props?.singleLineHeading}>
          <Tooltip title={props?.showExpandIconText} placement="top" arrow>
            <div>
              <StNextIcon
                suppressHydrationWarning
                alt="collapse-all"
                src={CollapseIcon}
                width={24}
                height={24}
                overrideIconColor={theme.palette.primary.main}
              />
            </div>
          </Tooltip>
        </StTypography>
      )}
      {matches && (
        <Typography
          sx={{
            cursor: 'pointer',
            color: theme.palette.primary.main,
            lineHeight: '24px',
            paddingInline: '0.5rem',
            paddingBlock: '4px',
            fontFamily: 'Proxima Nova SemiBold',
          }}
        >
          {props?.showExpandIconText}
        </Typography>
      )}
    </StcollapseExpandIcon>
  );
};

export default CollapseExpandIcon;
