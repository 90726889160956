import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect } from 'react';
import { CDP_EVENTS } from '../../constants';
import * as cdpService from '../../services/CdpService';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

const useTriggerCdpEvent = (router: any) => {
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const tenantKey = siteSettings?.tenantKey?.value || '';
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);

  const handleRouteChangeError = (err: any, url: any) => {
    if (err.cancelled) {
      cdpService.event(CDP_EVENTS.PAGE_LOAD_FAILURE, {
        tenantKey: tenantKey,
        journeyType: journeyType,
        url: url,
      });
    }
  };

  const handleRouteChangeComplete = (url: any) => {
    if (url) {
      cdpService.event(CDP_EVENTS.PAGE_LOAD_SUCCESS, {
        tenantKey: tenantKey,
        journeyType: journeyType,
        url: url,
      });
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', (url: any) => handleRouteChangeComplete(url));
    router.events.on('routeChangeError', (err: any, url: any) => handleRouteChangeError(err, url));

    return () => {
      router.events.off('routeChangeComplete');
      router.events.off('routeChangeError');
    };
  }, [router.events]);
};
export default useTriggerCdpEvent;
