import { styled } from '@mui/material/styles';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

export const StProfileModalForm = styled('form')(({ theme }) => ({
  paddingTop: '10px',
  color: theme.palette.neutral8.main,
}));

export const StFormLabel = styled(FormLabel)(({ theme }) => ({
  paddingTop: '20px',
  color: theme.palette.neutral8.main,
}));

export const StTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  '.MuiInputBase-root': {
    background: theme.palette.neutral0.main,
    borderRadius: '8px',
    border: '1px solid #E9E9E9',
    '& input': {
      padding: '8.5px 14px',
    },
  },
  '& .Mui-error': {
    marginRight: '0px',
    marginLeft: '0px',
  },
}));
