import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const StContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.neutral8.main,
}));

export const StLastUpdatedLabel = styled(Grid)(() => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '14px',
  color: '#5E5E5E',
}));

export const StDropDown = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '85vw !important',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '90vw !important',
  },
  '& .MuiSelect-select': {
    whiteSpace: 'normal !important',
  },
}));
