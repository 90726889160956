import graphQLClient from '../../../../src/GraphQL/graphQLClient';
import SuccessPageCompGLQuery, {
  getSuccessPageComponentGLQueryParameters,
} from '../../../../src/GraphQL/queries/successPageComponent.query';

export const convertCdpComponentData = (compData: any) => {
  return compData;
};

//Function to fetch component Data from sitecore based on datasource
export const fetchComponentData = async (
  componentData: any,
  language: any,
  componentsToHide: any,
  componentName: string
) => {
  let componentProps = {};
  let fields = {};

  // Dont send fields if we need default values as they would be populated from Component-props
  if (componentData[componentName] === undefined && componentsToHide[componentName] === undefined) {
    componentProps = {
      show: true,
    };
  }
  // Only hide the component if they are in the hide list
  else if (componentsToHide[componentName] === 'null') {
    componentProps = {
      show: false,
    };
  }
  // Only Gql call if the componentData has a value for that component
  else {
    const gqlData = await graphQLClient.request<any>(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      SuccessPageCompGLQuery as any,
      getSuccessPageComponentGLQueryParameters(componentData[componentName], language)
    );
    if (gqlData?.item?.fields) {
      fields = transformData(gqlData?.item?.fields);
    }

    componentProps = {
      fields: fields,
      show: true,
    };
  }
  return componentProps;
};

//Function to transform component Data to sitecore structure
export const transformData = (fields: any) => {
  const obj: { [key: string]: any } = {};
  for (const sitecoreItem of fields) {
    const { name, jsonValue } = sitecoreItem;
    if (jsonValue?.value) {
      obj[name] = {
        value: jsonValue?.value,
      };
    } else {
      obj[name] = jsonValue;
    }
  }
  return obj;
};

//Function to show/hide component
// datasourceId === "undefined" -> Show the component
// datasourceId === {'SomeID'} -> Show the component
// datasourceId === "null" -> Hide the component
export const showOrHideComponent = (datasourceId: any) => {
  let show = true;
  if (datasourceId === 'null') {
    show = false;
  }
  return show;
};
