import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller } from 'react-hook-form';
import FormToolTip from './FormToolTip';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ErrorMessage } from '@hookform/error-message';
import { StFormDiv } from './FormElements.styles';
import { useDispatch, useSelector } from 'react-redux';
import { updatePrepopulateConfig } from '../../../features/contactInfoSlice';
import { useEffect } from 'react';
import { RootState } from '../../../store/store';
import Box from '@mui/material/Box';

enum GENDER {
  M = 'Male',
  F = 'Female',
}

export const FormInputRadio: React.FC<any> = (props) => {
  const {
    name,
    control,
    options,
    toolTip,
    label,
    rules,
    errors,
    isReadOnly,
    prePopulateFromScreener,
  } = props;
  const dispatch = useDispatch();
  const prepopulateConfig = useSelector(
    (state: RootState) => state.contactInfoDetails.prepopulateConfig
  );
  const isGenderValueAvailable = useSelector((state: RootState) => state.screenerOutput.g);
  useEffect(() => {
    dispatch(
      updatePrepopulateConfig({
        [name]: {
          readOnly: isReadOnly?.value,
          prePopulate: prePopulateFromScreener?.value,
        },
      })
    );
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, onBlur } }) => {
        return (
          <FormControl>
            <StFormDiv>
              <Text field={label} />
              {toolTip && <FormToolTip value={toolTip} />}
            </StFormDiv>
            <RadioGroup
              defaultValue={
                isGenderValueAvailable && prePopulateFromScreener?.value
                  ? GENDER[isGenderValueAvailable as keyof typeof GENDER]
                  : undefined
              }
              value={value}
              row
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
            >
              {options.map((item: any, index: any) => (
                <FormControlLabel
                  key={index}
                  name={item.key}
                  value={item.value}
                  label={<Text field={item.label} />}
                  disableTypography={true}
                  disabled={
                    isGenderValueAvailable &&
                    prepopulateConfig[name]?.readOnly === true &&
                    prepopulateConfig[name]?.prePopulate === true
                  }
                  control={
                    <Radio
                      sx={{
                        '& .MuiRadio-root': {
                          padding: 0,
                        },
                      }}
                    />
                  }
                />
              ))}
            </RadioGroup>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <Box sx={{ color: (theme) => theme.palette.error.main }}>{message}</Box>
              )}
            />
          </FormControl>
        );
      }}
    />
  );
};
