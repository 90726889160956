import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { GetServerSideComponentProps, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNextImage } from 'components/shared/NextImage.styles';
import React from 'react';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import { CardWithIconProps } from './CardWithIcon.type';

const CardWithIcon: React.FC = (props: CardWithIconProps) => {
  const theme = useTheme();
  if (!props?.fields) return <></>;
  return (
    <Box sx={{ display: 'flex', gap: '1.5rem', marginBlock: '1rem' }}>
      {props?.fields?.CardWithIconBoxesList.map((data: any, index: number) => (
        <Box
          sx={{
            background: theme.palette.neutral2.main,
            borderRadius: '0.5rem',
            display: 'flex',
            padding: '1rem 0.5rem',
            gap: '14px',
          }}
          key={index}
        >
          <StNextImage
            suppressHydrationWarning
            field={data?.fields?.cardIcon}
            width={23}
            height={23}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Text field={data?.fields?.cardLabel} />
            <Typography>
              <Text field={data.fields?.cardNumberMergeTag} />
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CardWithIcon;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'CardWithIcon'
  );

  return { ...componentProps, confirmedsitesData: cdpData.confirmedsitesData };
};
