import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StCheckBoxLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: `${theme.palette.neutral8.main}`,
}));

export const StFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: `${theme.palette.neutral8.main}`,
}));
