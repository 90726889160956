import { useEffect, useCallback } from 'react';
import { verifyMyProfileEmail } from '../../lib/utils/axiosCDPFetcher';
import { setAuthentication, setLoading } from '../../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { resetProfileData } from '../../features/crProfileSlice';

const SESSION_TOKEN = 'myProfileVerificationToken';
const ClinicalResearch = 'ClinicalResearch';

export const handleLogout = (): void => {
  sessionStorage.removeItem(SESSION_TOKEN);
};

type AuthenticationHook = {
  isAuthenticated: boolean;
};

type VerifyProfileResponse = boolean;

const verifySessionToken = async (verificationToken: string): Promise<VerifyProfileResponse> => {
  const { data } = await verifyMyProfileEmail(`/api/contacts/VerifyToken`, {
    verificationToken: verificationToken,
  });
  return data;
};

const useAuthentication = (): AuthenticationHook => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state?.authentication?.isAuthenticated);
  const { sitecoreContext } = useSitecoreContext();
  const { site } = sitecoreContext;

  const verifyToken = useCallback(async () => {
    try {
      const sessionToken = sessionStorage.getItem(SESSION_TOKEN);
      if (sessionToken && !isAuthenticated) {
        const isTokenVerified = await verifySessionToken(sessionToken);
        if (isTokenVerified) {
          dispatch(setAuthentication(isTokenVerified));
        } else {
          dispatch(setAuthentication(false));
          dispatch(resetProfileData());
          sessionStorage.removeItem(SESSION_TOKEN);
        }
      }
    } catch (_error) {
      dispatch(setAuthentication(false));
    } finally {
      dispatch(setLoading(false));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (site?.name === ClinicalResearch) verifyToken();
  }, [site?.name, sitecoreContext, verifyToken]);

  return { isAuthenticated };
};

export default useAuthentication;
