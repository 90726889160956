import { useMutation } from 'react-query';
import { savePatientDelegateDetails } from '../../lib/utils/axiosFetcher';

const saveContactInfo = async (data: { url: string; payload: any }) => {
  const { url, payload } = data;
  return savePatientDelegateDetails(url, payload);
};

export const useStoreContactInfoData = (successCallback: any, failureCallback: any) => {
  return useMutation(saveContactInfo, {
    onError: (data) => failureCallback(data),
    onSuccess: (data) => successCallback(data),
    retry: 3,
    retryDelay: 1000,
  });
};
