import { VIEW_ALL_OPTION_VALUE } from '../../constants';

interface IStudyLocationItem {
  protocol?: string;
  indicationList?: Array<{ key: string; value?: string }>;
}

const filterByConditionValue = <T extends IStudyLocationItem>(
  locations: Array<T>,
  selectedValue: string
): Array<T> => {
  const arr = locations.filter((item) => {
    if (Array.isArray(item.indicationList)) {
      const indicationValues = item.indicationList?.map((indication) => indication.value);
      return Array.isArray(indicationValues) ? indicationValues.includes(selectedValue) : false;
    }
    return false;
  });
  return arr;
};

const filterByProtocol = <T extends IStudyLocationItem>(
  locations: Array<T>,
  selectedValue: string
): Array<T> => {
  return locations.filter((item) => item.protocol === selectedValue);
};

const studyLocationFilter = <T extends IStudyLocationItem>(
  locations: Array<T>,
  selectedValue: string,
  isConditionSwitcher: boolean
): Array<T> => {
  const isViewAll = selectedValue === VIEW_ALL_OPTION_VALUE;
  if (Array.isArray(locations)) {
    if (selectedValue && !isViewAll) {
      if (isConditionSwitcher) {
        const conditionFilteredLocations = filterByConditionValue(locations, selectedValue);
        return conditionFilteredLocations;
      } else {
        const protocolFilteredLocations = filterByProtocol(locations, selectedValue);
        return protocolFilteredLocations;
      }
    } else {
      return locations;
    }
  }
  return [];
};

export default studyLocationFilter;
