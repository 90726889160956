import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const StThankYouWrapperContainer = styled(Grid)(({ theme }) => {
  return {
    background: `${theme.palette.white.main}`,
    padding: '6rem 2rem ',
    minHeight: `calc(100vh - 7rem)`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '.custom-link': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '24px',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '.mail-text': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    '.title': {
      padingBottom: '5px',
      fontSize: '30px',
      fontFamily: 'Proxima Nova',
      fontWeight: 600,
      lineHeight: '30px',
      color: theme.palette.neutral8.main,
    },
    '.subtitle': {
      fontSize: '1rem',
      padingBottom: '5px',
      fontFamily: 'Proxima Nova',
      color: theme.palette.black.main,
    },
    [theme.breakpoints.down('md')]: {
      '.title': {
        fontSize: '1.2rem',
        padingBottom: '5px',
      },
      '.sub-title': {
        fontSize: '1.5rem',
        fontWeight: '400',
      },
    },
  };
});

export default StThankYouWrapperContainer;
