import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiTypography from '@mui/material/Typography';

export const StSectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: `"Proxima Nova", sans-serif`,
  fontSize: '32px',
  lineHeight: '24px',
  fontWeight: 600,
  color: theme.palette.neutral12.main,
  textAlign: 'center',
  marginTop: '2rem',
}));

export const StCardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: `"Proxima Nova", sans-serif`,
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 600,
  opacity: 1,
  color: theme.palette.neutral11.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  textTransform: 'uppercase',
}));

type SubTitleProps = {
  limit: number;
};

export const StCardSubTitle = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'limit',
})<SubTitleProps>(({ theme, limit }) => ({
  fontFamily: `"Proxima Nova", sans-serif`,
  fontSize: '18px',
  lineHeight: '27px',
  fontWeight: 600,
  color: theme.palette.black.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: limit || 2,
  height: `${limit * 25}px`,
  WebkitBoxOrient: 'vertical',
}));

export const StCardDesc = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'limit',
})<SubTitleProps>(({ theme, limit }) => ({
  fontFamily: `"Proxima Nova Medium", sans-serif`,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
  color: theme.palette.black.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: limit || 3,
  height: `${limit * 25}px`,
  WebkitBoxOrient: 'vertical',
}));
