import { useDispatch } from 'react-redux';
import { setScrollId } from '../../features/scrollIdSlice';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { useEffect } from 'react';

type ScrollToElementFunction = (id: string) => void;
const useScrollToElement = (): ScrollToElementFunction => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to: string, element: HTMLElement) => {
      console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to: string, element: HTMLElement) => {
      console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const scrollToElement: ScrollToElementFunction = async (id) => {
    const ids: { [key: string]: number } = { getstarted: 700 };
    const element = document.getElementById(id);

    if (element) {
      const offsetTop = parseInt(String(element.offsetTop), 10) + ids[id] || 0;
      scroll.scrollTo(offsetTop);
      dispatch(setScrollId(null));
    }
  };

  return scrollToElement;
};

export default useScrollToElement;
