import { useEffect } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useDispatch } from 'react-redux';
import { updateCRQueryParamList } from '../../features/crQueryParamSlice';

const useCRQueryParam = () => {
  const router: NextRouter = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const siteName = sitecoreContext?.site?.name;
  const retainQueryParamList = siteSettings?.RetainQueryParamList?.value?.split('&');

  const dispatch = useDispatch();
  const { query } = router;

  useEffect(() => {
    const result: any = {};
    if (
      siteName === 'ClinicalResearch' &&
      retainQueryParamList &&
      retainQueryParamList.length > 0
    ) {
      retainQueryParamList.forEach((key: string) => {
        if (query.hasOwnProperty(key)) {
          const newKey = key === '_utmz' ? 'utmz' : key;
          result[newKey] = query[key];
        }
      });
      if (Object.keys(result).length > 0) {
        dispatch(updateCRQueryParamList(result));
      }
    }
  }, [query]);
};

export default useCRQueryParam;
