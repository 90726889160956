import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { tabsClasses } from '@mui/material/Tabs';
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React from 'react';
import Dropdown from '../shared/Dropdown/Dropdown';
import IconWithTitle from '../shared/IconWithTitle/IconWithTitle';
import OneColumnGrid from '../shared/OneColumnGrid/OneColumnGrid';
import Tabs from '../shared/Tabs/Tabs';
import TwoColumnGrid from '../shared/TwoColumnGrid/TwoColumnGrid';
import { TwoColumnGridProps } from '../shared/TwoColumnGrid/TwoColumnGrid.types';
import { StDropDown, StTabListContainer, StTabsContainer } from './TabList.style';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type TabOption = {
  value: string;
};
type TabOptionArray = Array<TabOption> | [];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

export type ITabListProps = ComponentProps & {
  fields: {
    titleIcon: ImageField;
    iconWithTitleVariant: { value: string };
    title: Field<string>;
    listofItems: TwoColumnGridProps[];
    ariaLabel: Field<string>;
  };
};

const TabList = (props: ITabListProps): JSX.Element => {
  const theme = useTheme();
  const { titleIcon, title, iconWithTitleVariant } = props?.fields || {};
  const [value, setValue] = React.useState(0);
  const headingWithIconProps = {
    titleIcon: titleIcon,
    title: title,
    iconWithTitleVariant: iconWithTitleVariant?.value as string,
  };
  const tabListOptions: TabOptionArray =
    (props?.fields?.listofItems &&
      props?.fields?.listofItems.map(({ fields }: any) => fields.title!)) ||
    [];

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };
  if (!props.fields) {
    return <></>;
  }
  const isSingleColumn = (fields: any) => (fields?.contentList.length > 1 ? false : true);
  if (props?.fields?.listofItems && props?.fields?.listofItems.length < 1) {
    return <></>;
  }

  return (
    <Box
      id="tabs-list"
      sx={{
        [theme.breakpoints.up('xl')]: {
          width: '1366px',
          marginInline: 'auto',
        },
      }}
    >
      <StTabListContainer container direction={'column'} alignItems="center">
        <Grid item sx={{ marginTop: '1rem' }} id="tabs-list-title">
          <IconWithTitle {...headingWithIconProps} />
        </Grid>

        <Grid item container justifyContent={'center'}>
          <StDropDown
            item
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <Dropdown
              fullWidth={true}
              labelId="scroll-list-select-label"
              id="tab-list-select"
              dataTestId="tab-list-select"
              defaultValue={0}
              type="number"
              value={value}
              onChangeHandler={(e: any) => {
                const num = Number(e.target.value);
                handleChange(e, num);
              }}
              options={tabListOptions}
              selectSxProps={{
                borderRadius: '30px !important',
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: (theme: Theme) => `${theme.palette.neutral4.main}`,
                  },
                  '&:hover fieldset': {
                    borderColor: (theme: Theme) => `${theme.palette.neutral4.main}`,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: (theme: Theme) => `${theme.palette.neutral4.main}`,
                  },
                },
              }}
              menuItemSxProps={{ whiteSpace: 'normal' }}
            />
          </StDropDown>
          <StTabsContainer
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <Tabs
              id="tabs-list-tabs"
              value={value}
              onChangeHandler={handleChange}
              variant="scrollable"
              scrollButtons={'auto'}
              ariaLabel={props?.fields?.ariaLabel?.value}
              tabListOptions={tabListOptions}
              type="text"
              tabsContainerSxProps={{
                background: '#fff',
                border: (theme: Theme) => `1px solid ${theme.palette.neutral4.main}`,
                borderRadius: '32px',
                marginTop: '1rem',
                '& .Mui-selected': {
                  color: '#ffffff !important',
                  backgroundColor: 'primary.main',
                  fontWeight: '500',
                },
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
              tabSxProps={{
                minWidth: '160px',
                maxWidth: 'none !important',
                textTransform: 'none',
                color: '#757575',
                '&:hover:not(.Mui-selected)': {
                  backgroundColor: (theme: Theme) => theme.palette.secondary.dark,
                },
              }}
            />
          </StTabsContainer>
        </Grid>
        {props?.fields &&
          props?.fields?.listofItems?.map(({ fields }, index) => {
            const isOneColumn = isSingleColumn(fields);
            return (
              <TabPanel value={value} key={index} index={index}>
                {isOneColumn ? (
                  <OneColumnGrid {...fields?.contentList[0]} isSingleColumn={isOneColumn} />
                ) : (
                  <TwoColumnGrid
                    fields={fields}
                    isSingleColumn={isOneColumn}
                    maintainOrder={true}
                  />
                )}
              </TabPanel>
            );
          })}
      </StTabListContainer>
    </Box>
  );
};

export default TabList;
