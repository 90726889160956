import React, { useEffect, useState } from 'react';
import CRModal from '.';
import Box from '@mui/material/Box';
import { PromptIdleTimeouFooterModal } from './FooterModal';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';

interface ModalProps {
  getRemainingTime: () => any;
  modal: {
    open: boolean;
    onContinue: () => void;
    onSignOut: () => void;
  };
}

const PromptIdleTimeoutModal: React.FC<ModalProps> = ({
  modal: { open, onContinue, onSignOut },
  getRemainingTime,
}) => {
  const { sitecoreContext } = useSitecoreContext();
  const promptIdleSignouPopup = (sitecoreContext as any)?.idleSessionPopup?.promptIdleSignouPopup;
  const promptSignoutSessionTime = (sitecoreContext as any)?.idleSessionPopup
    ?.promptSignoutSessionTime;
  const [remainingTime, setRemainingTime] = React.useState<number>(
    promptSignoutSessionTime?.value * 60 * 1000
  );
  const { t } = useI18n();
  const {
    modalCTAButtons = [],
    modalTitle,
    modalDescription,
  } = promptIdleSignouPopup?.fields || {};
  const [modalDescriptionText, setModalDescriptionText] = useState<any>(
    modalDescription.value || ''
  );
  const timerRef = React.useRef<any>(null);
  const minutesLabel = t('minutes') || 'minutes';
  const minuteLabel = t('minute') || 'minute';
  const secondsLabel = t('seconds') || 'seconds';
  const secondLabel = t('second') || 'second';

  useEffect(() => {
    timerRef.current = setInterval(() => {
      const time = getRemainingTime();
      setRemainingTime(time);
    }, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  });

  useEffect(() => {
    const timeInSeconds = Math.floor(remainingTime / 1000);
    const mins = Math.floor(timeInSeconds / 60);
    const secs = timeInSeconds % 60;
    let timeText = '';
    if (mins >= 1) {
      const minLabel = mins > 1 ? minutesLabel : minuteLabel;
      const secLabel = secs > 1 ? secondsLabel : secondLabel;
      timeText = secs > 0 ? `${mins} ${minLabel} ${secs} ${secLabel}` : `${mins} ${minLabel}`;
    } else {
      const secLabel = secs > 1 ? secondsLabel : secondLabel;
      timeText = `${secs} ${secLabel}`;
    }
    const newString = modalDescription?.value?.replace(/\[time\]/g, `${timeText}`);
    setModalDescriptionText(newString as any);
  }, [remainingTime]);

  return (
    <CRModal
      open={open}
      handleClose={onContinue}
      handleCancel={onContinue}
      title={modalTitle?.value}
    >
      <Box sx={{ color: '#444444', width: '100%' }}>
        <RichText field={{ value: modalDescriptionText }} />
        <PromptIdleTimeouFooterModal
          modalCTAButtons={modalCTAButtons}
          onClose={onContinue}
          onSignOut={onSignOut}
        />
      </Box>
    </CRModal>
  );
};

export default PromptIdleTimeoutModal;
