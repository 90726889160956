import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StThreeStepItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '254px',
  rowGap: '24px',
}));

export const StThreeStepItemVar1 = styled(StThreeStepItem)(({ theme }) => ({
  '.MuiTypography-root': {
    textAlign: 'center',
    fontSize: '44px',
    fontWeight: '600',
    color: theme.palette.themeColor.main,
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '200px',
    '.MuiTypography-root': {
      marginTop: '2rem',
      fontSize: '32px',
    },
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
    marginInline: '0.5rem',
  },
}));

export const StThreeStepItemVar2 = styled(StThreeStepItem)(({ theme }) => ({
  rowGap: '0.5rem',
  '.MuiTypography-root': {
    fontSize: '32px',
    color: theme.palette.neutral8.main,
    marginBlock: '1rem',
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '400px',
  },
}));

export const StThreeStepItemVar3 = styled(StThreeStepItem)(({ theme }) => ({
  rowGap: '0.5rem',
  maxWidth: '312px',
  textAlign: 'center',
  '.MuiTypography-root': {
    fontSize: '32px',
    color: theme.palette.neutral8.main,
    marginBlock: '1rem',
  },
  '.MuiBox-root': {
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 600,
    },
    p: {
      color: theme.palette.neutral8.main,
      marginBottom: '0.5rem',
      marginTop: '0',
    },
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '400px',
  },
}));
