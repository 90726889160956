import { StMainHeadingTypography, StTitleBox } from './ViewTrials/ViewTrials.styles';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const TrialTitle = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const route: any = sitecoreContext?.route;
  const trialTitle = route?.fields?.trialTitle;

  return (
    <StTitleBox data-testid="trial-title">
      <StMainHeadingTypography variant="h1">{trialTitle?.value}</StMainHeadingTypography>
    </StTitleBox>
  );
};

export default TrialTitle;
