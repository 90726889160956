import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type InvertColorProps = {
  invert: boolean;
};

export const StNumber = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'invert',
})<InvertColorProps>(({ theme, invert }) => ({
  color: invert ? theme.palette.white.main : theme.palette.themeColor.main,
  textAlign: 'center',
  fontWeight: 800,
  fontSize: '72px',
  lineHeight: '1',
  paddingInline: '0.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
  },
}));
