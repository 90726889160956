import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const JssBarContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  [theme.breakpoints.down('md')]: {
    display: 'inherit',
  },
}));

export const JssBarItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginBottom: '1rem',
  padding: '10px 8px',
  '&.active-item': {
    background: '#f2f2f2',
    borderRadius: '8px',
  },
  '& h6': {
    fontWeight: 500,
  },
  '& .active-item': {
    fontWeight: 600,
  },
  '& .item-text': {
    fontSize: '18px',
    paddingLeft: '12px',
  },
  '& .item-text.disabled-item': {
    color: '#999999',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    padding: '12px',
    borderRadius: '15px',
    justifyContent: 'center',
    '& .item-text': {
      paddingLeft: '0px',
    },
    '& .MuiTypography-h6': {
      textAlign: 'center',
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    borderRadius: 'inherit',
    justifyContent: 'start',
    '& .item-text': {
      paddingLeft: '12px',
    },
    '& .MuiTypography-h6': {
      textAlign: 'inherit',
    },
  },
}));
