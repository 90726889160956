import React from 'react';
import CRModal from '.';
import Box from '@mui/material/Box';
import { ReSendFooterModal, ModalCTAButton, replaceValue } from './FooterModal';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

interface ModalProps {
  modal: {
    open: boolean;
    onClose: () => void;
    onResendEmail?: () => void;
    onCloseModals?: () => void;
  };
  fieldProps: {
    modalCTAButtons: ModalCTAButton[];
    modalTitle?: { value: string };
    modalDescription?: { value: string };
  };
  emailResendCount?: number;
  email: string;
}

const ReSendModal: React.FC<ModalProps> = ({
  modal: { open, onClose, onResendEmail, onCloseModals },
  fieldProps,
  emailResendCount,
  email,
}) => {
  const { modalCTAButtons = [], modalTitle, modalDescription } = fieldProps;
  return (
    <CRModal
      open={open}
      handleClose={onClose}
      handleContinue={onCloseModals}
      handleCancel={onClose}
      title={modalTitle?.value}
    >
      <Box sx={{ color: '#444444' }}>
        <RichText
          field={{ value: replaceValue(modalDescription?.value || '', { email: email }) }}
        />
        <ReSendFooterModal
          modalCTAButtons={modalCTAButtons}
          onClose={onClose}
          onResend={onResendEmail}
          emailResendCount={emailResendCount}
          onCloseModals={onCloseModals}
        />
      </Box>
    </CRModal>
  );
};

export default ReSendModal;
