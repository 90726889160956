import Grid from '@mui/material/Grid';
import FormFactory from './FormFactory';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { PRIMARY_CONSENT_MEDIUM, SECONDARY_CONSENT_MEDIUM, formFieldType } from './constants';
import {
  ItemInterface,
  FormStepDataInterface,
  ContactFieldListItemType,
  ContactInfoGenericItemInterface,
} from './ContactInfo.types';
import { useEffect, useState } from 'react';
import { updateContactInfoConsents } from '../../features/contactInfoSlice';
import { useDispatch } from 'react-redux';

const StTypography = styled(Typography)(({ theme }) => ({
  variant: 'h5',
  color: theme.palette.themeColor.main,
  marginBottom: '5px',
  fontWeight: 600,
}));

type FormBuilder = {
  data: ItemInterface<FormStepDataInterface>;
};
const FormBuilder = (props: FormBuilder): JSX.Element => {
  const [consents, setConsents] = useState<any>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const combinedConsentMedium: any = props?.data?.fields?.contactFieldList.filter((item: any) => {
      if (item?.fields?.key?.value === 'CombinedConsentMedium') {
        return item;
      }
    });
    if (combinedConsentMedium.length > 0) {
      dispatch(updateContactInfoConsents(combinedConsentMedium));
      setConsents(combinedConsentMedium);
    } else {
      const consents = props?.data?.fields?.contactFieldList.filter((item: any) => {
        if ([PRIMARY_CONSENT_MEDIUM, SECONDARY_CONSENT_MEDIUM].includes(item?.fields?.key?.value)) {
          return item;
        }
      });
      dispatch(updateContactInfoConsents(consents));
      setConsents(consents);
    }
  }, [props]);

  const formProps: ContactFieldListItemType[] = props?.data?.fields?.contactFieldList;
  return (
    <Grid container spacing={3}>
      {formProps.map((formDataObj, formDataIndex: number) => {
        if (formDataObj?.fields?.type?.value === formFieldType.SECTION_HEADING) {
          return (
            <Grid item key={formDataIndex}>
              <StTypography>
                <Text field={formDataObj?.fields?.label} />
              </StTypography>
            </Grid>
          );
        }
        const formData = formDataObj as ContactInfoGenericItemInterface;
        const formDataFields =
          formData?.fields &&
          formData?.fields?.contactFieldList &&
          formData?.fields?.contactFieldList?.length > 0;
        if (formDataFields) {
          if (formData?.fields?.contactFieldList?.length === 1) {
            return (
              <Grid item container xs={12} md={12} lg={12} key={formDataIndex}>
                <FormFactory formFields={formData?.fields?.contactFieldList[0].fields} />
              </Grid>
            );
          } else {
            return (
              <Grid item container xs={12} md={12} lg={12} key={formDataIndex} spacing={3}>
                {formData?.fields?.contactFieldList?.map((subFields: any, subFieldsIndex: any) => {
                  return (
                    <Grid item xs={12} md={12} lg={6} key={subFieldsIndex}>
                      <FormFactory formFields={subFields.fields} />
                    </Grid>
                  );
                })}
              </Grid>
            );
          }
        } else {
          const consentMedium = [
            'PrimaryConsentMedium',
            'SecondaryConsentMedium',
            'CombinedConsentMedium',
          ];

          if (!consentMedium.includes(formDataObj?.fields?.key?.value as string)) {
            const hideFromUI = formDataObj?.fields?.hideFromUI?.value;
            return (
              <Grid
                sx={{ display: hideFromUI ? 'none' : 'block' }}
                item
                xs={12}
                md={12}
                lg={12}
                key={formDataIndex}
              >
                <FormFactory formFields={formData.fields} />
              </Grid>
            );
          }
        }
        return null;
      })}
      {consents.map((item: any) => {
        const hideFromUI = item?.fields?.hideFromUI?.value;
        return (
          <Grid
            sx={{ display: hideFromUI ? 'none' : 'block' }}
            item
            xs={12}
            md={12}
            lg={12}
            key={item?.fields.key?.value}
            id={item?.fields.key?.value}
          >
            <FormFactory formFields={item.fields} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormBuilder;
