import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { StContainer, StLastUpdatedLabel, StDropDown } from './ScrollList.style';
import { ComponentProps } from 'lib/component-props';
import Dropdown from '../shared/Dropdown/Dropdown';
import Tabs from '../shared/Tabs/Tabs';

interface TabPanelProps {
  children?: React.ReactNode;
  ref?: any;
  index: number;
  value: number;
  heading: any;
}
interface ListItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    content: Field<string>;
    heading: Field<string>;
  };
}
type ScrollListProps = ComponentProps & {
  fields: {
    listofItems: ListItem[];
    content: Field<string>;
    heading: Field<string>;
  };
};

let shouldAvoidScrollEvent = false;
let timeout: any;

const ScrollList = (props: ScrollListProps): JSX.Element => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isLaptopView = useMediaQuery(theme.breakpoints.up('lg'));
  const refs = React.useRef<any>([]);
  type TabOption = {
    value: string;
  };
  type TabOptionArray = Array<TabOption> | [];
  const dropdownOptions: TabOptionArray =
    (props?.fields?.listofItems &&
      props?.fields?.listofItems?.map(({ fields }) => fields?.heading)) ||
    [];

  function TabPanel(props: TabPanelProps) {
    const { children, index, heading, ...other } = props;

    return (
      <div
        ref={(element) => {
          refs.current[index] = element;
        }}
        role="tabpanel"
        id={heading?.value?.replaceAll(' ', '-')}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        <Box sx={{ p: 2 }}>{children}</Box>
      </div>
    );
  }

  function onclickHandler(_e: React.SyntheticEvent, val: number) {
    shouldAvoidScrollEvent = true;
    const bodyElement = document.getElementsByTagName('body')[0];

    //workaround (Desktop view) we have added 500ms delay before right side scroll started,
    //without this delay if we click first or last item on left side that triggers left scroll and
    //that create issue for right side scrolling
    setTimeout(() => {
      bodyElement.classList.add('bodyFixed');
      refs.current[val].scrollIntoView();
      bodyElement.classList.remove('bodyFixed');
    }, 500);
  }

  const handleChange = (event: React.SyntheticEvent | any, newValue: number) => {
    setValue(newValue);
    event.preventDefault();
    event.stopPropagation();
    onclickHandler(event, newValue);
  };

  function onScrollEventHandler() {
    if (shouldAvoidScrollEvent) {
      return;
    }
    const sections = document.querySelectorAll('div[role="tabpanel"]');

    // Add an event listener listening for scroll
    const mainDiv = document.getElementById('main-scroll-list')! as HTMLDivElement;
    const scrollY = mainDiv.scrollTop;

    // Now we loop through sections to get height, top and ID values for each
    let activeTab = 0;
    const topMarginBuffer = isLaptopView ? 150 : 250;
    sections.forEach((current: HTMLDivElement, index) => {
      const sectionHeight = current?.offsetHeight;
      const sectionTop = current?.offsetTop - topMarginBuffer;

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        activeTab = index;
        setValue(activeTab);
      }
    });
  }
  if (!props?.fields) return <></>;

  return (
    <StContainer container justifyContent="center">
      <Grid item container direction={'column'} sx={{ padding: isLaptopView ? '2rem 3rem' : 0 }}>
        <Grid
          item
          container
          direction={isLaptopView ? 'row' : 'column'}
          sx={{ alignItems: 'baseline', padding: isLaptopView ? 0 : '2rem 2rem 0' }}
        >
          <Grid item sx={{ flexBasis: isLaptopView ? '375px' : 'auto' }}>
            <Typography variant="h2" data-testid="page-title">
              <Text field={props?.fields?.heading} />
            </Typography>
          </Grid>
          <StLastUpdatedLabel item>
            <RichText field={props?.fields?.content} />
          </StLastUpdatedLabel>
        </Grid>
        <Grid
          item
          container
          sx={{ marginTop: '0.5rem' }}
          direction={isLaptopView ? 'row' : 'column'}
        >
          {isLaptopView ? (
            <Grid
              item
              container
              sx={{
                flexBasis: '375px',
                height: '75vh !important',
              }}
            >
              <Grid item flex={1}>
                <Tabs
                  value={value}
                  type="text"
                  orientation="vertical"
                  onChangeHandler={handleChange}
                  variant="scrollable"
                  scrollButtons={'auto'}
                  tabAltComponent={'a'}
                  ScrollButtonComponent={() => {
                    return null;
                  }}
                  ariaLabel="scrollable-tabs"
                  tabListOptions={dropdownOptions}
                  tabsContainerSxProps={{
                    borderRight: 1,
                    borderColor: 'divider',
                    backgroundColor: '#fff',
                    height: '75vh !important',
                    overflowY: 'scroll',
                    scrollBehavior: 'smooth !important',

                    '& .Mui-selected': {
                      color: '#ffffff !important',
                      backgroundColor: 'primary.main',
                      fontWeight: '500',
                    },
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                    '& svg': {
                      display: 'block',
                    },
                    '&::-webkit-scrollbar-track': {
                      display: 'none',
                    },

                    '& .MuiTabs-scroller .MuiTabs-flexContainerVertical': {
                      overflowY: 'scroll !important',
                      height: '100% !important',
                      '&::-webkit-scrollbar-track': {
                        display: 'none',
                      },
                    },
                  }}
                  tabSxProps={{
                    fontFamily: 'Proxima Nova',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '18px',
                    lineHeight: '18px',

                    color: (theme: Theme) => theme.palette.neutral8.main,
                    textAlign: 'left',
                    maxWidth: '100%',
                    position: 'relative',
                    alignItems: 'flex-start',
                    textTransform: 'none',
                    left: 0,
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{
                  border: '1px solid #979797',
                  margin: '0 26px',
                }}
              ></Grid>
            </Grid>
          ) : (
            <StDropDown
              item
              sx={{
                margin: '0 32px 32px',
                display: { sm: 'block', lg: 'none' },
              }}
            >
              <Dropdown
                fullWidth={true}
                labelId="scroll-list-select-label"
                id="scroll-list-select"
                dataTestId="scroll-list-select"
                defaultValue={0}
                type="number"
                value={value}
                onChangeHandler={(e: any) => {
                  const num = Number(e.target.value);
                  handleChange(e, num);
                }}
                options={dropdownOptions}
                menuItemSxProps={{ whiteSpace: 'normal' }}
              />
            </StDropDown>
          )}
          <Grid
            item
            id="main-scroll-list"
            sx={{
              backgroundColor: theme.palette.neutral0.main,
              maxHeight: '75vh',
              overflowY: 'scroll',
              scrollBehavior: 'smooth !important',
              flex: 1,
              '&::-webkit-scrollbar-track': {
                display: 'none',
              },
            }}
            onScroll={() => {
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                shouldAvoidScrollEvent = false;
              }, 100);
              onScrollEventHandler();
            }}
          >
            {props?.fields?.listofItems &&
              props?.fields?.listofItems.map(({ fields }, index) => {
                return (
                  <TabPanel
                    value={value}
                    heading={fields?.heading}
                    index={index}
                    key={`${index + 1}`}
                  >
                    <Typography variant="h4">
                      {`${index + 1}. `}
                      <Text field={fields?.heading} />
                    </Typography>
                    <div style={{ padding: '8px 24px' }}>
                      <RichText field={fields?.content} />
                    </div>
                  </TabPanel>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </StContainer>
  );
};

export default ScrollList;
