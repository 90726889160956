import { useSelector } from 'react-redux';
import { ComponentProps } from 'lib/component-props';
import { useSitecoreContext, useComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import {
  CONSENTS_MEDIUM,
  PHONE_NUMBER,
  PRIMARY_CONSENT_MEDIUM,
  contactFormFieldType,
} from '../../components/ContactInfo/constants';
import { RootState } from '../../store/store';
import {
  ContactInfoFieldsInterface,
  ItemInterface,
} from '../../components/ContactInfo/ContactInfo.types';
import useStoreUpdateWithStateAndTimeZoneList from '../../lib/hooks/useStoreUpdateWithStateAndTimeZoneList';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import {
  goToStep,
  updateContactInfoUrl,
  updateFieldIsPresentOrNotFromSitecore,
} from '../../features/contactInfoSlice';
import useCustomErrorModal from './useCustomErrorModal';
import usePatientErrorModal from './usePatientErrorModal';
import { CONTACT_INFO_URLS } from '../../constants';
import { getCookieExpiryTime, getOrCreateEnrollmentId } from '../utils/enrollmentUtil';
import * as cdpService from '../../services/CdpService';
// import { addParticipantOnLoad, post as postToDB } from '../../lib/utils/axiosFetcher';
import { post as postToDB } from '../../lib/utils/axiosFetcher';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
// import { updatepotentialParticipantDetails } from '../../features/potentialParticipantDetailsSlice';
// import getCallCenterNumber from '../../store/selectors/getCallCenterNumber';
import { getCookie, setCookie } from 'cookies-next';
import useJourneyModal from './useJourneyModal';
import { formateCOIList, getQueryParams } from '../../lib/utils/common';

export type ContactListProps = ComponentProps & ContactInfoFieldsInterface;

const useContactInfoHelper = (props: ContactListProps) => {
  const router = useRouter();
  const { query, locale, defaultLocale } = router;
  const selectedLanguage = useSelector((state: RootState) => state.selectedLanguage);
  const potentialParticipant_ID = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialParticipant_ID
  );
  const potentialId = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialId
  );
  const screenerOutput = useSelector((state: RootState) => state.screenerOutput);
  const prepopulateConfig = useSelector(
    (state: RootState) => state.contactInfoDetails.prepopulateConfig
  );
  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const geolocation = useSelector((state: RootState) => state.geolocation);
  const cdpState = useSelector((state: RootState) => state.cdpData);
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { sitecoreContext } = useSitecoreContext();
  const { stateList, timeZoneList, COIList } = useComponentProps<any>(props.rendering.uid);
  const { language, siteRootPath, siteSettingsId, itemPath, siteSettings, resumeLinkModal } =
    sitecoreContext;
  const dispatch = useDispatch();
  const [isRecapchaEnabled] = useState(function () {
    let value = false;
    props?.fields?.items?.map((subItems: ItemInterface<any>) => {
      if (
        subItems?.fields?.type?.value === contactFormFieldType.RECAPTCHA_CONFIGURATION &&
        subItems?.fields?.enableGoogleRecaptcha.value === true
      ) {
        value = true;
      }
    });
    return value;
  });

  const [ContactNumberField] = useState(function () {
    let value: any = { fields: { mobileNumberValidationMessageOnSMSConsent: { value: '' } } };
    props?.fields?.items?.map((subItems: ItemInterface<any>) => {
      if (subItems?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE) {
        subItems?.fields?.contactFieldList.map((contactFieldSubItem: any) => {
          if (PHONE_NUMBER.indexOf(contactFieldSubItem?.fields?.key?.value) !== -1) {
            value = contactFieldSubItem;
          }
        });
      }
    });
    return value;
  });

  const [PrimaryConsentField] = useState(function () {
    let value: any = { fields: { hideFromUI: { value: '' } } };
    props?.fields?.items?.map((subItems: ItemInterface<any>) => {
      if (subItems?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE) {
        subItems?.fields?.contactFieldList.map((contactFieldSubItem: any) => {
          if (contactFieldSubItem?.fields?.key?.value === PRIMARY_CONSENT_MEDIUM) {
            value = contactFieldSubItem;
          }
        });
      }
    });
    return value;
  });

  const [ConsentsMedium] = useState(function () {
    const value: any = [];
    props?.fields?.items?.map((subItems: ItemInterface<any>) => {
      if (subItems?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE) {
        subItems?.fields?.contactFieldList.map((contactFieldSubItem: any) => {
          if (
            CONSENTS_MEDIUM.includes(contactFieldSubItem?.fields?.key?.value) &&
            !contactFieldSubItem?.fields?.hideFromUI?.value
          ) {
            value.push(contactFieldSubItem);
          }
        });
      }
    });
    return value;
  });

  const [IsConsentsMediumHidden] = useState(function () {
    let value: any = false;
    props?.fields?.items?.map((subItems: ItemInterface<any>) => {
      if (subItems?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE) {
        value = subItems?.fields?.contactFieldList.some(
          (contactFieldSubItem: any) => contactFieldSubItem?.fields?.hideFromUI?.value
        );
      }
    });
    return value;
  });

  const [defaultOptInAll] = useState(function () {
    let value: any = false;
    props?.fields?.items?.map((subItems: ItemInterface<any>) => {
      if (subItems?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE) {
        value = subItems?.fields?.contactFieldList.some(
          (contactFieldSubItem: any) => contactFieldSubItem?.fields?.defaultOptInAll?.value
        );
      }
    });
    return value;
  });
  const customErrorMessageModel: any = sitecoreContext?.route?.fields?.customErrorMessageModel;
  const patientExistErrorMessageModel: any =
    sitecoreContext?.route?.fields?.patientExistErrorMessageModel;
  const returningUserTimeInterval = (siteSettings as any)?.returningUserTimeInterval?.value;
  const returningUserTimeValue = (siteSettings as any)?.returningUserTimeValue?.value;

  const { setJourneyModal: setEmailSentModal, showJourneyModal: showEmailSentModal } =
    useJourneyModal({
      title: { value: (resumeLinkModal as any)?.emailSentHeading?.value },
      description: {
        value: (resumeLinkModal as any)?.emailSentDescription?.value,
      },
      customButtons: [
        {
          name: (resumeLinkModal as any)?.startOverText?.value,
          callback: async () => {
            callbackLeave(true);
          },
        },
        {
          name: (resumeLinkModal as any)?.doneText?.value,
          callback: async () => {
            callbackClose();
          },
        },
      ],
    });

  const getInitialVlaueForIsMobile = (existingValues: any = {}) => {
    if (existingValues?.isMobile) {
      return true;
    }
    if (Boolean(defaultOptInAll) === true) {
      return false;
    }
    let value = false;
    ConsentsMedium &&
      ConsentsMedium.length > 0 &&
      ConsentsMedium.forEach((item: any) => {
        value =
          item?.fields?.consentMediumList &&
          item?.fields?.consentMediumList.some((consentMedium: any) => {
            const fieldName = consentMedium?.fields?.key?.value;
            const defaultOptInState = consentMedium?.fields?.defaultOptIn?.value;
            const defaultHideState = consentMedium?.fields?.hideFromUI?.value;
            if (fieldName === 'SMS') {
              if (!!defaultOptInState && !!defaultHideState) {
                return false;
              } else if (!!defaultOptInState) {
                return true;
              }
              return false;
            }
            return false;
          });
      });
    return value;
  };

  const resetCookies = () => {
    setCookie('enrollmentID', null, {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStatus', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStep', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyType', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    if (!isAuthenticated) {
      setCookie('bx_guest_ref', '', {
        expires: getCookieExpiryTime(0),
        secure: true,
      });
      const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
      setCookie(cookieName, '', {
        secure: true,
        expires: getCookieExpiryTime(0),
      });
    }
  };

  const updateCurrentJourneyStepinCDP = async (isResumeUser?: boolean) => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: getCookie('journeyStep') as string,
      journeyStatus: isResumeUser ? 'Terminated' : 'Re-registrationProhibited',
      isActiveEnrollment: '0',
    };

    const { potentialParticipant_ID } = potentialParticipantDetailsFromStore;
    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      await cdpService.updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const updateParticipantStatusInDB = async (isResumeUser?: boolean) => {
    //TODO: payload
    const payload = {
      potentialId: potentialParticipantDetailsFromStore?.potentialId,
      isActiveEnrollment: '0',
      journeyStatus: isResumeUser ? 'Terminated' : 'Re-registrationProhibited',
    };
    const { data: response } = await postToDB('/Participant/UpdateParticipantStatus', payload);
    return { ...response, isResumeUser: isResumeUser ? isResumeUser : false };
  };

  const { mutate: updateParticipantStatus } = useMutation(
    'update-participant',
    updateParticipantStatusInDB,
    {
      onSuccess: async (data: any) => {
        try {
          await updateCurrentJourneyStepinCDP(data?.isResumeUser);
          resetCookies();
          /*************TODO*********************** */
          // const addParticipantPayload = {
          //   ...potentialParticipantDetailsFromStore,
          //   // enrollmentId: getEnrollmentId(),
          //   enrollmentId: getOrCreateEnrollmentId(),
          //   potentialId: '',
          // };
          // //TODO: addGlogalSetting call
          // const newParticipantData = await addParticipantOnLoad({
          //   ...addParticipantPayload,
          //   callCenterNumber: getCallCenterNumber(sitecoreContext)?.value,
          // });
          // dispatch(
          //   updatepotentialParticipantDetails({
          //     potentialParticipant_ID: getCookie('bx_guest_ref') as string,
          //     enrollmentId: addParticipantPayload.enrollmentId,
          //     potentialId: newParticipantData?.potentialId,
          //   })
          // );
          // await router.push('/');
          // cdpService.addGlobalSettingToCDP(context, router, newPotentialId);
          const queryData = getQueryParams(query);
          let hrefValue: string;
          if (queryData === '') {
            hrefValue = locale === defaultLocale ? `/` : `/${locale}`;
          } else {
            hrefValue = locale === defaultLocale ? `/?${queryData}` : `/${locale}?${queryData}`;
          }
          window.location.href = hrefValue;
        } catch (_e) {}
      },
      onError: () => undefined,
    }
  );

  const callbackLeave = async (isResumeUser?: boolean) => {
    await updateParticipantStatus(isResumeUser);
  };

  const callbackClose = () => undefined;

  const { setCustomErrorModal, showCustomErrorModal } = useCustomErrorModal(
    siteSettings,
    customErrorMessageModel,
    callbackLeave,
    callbackClose
  );

  const { setPatientExistModal, showPatientExistErrorModal } = usePatientErrorModal(
    patientExistErrorMessageModel,
    callbackLeave,
    callbackClose
  );

  const reCaptchItem: any = props?.fields?.items?.filter((subItems: any) => {
    if (subItems?.fields?.type?.value === contactFormFieldType.RECAPTCHA_CONFIGURATION) {
      return subItems;
    }
  });

  let submitButton: any;
  for (const subItems of props?.fields?.items) {
    //finding submit button item, there is onl one field of type CTA_BUTTON_TYPE in any contact info form
    if (subItems?.fields?.type?.value === contactFormFieldType.CTA_BUTTON_TYPE) {
      submitButton = subItems;
    }
  }

  const autoCompleteLocationInfo = useSelector(
    (state: RootState) => state.contactInfoDetails.autoCompleteLocationInfo
  );

  useStoreUpdateWithStateAndTimeZoneList(stateList, timeZoneList, formateCOIList(COIList));

  const nextPath = submitButton?.fields?.link?.value?.href || '';

  const getAllPossibleFields = () => {
    if ((itemPath as string).includes(CONTACT_INFO_URLS.CAREGIVER)) {
      return {
        CaregiverZipCode: false,
        CaregiverCity: false,
        CaregiverState: false,
        ParticipantZipcode: false,
        ParticipantCity: false,
        ParticipantState: false,
      };
    } else if ((itemPath as string).includes(CONTACT_INFO_URLS.MINOR)) {
      return {
        ParentState: false,
        ParentCity: false,
        ZipCode: false,
        ParticipantState: false,
        ParticipantZipcode: false,
      };
    } else {
      return {
        City: false,
        State: false,
        Zipcode: false,
      };
    }
  };
  const getContactFormType = () => {
    if ((itemPath as string).includes(CONTACT_INFO_URLS.CAREGIVER)) {
      return 'caregiver';
    } else if ((itemPath as string).includes(CONTACT_INFO_URLS.MINOR)) {
      return 'minor';
    } else {
      return 'adult';
    }
  };
  const CheckingFieldExistOrNotForCaregiverFrom = () => {
    const allFields = getAllPossibleFields();
    const allContactStepFields: any = props?.fields?.items?.filter(
      (item) => item?.fields?.type?.value === contactFormFieldType.CONTACT_TYPE
    );

    for (const item of allContactStepFields) {
      const itemsInStep = item?.fields?.contactFieldList;

      if (Array.isArray(itemsInStep)) {
        itemsInStep.forEach((insideField) => {
          if (insideField.fields && insideField.fields.contactFieldList) {
            insideField.fields.contactFieldList.forEach((f: any) => {
              if (Object.keys(allFields).indexOf(f.fields.key.value) > -1) {
                allFields[f.fields.key.value as keyof typeof allFields] = true;
              }
            });
          }
        });
      }
    }
    return allFields;
  };

  const updateAvailableFieldsFromSitecoreToRedux = () => {
    const formType = getContactFormType();
    dispatch(
      updateFieldIsPresentOrNotFromSitecore({
        [formType]: CheckingFieldExistOrNotForCaregiverFrom(),
      })
    );
  };

  useEffect(() => {
    dispatch(goToStep(0));
    dispatch(updateContactInfoUrl(itemPath as string));
    setTimeout(() => {
      updateAvailableFieldsFromSitecoreToRedux();
    }, 1000);
  }, [itemPath]);

  return {
    dispatch,
    executeRecaptcha,
    stateList,
    timeZoneList,
    COIList,
    selectedLanguage,
    potentialParticipant_ID,
    potentialId,
    language,
    siteRootPath,
    isRecapchaEnabled,
    screenerOutput,
    prepopulateConfig,
    nextPath,
    geolocation,
    reCaptchItem: reCaptchItem[0],
    autoCompleteLocationInfo,
    cdpState,
    siteSettingsId,
    setCustomErrorModal,
    showCustomErrorModal,
    setPatientExistModal,
    showPatientExistErrorModal,
    returningUserTimeInterval,
    returningUserTimeValue,
    setEmailSentModal,
    showEmailSentModal,
    ContactNumberField,
    PrimaryConsentField,
    ConsentsMedium,
    IsConsentsMediumHidden,
    getInitialVlaueForIsMobile,
  };
};

export default useContactInfoHelper;
