import Box from '@mui/material/Box';
import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import SuccessPageMergeTag from '../SuccessPageMergeTag/SuccessPageMergeTag';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';

const TextContent: React.FC = (props: any) => {
  const { show } = props;
  if (!props?.fields) return <></>;

  let { fields } = props;

  if (!show) {
    return <></>;
  }

  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }
  return (
    <>
      <Box sx={{ marginBlock: '1rem' }}>
        {/* <RichText field={props.fields.textContent} /> */}
        <SuccessPageMergeTag
          mergeTagField={props.fields.textContent}
          mergeTagObj={props.mergeTagData}
        />
      </Box>
    </>
  );
};

export default TextContent;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'TextContent'
  );

  return {
    ...componentProps,
    confirmedsitesData: cdpData.confirmedsitesData,
    mergeTagData: cdpData,
  };
};
