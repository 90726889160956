import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StCardDesc, StCardSubTitle, StCardTitle } from '../shared/Carousel/Carousel.styles';
import Image from 'next/image';
import { UIButton } from '../shared/UIButton.styles';
import { IStudyListCardProps } from './StudyListing.type';

const StudyListingCard = (props: IStudyListCardProps | any): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const study = props?.fields;
  const getCardWidth = (flag = false) => {
    if (isTablet) {
      return 306;
    } else if (isMobile) {
      return 271;
    } else {
      return flag ? 355 : 282;
    }
  };

  return (
    <Card
      sx={{
        minWidth: getCardWidth(),
        maxWidth: getCardWidth(true),
        marginInline: isTablet || isMobile ? 'none' : 'auto',
        margin: isTablet || isMobile ? '1.4rem' : 'none',
        borderRadius: '0.6rem',
        boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Image src={study?.studyImage?.value} alt="image" width={355} height={280} />
      </Box>

      <CardContent sx={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
        <StCardTitle>{study?.studyCondition?.fields?.description?.value}</StCardTitle>
        <StCardSubTitle limit={props?.titleLineLimit || 2}>
          {study?.studyTitle?.value}
        </StCardSubTitle>
        <StCardDesc limit={props?.descriptionLineLimit || 3}>
          {study?.studyDescription?.value}
        </StCardDesc>
      </CardContent>
      <CardActions sx={{ margin: '1rem' }}>
        <UIButton
          variant={'outlined'}
          sx={{ width: '100%', padding: '5px' }}
          href={study?.studyLink?.value?.href}
        >
          {study?.studyLink?.value?.text}
        </UIButton>
      </CardActions>
    </Card>
  );
};

export default StudyListingCard;
