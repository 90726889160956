/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ComponentProps } from 'lib/component-props';
import { tabsClasses } from '@mui/material/Tabs';
import { OneColumnGrid } from 'components/shared/OneColumnGrid';
import { TwoColumnGrid } from 'components/shared/TwoColumnGrid';
import Dropdown from '../shared/Dropdown/Dropdown';
import Tabs from '../shared/Tabs/Tabs';
import { StDropDown, StTabListContainer, StTabsContainer } from '../TabList/TabList.style';
import { IStudyItem, ITabOption, TemplateNames, IComponentListItem } from './StudySwitcher.types';
import InformationalSteps from '../InformationalSteps/InformationalSteps';
import FlexiContainer from '../FlexiContainer/FlexiContainer';
import FeatureHighlights from 'components/DTP/FeatureHighlights/FeatureHighlights';
import { StDTPGridContainer } from '../shared/Container/Container.styles';
import { useDispatch } from 'react-redux';
import { updateSelectedOptionName } from '../../features/studySwitcherSlice';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface IStudySwitchTabListProps {
  componentProps: ComponentProps;
  tabListOptions: Array<ITabOption>;
  studyItemList: Array<IStudyItem>;
}

function isHexadecimalColor(str: string) {
  const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexColorPattern.test(str);
}

const StudySwitchTabList: React.FC<IStudySwitchTabListProps> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  const dispatch = useDispatch();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const [value, setValue] = useState(0);
  const componentProps = props.componentProps;
  const studyItemList = props.studyItemList;
  const [componentList, setComponentList] = useState<IComponentListItem[]>([]);
  const tabBtnColor = studyItemList[value]?.fields?.btnColor?.value || '';
  const tabListOptions: Array<ITabOption> = props.tabListOptions;
  const siteSettings: any = sitecoreContext?.siteSettings;
  const enableMapRefresh = siteSettings?.enableMapRefresh?.value || false;

  const RenderOneAndTwoColumn: React.FC<{ fields: { contentList?: any[] } }> = (props) => {
    const contentList = props.fields?.contentList || [];
    const isContentList = Array.isArray(contentList) && contentList.length > 0;
    const isSingleColumn: boolean = isContentList ? contentList?.length < 2 : true;
    if (isContentList) {
      if (isSingleColumn) {
        return (
          <StDTPGridContainer>
            <OneColumnGrid {...contentList[0]} isSingleColumn={isSingleColumn} />;
          </StDTPGridContainer>
        );
      } else {
        return (
          <StDTPGridContainer>
            <TwoColumnGrid
              // @ts-ignore
              fields={props.fields}
              isSingleColumn={isSingleColumn}
              maintainOrder={true}
            />
          </StDTPGridContainer>
        );
      }
    }
    return <></>;
  };

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
    if (enableMapRefresh) {
      dispatch(updateSelectedOptionName(tabListOptions[newValue].value));
    }
  };

  useEffect(() => {
    if (Array.isArray(studyItemList)) {
      setComponentList(studyItemList[value]?.fields?.componentList || []);
    }
  }, [value]);

  if (!studyItemList || !Array.isArray(studyItemList) || studyItemList.length < 1) {
    return <></>;
  }
  return (
    <Box
      id="study-list-tabs"
      sx={{
        [theme.breakpoints.up('xl')]: {
          width: '1366px',
          marginInline: 'auto',
        },
      }}
    >
      <StTabListContainer
        className="MyStTabListContainer"
        container
        direction={'column'}
        alignItems="flex-start"
        style={{ paddingLeft: '0rem', paddingRight: '0rem', paddingBlock: '4px' }}
      >
        <Grid item container justifyContent={'center'}>
          <StDropDown
            item
            sx={{
              display: { xs: 'block', md: 'none' },
              [theme.breakpoints.up('sm')]: {
                marginInline: '2rem',
              },
            }}
          >
            <Dropdown
              fullWidth={true}
              labelId="scroll-list-select-label"
              id="tab-list-select"
              dataTestId="tab-list-select"
              defaultValue={0}
              type="number"
              value={value}
              onChangeHandler={(e: any) => {
                const num = Number(e.target.value);
                handleChange(e, num);
              }}
              options={tabListOptions}
              selectSxProps={{
                borderRadius: '30px !important',
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: (theme: Theme) => `${theme.palette.neutral4.main}`,
                  },
                  '&:hover fieldset': {
                    borderColor: (theme: Theme) => `${theme.palette.neutral4.main}`,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: (theme: Theme) => `${theme.palette.neutral4.main}`,
                  },
                },
              }}
              menuItemSxProps={{ whiteSpace: 'normal' }}
            />
          </StDropDown>
          <StTabsContainer
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Tabs
              id="study-list-tabs-list"
              value={value}
              onChangeHandler={handleChange}
              variant="scrollable"
              scrollButtons={'auto'}
              ariaLabel="visible arrows tabs"
              tabListOptions={tabListOptions}
              type="text"
              tabsContainerSxProps={{
                background: '#fff',
                border: (theme: Theme) => `1px solid ${theme.palette.neutral4.main}`,
                borderRadius: '32px',
                marginTop: '1rem',
                '& .Mui-selected': {
                  color: '#ffffff !important',
                  backgroundColor: isHexadecimalColor(tabBtnColor) ? tabBtnColor : primaryColor,
                  fontWeight: '500',
                },
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
              tabSxProps={{
                minWidth: '160px',
                maxWidth: 'none !important',
                textTransform: 'none',
                color: '#757575',
                '&:hover:not(.Mui-selected)': {
                  backgroundColor: (theme: Theme) => theme.palette.secondary.dark,
                },
              }}
            />
          </StTabsContainer>
        </Grid>
        {Array.isArray(componentList) &&
          componentList?.map((item) => {
            const templateName = item.templateName;
            const fields = item.fields;
            return (
              <div key={templateName}>
                {templateName === TemplateNames.OneAndTwoColumn && (
                  <RenderOneAndTwoColumn fields={fields} />
                )}
                {templateName === TemplateNames.InformationalSteps && (
                  <InformationalSteps fields={fields} />
                )}
                {templateName === TemplateNames.FlexiContainer && (
                  <FlexiContainer fields={fields} {...componentProps} />
                )}
                {templateName === TemplateNames.FeatureHighlights && (
                  <FeatureHighlights fields={fields} />
                )}
              </div>
            );
          })}
      </StTabListContainer>
    </Box>
  );
};

export default StudySwitchTabList;
