import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  MainContentBox,
  SideBox,
  TrialDetailsContainer,
  TrialTitleContainer,
  TrialConatiner,
  TrialWrapper,
} from './TrialDetails.styles';

interface TrialDetailsContentProps {
  JssTrialLeft: React.FunctionComponent;
  JssTrialRight: React.FunctionComponent;
  JssTrialTitle: React.FunctionComponent;
}

const TrialDetailsContent: React.FC<TrialDetailsContentProps> = ({
  JssTrialLeft,
  JssTrialRight,
  JssTrialTitle,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <TrialConatiner>
      <TrialWrapper>
        <TrialTitleContainer>
          <JssTrialTitle />
        </TrialTitleContainer>
        <TrialDetailsContainer data-testid={'TrialDetailsContainer'}>
          <MainContentBox>
            {isTablet && <JssTrialRight />}
            <JssTrialLeft />
          </MainContentBox>
          <SideBox>
            <JssTrialRight />
          </SideBox>
        </TrialDetailsContainer>
      </TrialWrapper>
    </TrialConatiner>
  );
};

export default TrialDetailsContent;
