import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {
  Field,
  RichTextField,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Marker from './Marker';
import studyLocationFilter from '../../lib/utils/studyLocationFilter';

interface IStudyMap {
  variation: string | undefined;
  width?: string;
  minWidth?: string;
  buttonText: TextField;
  noSitesAvailableText?: TextField;
  enrollingMapIcon?: TextField;
  notEnrollingMapIcon?: TextField;
  enrollSoonIcon?: Field<string>;
  enrollSoonText?: Field<string>;
  siteInfoDisplayFormatHover?: RichTextField;
  siteInfoDisplayFormat?: RichTextField;
  enrollSoonHovertext?: RichTextField;
}

const StudyMap: React.FC<IStudyMap> = (props: any): any => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const selectedOptionValue = useSelector(
    (state: RootState) => state.studySwitcher.selectedOptionValue
  );
  const isConditionSwitcher = useSelector(
    (state: RootState) => state.studySwitcher.isConditionSwitcher
  );
  const [mapLoaded, setMapLoaded] = useState(false);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const locations = useSelector((state: RootState) => state.studyLocation.locations);
  const enrollingMapIcon = props.enrollingMapIcon;
  const notEnrollingMapIcon = props.notEnrollingMapIcon;
  const enrollSoonIcon = props.enrollSoonIcon;
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const enrollSoonHovertext = props?.enrollSoonHovertext;
  const buttonText = props?.buttonText;
  const siteInfoDisplayFormatHover = props?.siteInfoDisplayFormatHover;
  const enrollSoonText = props?.enrollSoonText;
  const siteInfoDisplayFormat = props?.siteInfoDisplayFormat;
  let isLoaded = false;

  const lng =
    sitecoreContext?.site?.name === 'ClinicalResearch' ? 'en' : sitecoreContext?.language || 'en';

  try {
    const loaderObj = useJsApiLoader({
      id: 'google-map-script',
      language: lng,
      googleMapsApiKey: atob(siteSettings?.googleMapsAPIKey?.value || ''),
    });
    isLoaded = loaderObj.isLoaded;
  } catch (_error) {}

  const centerCord = useMemo(
    () => ({
      lat: locationsList[0]?.latitude || Number(siteSettings?.countryLatitude?.value) || 58.3,
      lng: locationsList[0]?.longitude || Number(siteSettings?.countryLongitude?.value) || -134.43,
    }),
    [locationsList, siteSettings?.countryLatitude, siteSettings?.countryLongitude]
  );

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const getHeight = (): string => {
    if (isMobile) {
      return '550px';
    } else if (isTablet) {
      return '550px';
    } else {
      return '100%';
    }
  };
  const containerStyle = {
    height: getHeight(),
    minHeight: getHeight(),
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  };

  useEffect(() => {
    const newLocations = studyLocationFilter(locations, selectedOptionValue, isConditionSwitcher);
    setLocationsList(newLocations);
  }, [locations, selectedOptionValue, isConditionSwitcher]);

  const renderMap = useCallback(() => {
    return (
      mapLoaded &&
      locationsList &&
      locationsList?.map((study: any) => {
        const siteInfoFormatOnHover = study?.showEnrollSoon
          ? enrollSoonHovertext
          : siteInfoDisplayFormatHover;
        const siteInfoFormat = study?.showEnrollSoon ? enrollSoonText : siteInfoDisplayFormat;
        return (
          <Marker
            key={study?.siteID}
            study={study}
            siteInfoDisplayFormatHover={siteInfoFormatOnHover}
            siteInfoDisplayFormat={siteInfoFormat}
            buttonText={buttonText}
            mapIcon={
              !study?.showEnrollSoon && !study?.notYetEnroll
                ? enrollingMapIcon?.value?.src
                : study?.showEnrollSoon && !study?.notYetEnroll
                ? enrollSoonIcon?.value?.src
                : notEnrollingMapIcon?.value?.src
            }
          />
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOptionValue,
    mapLoaded,
    locationsList,
    enrollSoonHovertext,
    siteInfoDisplayFormatHover,
    enrollSoonText,
    siteInfoDisplayFormat,
    buttonText,
    enrollingMapIcon?.value?.src,
    enrollSoonIcon?.value?.src,
    notEnrollingMapIcon?.value?.src,
  ]);

  return (
    <Box
      className="study-map"
      sx={{
        borderBottomRightRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        '.gm-style-iw-c': {
          padding: '0px',
          overflow: 'visible',
        },
        '.gm-style-iw-d': {
          overflow: 'visible !important',
        },
        '.gm-style-iw-d + button': {
          display: 'none !important',
        },
        height: '100%',
        width: '100%',
      }}
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={centerCord}
          zoom={4}
          onLoad={handleMapLoad}
          options={{ scrollwheel: false, mapTypeControl: false }}
        >
          {renderMap()}
        </GoogleMap>
      )}
    </Box>
  );
};

export default StudyMap;
