import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const StTypographyTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontSize: '48px',
  lineHeight: theme.spacing(6),
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    fontSize: '24px',
    lineHeight: theme.spacing(4),
  },
  [theme.breakpoints.up('lg')]: {
    height: '190px',
  },
  [theme.breakpoints.down('md')]: {
    lineHeight: '2rem',
    fontSize: '24px',
  },
}));

export const StTypographyHeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.themeColor.main,
  fontSize: '30px',
  lineHeight: '2rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '30px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: '38px',
  },
}));

export const StTypographyContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  fontSize: '48px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
}));

export const StArticleWrapContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '1302px',
  width: '100%',
  backgroundColor: theme.palette.neutral0.main,
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    margin: '30px auto',
    borderRadius: '16px',
  },
}));

export const StContainer = styled(Grid)(({ theme }) => {
  const neutral0 = theme.palette.neutral0.main;
  const themeColor = theme.palette.themeColor.main;
  const themeColorSecondary = theme.palette.themeColorSecondary.main;
  return {
    minHeight: `calc(100vh - 7rem)`,
    [theme.breakpoints.down('lg')]: {
      backgroundImage: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `linear-gradient(to top, ${neutral0} 70%, ${themeColorSecondary} 80%, ${themeColor})`,
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `linear-gradient(to top, ${neutral0} 60%, ${themeColorSecondary} 80%, ${themeColor})`,
    },
  };
});
