import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

export const StBox = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
}));

export const StTypography = styled(MuiTypography)(({ theme }) => ({
  color: theme?.palette?.text.primary,
  textAlign: 'left',
  fontSize: '24px',
  fontWeight: 500,
  marginLeft: '0.5rem',
}));

export const StHeadingTypography = styled(MuiTypography)(({ theme }) => ({
  color: theme?.palette?.text.primary,
  textAlign: 'left',
  fontSize: '18px',
  fontWeight: 600,
}));

export const StMainHeadingTypography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontSize: '30px! important',
  fontWeight: 600,
  [theme.breakpoints.up('lg')]: {
    marginTop: '37px',
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: '0px',
  },
}));

export const StRichText = styled(RichText)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
}));

export const CriteriaRichText = styled(RichText)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
  [theme.breakpoints.down('sm')]: {
    '& ol': {
      paddingInlineStart: '20px',
    },
  },
}));

export const StTitleBox = styled(Box)(({ theme }) => ({
  marginBottom: '-3rem',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    columnGap: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '90%',
    margin: '2rem 0 -3rem 0!important',
  },
}));
