import gql from 'graphql-tag';

const SuccessPageCompGLQuery = gql`
  query SuccessPageComponentGLQuery($itemId: String!, $language: String!) {
    item(path: $itemId, language: $language) {
      # items can be cast to a Template type with inline fragments
      # fields can be cast to a Field type with inline fragments
      fields {
        name
        jsonValue
      }
    }
  }
`;

export const getSuccessPageComponentGLQueryParameters = (itemId: string, language: string) => ({
  itemId: itemId,
  language: language,
});

export default SuccessPageCompGLQuery;
