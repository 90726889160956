import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type CloseIconProps = {
  color?: string;
};

const StyledDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<CloseIconProps>(({ theme, color }) => ({
  width: '16px',
  height: '2px',
  margin: '4px 0',
  backgroundColor: color || theme.palette.themeColor.main,
}));

const CloseIcon = (props: CloseIconProps): JSX.Element => {
  return (
    <div>
      <StyledDiv
        style={{
          transform: 'translate(0,0) rotate(-45deg)',
        }}
        color={props.color}
      />
      <StyledDiv
        style={{
          transform: 'translate(0, -6px) rotate(45deg)',
        }}
        color={props.color}
      />
    </div>
  );
};

export default CloseIcon;
