import { SxProps } from '@mui/material/styles';
import { StCommonTypography } from '../shared/Container/Container.styles';
import Grid from '@mui/material/Grid';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Image from 'next/image';
import axios from 'axios';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

type CommunityCardProps = {
  title: string;
  imageSrc: string;
  communityCardColor?: string;
  isMobile: boolean;
  sxCommunityCardProps: SxProps;
  sxCommunityCardTitleProps: SxProps;
  communityItem: any;
};

const fetchRoutesForCommunityCard = async (id: string, language: string) => {
  try {
    const payload = {
      communityItem: id,
      language,
    };

    const response = await axios.post('/api/communitiesRoutes', { ...payload });
    return response?.data?.search?.communityPage[0] || response;
  } catch (error) {
    return { path: '#' };
  }
};

const MyProfileCommunityCard = (props: CommunityCardProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language || 'en';
  const { title, imageSrc, isMobile, sxCommunityCardProps, sxCommunityCardTitleProps } = props;
  const router = useRouter();

  const navigateRoutes = async (communityItem: any) => {
    const resp = await fetchRoutesForCommunityCard(communityItem, language);
    if (resp?.path === '#' || !resp) {
      return;
    }
    await router.push({
      pathname: resp?.url?.path,
    });
  };
  return (
    <Grid
      item
      xs={12}
      sm={'auto'}
      id={`communityItem-${title}`}
      data-testid="community-card"
      // role="link"
      sx={{ marginBottom: '1rem', marginLeft: isMobile ? '0' : '1rem' }}
      tabIndex={0}
      onClick={() => navigateRoutes(props?.communityItem)}
    >
      <Grid
        className="main-grid"
        item
        container
        direction={isMobile ? 'row' : 'column'}
        sx={{
          ...sxCommunityCardProps,
          minHeight: '100%',
          ...(isMobile && { justifyContent: 'flex-start' }),
        }}
      >
        {/* {communityCardColor ? (
          <StNextImage
            field={communityItem?.fields?.relatedCommunity?.fields?.icon}
            height={isMobile ? '24' : '59'}
            width={
              isMobile
                ? '24'
                : (communityItem?.fields?.relatedCommunity.fields.icon?.value?.width as string)
            }
            overrideIconColor={communityCardColor as string}
          />
        ) : (
          <NextImage
            field={communityItem?.fields?.relatedCommunity?.fields?.icon}
            height={isMobile ? '24' : '59'}
            width={
              isMobile
                ? '24'
                : (communityItem?.fields?.relatedCommunity?.fields.icon?.value?.width as string)
            }
          />
        )} */}
        {!!imageSrc && <Image src={imageSrc} alt={title + ' logo'} width={59} height={59} />}
        <StCommonTypography variant="body" sx={sxCommunityCardTitleProps}>
          <Text data-testid={title} field={{ value: title }} />
        </StCommonTypography>
      </Grid>
    </Grid>
  );
};

export default MyProfileCommunityCard;
