import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';

import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  HCPCRGridContainerWithoutBackground,
  HCPWrapper,
} from '../HcpTrainingAccess/HcpTrainingAccess.style';
const StContainer = styled('div')(() => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#000',
  '& p': {
    margin: 0,
  },
}));

const HcpTrainingUsers = (props: any) => {
  const { listofItems } = props?.fields || {};
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <HCPWrapper id="hcp-wrapper">
      <HCPCRGridContainerWithoutBackground item container>
        {listofItems?.map((item: any, index: number) => (
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={listofItems?.length > 1 ? 6 : 12}
            direction={'column'}
            justifyContent={'flex-start'}
            mb={2}
            key={index}
            sx={{
              paddingRight: isDesktop ? (index % 2 === 0 ? '1rem' : 0) : 0,
              paddingLeft: isDesktop ? (index % 2 === 1 ? '1rem' : 0) : 0,
            }}
          >
            <Typography variant="h4" sx={{ marginBottom: '0.5rem' }}>
              <Text field={item?.fields?.heading} />
            </Typography>
            <StContainer>
              <RichText field={item?.fields?.content} />
            </StContainer>
          </Grid>
        ))}
      </HCPCRGridContainerWithoutBackground>
    </HCPWrapper>
  );
};

export default HcpTrainingUsers;
