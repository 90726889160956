import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import {
  StModalContainerWithModal,
  StmodalContainer,
  StHeadingContainer,
  StHeadingText,
  StFooterbuttons,
} from './CustomModal.styles';
import { UIButton } from '../UIButton.styles';
import CloseIcon from '../../../../public/close-icon.png';
import Image from 'next/image';

interface ICRSiteInfoModalProps {
  children: React.ReactNode;
  open: boolean;
  heading?: string;
  showActionButton?: boolean;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
  variant?: 'contained' | 'text' | 'outlined';
}

const CRSiteInfoModal: React.FC<ICRSiteInfoModalProps> = (props) => {
  const heading = props.heading || '';
  const variant = props?.variant || 'contained';
  const actionButtonText = props.actionButtonText || 'Close';
  const style = props.style || {};
  const onClose = props.onClose;
  return (
    <Modal
      open={props.open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StModalContainerWithModal sx={style}>
        <StmodalContainer
          sx={{
            width: '500px',
            height: '246px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <StHeadingContainer>
              <StHeadingText id="modal-modal-title" variant="h6">
                {heading}
              </StHeadingText>
              <IconButton
                sx={{ top: '-4px' }}
                color="inherit"
                aria-label="close modal button"
                edge="start"
                onClick={onClose}
                data-testid="exitform-disclaimer-image"
              >
                <Image alt="close-icon" src={CloseIcon} width={12} height={12} />
              </IconButton>
            </StHeadingContainer>
            {props.children}
          </Box>
          {props.showActionButton && (
            <StFooterbuttons>
              <UIButton
                variant={variant}
                onClick={props.onActionButtonClick}
                sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
              >
                {actionButtonText}
              </UIButton>
            </StFooterbuttons>
          )}
        </StmodalContainer>
      </StModalContainerWithModal>
    </Modal>
  );
};

export default CRSiteInfoModal;
