import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import { StButton, StPaginationItem } from './Pagination.style';
import { Pager } from '@coveo/headless';

type PaginationProps = {
  pager: Pager;
};

const Pagination: FC<PaginationProps> = ({ pager }) => {
  const [state, setState] = useState(pager.state);
  useEffect(() => pager.subscribe(() => setState(pager.state)), []);

  return (
    <Box style={{ margin: '15px auto', textAlign: 'center' }}>
      <StButton disabled={!state.hasPreviousPage} onClick={() => pager.selectPage(1)}>
        <SkipPreviousIcon sx={{ fontSize: '1rem' }} />
      </StButton>
      <StButton disabled={!state.hasPreviousPage} onClick={() => pager.previousPage()}>
        <NavigateBeforeIcon sx={{ fontSize: '1rem' }} />
      </StButton>
      {state.currentPages.map((page) => (
        <>
          <StPaginationItem
            page={page}
            key={page}
            selected={pager.isCurrentPage(page)}
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
              pager.selectPage(page);
            }}
          />
        </>
      ))}
      <StButton disabled={!state.hasNextPage} onClick={() => pager.nextPage()}>
        <NavigateNextIcon sx={{ fontSize: '1rem' }} />
      </StButton>
      <StButton disabled={!state.hasNextPage} onClick={() => pager.selectPage(state.maxPage)}>
        <SkipNextIcon sx={{ fontSize: '1rem' }} />
      </StButton>
    </Box>
  );
};

export default Pagination;
