import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const IconMainContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    top: '140px',
    right: '94px',
  },
  [theme.breakpoints.up('xl')]: {
    top: '125px',
    right: '63px',
  },
  [theme.breakpoints.down(960)]: {
    top: '110px',
    right: '70px',
  },
  [theme.breakpoints.down(769)]: {
    top: '110px',
    right: '32px',
  },
  [theme.breakpoints.down(426)]: {
    top: '85px',
    right: '17px',
  },
}));

export const IconBoxWrapper = styled(Box)(({}) => ({
  width: '100%',
  margin: '0 auto',
  maxWidth: '1302px',
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  marginBottom: '40px',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    top: '93px',
    right: '94px',
  },
  [theme.breakpoints.up('xl')]: {
    top: '93px',
    right: '158px',
  },
  [theme.breakpoints.down(960)]: {
    top: '40px',
    right: '70px',
  },
  [theme.breakpoints.down(769)]: {
    top: '60px',
    right: '74px',
  },
  [theme.breakpoints.down(426)]: {
    top: '36px',
    right: '66px',
  },
}));
