import useJourneyModal from './useJourneyModal';

const useCustomErrorModal = (
  siteSettings: any,
  customErrorMessageModel: any,
  callbackLeave: () => void,
  callbackClose: () => void
): {
  setCustomErrorModal: () => void;
  showCustomErrorModal: () => void;
} => {
  const description: string = customErrorMessageModel?.fields?.modalDescription?.value;
  const mergeTagValue =
    siteSettings?.returningUserTimeValue?.value && siteSettings?.returningUserTimeInterval?.value
      ? `${siteSettings?.returningUserTimeValue?.value} ${siteSettings?.returningUserTimeInterval?.value}`
      : '';
  const descriptionWithMergeTag =
    description && description.replace(new RegExp('\\[xHours\\]', 'g'), mergeTagValue);

  const { setJourneyModal: setCustomErrorModal, showJourneyModal: showCustomErrorModal } =
    useJourneyModal({
      title: { value: customErrorMessageModel?.fields?.modalTitle?.value },
      description: {
        value: descriptionWithMergeTag || '',
      },
      customButtons:
        customErrorMessageModel?.fields?.modalCTAButtons &&
        customErrorMessageModel?.fields?.modalCTAButtons?.map((btn: any) => {
          return {
            name: btn?.displayName,
            callback: btn?.fields?.isLeavePage?.value ? callbackLeave : callbackClose,
          };
        }),
    });

  return {
    setCustomErrorModal,
    showCustomErrorModal,
  };
};

export default useCustomErrorModal;
