import Box from '@mui/material/Box';
import { ComponentProps } from 'lib/component-props';
import { IStudySwitcherProps, ITabOption } from './StudySwitcher.types';
import StudySwitchTabList from './StudySwitchTabList';
import IconWithTitle from '../shared/IconWithTitle/IconWithTitle';
import { useDispatch } from 'react-redux';
import { updateOptions } from '../../features/studySwitcherSlice';
import { useEffect } from 'react';
import { setIsConditionSwitcher } from '../../features/studySwitcherSlice';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { styled } from '@mui/material';

const fallback = {
  text: { value: 'FallbackValue' },
};

const StContent = styled(RichText)(({ theme }) => ({
  maxWidth: '1366px',
  width: '100%',
  margin: '0 auto',
  paddingLeft: '6.5rem',
  paddingRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    paddingLeft: '4rem',
    paddingRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    paddingLeft: '3rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down(500)]: {
    paddingInline: '2rem',
  },
}));

const StudySwitcher: React.FC<IStudySwitcherProps> = (props) => {
  const dispatch = useDispatch();
  const componentProps: ComponentProps = { params: props.params, rendering: props.rendering };
  const title = props.fields?.heading || { value: '' };
  const titleIcon = props.fields?.icon || { value: { src: '' } };
  const iconWithTitleVariant = props.fields?.iconWithTitleVariant?.value || 'ImageUp';
  const isConditionSwitcher = props.fields?.isConditionSwitcher?.value || false;
  const tabListOptions: Array<ITabOption> = [];
  const conditionValues: string[] = [];
  const protocolIds: string[] = [];
  const studyItemList = props.fields?.studyItemList || [];

  if (Array.isArray(studyItemList) && studyItemList.length > 0) {
    if (isConditionSwitcher) {
      studyItemList.forEach((item) => {
        const conditionDescription = item.fields?.condition?.fields?.description?.value
          ? item.fields?.condition?.fields?.description
          : null;
        const conditionName = item.fields?.conditionName?.value ? item.fields?.conditionName : null;
        tabListOptions.push(conditionName || conditionDescription || fallback.text);
        // For StudyLocation Dropdown Values
        const conditionValue = item.fields?.condition?.fields?.value?.value
          ? String(item.fields?.condition?.fields?.value?.value)
          : '';
        conditionValues.push(conditionValue);
      });
    } else {
      studyItemList.forEach((item) => {
        const protocolName = item.fields?.protocol?.fields?.studyName?.value
          ? item.fields?.protocol?.fields?.studyName
          : null;
        const studyName = item.fields?.studyName?.value ? item.fields?.studyName : null;
        tabListOptions.push(studyName || protocolName || fallback.text);
        // For StudyLocation Dropdown Values
        const protocolId = item.fields?.protocol?.fields?.protocolId?.value
          ? item.fields?.protocol?.fields?.protocolId?.value
          : '';
        protocolIds.push(protocolId);
      });
    }
  }

  useEffect(() => {
    const options: any = [];
    const valueArr: any = isConditionSwitcher ? conditionValues : protocolIds;
    tabListOptions.forEach((item, index) => {
      options.push({ name: item.value, value: valueArr[index] });
    });
    if (isConditionSwitcher) {
      dispatch(setIsConditionSwitcher(true));
    }
    dispatch(updateOptions(options));
  }, []);

  if (tabListOptions.length < 1 || studyItemList.length < 1) {
    return <></>;
  }

  if (!props?.fields) return <></>;

  return (
    <Box id="study-switcher" paddingY="1rem">
      <IconWithTitle
        id={'about-the-study'}
        title={title}
        titleIcon={titleIcon}
        iconWithTitleVariant={iconWithTitleVariant}
      />
      <StContent field={props.fields?.content} />
      <StudySwitchTabList
        componentProps={componentProps}
        tabListOptions={tabListOptions}
        studyItemList={studyItemList}
      />
    </Box>
  );
};

export default StudySwitcher;
