export const SMA_SITE = 'SiteManagementApp';
export const ROOT_PATH_ID = '{8ABC0FE8-331F-401F-95BC-BAB80811FCE4}';
export const CR_ROOT_PATH_ID = '{33E0826A-F8F6-5961-A24A-2F4CC7FA946E}';
export const SMA_TEMPLATE_ID = '{5997305A-E98B-4EAB-9763-5F73CFCE84EC}';
export const SIGN_OUT = 'Sign out';
export const CR_PROTOCOL_TEMPLATE_ID = '{7D58B0D9-D231-49DA-BF63-61EE4A1336BD}';
export const CR_SITE = 'ClinicalResearch';
export const ABOUT_US_PATH = [
  '/about-us',
  '/about-clinical-research',
  '/role-of-participants',
  '/how-to-participate',
  'glossary',
];
