import { ChildLinkItem, CRNavLnk } from './CRHeaderNavigation.types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { DrawerListItem, AppBarLink, AppBarProfileLink } from './CRHeaderNavigation.style';
import { LinkField } from '@sitecore-jss/sitecore-jss-react';
import { useTheme } from '@mui/material/styles';
import { SIGN_OUT } from '../../lib/constant';

const fallbackLink: LinkField = { value: { href: '', text: '' } };
const fallbackChildLinks = [{ fields: { link: fallbackLink } }];

export const ChildList: React.FC<{
  links: Array<ChildLinkItem>;
  openProfileModal?: any;
  fieldItem?: any;
  handleDrawerToggle: () => void;
}> = (props) => {
  const theme = useTheme();
  if (Array.isArray(props.links) && props.links.length > 0) {
    return (
      <List>
        {props?.links.map((item, index) => {
          return (
            <DrawerListItem className={'child-link-list-item'} key={index}>
              {item.name === SIGN_OUT ? (
                <AppBarProfileLink
                  sx={{ color: theme.palette.themeColor.main }}
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleDrawerToggle();
                    props?.openProfileModal({
                      open: false,
                      profileFields: props?.fieldItem || item.fields,
                      signOutOpen: true,
                    });
                  }}
                >
                  {item.fields?.link?.value.text || ''}
                </AppBarProfileLink>
              ) : (
                <Box onClick={props.handleDrawerToggle}>
                  <AppBarLink field={item.fields?.link || fallbackLink} />
                </Box>
              )}
            </DrawerListItem>
          );
        })}
      </List>
    );
  }
  return null;
};

export const LinkList: React.FC<{
  navigationLinks: Array<CRNavLnk>;
  openProfileModal: any;
  isAuthenticated: boolean;
  handleDrawerToggle: () => void;
}> = (props) => {
  if (Array.isArray(props.navigationLinks) && props.navigationLinks.length > 0) {
    return (
      <List>
        {props?.navigationLinks.map((item, index) => {
          const childNavList = item.fields?.childNavList;
          const isProfileLinkAsModal = item.fields?.hasOwnProperty('myProfile');
          if (isProfileLinkAsModal) {
            if (!props.isAuthenticated) {
              return (
                <DrawerListItem key={index}>
                  <AppBarLink
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      props.handleDrawerToggle();
                      props?.openProfileModal({ open: true, profileFields: item.fields });
                    }}
                    field={fallbackLink}
                  >
                    {item.fields?.link?.value.text || ''}
                  </AppBarLink>
                </DrawerListItem>
              );
            }
            if (Array.isArray(childNavList) && childNavList.length > 0) {
              return (
                <>
                  <DrawerListItem>{item.fields?.link?.value.text}</DrawerListItem>
                  <ChildList
                    links={item.fields?.childNavList || (fallbackChildLinks as ChildLinkItem[])}
                    fieldItem={item.fields}
                    openProfileModal={props?.openProfileModal}
                    handleDrawerToggle={props.handleDrawerToggle}
                  />
                </>
              );
            }
          }
          if (Array.isArray(childNavList) && childNavList.length > 0) {
            return (
              <>
                <DrawerListItem>{item.fields?.link?.value.text}</DrawerListItem>
                <ChildList
                  links={item.fields?.childNavList || (fallbackChildLinks as ChildLinkItem[])}
                  fieldItem={item.fields}
                  handleDrawerToggle={props.handleDrawerToggle}
                />
              </>
            );
          }
          return (
            <DrawerListItem key={index} onClick={props.handleDrawerToggle}>
              <AppBarLink field={item.fields?.link || fallbackLink} />
            </DrawerListItem>
          );
        })}
      </List>
    );
  }
  return null;
};

const CRMobileDrawConent: React.FC<{
  navigationLinks: Array<CRNavLnk>;
  openProfileModal: any;
  isAuthenticated: boolean;
  handleDrawerToggle: () => void;
}> = (props) => {
  return (
    <Box
      marginTop={'10px'}
      sx={{
        '.MuiList-root': {
          paddingTop: '0px',
          paddingBottom: '5px',
        },
      }}
    >
      <LinkList
        navigationLinks={props.navigationLinks}
        openProfileModal={props?.openProfileModal}
        isAuthenticated={props?.isAuthenticated}
        handleDrawerToggle={props?.handleDrawerToggle}
      />
    </Box>
  );
};
export default CRMobileDrawConent;
