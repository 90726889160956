import useJourneyModal from './useJourneyModal';

const usePatientErrorModal = (
  patientErrorMessageModel: any,
  callbackLeave: () => void,
  callbackClose: () => void
): {
  setPatientExistModal: () => void;
  showPatientExistErrorModal: () => void;
} => {
  const { setJourneyModal: setPatientExistModal, showJourneyModal: showPatientExistErrorModal } =
    useJourneyModal({
      title: { value: patientErrorMessageModel?.fields?.modalTitle?.value },
      description: {
        value: patientErrorMessageModel?.fields?.modalDescription?.value,
      },
      customButtons:
        patientErrorMessageModel?.fields?.modalCTAButtons &&
        patientErrorMessageModel?.fields?.modalCTAButtons?.map((btn: any) => {
          return {
            name: btn?.displayName,
            callback: btn?.fields?.isLeavePage?.value ? callbackLeave : callbackClose,
          };
        }),
    });

  return {
    setPatientExistModal,
    showPatientExistErrorModal,
  };
};

export default usePatientErrorModal;
