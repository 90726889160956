import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

type InputBaseProps = {
  error: boolean;
};

export const StInputBase = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'errors',
})<InputBaseProps>(({ theme, error }) => ({
  background: theme.palette.white.main,
  color: `${theme.palette.neutral11.main} !important`,
  borderRadius: '8px',
  padding: '0 2px 0 11px',
  marginTop: '2px',
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 400,
  height: '40px',
  minWidth: '190px',
  border: error
    ? `1px solid ${theme.palette.error.main}`
    : `1px solid ${theme.palette.neutral3.main}`,
  '&:hover': {
    border: error
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.primary.main}`,
  },
  '&.Mui-focused': {
    border: error
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.primary.main}`,
  },
  '& input::placeholder': {
    color: `${theme.palette.neutral11.main} !important`,
    fontFamily: 'Proxima Nova',
    fontSize: '13px',
    lineHeight: '16px',
    opacity: 1,
    fontWeight: 400,
  },
}));

export const StFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: `${theme.palette.neutral8.main}`,
}));
