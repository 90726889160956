import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const StTagInfo = styled(Grid)(() => ({
  borderRadius: '5px',
  color: '#FFF',
  fontFamily: 'Proxima Nova',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '500',
  alignSelf: 'center',
  padding: '2px 6px',
  marginRight: '0.5rem',
}));

export const StHTMLDescription = styled(Grid)(() => ({
  color: '#444',
  fontFamily: 'Proxima Nova',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '33px',
  marginBottom: '24px',
}));
