import { MarkerF, InfoWindowF } from '@react-google-maps/api';
import StudyInfoCard from 'components/StudyInfoCard/StudyInfoCard';
import { RootState } from 'src/store/store';
import { useSelector, useDispatch } from 'react-redux';
import { selectStudyLocation, setHoveredLocation } from '../../features/studyLocationSlice';

const Markers = (props: any): React.ReactElement => {
  const dispatch = useDispatch();
  const study = props.study;
  const siteInfoDisplayFormatHover = props.siteInfoDisplayFormatHover;
  const buttonText = props.buttonText;
  const hoveredLocation = useSelector((state: RootState) => state.studyLocation.hoveredLocation);
  const selectedLocation = useSelector((state: RootState) => state.studyLocation.selectedLocation);
  const isSelected = selectedLocation?.siteCode === study.siteCode;
  const mapIcon = props?.mapIcon;
  const enrollSoonHovertext = props?.enrollSoonHovertext;

  const onClick = (study: any) => {
    if (selectedLocation) {
      dispatch(selectStudyLocation(null));
    } else {
      dispatch(selectStudyLocation(study));
    }
  };

  const onMouseOver = (study: any) => {
    if (selectedLocation) {
      dispatch(selectStudyLocation(null));
    }
    dispatch(setHoveredLocation(study));
  };

  const onMouseOut = () => {
    dispatch(setHoveredLocation(null));
  };

  const hoverInfoWindow = (lat: number, lng: number, study: any) => {
    return (
      <InfoWindowF position={{ lat: lat, lng: lng }} zIndex={100}>
        <StudyInfoCard
          siteInfo={study}
          siteAddressFormat={siteInfoDisplayFormatHover}
          buttonText={buttonText}
          selectedLocation={selectedLocation}
          isSelected={isSelected}
          isInsideMap={true}
          isHover={true}
        />
      </InfoWindowF>
    );
  };

  const selectedInfoWindow = (lat: number, lng: number, study: any) => {
    return (
      <InfoWindowF position={{ lat: lat, lng: lng }}>
        <StudyInfoCard
          siteInfo={study}
          siteAddressFormat={siteInfoDisplayFormatHover}
          buttonText={buttonText}
          selectedLocation={selectedLocation}
          isSelected={true}
          isInsideMap={true}
          enrollSoonHovertext={enrollSoonHovertext}
          isHover={true}
        />
      </InfoWindowF>
    );
  };
  return (
    <MarkerF
      position={{ lat: study?.latitude, lng: study?.longitude }}
      title={study.name}
      onClick={() => onClick(study)}
      onMouseOver={() => onMouseOver(study)}
      onMouseOut={onMouseOut}
      icon={mapIcon}
    >
      {hoveredLocation && !study?.notYetEnroll && hoveredLocation?.siteCode === study?.siteCode
        ? hoverInfoWindow(study?.latitude, study?.longitude, study)
        : null}
      {selectedLocation && !study?.notYetEnroll && selectedLocation?.siteCode === study?.siteCode
        ? selectedInfoWindow(study?.latitude, study?.longitude, study)
        : null}
    </MarkerF>
  );
};

export default Markers;
