import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export const StyledDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.themeColorSecondary.main,
  width: '238px',
  height: '3px',
  borderRadius: '4px',
  marginBlock: '0.5rem',
}));

export const StTextContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '330px',
    '.MuiTypography-root': {
      fontSize: '1.5rem',
    },
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '550px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '630px',
  },
}));
