import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import FormLabel from '@mui/material/FormLabel';

export const StInputBase = styled(InputBase)(({ theme }) => ({
  background: theme.palette.white.main,
  borderRadius: '4px',
  padding: '0 2px 0 8px',
  marginTop: '2px',
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 400,
  height: '40px',
  '&:hover': {
    border: `1px solid ${theme.palette.neutral6.main}`,
  },
  '&.Mui-focused': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& ::placeholder': {
    color: `${theme.palette.neutral8.main} !important`,
    fontFamily: 'Proxima Nova',
    fontSize: '13px',
    lineHeight: '16px',
  },
}));

export const StFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  color: `${theme.palette.neutral8.main}`,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  '&.Mui-focused': {
    color: `${theme.palette.neutral8.main}`,
  },
}));

export const StFormDiv = styled('div')(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  color: `${theme.palette.neutral8.main}`,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  '&.Mui-focused': {
    color: `${theme.palette.neutral8.main}`,
  },
}));

export const StCheckBoxLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  paddingBottom: '6px',
  color: `${theme.palette.neutral8.main}`,
}));

export const StPlaceholderTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral10.main,
  fontFamily: 'Proxima Nova',
  fontSize: '13px',
  lineHeight: '16px',
  padding: '4px 0 5px',
}));
