import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { JSSLink } from '../shared/Links/JSSLink.styles';
import Image from 'next/image';

export const StstickyBox = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  bottom: '0',
  padding: '24px 36px',
  background: '#FFFFFF',
  boxShadow: '0px -3px 4px rgba(0, 0, 0, 0.25)',
  zIndex: '9',
  [theme.breakpoints.down('md')]: {
    padding: '24px 32px',
  },
}));

export const StStickyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '1.5rem',
  [theme.breakpoints.down(500)]: {
    flexDirection: 'column',
    gap: '0.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1300px',
    margin: '0 auto !important',
  },
}));

export const StButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: '0.5rem',
}));

export const StCtaButtons = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment: string }>(({ theme, alignment }) => ({
  display: 'flex',
  gap: '1.5rem',
  width: '80%',
  justifyContent: alignment === 'Right' ? 'flex-end' : 'center',
  [theme.breakpoints.down(500)]: {
    flexDirection: 'column',
    gap: '0.5rem',
    alignItems: 'center',
    width: '100%',
  },
}));

export const StJSSLink = styled(JSSLink, {
  shouldForwardProp: (prop) => prop !== 'alignment' && prop !== 'numberOfCTA',
})<{ alignment: string; numberOfCTA: number }>(({ theme, alignment, numberOfCTA }) => ({
  lineHeight: '1.4rem',
  padding: '0.5rem 1.5rem',
  width: '100%',
  minWidth: '140px',
  alignSelf: 'center',
  maxWidth: alignment === 'Right' ? '192px' : numberOfCTA === 1 ? '252px' : '220px',
  [theme.breakpoints.down(500)]: {
    maxWidth: 'unset',
  },
}));

export const StFooterText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '26px',
  lineHeight: '39px',
  color: theme.palette.neutral8.main,
  width: '100%',
  [theme.breakpoints.down(500)]: {
    display: 'none',
  },
}));

export const StUpArrowImg = styled(Image)(({ theme }) => ({
  [theme.breakpoints.down(510)]: {
    display: 'none',
  },
}));

export const StMoveTotopLink = styled('div')(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  color: theme?.palette?.primary?.main,
  cursor: 'pointer',
  [theme.breakpoints.down(500)]: {
    marginTop: '0.5rem',
  },
  '& span': {
    width: '8px !important',
  },
}));

export const StMoveText = styled('span')(({ theme }) => ({
  marginLeft: '10px',
  minWidth: 'max-content',
  [theme.breakpoints.down(500)]: {
    marginLeft: '0px',
  },
}));
