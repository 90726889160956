import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

type TypographyProps = {
  isTablet: boolean;
  isMobile: boolean;
};

export const StTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<TypographyProps>(({ theme, isMobile, isTablet }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 600,
  color: theme.palette.themeColor.main,
  textAlign: isTablet || isMobile ? 'center' : 'unset',
  marginInline: isTablet ? '8rem' : isMobile ? '3rem' : 0,
}));

export const StDesc = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<TypographyProps>(({ theme, isMobile, isTablet }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '16px',
  fontWeight: 400,
  color: theme.palette.neutral8.main,
  textAlign: isTablet || isMobile ? 'center' : 'unset',
  marginInline: isTablet ? '8rem' : isMobile ? '3rem' : 0,
}));

export const StCardText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  fontWeight: 600,
  color: theme.palette.black.main,
}));

export const StCardTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontSize: '15px',
  fontWeight: 600,
  color: theme.palette.black.main,
}));

export const StNoSites = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTablet',
})<TypographyProps>(({ theme }) => ({
  color: theme.palette.hintText.main,
  textAlign: 'center',
  fontFamily: ' Proxima Nova',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  margin: '20px',
}));

export const StTextField = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'errors',
})<any>(({ theme, error, minWidth, width }) => ({
  background: theme.palette.white.main,
  borderRadius: '4px',
  padding: '0 2px 0 8px',
  marginTop: '2px',
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 400,
  height: '40px',
  ...(minWidth && { minWidth: `${minWidth}` }),
  ...(width && { width: `${width}` }),

  border: error
    ? `1px solid ${theme.palette.error.main}`
    : `1px solid ${theme.palette.neutral3.main}`,
  '&:hover': {
    border: error
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.primary.main}`,
  },
  '&.Mui-focused': {
    border: error
      ? `1px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.primary.main}`,
  },
  '& ::placeholder': {
    color: `${theme.palette.neutral7.main} !important`,
    fontFamily: 'Proxima Nova',
    fontSize: '16px',
    lineHeight: '16px',
    opacity: 100,
  },
}));

export const StDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.themeColorSecondary.main,
  width: '100px',
  height: '3px',
  borderRadius: '4px',
  // marginBlock: '0.5rem',
}));
