import { TextField, RichTextField, Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect } from 'react';
import React from 'react';

const replaceValue = (originaValue: string, lookupObj: Record<string, string>): string => {
  const tags = Object.keys(lookupObj);
  let replacedValue = originaValue;
  tags.forEach((tag) => {
    const tagValue = lookupObj[tag] || '';
    replacedValue = replacedValue.replace(new RegExp(`\\[${tag}\\]`, 'g'), tagValue as string);
  });
  return replacedValue;
};

export const RegisterMergeText: React.FC<{ field: TextField }> = (props) => {
  const aboutYourSelf = useSelector((state: RootState) => state.registrationDetails.aboutYourSelf);
  const registration = useSelector((state: RootState) => state.registrationDetails.registration);
  const lookupObj = {
    firstName: aboutYourSelf.firstName || '',
    lastName: aboutYourSelf.lastName || '',
    email: registration.email || '',
  };
  const newValue = replaceValue(String(props.field.value), lookupObj);
  return <Text field={{ value: newValue, editable: props.field.editable }} />;
};

export const RegisterMergeRichText: React.FC<{ field: RichTextField; className?: string }> = (
  props
) => {
  const aboutYourSelf = useSelector((state: RootState) => state.registrationDetails.aboutYourSelf);
  const crEmail = useSelector((state: RootState) => state.registrationDetails.registration.email);
  const myProfileData = useSelector((state: RootState) => state.crProfile.profileData);
  const emailFromProfile = myProfileData?.basicDetails?.email;
  const emailId = crEmail || emailFromProfile;
  const [newValue, setNewValue] = React.useState('');
  useEffect(() => {
    const lookupObj = {
      firstName: aboutYourSelf.firstName || '',
      lastName: aboutYourSelf.lastName || '',
      email: `<strong>${emailId}</strong>`,
    };

    const newValue = replaceValue(String(props.field.value), lookupObj);
    setNewValue(newValue);
  }, [props.field.value, crEmail, emailId]);

  return (
    <RichText
      field={{ value: newValue, editable: props.field.editable }}
      className={props.className}
    />
  );
};
