import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';

export const StBackDrop = styled(Backdrop)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
}));
