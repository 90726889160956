/**
 * Replaces the [pn] merge tag in the inputString with the call center number, formatted as an HTML anchor tag with a tel href.
 * @param inputString - The input string that may contain the [pn] merge tag.
 * @param phoneNumber - The phone number to be inserted into the [pn] merge tag.
 * @returns - The modified string with the [pn] merge tag replaced by the call center number formatted as an HTML anchor tag.
 */
const pnMergetagReplacer = (inputString = '', phoneNumber = '', getAsLink = true): string => {
  let newString = '';
  if (!phoneNumber && !inputString) {
    return '';
  }
  if (phoneNumber && inputString) {
    if (getAsLink) {
      newString = inputString.replace(
        /\[pn\]/g,
        `<a href="tel:${phoneNumber}" target="_blank" rel="noopener noreferrer">${phoneNumber}</a>`
      );
    } else {
      newString = inputString.replace(/\[pn\]/g, phoneNumber);
    }
    return newString;
  }
  return inputString ? inputString : '';
};
export default pnMergetagReplacer;
