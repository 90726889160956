/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { updateCookiePreference } from '../../services/CdpService';
import getDomainName from './getDomainName';
import { ICDPCookieCategoryItem, ICDPCookiePayload } from '../../types/CookieTypes';
import { deleteCookie, getCookie, getCookies, setCookie } from 'cookies-next';
import { initializeTagManager, trackEvent } from './gtmService';

const removeSpaces = (input: string) => input.replace(/\s+/g, '');

const acceptAll = async (
  potentialParticipantID: string,
  cookieCategoryNames: string[],
  guestType: string,
  maxAgeInDays: number
) => {
  const cookieCategory: ICDPCookieCategoryItem[] = cookieCategoryNames.map(
    (categoryName): ICDPCookieCategoryItem => {
      return {
        categoryType: categoryName,
        isActive: true,
        isOptin: true,
      };
    }
  );
  const payload: ICDPCookiePayload = {
    domain: getDomainName(),
    cookieCategory: cookieCategory,
  };
  try {
    await updateCookiePreference(
      potentialParticipantID || (getCookie('bx_guest_ref') as string),
      payload,
      guestType
    );
    await setAcceptCookieSettings(payload, maxAgeInDays);
  } catch (_e) {}
};
const rejectAll = async (
  potentialParticipantID: string,
  cookieCategoryNames: string[],
  guestType: string,
  maxAgeInDays: number
) => {
  const cookieCategory: ICDPCookieCategoryItem[] = cookieCategoryNames.map(
    (categoryName): ICDPCookieCategoryItem => {
      return {
        categoryType: categoryName,
        isActive: true,
        isOptin: removeSpaces(categoryName).toLowerCase() === 'essentialcookies',
      };
    }
  );
  const payload: ICDPCookiePayload = {
    domain: getDomainName(),
    cookieCategory: cookieCategory,
  };
  try {
    await updateCookiePreference(
      potentialParticipantID || (getCookie('bx_guest_ref') as string),
      payload,
      guestType
    );
    await setAcceptCookieSettings(payload, maxAgeInDays);
  } catch (_e) {}
};

const acceptCustom = async (
  potentialParticipantID: string,
  cookieCategoryArr: ICDPCookieCategoryItem[],
  guestType: string,
  maxAgeInDays: number
) => {
  const payload: ICDPCookiePayload = {
    domain: getDomainName(),
    cookieCategory: cookieCategoryArr,
  };
  await updateCookiePreference(
    potentialParticipantID || (getCookie('bx_guest_ref') as string),
    payload,
    guestType
  );
  await setAcceptCookieSettings(payload, maxAgeInDays);
};

const setAcceptCookies = (value: boolean, maxAgeInDays: number) => {
  const maxAgeInSeconds = maxAgeInDays * 24 * 60 * 60;
  setCookie('AcceptCookie', value, {
    maxAge: maxAgeInSeconds,
  });
};

const setAcceptCookieSettings = (value: ICDPCookiePayload, maxAgeInDays: number) => {
  const maxAgeInSeconds = maxAgeInDays * 24 * 60 * 60;
  setCookie('AcceptCookieSettings', JSON.stringify(value), {
    maxAge: maxAgeInSeconds,
  });
};

const isAcceptCookieOptinAndActive = (setting: string) => {
  if (!getCookie('AcceptCookieSettings')) return deleteGAandAdvertisementCookies(), false;

  const acceptCookieData = JSON.parse(
    getCookie('AcceptCookieSettings') as string
  ) as ICDPCookiePayload;
  const acceptCookieSettings = acceptCookieData.cookieCategory.filter(
    (x) => x.categoryType === setting
  )[0];
  if (
    setting === 'Non-Essential Cookies' &&
    !(acceptCookieSettings?.isOptin && acceptCookieSettings?.isActive)
  ) {
    deleteGAandAdvertisementCookies();
  }

  return acceptCookieSettings?.isOptin && acceptCookieSettings?.isActive;
};

const addOrRemovedAnalythicsCookie = (gtmId: string) => {
  if (isAcceptCookieOptinAndActive('Non-Essential Cookies')) {
    initializeTagManager(gtmId);
  } else {
    deleteGAandAdvertisementCookies();
  }
};

const deleteGAandAdvertisementCookies = () => {
  const allCookies = getCookies();
  const gaCookies =
    (allCookies && Object.keys(allCookies).filter((a) => a.startsWith('_ga'))) || [];
  gaCookies?.forEach((cookie: string) => {
    deleteSubdomainCookies(cookie);
  });
  deleteSubdomainCookies('_fbp');
};

const setOptinCookies = (isAdv: boolean, isAnalytics: boolean) => {
  trackEvent({
    event: 'CookieModalUpdate',
    CookieModalAds: isAdv ? 'on' : 'off',
    CookieModalAnalytics: isAnalytics ? 'on' : 'off',
  });
};

const deleteCookieData = (cookieName: string) => {
  const expireAfterTwoSecond = new Date();
  expireAfterTwoSecond.setTime(expireAfterTwoSecond.getTime() + 20000);
  deleteCookie(cookieName, {
    expires: expireAfterTwoSecond,
    secure: true,
  });
};

const deleteSubdomainCookies = (cookieName: string) => {
  document.cookie =
    cookieName +
    '=; Path=/; Domain=' +
    location.hostname +
    '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export {
  acceptAll,
  acceptCustom,
  setAcceptCookies,
  setAcceptCookieSettings,
  isAcceptCookieOptinAndActive,
  deleteGAandAdvertisementCookies,
  addOrRemovedAnalythicsCookie,
  setOptinCookies,
  deleteCookieData,
  deleteSubdomainCookies,
  rejectAll,
};
