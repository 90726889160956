import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import StudyMap from 'components/StudyMap/StudyMap';
import React from 'react';
import { Field, RichTextField, TextField } from '@sitecore-jss/sitecore-jss-nextjs';

interface IVariationOne {
  variation: string;
  siteInfoDisplayFormatHover: RichTextField;
  buttonText: TextField;
  enrollSoonIcon: Field<string>;
  enrollingMapIcon: Field<string>;
  notEnrollingMapIcon: Field<string>;
  enrollSoonText: Field<string>;
  enrollSoonHovertext: Field<string>;
}

const VariationOne: React.FC<IVariationOne> = ({
  variation,
  siteInfoDisplayFormatHover,
  ...props
}) => {
  const { buttonText } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const notEnrollingMapIcon = props.notEnrollingMapIcon;
  const enrollingMapIcon = props.enrollingMapIcon;
  const enrollSoonIcon = props?.enrollSoonIcon;
  const enrollSoonHovertext = props?.enrollSoonHovertext;

  return (
    <Grid
      item
      className="variation-one"
      style={
        isDesktop
          ? {
              width: '805px',
              height: '505px',
            }
          : isMobile
          ? {
              width: '100%',
              paddingLeft: '16px',
              paddingRight: '16px',
            }
          : isTablet
          ? {
              maxWidth: '805px',
              minHeight: '540px',
              width: '100%',
              paddingLeft: '3%',
              paddingRight: '3%',
            }
          : {}
      }
    >
      <StudyMap
        variation={variation}
        siteInfoDisplayFormatHover={siteInfoDisplayFormatHover}
        buttonText={buttonText}
        notEnrollingMapIcon={notEnrollingMapIcon}
        enrollingMapIcon={enrollingMapIcon}
        enrollSoonIcon={enrollSoonIcon}
        enrollSoonHovertext={enrollSoonHovertext}
      />
    </Grid>
  );
};

export default VariationOne;
