import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { Controller } from 'react-hook-form';

const consentsMedium = (props) => {
  return (
    <>
      <Grid className="consent-section">
        <Controller
          name={props?.name?.value}
          control={props.control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        alignSelf: 'flex-start',
                        padding: '0 9px',
                        background: '#ffffff',
                      }}
                      {...field}
                      defaultChecked={field.value}
                    />
                  }
                  label={
                    <Box>
                      <RichText field={props?.consentTitle}></RichText>
                      <Grid sx={{ display: field?.value ? 'block' : 'none' }}>
                        <Box
                          sx={{
                            display: 'block',
                            background: '#f2f2f2',
                            marginTop: '0.5rem',
                            padding: '1.4rem',
                            borderRadius: '10px',
                          }}
                        >
                          <Typography
                            onClick={(event: React.SyntheticEvent) => event.preventDefault()}
                          >
                            <Text field={props?.consentSubTitle}></Text>
                          </Typography>
                          <FormGroup aria-label={props?.formElements?.ariaLabel?.value} row={true}>
                            {props?.consentMediumList &&
                              props?.consentMediumList.map((consentMedium: any, index: any) => {
                                const fieldName = `${props?.name?.value}${consentMedium?.fields?.key?.value}`;
                                if (!consentMedium?.fields?.hideFromUI?.value) {
                                  return (
                                    <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
                                      <Controller
                                        name={fieldName}
                                        key={props?.name?.value}
                                        control={props.control}
                                        render={({ field }) => (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  sx={{
                                                    paddingInline: '0',
                                                  }}
                                                  {...field}
                                                  defaultChecked={field.value}
                                                />
                                              }
                                              label={<Text field={consentMedium?.fields?.name} />}
                                              sx={{
                                                display: 'flex',
                                                margin: 0,
                                                gap: '6px',
                                                '& .MuiFormControlLabel-label': {
                                                  fontFamily: 'Proxima Nova',
                                                  fontSize: '14px',
                                                  lineHeight: '16px',
                                                },
                                              }}
                                            />
                                            {props?.renderError && props?.renderError(fieldName)}
                                          </>
                                        )}
                                      />
                                    </Grid>
                                  );
                                } else if (
                                  consentMedium?.fields?.hideFromUI?.value &&
                                  consentMedium?.fields?.isRequired?.value
                                ) {
                                  return (
                                    <Controller
                                      key={index}
                                      name={fieldName}
                                      control={props.control}
                                      defaultValue={true}
                                      render={({ field }) => <input type="hidden" {...field} />}
                                    />
                                  );
                                } else if (
                                  consentMedium?.fields?.hideFromUI?.value &&
                                  consentMedium?.fields?.defaultOptIn?.value
                                ) {
                                  return (
                                    <Controller
                                      key={index}
                                      name={fieldName}
                                      control={props.control}
                                      defaultValue={true}
                                      render={({ field }) => <input type="hidden" {...field} />}
                                    />
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Box>
                  }
                  labelPlacement="end"
                />
                {props?.renderError && props?.renderError(props?.name?.value)}
              </FormControl>
            );
          }}
        />
      </Grid>
    </>
  );
};

export default consentsMedium;
