import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Carousel from 'react-material-ui-carousel';
import { defaultConfig } from '../Carousel.Constants';
import {
  CarouselWrapperType,
  FeaturedTrialItemType,
  ArticleListType,
  CRQuestionaaireListType,
  SavedTrialsListItem,
  PersonalizedFeaturedTrialItemType,
  studyQuestionaireType,
} from '../Carousel.types';
import CarouselItem from '../CarouselItem';
import CarouselTabWrapper from '../CarouselTabWrapper';
import { useState } from 'react';

const CarouselWrapper = (props: CarouselWrapperType): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 1024));

  //Currently using only for saved trials as the card size is bigger
  const reduceCardCount = useMediaQuery(theme.breakpoints.between(1024, 1200));

  // Using this only for mobile and tablet
  let carouselCardGroups:
    | ArticleListType[]
    | FeaturedTrialItemType[]
    | CRQuestionaaireListType[]
    | PersonalizedFeaturedTrialItemType[] = [];

  const carouselItemGroups: any = [];
  let showCount = false;
  let totalCount = 0;
  let autoplay = false;
  let autoplayInterval = 0;

  //Added Default Values from Config
  const descriptionWordLimit =
    props?.fields?.descriptionWordLimit?.value || defaultConfig.descriptionWordLimit;
  const titleCharacterLimit =
    props?.fields?.titleCharacterLimit?.value || defaultConfig.titleCharacterLimit;
  const descriptionLineLimit =
    props?.fields?.descriptionLineLimit?.value || defaultConfig.descriptionLineLimit;
  const titleLineLimit = props?.fields?.titleLineLimit?.value || defaultConfig.titleLineLimit;

  if (props.callerComponent === 'Articles') {
    const data = props?.fields?.articleList ? props?.fields?.articleList : [];
    totalCount = data.length;
    carouselCardGroups = props?.fields?.articleList || [];
    if (totalCount > 0) {
      for (let i = 0; i < totalCount; i += 3) {
        carouselItemGroups.push(props?.fields?.articleList.slice(i, i + 3));
      }
    }
    autoplay = props?.fields?.isAutomaticSlide ? props?.fields?.isAutomaticSlide.value : false;
    autoplayInterval = autoplay
      ? props?.fields?.slideDuration.value != ''
        ? parseInt(props?.fields?.slideDuration.value) * 1000
        : defaultConfig.autoplayInterval
      : 0;
  } else if (props.callerComponent === 'CR-Questionaaire') {
    const data = props?.questionaaireList || [];
    totalCount = data.length;
    carouselCardGroups = props?.questionaaireList || [];
    if (totalCount > 0) {
      for (let i = 0; i < totalCount; i += 3) {
        carouselItemGroups.push(props?.questionaaireList?.slice(i, i + 3));
      }
    }
    autoplay = props?.fields?.isAutomaticSlide ? props?.fields?.isAutomaticSlide.value : false;
    autoplayInterval = autoplay
      ? props?.fields?.slideDuration.value != ''
        ? parseInt(props?.fields?.slideDuration.value) * 1000
        : defaultConfig.autoplayInterval
      : 0;
  } else if (props.callerComponent === 'saved-trials') {
    const data = props?.data || [];
    totalCount = data.length;
    carouselCardGroups = props?.data || [];
    const cardGroupCount = reduceCardCount ? 2 : 3;
    if (totalCount > 0) {
      for (let i = 0; i < totalCount; i += cardGroupCount) {
        carouselItemGroups.push(props?.data?.slice(i, i + cardGroupCount));
      }
    }
  } else if (props.callerComponent === 'personalized-featured-trials') {
    const data = props?.personalizedFeaturedTrialList ? props?.personalizedFeaturedTrialList : [];

    showCount = props?.fields?.showCount?.value ? props?.fields?.showCount?.value : false;
    totalCount = data.length;
    carouselCardGroups = props?.personalizedFeaturedTrialList || [];
    if (totalCount > 0) {
      for (let i = 0; i < totalCount; i += 3) {
        carouselItemGroups.push(props?.personalizedFeaturedTrialList.slice(i, i + 3));
      }
    }
    autoplay = props?.fields?.isAutomaticSlide ? props?.fields?.isAutomaticSlide.value : false;
    autoplayInterval = autoplay
      ? props?.fields?.slideDuration.value != ''
        ? parseInt(props?.fields?.slideDuration.value) * 1000
        : defaultConfig.autoplayInterval
      : 0;
  } else {
    const data = props?.fields?.featuredTrialList ? props?.fields?.featuredTrialList : [];
    showCount = props?.fields?.showCount?.value ? props?.fields?.showCount?.value : false;
    totalCount = data.length;
    carouselCardGroups = props?.fields?.featuredTrialList || [];
    if (totalCount > 0) {
      const filteredTrials = props?.fields?.featuredTrialList.filter(
        (trials: FeaturedTrialItemType) =>
          trials?.fields?.relatedTrial?.fields?.deactivateTrial.value !== true
      );

      if (filteredTrials && filteredTrials?.length > 0) {
        for (let i = 0; i < totalCount; i += 3) {
          carouselItemGroups.push(filteredTrials.slice(i, i + 3));
        }
      }
    }
    autoplay = props?.fields?.isAutomaticSlide ? props?.fields?.isAutomaticSlide.value : false;
    autoplayInterval = autoplay
      ? props?.fields?.slideDuration.value != ''
        ? parseInt(props?.fields?.slideDuration.value) * 1000
        : defaultConfig.autoplayInterval
      : 0;
  }

  const [page, setPage] = useState(0);

  return (
    <>
      {isMobile || isTablet ? (
        // Added this Condition for typescript related changes
        props?.callerComponent === 'Articles' ? (
          <CarouselTabWrapper
            isMobile={isMobile}
            isTablet={isTablet}
            totalCount={totalCount}
            descriptionWordLimit={descriptionWordLimit}
            descriptionLineLimit={descriptionLineLimit}
            titleCharacterLimit={titleCharacterLimit}
            titleLineLimit={titleLineLimit}
            showCount={showCount}
            callerComponent={props?.callerComponent}
            data={carouselCardGroups as ArticleListType[]}
          />
        ) : props?.callerComponent === 'CR-Questionaaire' ? (
          <CarouselTabWrapper
            isMobile={isMobile}
            isTablet={isTablet}
            totalCount={totalCount}
            descriptionWordLimit={descriptionWordLimit}
            descriptionLineLimit={descriptionLineLimit}
            titleCharacterLimit={titleCharacterLimit}
            titleLineLimit={titleLineLimit}
            showCount={showCount}
            callerComponent={props?.callerComponent}
            data={carouselCardGroups as studyQuestionaireType[]}
            dataProps={props}
          />
        ) : props?.callerComponent === 'saved-trials' ? (
          <CarouselTabWrapper
            isMobile={isMobile}
            isTablet={isTablet}
            totalCount={totalCount}
            descriptionWordLimit={descriptionWordLimit}
            descriptionLineLimit={descriptionLineLimit}
            titleCharacterLimit={titleCharacterLimit}
            titleLineLimit={titleLineLimit}
            showCount={showCount}
            callerComponent={props?.callerComponent}
            data={carouselCardGroups as any}
            modalProps={props?.modalProps}
            state={props?.state}
          />
        ) : props?.callerComponent === 'personalized-featured-trials' ? (
          <CarouselTabWrapper
            isMobile={isMobile}
            isTablet={isTablet}
            totalCount={totalCount}
            descriptionWordLimit={descriptionWordLimit}
            descriptionLineLimit={descriptionLineLimit}
            titleCharacterLimit={titleCharacterLimit}
            titleLineLimit={titleLineLimit}
            showCount={showCount}
            callerComponent={props?.callerComponent}
            data={carouselCardGroups as PersonalizedFeaturedTrialItemType[]}
            seeIfYouQualifyText={props?.fields?.seeIfYouQualifyText}
            findOutMoreText={props?.fields?.findOutMoreText}
          />
        ) : (
          <CarouselTabWrapper
            isMobile={isMobile}
            isTablet={isTablet}
            totalCount={totalCount}
            descriptionWordLimit={descriptionWordLimit}
            descriptionLineLimit={descriptionLineLimit}
            titleCharacterLimit={titleCharacterLimit}
            titleLineLimit={titleLineLimit}
            showCount={showCount}
            callerComponent={props?.callerComponent}
            data={carouselCardGroups as FeaturedTrialItemType[]}
          />
        )
      ) : (
        <Carousel
          className={props?.identifier}
          NextIcon={
            (totalCount > 3 && (
              <ArrowForwardIosIcon
                className="next-btn"
                sx={{
                  fontSize: '2.5rem',
                  ...(page == carouselItemGroups.length - 1 && {
                    color: '#979797',
                  }),
                }}
              />
            )) ||
            ''
          }
          PrevIcon={
            (totalCount > 3 && (
              <ArrowBackIosNewIcon
                className="prev-btn"
                sx={{
                  fontSize: '2.5rem',
                  ...(page == 0 && {
                    color: '#979797',
                  }),
                }}
              />
            )) ||
            ''
          }
          sx={{
            ...(page == 0 && {
              '& div:has(button[aria-label="Previous"]):hover button': {
                opacity: '1 !important',
              },
              '& div:has(button[aria-label="Previous"]):hover svg': {
                fill: '#979797',
              },
              '& button[aria-label="Previous"] ': {
                userSelect: 'none',
                pointerEvents: 'none',
                cursor: 'default',
              },
            }),
            ...(page == carouselItemGroups.length - 1 && {
              '& div:has(button[aria-label="Next"]):hover button': {
                opacity: '1 !important',
              },
              '& div:has(button[aria-label="Next"]):hover svg': {
                fill: '#979797',
              },
              '& button[aria-label="Next"] ': {
                userSelect: 'none',
                pointerEvents: 'none',
                cursor: 'default',
              },
            }),
          }}
          navButtonsAlwaysVisible={true}
          fullHeightHover={false}
          autoPlay={autoplay}
          interval={autoplayInterval}
          indicators={totalCount > 3}
          navButtonsWrapperProps={{
            style: {
              // top: `calc(50% - 70px)`,
            },
          }}
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
              borderRadius: 0,
              margin: 0,
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: '1rem',
            },
          }}
          indicatorIconButtonProps={{
            style: {
              color: theme.palette.neutral4.main,
              margin: '2px',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: theme.palette.primary.main,
            },
          }}
          onChange={(now) => {
            setPage(now ?? 0);
          }}
        >
          {carouselItemGroups.map(
            (
              carouselItem:
                | FeaturedTrialItemType[]
                | ArticleListType[]
                | CRQuestionaaireListType[]
                | studyQuestionaireType[]
                | SavedTrialsListItem[]
                | PersonalizedFeaturedTrialItemType[],
              carouselItemIndex: number
            ) => {
              return (
                <Stack
                  key={carouselItemIndex}
                  direction="row"
                  justifyContent="center"
                  sx={{
                    maxWidth: `calc(100vw - 250px)`,
                    ...(props?.callerComponent === 'saved-trials' && {
                      maxWidth: 'calc(100vw - 20px)',
                    }),
                    marginInline: 'auto',
                    minHeight:
                      props?.callerComponent === 'Articles'
                        ? '400px'
                        : props?.callerComponent === 'CR-Questionaaire'
                        ? 'max-content'
                        : props?.callerComponent === 'personalized-featured-trials'
                        ? '600px'
                        : '530px',
                    ...(props?.callerComponent === 'FeaturedTrials' && { gap: 2 }),
                    [theme.breakpoints.between(1024, 1149)]: {
                      ...(props?.callerComponent === 'FeaturedTrials' && {
                        maxWidth: `calc(100vw - 95px)`,
                        marginLeft: '45px',
                      }),
                    },
                    [theme.breakpoints.between(1150, 1300)]: {
                      ...(props?.callerComponent === 'FeaturedTrials' && {
                        maxWidth: `calc(100vw - 170px)`,
                      }),
                    },
                  }}
                >
                  {/* Added The logic for conditional Rendering with Typescript */}
                  {props?.callerComponent === 'Articles' ? (
                    <CarouselItem
                      isMobile={isMobile}
                      isTablet={isTablet}
                      totalCount={totalCount}
                      slideNumber={carouselItemIndex}
                      data={carouselItem as ArticleListType[]}
                      descriptionWordLimit={descriptionWordLimit}
                      descriptionLineLimit={descriptionLineLimit}
                      titleCharacterLimit={titleCharacterLimit}
                      titleLineLimit={titleLineLimit}
                      showCount={showCount}
                      callerComponent={props.callerComponent}
                    />
                  ) : props?.callerComponent === 'CR-Questionaaire' ? (
                    <CarouselItem
                      isMobile={isMobile}
                      isTablet={isTablet}
                      totalCount={totalCount}
                      slideNumber={carouselItemIndex}
                      data={carouselItem as studyQuestionaireType[]}
                      dataProps={props}
                      descriptionWordLimit={descriptionWordLimit}
                      descriptionLineLimit={descriptionLineLimit}
                      titleCharacterLimit={titleCharacterLimit}
                      titleLineLimit={titleLineLimit}
                      showCount={showCount}
                      callerComponent={props.callerComponent}
                    />
                  ) : props?.callerComponent === 'saved-trials' ? (
                    <CarouselItem
                      isMobile={isMobile}
                      isTablet={isTablet}
                      totalCount={totalCount}
                      slideNumber={carouselItemIndex}
                      data={carouselItem as SavedTrialsListItem[]}
                      descriptionWordLimit={descriptionWordLimit}
                      descriptionLineLimit={descriptionLineLimit}
                      titleCharacterLimit={titleCharacterLimit}
                      titleLineLimit={titleLineLimit}
                      showCount={showCount}
                      callerComponent={props.callerComponent}
                      modalProps={props?.modalProps}
                      state={props?.state}
                    />
                  ) : props?.callerComponent === 'personalized-featured-trials' ? (
                    <CarouselItem
                      isMobile={isMobile}
                      isTablet={isTablet}
                      totalCount={totalCount}
                      slideNumber={carouselItemIndex}
                      data={carouselItem as PersonalizedFeaturedTrialItemType[]}
                      descriptionWordLimit={descriptionWordLimit}
                      descriptionLineLimit={descriptionLineLimit}
                      titleCharacterLimit={titleCharacterLimit}
                      titleLineLimit={titleLineLimit}
                      showCount={showCount}
                      callerComponent={props.callerComponent}
                      seeIfYouQualifyText={props?.fields?.seeIfYouQualifyText}
                      findOutMoreText={props?.fields?.findOutMoreText}
                    />
                  ) : (
                    <CarouselItem
                      isMobile={isMobile}
                      isTablet={isTablet}
                      totalCount={totalCount}
                      slideNumber={carouselItemIndex}
                      data={carouselItem as FeaturedTrialItemType[]}
                      descriptionWordLimit={descriptionWordLimit}
                      descriptionLineLimit={descriptionLineLimit}
                      titleCharacterLimit={titleCharacterLimit}
                      titleLineLimit={titleLineLimit}
                      showCount={showCount}
                      callerComponent={props.callerComponent}
                    />
                  )}
                </Stack>
              );
            }
          )}
        </Carousel>
      )}
    </>
  );
};

export default CarouselWrapper;
