import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StModalContainerWithModal = styled(Box)(({ theme }) => ({
  padding: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  background: theme.palette.white.main,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  '&:before': {
    content: '""',
    display: 'block',
    background: `linear-gradient(268.67deg, ${theme.palette.themeColorSecondary.main} -16.55%, ${theme.palette.themeColor.main} 73.37%)`,
    height: '16px',
    borderRadius: '16px 16px 0px 0px',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  },
  '&:focus': {
    outline: '0 !important',
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: '521px !important',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: '465px !important',
  },
  '@media screen and (max-width:600px)': {
    minWidth: '332px !important',
  },
}));

export const StmodalContainer = styled(Box)(() => ({
  padding: '24px',
}));

export const StHeadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',

  '@media screen and (max-width:600px)': {
    padding: '1px !important',
  },
}));

export const StHeadingText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '20px',
  color: '#000000',
}));

export const StDescriptionText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
  margin: '28px 0 32px',
}));

export const StFooterbuttons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  a: {
    padding: '12px 22px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    minWidth: '110px',
    marginLeft: '32px',
  },
}));
