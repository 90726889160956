import React, { CSSProperties } from 'react';
import { ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { hexToCSSFilter } from 'hex-to-css-filter';

interface ICustomNextImageProps {
  mobileImg?: ImageField | undefined;
  tabletImg?: ImageField | undefined;
  desktopImg?: ImageField | undefined;
  field?: ImageField | undefined;
  alt?: string;
  width?: string;
  height?: string;
  quality?: number;
  id?: string;
  priority?: boolean;
  style?: any;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: string;
  invertColor?: boolean;
  overrideIconColor?: string;
  ariaLabel?: string;
}

// default configuration for hex to css filter
const config = {
  acceptanceLossPercentage: 1,
};

const getIconColor = function (invertColor: boolean, overrideIconColor: string, theme: any) {
  if (invertColor) {
    return theme.palette.whiteColorFilter.main;
  } else if (!!overrideIconColor) {
    return `brightness(0) saturate(100%) ${hexToCSSFilter(overrideIconColor, config).filter}`;
  } else {
    return theme.palette.themeColorFilter.main;
  }
};

const CustomNextImage: React.FC<ICustomNextImageProps> = ({
  mobileImg,
  tabletImg,
  desktopImg,
  alt = '',
  id,
  style,
  layout,
  objectFit,
  objectPosition,
  width,
  height,
  invertColor,
  overrideIconColor,
  priority = false,
  ariaLabel,
  ...props
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));

  let imageSource = desktopImg;
  if (isMobile) {
    if (!!mobileImg && mobileImg?.value?.src) {
      imageSource = mobileImg;
    } else if (!!tabletImg && tabletImg?.value?.src) {
      imageSource = tabletImg;
    } else {
      imageSource = desktopImg;
    }
  } else if (isTablet) {
    if (!!tabletImg && tabletImg?.value?.src) {
      imageSource = tabletImg;
    } else if (!!mobileImg && mobileImg?.value?.src) {
      imageSource = mobileImg;
    } else {
      imageSource = desktopImg;
    }
  }
  const nextImageProps = {
    field: props?.field !== undefined ? props?.field : imageSource, // This condition is added to handle backwared compartabilty
    alt: alt,
    ...(!!ariaLabel && { ['aria-label']: ariaLabel }),
    ...(id && { id: id }),
    ...(style && { style: style }),
    ...(priority && { priority: priority }),
    ...(layout && { layout: layout }),
    ...(objectFit && { objectFit: objectFit }),
    ...(objectPosition && { objectPosition: objectPosition }),
    ...(props?.field && { width: width }),
    ...(props?.field && { height: height }),
    ...(invertColor || overrideIconColor
      ? { filter: getIconColor(invertColor!, overrideIconColor!, theme) }
      : {}),
  };
  return imageSource?.value?.src === undefined ? null : <NextImage {...nextImageProps} />;
};

export default CustomNextImage;
