import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Field, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { ContentBox, StyledDivider } from './StudyDetail.styles';
import StudyDetailList from './StudyDetailList';
import formatDate from '../../lib/utils/date/formatDate';
import getAgeRange from '../../lib/utils/coveo/getAgeRange';

type StudyDetailProps = ComponentProps & {
  fields: {
    ageLabel: Field<string>;
    completionDateLabel: Field<string>;
    conditionLabel: Field<string>;
    ctGovIdLabel: Field<string>;
    investigationalProductLabel: Field<string>;
    participantLabel: Field<string>;
    phaseLabel: Field<string>;
    sectionTitle: Field<string>;
    sponsorLabel: Field<string>;
    studyNymberLabel: Field<string>;
    treatmentTypeLabel: Field<string>;
  };
};

export type TrialConditionsType = {
  fields: {
    conditionValue: Field<string>;
  };
};

export type FieldValues = {
  minimumAge: Field<string>;
  maximumAge: Field<string>;
  phase: Field<string>;
  enrollment: Field<string>;
  completionDate: Field<string>;
  studyType: Field<string>;
  investigationalProduct: Field<string>;
  source: Field<string>;
  nctId: Field<string>;
  orgStudyId: Field<string>;
  trialConditions: TrialConditionsType[];
};
const StudyDetail = (props: StudyDetailProps): JSX.Element => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();

  if (!props.fields) {
    return <></>;
  }
  const fieldValues = sitecoreContext?.route?.fields as unknown as FieldValues;
  const fieldLabels = props?.fields;

  const trials = {
    value: fieldValues?.trialConditions
      ?.map((x: TrialConditionsType) => x.fields?.conditionValue.value)
      .join(','),
  };
  const age = {
    value: getAgeRange(fieldValues?.minimumAge?.value, fieldValues?.maximumAge?.value),
  };
  const formattedDate = {
    value:
      fieldValues?.completionDate?.value &&
      fieldValues?.completionDate?.value !== '0001-01-01T00:00:00Z'
        ? formatDate(fieldValues?.completionDate?.value)
        : '',
  };
  return (
    <ContentBox>
      <Typography
        variant="h4"
        data-testid="title"
        sx={{
          width: '83%',
          textAlign: 'center',
          fontWeight: '600',
          color: theme.palette.themeColor.main,
        }}
      >
        <Text field={fieldLabels?.sectionTitle} />
      </Typography>
      <StyledDivider />
      <StudyDetailList label={fieldLabels.conditionLabel} value={trials} />
      <StudyDetailList label={fieldLabels.ageLabel} value={age} />
      <StudyDetailList label={fieldLabels.phaseLabel} value={fieldValues?.phase} />
      <StudyDetailList label={fieldLabels.participantLabel} value={fieldValues?.enrollment} />
      <StudyDetailList label={fieldLabels.completionDateLabel} value={formattedDate} />
      <StudyDetailList label={fieldLabels.treatmentTypeLabel} value={fieldValues?.studyType} />
      <StudyDetailList
        label={fieldLabels.investigationalProductLabel}
        value={fieldValues?.investigationalProduct}
      />
      <StyledDivider />
      <StudyDetailList label={fieldLabels.sponsorLabel} value={fieldValues?.source} />
      <StudyDetailList label={fieldLabels.ctGovIdLabel} value={fieldValues?.nctId} />
      <StudyDetailList label={fieldLabels.studyNymberLabel} value={fieldValues?.orgStudyId} />
    </ContentBox>
  );
};

export default StudyDetail;
