import SitesList from '../../components/SitesList/SitesList';
import SitesMap from '../../components/SitesMap/SitesMap';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useRef, useState } from 'react';
import { post, postToLifeLink, updateLLDetailsTODB } from '../../lib/utils/axiosFetcher';
import { setModalData, showModal } from '../../features/modalSlice';
import { TextField, RichTextField, Text } from '@sitecore-jss/sitecore-jss-react';
import { UIButton } from '../../components/shared/UIButton.styles';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedSiteMetaData, siteSelection } from '../../features/siteSelectionSlice';
import { showLoader, hideLoader } from '../../features/loaderSlice';
import ToggleViewPanel from '../../components/shared/ToggleViewPanel/ToggleViewPanel';
import { RootState } from '../../store/store';
import { StDesc, StNoSites, StTitle } from './SiteSelection.styles';
import { getSiteDataPayload, transformSiteSelectionData } from './helper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { getKIElementsDecisionData } from '../../lib/utils/kiElements';
import { ISelectedSiteProps } from './SiteSelection.types';
import * as cdpService from '../../services/CdpService';
import { CDP_EVENTS, JOURNEYS } from '../../constants';
import {
  fetchNextPossiblePathAndData,
  IUpdateCurrentJourneyPayload,
} from '../../services/CdpService';
import { storeCDPData } from '../../features/cdpDataSlice';
import { useRouter } from 'next/router';
import { post as cdpPost } from '../../lib/utils/axiosCDPFetcher';
import {
  JOURNEY_STEP,
  NSC_STATUS,
  DEFAULT_ZIPCODE_ERROR_MESSAGE,
  DEFAULT_ZIPCODE_INVALID_MESSAGE,
  DEFAULT_ZIPCODE_REQUIRED_MESSAGE,
} from '../../constants';
import {
  getCookie,
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookieExpiryTime,
  getOrCreateEnrollmentId,
} from '../../lib/utils/enrollmentUtil';
import { LifeLinkRequest } from '../../types/LifeLink';
import { updateZipcode } from '../../features/geolocationSlice';
import getCurrentPageURL from '../../lib/utils/getBaseURL';
import getQueryParamsAsString from '../../lib/utils/getQueryParamsAsString';
import { StTextField } from '../../components/StudyLocation/StudyLocation.style';
import { Controller, useForm } from 'react-hook-form';
import { getQuery } from '../../lib/utils/common';
import { setCookie } from 'cookies-next';
import { trackEvent } from '../../lib/utils/gtmService';
import RichTextWithStudyPnMergeTag from '../../components/shared/RichTextWithStudyPnMergeTag/RichTextWithStudyPnMergeTag';
import { getStateFromZipcode } from '../../lib/utils/contactForm';
import { CR_SITE } from '../../lib/constant';

const SiteSelection: React.FC<any> = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const siteSettingsId: any = sitecoreContext?.siteSettingsId;
  const language: any = sitecoreContext?.language;
  const {
    enableSponsorConsentKiElement,
    enableSponsorConsentforLifeLink,
    invitedSiteQueryParamName,
    invitedProtocolQueryParamName,
  } = siteSettings || {};
  const router = useRouter();
  const { query } = router;
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const selectedPlace = useSelector((state: RootState) => state.selectedSite);
  const potentialParticipant_ID = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialParticipant_ID
  );
  const siteName = sitecoreContext?.site?.name;
  const geolocation = useSelector((state: RootState) => state.geolocation);
  const cdpState = useSelector((state: RootState) => state.cdpData);
  const potentialId = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialId
  );

  const queryParamState = useSelector((state: RootState) => state.queryParams);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const contactInfo = useSelector((state: RootState) => state.contactInfoDetails.contactInfo);
  const data = cdpState?.sitesData;

  const isDefaultSiteSelected = useRef<boolean>(false);
  const lastSelectedSite = useRef<{ type: string; siteCode: string | number }>({
    type: '',
    siteCode: '',
  });
  //const [enabled, setEnabled] = useState(false);
  const kieConsentRef = useRef<null | string>(null);
  const llConsentRef = useRef<null | string>(null);
  const { toggleOptions, nscModalPup, kiElementsModelPopup, SearchPlaceholderText, zipCodeRegex } =
    props?.fields || {};
  const errorMessage = props?.fields?.errorMessage || DEFAULT_ZIPCODE_ERROR_MESSAGE;
  const requiredValidationMessage =
    props?.fields?.requiredValidationMessage || DEFAULT_ZIPCODE_REQUIRED_MESSAGE;
  const errorValidationMessage =
    props?.fields?.errorValidationMessage || DEFAULT_ZIPCODE_INVALID_MESSAGE;
  const tenantKey = siteSettings?.tenantKey?.value || '';
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const screenerOutput = useSelector((state: RootState) => state.screenerOutput);
  const description = props.fields?.description;
  const title = props?.fields?.title;

  const decideLLEnabled = (
    isLLEnabledInSiteData: boolean,
    siteDirectedSchedule: boolean,
    isHoldscheduling: boolean
  ): boolean => {
    return (
      enableSponsorConsentforLifeLink?.value &&
      isLLEnabledInSiteData &&
      !siteDirectedSchedule &&
      !isHoldscheduling
    );
  };

  const getPostToLLPayload = (): LifeLinkRequest => {
    return {
      tenantKey: siteSettings?.tenantKey?.value || '',
      enrollmentId: getOrCreateEnrollmentId(),
      lifeLinkEnabled: '1',
      subjectHighRisk: screenerOutput.r || '',
      baseURL: getCurrentPageURL(),
      queryParams: getQueryParamsAsString(),
      sitecode: selectedPlace?.siteInfo?.siteCode || '',
      protocol: selectedPlace?.siteInfo?.protocol || '',
      languageName: language,
      siteSettingsId: siteSettingsId || '',
      isLLBackgroundJobCall: false,
      PotentialId: potentialId,
    };
  };

  const updateCurrentJourneyStepinCDP = async () => {
    const payload: IUpdateCurrentJourneyPayload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: JOURNEY_STEP.SITESELECTION,
      journeyStatus: '',
      isActiveEnrollment: '1',
    };

    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      cdpService.updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const updateZipcodeReferralinCDP = async (
    zipCode: string,
    state: string | null,
    referralStatus: string,
    isNsc: boolean
  ) => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      zipCode: zipCode,
      patientState: state,
      journeyStep: JOURNEY_STEP.SITESELECTION,
      patientReferralStatus: referralStatus,
      patientType: cdpState?.patientType,
    };
    if (potentialParticipant_ID) {
      const URL = `/api/contacts/UpdateZipcodeReferral/${potentialParticipant_ID}?guestType=${
        cdpState?.guestType || 'visitor'
      }`;
      try {
        if (isNsc) {
          setIsRedirecting(true);
        }
        await cdpPost(URL, payload)
          .then(async () => {
            const experienceId = process.env.NEXT_PUBLIC_CDP_EXPERIENCE_SITESELECTION_ID;
            const data = await fetchNextPossiblePathAndData(
              experienceId,
              isNsc,
              false,
              sitecoreContext?.site?.name
            );
            //storing cdp data, so can be used in next step
            // setLoading(false);

            //Added for workflow1 virtual site
            if (cdpState.journeyType === JOURNEYS.JOURNEY_01 && data?.isVirtual) {
              const selectedSitesData = data?.sitesData[0];

              await cdpService.updateIsVirtualinCDP(
                getCookie('bx_guest_ref'),
                cdpState?.guestType || 'visitor', // TODO: Confirm if it is Visitor or customer
                data?.isHumanAPIEnabledForVirtual,
                data?.sitesData,
                potentialId,
                potentialParticipant_ID,
                ''
              );

              const { isKIEenabled } = getKIElementsDecisionData(
                enableSponsorConsentKiElement,
                selectedSitesData.isProtocalKiElementsEnabled,
                selectedSitesData.isSiteKiElementsEnabled
              );
              const isLLEnabled = decideLLEnabled(
                selectedSitesData.isLifeLinkEnabled,
                selectedSitesData.siteDirectedSchedule,
                selectedSitesData.isHoldscheduling
              );

              const virtualSitesData: SelectedSiteMetaData = {
                selectionType: 'Confirm',
                shouldInfoWindowVisible: true,
                siteInfo: selectedSitesData,
              };
              dispatch(siteSelection(virtualSitesData));
              saveSelectedSiteData({
                selectedPlace: virtualSitesData,
                isKIEenabled,
                isLLEnabled,
                isSponserKieEnabled: enableSponsorConsentKiElement?.value,
                isSponsorLifeLinkEnabled: enableSponsorConsentforLifeLink?.value,
                isUserOptedKiElementConsent: null,
                isUserOptedLifeLinkConsent: null,
              });

              setCookie('journeyStep', JOURNEY_STEP.SITESELECTION, {
                expires: getCookieExpiryTime(
                  siteSettings?.resumeEnrollmentExpirationDays?.value ||
                    ENROLLMENT_EXPIRY_TIME_FALLBACK
                ),
                secure: true,
              });
              dispatch(storeCDPData(data));
              router.replace(
                {
                  pathname: data?.nextStepUrl,
                  query: getQuery(query),
                },
                data?.nextStepUrl
              );
            } else {
              isDefaultSiteSelected.current = false;
              dispatch(hideLoader());
              dispatch(storeCDPData(data));
              if (!data?.sitesData?.length && data?.nextStepUrl) {
                setCookie('journeyStep', JOURNEY_STEP.SITESELECTION, {
                  expires: getCookieExpiryTime(siteSettings?.resumeEnrollmentExpirationDays?.value),
                  secure: true,
                });
                router.replace(
                  {
                    pathname: data?.nextStepUrl,
                    query: getQuery(query),
                  },
                  data?.nextStepUrl
                );
              }
            }
            //End of Added for workflow1 virtual site
          })
          .finally(() => {
            if (isNsc) {
              setIsRedirecting(false);
            }
          });
      } catch (_e) {
        // setLoading(false);
      }
    }
  };

  const handleClickOnNSCLink = () => {
    const data = {
      title: nscModalPup?.fields?.modalTitle,
      description: nscModalPup?.fields?.modalDescription,
      customButtons: nscModalPup?.fields?.modalCTAButtons,
      hideCloseIcon: true,
      continueButtonCallback: handleNSCContinueClick,
    };
    dispatch(setModalData(data));
    dispatch(showModal());
  };

  useEffect(() => {
    updateCurrentJourneyStepinCDP();
    trackEvent(
      {
        event: 'Landed on Site selection page',
      },
      siteName === CR_SITE ? true : false
    );
  }, []);

  useEffect(() => {
    //setLoading(false);
    if (!isDefaultSiteSelected.current && Array.isArray(data) && data.length > 0) {
      const firstSiteFromList = data[0];
      const payload = {
        siteInfo: firstSiteFromList,
        selectionType: 'Partial',
        shouldInfoWindowVisible: true,
      };
      dispatch(siteSelection(payload));
      const extensionData = {
        protocol: firstSiteFromList.protocol,
        isHybridSite: firstSiteFromList.isHybridSite,
        siteCode: firstSiteFromList.siteCode,
      };
      cdpService.event(CDP_EVENTS.SITE_SELECTION_TOGGLE, extensionData);

      isDefaultSiteSelected.current = true;
    }
  }, [data]);

  const saveSiteSelection = async (payload: ISelectedSiteProps) => {
    //TODO: get api and payload from backend team
    lastSelectedSite.current = {
      type: payload?.selectedPlace?.selectionType,
      siteCode: payload?.selectedPlace?.siteInfo?.siteCode,
    };
    const reqBody = getSiteDataPayload(
      payload,
      contactInfo,
      tenantKey,
      getOrCreateEnrollmentId(),
      potentialParticipant_ID,
      potentialId,
      '' // TODO: Need to get appointment status
    );
    dispatch(showLoader());
    return new Promise(async (resolve, reject) => {
      try {
        const response = await post('/SiteSelection/AddOrUpdatePatientSiteDetails', reqBody);
        resolve({
          response: response,
          reqBody,
          payload: {
            ...reqBody,
            ...selectedPlace?.siteInfo,
            patientReferralStatus: cdpState?.patientReferralStatus,
            isSiteKiElementsEnabled: payload?.selectedPlace?.siteInfo?.isSiteKiElementsEnabled,
            isProtocolKiElementsEnabled:
              payload?.selectedPlace?.siteInfo?.isProtocalKiElementsEnabled,
            piName: selectedPlace?.siteInfo?.piName,
            eConsentUrl: selectedPlace?.siteInfo?.eConsentUrl,
            maxAgeForLL: selectedPlace?.siteInfo?.maxAgeForLL,
            minAgeForLL: selectedPlace?.siteInfo?.minAgeForLL,
            emailCras: selectedPlace?.siteInfo?.emailCras,
            studyStaffEmails: selectedPlace?.siteInfo?.studyStaffEmails,
            status: selectedPlace?.siteInfo?.siteStatus,
            allowEmails: selectedPlace?.siteInfo?.allowEmails,
            allowSms: selectedPlace?.siteInfo?.allowSms,
            latitude: selectedPlace?.siteInfo?.latitude,
            longitude: selectedPlace?.siteInfo?.longitude,
            radius: selectedPlace?.siteInfo?.siteRadius,
          },
        });
      } catch (error) {
        reject({ error: error, payload: reqBody });
      }
    });
  };

  const addUpdateSiteProtocolToDataExtension = async (data: any): Promise<any> => {
    const transformedData = transformSiteSelectionData(data);
    const guestRef = getCookie('bx_guest_ref');
    const URL = `/api/contacts/AddUpdateSiteProtocolToDataExtension/${guestRef}?guestType=${
      cdpState?.guestType || 'customer'
    }`;
    try {
      const res = await cdpPost(URL, transformedData);
      return res;
    } catch (error: any) {
      throw error;
    }
  };

  const { mutate: saveSelectedSiteData } = useMutation(saveSiteSelection, {
    onError: async () => {
      const extensionData = {
        tenantKey: tenantKey,
        journeyType: journeyType as string,
        selectedSite: lastSelectedSite.current.siteCode,
      };
      cdpService.event(CDP_EVENTS.SITE_SELECTION_SITE_SELECTED_ERROR, extensionData);

      lastSelectedSite.current = { type: '', siteCode: '' };
      dispatch(hideLoader());
    },
    onSuccess: async ({ payload }: any) => {
      const extensionData = {
        protocol: selectedPlace?.siteInfo?.protocol,
        isHybridSite: selectedPlace?.siteInfo?.isHybridSite,
        siteCode: selectedPlace?.siteInfo.siteCode,
      };

      // Post CDP event data and redirect on completion
      if (selectedPlace.selectionType === 'Confirm') {
        //Navigate as per CDP response
        //TODO: handle negative response
        try {
          //setIsRedirecting(true);
          const retVal: any = await addUpdateSiteProtocolToDataExtension(payload);

          const experienceId = process.env.NEXT_PUBLIC_CDP_EXPERIENCE_SITESELECTION_ID;
          if (retVal?.data === true) {
            setCookie('journeyStep', JOURNEY_STEP.SITESELECTION, {
              expires: getCookieExpiryTime(
                siteSettings?.resumeEnrollmentExpirationDays?.value ||
                  ENROLLMENT_EXPIRY_TIME_FALLBACK
              ),
              secure: true,
            });
            const nextStepData = await fetchNextPossiblePathAndData(
              experienceId,
              false,
              true,
              sitecoreContext?.site?.name
            );
            //storing cdp data, so can be used in next step

            if (
              (nextStepData?.isPostTOLL &&
                llConsentRef.current === 'Yes' &&
                enableSponsorConsentforLifeLink?.value) ||
              (nextStepData?.isPostTOLL && !enableSponsorConsentforLifeLink?.value)
            ) {
              llConsentRef.current = null;
              try {
                const dapApiLLResponse = await postToLifeLink(getPostToLLPayload());
                if (dapApiLLResponse.lifeLinkUrl) {
                  sessionStorage.setItem('isLLRedirection', 'true');
                  window.location.href = dapApiLLResponse.lifeLinkUrl;
                } else {
                  if (nextStepData?.nextStepUrl) {
                    //setLoading(true);
                    await router.replace(
                      {
                        pathname: nextStepData?.nextStepUrl,
                        query: getQuery(query),
                      },
                      nextStepData?.nextStepUrl
                    );
                    return;
                  }
                }
              } catch (error) {
                if (nextStepData?.nextStepUrl) {
                  //setLoading(true);
                  await router.replace(
                    {
                      pathname: nextStepData?.nextStepUrl,
                      query: getQuery(query),
                    },
                    nextStepData?.nextStepUrl
                  );
                }
              }
            } else if (nextStepData?.nextStepUrl) {
              let appointmentStatus = nextStepData?.appointmentStatus;
              if (!appointmentStatus) {
                appointmentStatus = 'NotInitiated';
              }
              if (
                journeyType &&
                journeyType !== JOURNEYS.JOURNEY_01 &&
                selectedPlace?.siteInfo?.isLifeLinkEnabled
              ) {
                const patientid = contactInfo?.patientId || '';
                await updateLLDetailsTODB(
                  getOrCreateEnrollmentId(),
                  tenantKey,
                  appointmentStatus,
                  potentialId,
                  patientid
                );
              }
              //setLoading(true);
              await router.replace(
                {
                  pathname: nextStepData?.nextStepUrl,
                  query: getQuery(query),
                },
                nextStepData?.nextStepUrl
              );
            }
            dispatch(storeCDPData(nextStepData));
          }
        } catch (_e: any) {
          //TODO: CDP call failed need to check
        }
        cdpService.event(CDP_EVENTS.SITE_SELECTION_SITE_SELECTED, extensionData);
      } else {
        // Saving siteinfo data to cdp on toggle site also
        await addUpdateSiteProtocolToDataExtension(payload)
          .then(() => {
            cdpService.event(CDP_EVENTS.SITE_SELECTION_TOGGLE, extensionData);
          })
          .catch(() => {
            const errorData = {
              tenantKey: tenantKey,
              journeyType: journeyType as string,
              selectedSite: lastSelectedSite.current?.siteCode,
            };
            cdpService.event(CDP_EVENTS.SITE_SELECTION_SITE_SELECTED_ERROR, errorData);
          });
      }
      dispatch(hideLoader());
    },
  });

  const updateNoSiteConvenientForPatient = (data: any) => {
    const { tenantKey, enrollmentId, referralStatus } = data;
    setIsRedirecting(true);

    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await post(
          `/SiteSelection/UpdatePatientDetailsForNSC?potentialId=${potentialId}&tenantKey=${tenantKey}&enrollmentId=${enrollmentId}&patientReferralStatus=${referralStatus}`,
          {}
        );
        resolve({
          data,
          payload: {},
        });
      } catch (error: any) {
        reject({ error: error, payload: {} });
      } finally {
        setIsRedirecting(false);
      }
    });
  };

  const { mutate: nscMutate } = useMutation(updateNoSiteConvenientForPatient, {
    onError: () => {
      cdpService.event(CDP_EVENTS.SITE_SELECTION_NO_SITE_CONVENIENT_ERROR, {
        tenantKey: tenantKey,
        journeyType: journeyType as string,
      });
    },
    onSuccess: async () => {
      const extensionData = {
        referralStatus: '',
      };
      const enrollmentId: any = getOrCreateEnrollmentId();
      try {
        await cdpPost(
          `/api/contacts/UpdateSiteProtocolDetailsForNSC/${getCookie(
            'bx_guest_ref'
          )}?enrollmentId=${enrollmentId}`,
          {}
        );
      } catch (_error) {}
      cdpService.event(CDP_EVENTS.SITE_SELECTION_NO_SITE_CONVENIENT, extensionData);

      try {
        const referralStatus =
          queryParamState[invitedSiteQueryParamName?.value] &&
          invitedSiteQueryParamName?.value &&
          queryParamState[invitedProtocolQueryParamName?.value] &&
          invitedProtocolQueryParamName?.value
            ? NSC_STATUS?.SITENOTCONVENIENT
            : NSC_STATUS?.NOSITECONVENIENT;
        updateZipcodeReferralinCDP('', '', referralStatus, true);
      } catch (_e) {
        //TODO: need to check if exp api fails what need to be done
      }
    },
  });

  const handleNSCContinueClick = () => {
    if (tenantKey && getOrCreateEnrollmentId()) {
      const nscPayload = {
        tenantKey,
        enrollmentId: getOrCreateEnrollmentId(),
        referralStatus:
          queryParamState[invitedSiteQueryParamName?.value] &&
          invitedSiteQueryParamName?.value &&
          queryParamState[invitedProtocolQueryParamName?.value] &&
          invitedProtocolQueryParamName?.value
            ? NSC_STATUS?.SITENOTCONVENIENT
            : NSC_STATUS?.NOSITECONVENIENT,
      };
      // setNscStatus(nscPayload?.referralStatus);
      nscMutate(nscPayload);
    }
  };

  const handleConsents = (isKIEenabled: boolean, isLLEnabled: boolean) => {
    saveSelectedSiteData({
      selectedPlace,
      isKIEenabled,
      isLLEnabled,
      isSponserKieEnabled: enableSponsorConsentKiElement?.value,
      isSponsorLifeLinkEnabled: enableSponsorConsentforLifeLink?.value,
      isUserOptedKiElementConsent: kieConsentRef.current,
      isUserOptedLifeLinkConsent: llConsentRef.current,
    });

    const extensionData: any = {
      kieConsent: kieConsentRef.current,
      llConsent: llConsentRef.current,
    };
    if (isKIEenabled && isLLEnabled) {
      cdpService.event(CDP_EVENTS.SITE_SELECTION_KIELEMENT_POPUP, extensionData);
      cdpService.event(CDP_EVENTS.SITE_SELECTION_LIFELINK_POPUP, extensionData);
    } else if (isKIEenabled) {
      cdpService.event(CDP_EVENTS.SITE_SELECTION_KIELEMENT_POPUP, extensionData);
    } else {
      cdpService.event(CDP_EVENTS.SITE_SELECTION_LIFELINK_POPUP, extensionData);
    }
  };

  const showKIEModal = (isLLenabled: boolean) => {
    const data = {
      title: kiElementsModelPopup?.fields?.modalTitle,
      description: kiElementsModelPopup?.fields?.modalDescription,
      customButtons: kiElementsModelPopup?.fields?.modalCTAButtons,
      hideCloseIcon: true,
      continueButtonCallback: !isLLenabled
        ? () => {
            kieConsentRef.current = 'Yes';
            handleConsents(true, isLLenabled);
          }
        : () => {
            kieConsentRef.current = 'Yes';
            showLLModal(true);
          },
      cancelButtonCallback: !isLLenabled
        ? () => {
            kieConsentRef.current = 'No';
            handleConsents(true, isLLenabled);
          }
        : () => {
            kieConsentRef.current = 'No';
            showLLModal(true);
          },
    };
    dispatch(setModalData(data));
    dispatch(showModal());
  };

  const showLLModal = (isKieEnaled: boolean) => {
    const data = {
      title: props?.fields?.modalTitle,
      description: props?.fields?.modalDescription,
      summary: props?.fields?.modalSummary,
      customButtons: props?.fields?.modalCTAButtons,
      siteAddressFormatinModalPopUp: props?.fields?.siteAddressFormatinModalPopUp,
      mergeTagData: selectedPlace?.siteInfo,
      hideCloseIcon: true,
      continueButtonCallback: () => {
        trackEvent(
          {
            event: 'Visited appointment scheduler',
          },
          siteName === CR_SITE ? true : false
        );
        llConsentRef.current = 'Yes';
        handleConsents(isKieEnaled, true);
        cdpService.event(CDP_EVENTS.SITE_SELECTION_LIFELINK_POPUP_CONTINUE, {
          tenantKey: tenantKey,
          selectedSite: lastSelectedSite.current.siteCode,
          journeyType: journeyType as string,
        });
      },
      cancelButtonCallback: () => {
        llConsentRef.current = 'No';
        handleConsents(isKieEnaled, true);
        cdpService.event(CDP_EVENTS.SITE_SELECTION_LIFELINK_POPUP_CANCELLED, {
          tenantKey: tenantKey,
          selectedSite: lastSelectedSite.current.siteCode,
          journeyType: journeyType as string,
        });
      },
    };
    dispatch(setModalData(data));
    dispatch(showModal());
  };

  //Partial site saving  on site selection (Clicking on card or marker)
  useEffect(() => {
    // IN CASE OF VIRTUAL SITE, WE SHOULD NOT SAVE THE SITE SELECTION FROM HERE
    if (cdpState?.isVirtual) {
      return;
    }
    if (selectedPlace && selectedPlace?.siteInfo?.siteCode) {
      // Check KIE
      const { isKIEenabled, isSponserSpecificConsentEnabled } = getKIElementsDecisionData(
        enableSponsorConsentKiElement,
        selectedPlace?.siteInfo?.isProtocalKiElementsEnabled,
        selectedPlace?.siteInfo?.isSiteKiElementsEnabled
      );
      const isLLEnabled = decideLLEnabled(
        selectedPlace?.siteInfo?.isLifeLinkEnabled,
        selectedPlace?.siteInfo?.siteDirectedSchedule,
        selectedPlace?.siteInfo?.isHoldscheduling
      );
      // if (isKIEenabled && isSponserSpecificConsentEnabled) {
      if (selectedPlace.selectionType === 'Confirm') {
        if (isKIEenabled && isSponserSpecificConsentEnabled && isLLEnabled) {
          showKIEModal(isLLEnabled);
        } else if (isKIEenabled && isSponserSpecificConsentEnabled) {
          showKIEModal(false);
        } else if (isLLEnabled) {
          showLLModal(false);
        } else {
          // payload fo LL to be added
          saveSelectedSiteData({
            selectedPlace,
            isKIEenabled,
            isLLEnabled,
            isSponserKieEnabled: enableSponsorConsentKiElement?.value,
            isSponsorLifeLinkEnabled: enableSponsorConsentforLifeLink?.value,
            isUserOptedKiElementConsent: null,
            isUserOptedLifeLinkConsent: null,
          });
        }
      } else {
        // SITE_SELECTION_TOGGLE
        // payload fo LL to be added
        saveSelectedSiteData({
          selectedPlace,
          isKIEenabled,
          isLLEnabled,
          isSponserKieEnabled: enableSponsorConsentKiElement?.value,
          isSponsorLifeLinkEnabled: enableSponsorConsentforLifeLink?.value,
          isUserOptedKiElementConsent: null,
          isUserOptedLifeLinkConsent: null,
        });
      }
    }
  }, [selectedPlace]);

  const buttonText: TextField = {
    value: props?.fields?.selectButton?.value || '',
  };
  const siteAddressFormat: RichTextField = {
    value: props?.fields?.siteAddressFormat?.value,
  };
  const siteAddressFormatinMap: RichTextField = {
    value: props?.fields?.siteAddressFormatinMap?.value,
  };

  const noSitesAvailable = () => {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ maxHeight: '550px', height: '550px' }}
      >
        <StNoSites isMobile={isMobile} isTablet={isTablet}>
          {journeyType &&
            journeyType === JOURNEYS.JOURNEY_01 &&
            'Please enter your ZIP code above to find a location near you'}
        </StNoSites>
      </Grid>
    );
  };

  const {
    control,
    getValues,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all' });

  // Added to populate zipcode field with geolocation zipcode
  useEffect(() => {
    setValue('zipcode', geolocation?.location?.zipcode);
  }, [geolocation?.location?.zipcode]);

  const numericOnly = (e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Enter') {
      e.preventDefault();
    }
  };

  const handleZipcodeSearch = handleSubmit(async () => {
    if (errors?.zipcode) {
      setError('zipcode', { type: 'manual', message: errorMessage?.value });
    } else {
      clearErrors('zipcode'); // will clear both errors from test.firstName and test.lastName
      // No errors, proceed with the search.
      dispatch(showLoader());
      const value = getValues();
      let state: null | string = null;
      try {
        state = (await getStateFromZipcode(value?.zipcode)) as string;
      } catch (_e) {}

      if (JOURNEYS.JOURNEY_01 === journeyType) {
        const data: any = { zipcode: value?.zipcode, state: state || null, city: '' };
        dispatch(updateZipcode(data));
      }
      updateZipcodeReferralinCDP(value?.zipcode, state, '', false);
    }
  });

  //TODO: what should happen when no site from backend or any error
  return (
    <Grid container direction="column" gap={2} id="site-selection">
      <Grid item>
        <StTitle isMobile={isMobile} isTablet={isTablet}>
          <RichTextWithStudyPnMergeTag field={title} />
        </StTitle>
      </Grid>
      <Grid item>
        <StDesc isMobile={isMobile} isTablet={isTablet}>
          <RichTextWithStudyPnMergeTag field={description} />
        </StDesc>
      </Grid>
      {journeyType && typeof journeyType === 'string' && journeyType === JOURNEYS.JOURNEY_01 && (
        <Grid item>
          <Stack sx={{ pt: 1 }}>
            <StDesc
              style={{ paddingTop: '20px', paddingBottom: '10px' }}
              isMobile={isMobile}
              isTablet={isTablet}
            >
              <Text field={props?.fields?.SearchHeading} />
            </StDesc>
            {!isMobile && (
              <Stack
                direction="row"
                justifyContent={!isTablet ? 'flex-start' : 'center'}
                alignItems="center"
                spacing={2}
              >
                <Grid width={'225px'}>
                  {/* <TextBox
                    onChangeHandler={handleZipcode}
                    // onKeyDown={numericOnly}
                    placeholder={
                      props?.fields?.SearchPlaceholderText
                        ? props?.fields?.SearchPlaceholderText.value
                        : ''
                    }
                    requiredMessage={requiredValidationMessage}
                    name="zipcode"
                    value={zipcode}
                  /> */}
                  <Controller
                    defaultValue={geolocation?.location?.zipcode}
                    rules={{
                      required: {
                        value: true,
                        message: requiredValidationMessage?.value,
                      },
                      pattern: {
                        // value: /^\d{5}$/,
                        value: new RegExp(zipCodeRegex?.fields?.value?.value),
                        message: errorValidationMessage?.value,
                      },
                    }}
                    control={control}
                    name="zipcode"
                    render={({ field }) => (
                      <>
                        <StTextField
                          sx={{ width: '100%' }}
                          placeholder={SearchPlaceholderText?.value}
                          onKeyDown={numericOnly}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
                          error={Boolean(errors?.zipcode)} // Set the error prop based on the presence of an error
                          {...field}
                        />
                        {errors.zipcode && (
                          <Stack style={{ marginTop: '0px' }} alignItems="center" direction="row">
                            <Typography color="error">{`${errors?.zipcode?.message}`}</Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Button
                  style={{
                    height: '40px',
                    padding: '12px 22px 12px 22px',
                    borderRadius: '20px',

                    fontFamily: 'Proxima Nova SemiBold',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    textAlign: 'center',
                    textTransform: 'none',
                    maxWidth: '122px',
                    alignSelf: 'baseline',
                  }}
                  variant="contained"
                  onClick={handleZipcodeSearch}
                >
                  Search
                </Button>
              </Stack>
            )}

            {isMobile && (
              <Stack direction="column" alignItems="center" spacing={2}>
                <Grid width={isMobile ? '250px' : '225px'}>
                  {/* <TextBox
                    onChangeHandler={handleZipcode}
                    placeholder={
                      props?.fields?.SearchPlaceholderText
                        ? props?.fields?.SearchPlaceholderText.value
                        : ''
                    }
                    defaultValue={geolocation?.location?.zipcode}
                    requiredMessage={requiredValidationMessage}
                    name="zipcode"
                    value={zipcode}
                  /> */}
                  <Controller
                    defaultValue={geolocation?.location?.zipcode}
                    rules={{
                      required: {
                        value: true,
                        message: requiredValidationMessage?.value,
                      },
                      pattern: {
                        value: new RegExp(zipCodeRegex?.fields?.value?.value),
                        message: errorValidationMessage?.value,
                      },
                    }}
                    control={control}
                    name="zipcode"
                    render={({ field }) => (
                      <>
                        <StTextField
                          sx={{ width: '100%' }}
                          placeholder={SearchPlaceholderText?.value}
                          onKeyDown={numericOnly}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}
                          error={Boolean(errors?.zipcode)} // Set the error prop based on the presence of an error
                          {...field}
                        />
                        {errors.zipcode && (
                          <Stack style={{ marginTop: '0px' }} alignItems="center" direction="row">
                            <Typography color="error">{`${errors?.zipcode?.message}`}</Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Button
                  style={{
                    height: '40px',
                    padding: '12px 22px 12px 22px',
                    borderRadius: '20px',
                    fontFamily: 'Proxima Nova SemiBold',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    textAlign: 'center',
                    textTransform: 'none',
                    width: '252px',
                  }}
                  variant="contained"
                  onClick={handleZipcodeSearch}
                  type="submit"
                >
                  Search
                </Button>
              </Stack>
            )}
          </Stack>
        </Grid>
      )}
      <Grid item container sx={{ maxHeight: isMobile || isTablet ? '650px' : '550px' }}>
        {/* TODO: Currently sites will get from CDP so there wont be loading also isError flag, 
          so for time being keeping this flag false, once we
          have zipcode story we can modify this logic  */}
        {false ? (
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <ToggleViewPanel
            option1={toggleOptions[0]?.fields?.toggleOption}
            option2={toggleOptions[1]?.fields?.toggleOption}
            option1Component={
              data && data.length > 0 ? (
                <SitesList
                  siteDetails={data}
                  buttonText={buttonText}
                  siteAddressFormat={siteAddressFormat}
                  mapIcon={props?.fields?.mapIcon}
                  phoneIcon={props?.fields?.phoneIcon}
                  isError={false}
                  virtualIcon={props?.fields?.virtualIcon}
                  virtualMapIcon={props?.fields?.virtualMapIcon}
                  virutalSiteText={props?.fields?.virutalSiteText}
                />
              ) : (
                noSitesAvailable()
              )
            }
            option2Component={
              <SitesMap
                siteDetails={data}
                fields={props?.fields}
                buttonText={buttonText}
                siteAddressFormat={siteAddressFormatinMap}
                mapIcon={props?.fields?.mapIcon}
                phoneIcon={props?.fields?.phoneIcon}
                isError={false}
              />
            }
            inputProps={{ 'aria-label': props?.fields.switchToggleAriaLabel?.value }}
          />
        )}
      </Grid>
      <Grid
        item
        sx={{
          textAlign: isMobile || isTablet ? 'center' : 'center',
          ...(isMobile && { marginInline: '35px' }),
        }}
      >
        {data && data.length > 0 && (
          <UIButton className="btn" onClick={handleClickOnNSCLink}>
            <Text field={props?.fields?.noSitesConvenientText} />
          </UIButton>
        )}
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isRedirecting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};
export default SiteSelection;
