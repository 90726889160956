import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StContainer, StContent, StSubTitle, StTitle } from './ContentBlockWithImage.styles';
import { ContentBlockWithImageProps } from './ContentBlockWithImage.type';
import CustomNextImage from '../CustomNextImage/CustomNextImage';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ContentBlockWithImage = (props: ContentBlockWithImageProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (!props?.fields) return <></>;

  return (
    <StContainer>
      <CustomNextImage
        priority={true} // When true, the image will be considered high priority and preload
        style={{ zIndex: 0 }}
        mobileImg={props?.fields?.imageContentMobile}
        tabletImg={props?.fields?.imageContentTablet}
        desktopImg={props?.fields?.imageContentDesktop}
      />
      {props.fields.heading.value && (
        <StTitle isMobile={isMobile}>
          <Text field={props.fields.heading} />
        </StTitle>
      )}
      {props.fields.subHeading.value && (
        <StSubTitle>
          <Text field={props.fields.subHeading} />
        </StSubTitle>
      )}
      {props.fields.content.value && (
        <StContent>
          <RichText field={props.fields.content} />
        </StContent>
      )}
    </StContainer>
  );
};

export default ContentBlockWithImage;
