import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from '../../components/shared/Dropdown/Dropdown';
import { RootState } from '../../store/store';
import { updateSelectedOptionValue } from '../../features/studySwitcherSlice';
import { Field, Text } from '@sitecore-jss/sitecore-jss-react';
import { VIEW_ALL_OPTION_VALUE } from '../../constants/index';
import Typography from '@mui/material/Typography';
import { StDTPGridContainer } from '../../components/shared/Container/Container.styles';
import { SxProps } from '@mui/material/styles';

export interface IProtocolDropdownProps {
  enableViewAllSites?: Field<boolean>;
  makeDropdownReadOnly?: Field<boolean>;
  viewAllSitesText?: Field<string>;
  viewSitesForLabelText?: Field<string>;
  positionDropDownInsideList?: boolean;
  style?: SxProps;
}
/**
 * StudySwitch Dropdown inside StudyLocation
 * This value reflect the respective Tab in StudySwitcher Component
 * But Changing values here shouldn't change StudySwitcher Tabs
 */
const ProtocolDropdown: React.FC<IProtocolDropdownProps> = (props) => {
  const style: SxProps = props.style || {};
  const dispatch = useDispatch();
  const [localOptions, setLocalOptions] = useState<Array<any>>([]);
  const options = useSelector((state: RootState) => state.studySwitcher.options);
  const selectedOptionName = useSelector(
    (state: RootState) => state.studySwitcher.selectedOptionName
  );
  const selectedOptionValue = useSelector(
    (state: RootState) => state.studySwitcher.selectedOptionValue
  );
  const isReadOnly = props.makeDropdownReadOnly?.value || false;
  const enableViewAllSites = props.enableViewAllSites?.value || false;
  const viewAllSitesText = props.viewAllSitesText?.value || 'View All';

  useEffect(() => {
    if (Array.isArray(options)) {
      const matchingOption = options.find((item) => item.name === selectedOptionName);
      const newValue = matchingOption?.value || '';
      dispatch(updateSelectedOptionValue(newValue));
    }
  }, [selectedOptionName]);

  useEffect(() => {
    if (Array.isArray(options) && options.length > 0) {
      if (enableViewAllSites) {
        const isViewAllInOptons = options.find((item) => item.value === VIEW_ALL_OPTION_VALUE);
        if (!isViewAllInOptons) {
          const viewAllOption = { name: viewAllSitesText, value: VIEW_ALL_OPTION_VALUE };
          setLocalOptions([...options, viewAllOption]);
          dispatch(updateSelectedOptionValue(options[0].value));
        }
      } else {
        setLocalOptions(options);
        dispatch(updateSelectedOptionValue(options[0].value));
      }
    }
  }, [options]);

  return (
    <>
      {localOptions.length > 0 ? (
        <StDTPGridContainer sx={{ margin: '0px !important', ...style }}>
          <Typography variant="body">
            <Text field={props?.viewSitesForLabelText} />
          </Typography>
          <Dropdown
            ariaLabel={props?.viewSitesForLabelText?.value}
            fullWidth={true}
            labelId="protocol-switcher"
            id="protocol-switcher-dropdown"
            dataTestId="protocol-switcher-dropdown"
            defaultValue={localOptions[0]}
            type="string"
            value={selectedOptionValue}
            onChangeHandler={(e: any) => {
              const newValue = e.target?.value || '';
              dispatch(updateSelectedOptionValue(newValue));
            }}
            options={localOptions}
            menuItemSxProps={{ whiteSpace: 'normal' }}
            selectSxProps={{
              width: '100%',
            }}
            isDisabled={isReadOnly}
          />
        </StDTPGridContainer>
      ) : null}
    </>
  );
};

export default ProtocolDropdown;
