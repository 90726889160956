import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  ImageField,
  Placeholder,
  RichText,
  RichTextField,
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StDTPGridWithoutBackground, StDTPWrapper } from '../shared/Container/Container.styles';
import { useEffect, useState } from 'react';
import pnMergetagReplacer from '../../lib/utils/pnMergeTagReplacer';
import getCallCenterNumber from '../../store/selectors/getCallCenterNumber';
import { StNextImage } from '../shared/NextImage.styles';
import {
  PreScreenerFooter,
  PreScreenerHeader,
  PreScreenerTitle,
  PreScreenerWrapper,
  StyledDivider,
} from './PreScreenerBanner.style';
import {
  PreScreenerBannerFields,
  PreScreenerBannerProps,
  PreScreenerFooterItemProps,
  PreScreenerFooterProps,
  PreScreenerHeaderProps,
} from './PreScreenerBanner.types';

const fallbackIcon: ImageField = {
  value: {
    src: '',
  },
};
const fallbackTextField: TextField = {
  value: '',
};
const fallbackRT: RichTextField = {
  value: '',
};
const initialProps: PreScreenerBannerFields = {
  heading: fallbackTextField,
  headingIcon: fallbackIcon,
  subHeading: fallbackRT,
  contentList: [{ fields: { content: fallbackRT, icon: fallbackIcon } }],
  applyGradient: {
    value: false,
  },
};

const Header: React.FC<PreScreenerHeaderProps> = ({
  heading,
  subHeading,
  headingIcon,
  applyGradient,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const themeColor = theme.palette.themeColor.main;
  const themeColorSecondary = theme.palette.themeColorSecondary.main;
  const iconDimentions = () => {
    if (isMobile) {
      return '48px';
    }
    if (isTablet) {
      return '64px';
    }
    return '80px';
  };
  const backgroundColor = applyGradient.value
    ? `linear-gradient(to top, ${themeColor}, ${themeColorSecondary} 150%)`
    : themeColor;

  return (
    <Box
      sx={{
        background: backgroundColor,
      }}
    >
      <PreScreenerWrapper>
        <PreScreenerHeader id="pre-screener-header">
          <Box flexShrink="0">
            <StNextImage
              suppressHydrationWarning
              field={headingIcon}
              height={iconDimentions()}
              width={iconDimentions()}
              style={{
                filter: 'brightness(0) invert(1)', // White color
              }}
            />
          </Box>
          <PreScreenerTitle>
            <Typography className="pre-screener-banner__header__title" variant="h3">
              <Text data-testid="title" field={heading} />
            </Typography>
            <StyledDivider />
            <RichText className="pre-screener-banner__header__body" field={subHeading} />
          </PreScreenerTitle>
        </PreScreenerHeader>
      </PreScreenerWrapper>
    </Box>
  );
};

const FooterInfoItem: React.FC<PreScreenerFooterItemProps> = ({ icon, text }) => {
  return (
    <Box display={'flex'} columnGap={'20px'}>
      {icon?.value?.src !== '' ? (
        <Box flexShrink={0}>
          <StNextImage suppressHydrationWarning field={icon} height={'40px'} width={'40px'} />
        </Box>
      ) : null}

      {text?.value !== '' ? <RichText field={text} /> : null}
    </Box>
  );
};
const Footer: React.FC<PreScreenerFooterProps> = ({ contentList }) => {
  if (Array.isArray(contentList) && contentList.length > 0) {
    return (
      <PreScreenerFooter>
        {contentList.map((item, index) => {
          return (
            <FooterInfoItem key={index} icon={item.fields?.icon} text={item.fields?.content} />
          );
        })}
      </PreScreenerFooter>
    );
  }
  return null;
};
const PreScreenerBanner: React.FC<PreScreenerBannerProps> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  const callCenterNumber = getCallCenterNumber(sitecoreContext);
  const applyGradient = props.fields?.applyGradient || initialProps.applyGradient;
  const heading = props.fields?.heading || initialProps.heading;
  const headingIcon = props.fields?.headingIcon || initialProps.headingIcon;
  const contentList = props.fields?.contentList || initialProps.contentList;
  const originalSubHeading = props.fields?.subHeading || initialProps.subHeading;
  const [subHeading, setSubHeading] = useState(originalSubHeading);
  useEffect(() => {
    if (callCenterNumber.value) {
      const newSubHeadingValue = pnMergetagReplacer(
        originalSubHeading?.value,
        String(callCenterNumber.value)
      );
      setSubHeading({ ...originalSubHeading, value: newSubHeadingValue });
    }
  }, [callCenterNumber.value]);

  return (
    <div id="pre-screener-wrapper" style={{ marginBlock: '2rem' }}>
      <Header
        heading={heading}
        subHeading={subHeading}
        headingIcon={headingIcon}
        applyGradient={applyGradient}
      />
      {/* Body */}
      <StDTPWrapper>
        <StDTPGridWithoutBackground>
          <Placeholder name="jss-main-content" rendering={props?.rendering} />
        </StDTPGridWithoutBackground>
      </StDTPWrapper>
      <StDTPWrapper>
        <StDTPGridWithoutBackground>
          <Footer contentList={contentList} />
        </StDTPGridWithoutBackground>
      </StDTPWrapper>
    </div>
  );
};

export default PreScreenerBanner;
