import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {
  StCardDesc,
  StCardSubTitle,
  StCardTitle,
  StCountContainer,
  StCountText,
} from '../shared/Carousel/Carousel.styles';
import Image from 'next/image';
import { UIButton } from '../shared/UIButton.styles';
import { useRouter } from 'next/router';
// import { getQuery } from '../../lib/utils/common';

const PersonalizedTrialCardItem = (props: any): JSX.Element => {
  const theme = useTheme();
  const router = useRouter();
  // const { query } = router;

  const trials = props?.data?.raw;
  const getCardWidth = () => {
    if (props?.isTablet) {
      return 306;
    } else if (props?.isMobile) {
      return 271;
    } else {
      return 282;
    }
  };

  const getCardMaxWidth = () => {
    if (props?.isTablet) {
      return 306;
    } else if (props?.isMobile) {
      return 271;
    } else {
      return 355;
    }
  };
  const carouselDataIndex = props?.carouselDataIndex ? props.carouselDataIndex : 0;
  const handleClick = () => {
    if (props?.data?.raw?.featuredeprurl) {
      router.push(props?.data?.raw?.featuredeprurl);
    } else {
      window.open(props?.data?.uri, '_blank');
    }
  };

  return (
    <Card
      sx={{
        minWidth: getCardWidth(),
        maxWidth: getCardMaxWidth(),
        marginInline: props?.isTablet || props?.isMobile ? 'none' : 'auto',
        margin: props?.isTablet || props?.isMobile ? '1.4rem' : 'none',
        borderRadius: '0.6rem',
        boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Image src={trials?.featuredimage} alt="image" width={355} height={280} />
      </Box>

      {props.showCount && (
        <StCountContainer data-testid={`showCount-${props.itemIndex}`}>
          <StCountText>
            {props?.isTablet || props?.isMobile
              ? props.itemIndex + 1
              : props.itemIndex + 1 + carouselDataIndex * 3}
            /{props.totalCount}
          </StCountText>
        </StCountContainer>
      )}

      <CardContent sx={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
        <StCardTitle>{trials?.featuredcondition}</StCardTitle>
        <StCardSubTitle limit={props?.titleLineLimit}>{trials?.trialtitle}</StCardSubTitle>
        <StCardDesc limit={props?.descriptionLineLimit}>{trials?.trialdescription}</StCardDesc>
      </CardContent>
      <CardActions sx={{ margin: '1rem' }}>
        <UIButton
          variant={trials?.featuredeprurl ? 'contained' : 'outlined'}
          sx={{ width: '100%', padding: '5px' }}
          onClick={handleClick}
        >
          {trials?.featuredeprurl
            ? props?.seeIfYouQualifyText?.value
            : props?.findOutMoreText?.value}
        </UIButton>
      </CardActions>
    </Card>
  );
};

export default PersonalizedTrialCardItem;
