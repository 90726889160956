import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

type StTitleProps = {
  isMobile: boolean;
};
export const StContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBlock: '5rem',
  gap: '1.5rem',
}));

export const StTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StTitleProps>(({ theme, isMobile }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 600,
  lineHeight: 'normal',
  color: `${theme.palette.themeColor.main}`,
  fontSize: isMobile ? '24px' : '2rem',
  width: isMobile ? '370px' : '420px',
  textAlign: 'center',
}));

export const StSubTitle = styled(Box)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 600,
  fontSize: '1.5rem',
  lineHeight: 'normal',
  color: `${theme.palette.themeColor.main}`,
}));

export const StContent = styled(Box)(({ theme }) => ({
  fontFamily: 'Proxima Nova Regular',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  color: `${theme.palette.themeColor.main}`,
}));
