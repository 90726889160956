import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontFamily: `"Proxima Nova Regular", sans-serif`,
  fontWeight: 600,
  fontSize: '30px',
  lineHeight: '30px',
  [theme.breakpoints.down(510)]: {
    fontSize: '22px',
    textAlign: 'center',
  },
}));

export const StSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontFamily: `"Proxima Nova Regular", sans-serif`,
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '30px',
  [theme.breakpoints.down(510)]: {
    fontSize: '16px',
    textAlign: 'center',
  },
}));

export const StLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.themeColor.main,
  fontFamily: `"Proxima Nova", sans-serif`,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '16px',
  [theme.breakpoints.down(510)]: {
    fontSize: '14px',
  },
}));

export const StFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  color: `${theme.palette.neutral8.main}`,
}));

export const RegWorkflowContainer = styled(Box)(({ theme }) => ({
  background: `${theme.palette.white.main}`,
  padding: '2rem',
  minHeight: `calc(100vh - 7rem)`,
}));
