import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

type FooterWrapperProps = {
  bgColor?: string;
};
export const StFooterWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<FooterWrapperProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || theme.palette.backgroundType1.main,
}));

export const StFooterContainer = styled(Grid)(({ theme }) => ({
  boxSizing: 'border-box',
  minHeight: '7rem',
  bottom: 0,
  padding: '2rem 2rem',
  [theme.breakpoints.up('xl')]: {
    width: '1300px',
    margin: '0 auto !important',
  },
})) as typeof Grid;

export const StPrimaryLinkContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

export const StSocialMediaContainer = styled(Grid)(({ theme }) => ({
  marginTop: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 0,
  },
}));

export const StSecondaryLinkContainer = styled(Grid)(({ theme }) => ({
  marginTop: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.between(600, 700)]: {
    paddingLeft: '6rem',
    paddingRight: '6rem',
  },
  [theme.breakpoints.between(700, 'lg')]: {
    paddingLeft: '9rem',
    paddingRight: '9rem',
  },
}));

export const StCopyRightText = styled(Grid)(({ theme }) => ({
  marginTop: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    marginTop: '1rem',
  },
  '& div p': {
    display: 'inline',
    marginBottom: 0,
  },
})) as typeof Grid;

export const StDisclaimerText = styled(Grid)(({ theme }) => ({
  marginTop: '1.5rem',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));
