import axios from 'axios';

const objectToQueryString = (obj: any) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join('&');
};

export const getStudyLocation = async (params: any): Promise<any> => {
  const timeout = 1000 * 20; // 20 seconds
  try {
    const response = await axios.get(
      `/api/dap-api?url=/SiteSelection/GetSites&${objectToQueryString(params)}`,
      { timeout }
    );
    return response?.data?.siteDetails ? response?.data?.siteDetails : [];
  } catch (error) {
    throw error;
  }
};

export const convertToString = (input: string): string => {
  return input ? input.replace(/[{()}]/g, '') : '';
};
