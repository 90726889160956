import * as VerificationAlert from 'src/components/AddToMyInterest/VerificationAlert/VerificationAlert';
import * as ArticlePage from 'src/components/ArticlePage/ArticlePage';
import * as ArticleCarousel from 'src/components/Articles/ArticleCarousel';
import * as SmallBanner from 'src/components/Banner/SmallBanner/SmallBanner';
import * as ButtonGroups from 'src/components/ButtonGroups/ButtonGroups';
import * as CarousalList from 'src/components/CarousalList/CarousalList';
import * as Content from 'src/components/CarousalList/Content/Content';
import * as Slide from 'src/components/CarousalList/Slide/Slide';
import * as CommunityList from 'src/components/CommunityList/CommunityList';
import * as ContactInfoContextProvider from 'src/components/ContactInfo/ContactInfoContextProvider';
import * as AdultContactInfo from 'src/components/ContactInfo/adult/AdultContactInfo';
import * as CaregiverContactInfo from 'src/components/ContactInfo/caregiver/CaregiverContactInfo';
import * as ContactInfoHeader from 'src/components/ContactInfo/ContactinfoHeaders/ContactInfoHeader';
import * as ContactInfoHeaderWithIcon from 'src/components/ContactInfo/ContactinfoHeaders/ContactInfoHeaderWithIcon';
import * as MinorContactInfo from 'src/components/ContactInfo/minor/MinorContactInfo';
import * as ContactInfoQuestionnaire from 'src/components/ContactInfoQuestionnaire/ContactInfoQuestionnaire';
import * as ContentBlock from 'src/components/ContentBlock/ContentBlock';
import * as ContentWithIconList from 'src/components/ContentWithIconList/ContentWithIconList';
import * as ContentWithMedia from 'src/components/ContentWithMedia/ContentWithMedia';
import * as CookieBanner from 'src/components/Cookie/CookieBanner/CookieBanner';
import * as CookieCategoryItem from 'src/components/Cookie/ManageCookie/CookieCategoryItem';
import * as CookieTable from 'src/components/Cookie/ManageCookie/CookieTable';
import * as AboutUsWrapper from 'src/components/CR/AboutUsWrapper';
import * as Pagination from 'src/components/CR/Pagination';
import * as PersonalizedTrialCardItem from 'src/components/CR/PersonalizedTrialCardItem';
import * as SearchResultRendering from 'src/components/CR/SearchResultRendering';
import * as ConnectUsMergeTag from 'src/components/CR/HCPConnectUs/ConnectUsMergeTag';
import * as HcpContent from 'src/components/CR/HCPContent/HcpContent';
import * as HcpContentBlockList from 'src/components/CR/HCPContent/HcpContentBlockList';
import * as HcpTrainingAccess from 'src/components/CR/HcpTrainingAccess/HcpTrainingAccess';
import * as HcpTrainingModules from 'src/components/CR/HcpTrainingModules/HcpTrainingModules';
import * as HcpTrainingUsers from 'src/components/CR/HcpTrainingUsers/HcpTrainingUsers';
import * as CRHeaderNavigation from 'src/components/CRHeaderNavigation/CRHeaderNavigation';
import * as DTPHeroBanner from 'src/components/DTP/DTPHeroBanner/DTPHeroBanner';
import * as FeatureHighlightsmock from 'src/components/DTP/FeatureHighlights/FeatureHighlights.mock';
import * as FeatureHighlights from 'src/components/DTP/FeatureHighlights/FeatureHighlights';
import * as Logo from 'src/components/DTP/HeaderNavigation/Logo';
import * as ErrorPage from 'src/components/ErrorPage/ErrorPage';
import * as FeaturedTrial from 'src/components/FeaturedTrials/FeaturedTrial';
import * as FlexiContainer from 'src/components/FlexiContainer/FlexiContainer';
import * as Footer from 'src/components/footer/Footer';
import * as HcpSubPageBanner from 'src/components/HcpSubPageBanner/HcpSubPageBanner';
import * as HowToApply from 'src/components/HowToApply/HowToApply';
import * as InformationalSteps from 'src/components/InformationalSteps/InformationalSteps';
import * as MinorDNQ from 'src/components/MinorDNQ/MinorDNQ';
import * as MyProfile from 'src/components/MyProfile/MyProfile';
import * as FooterModal from 'src/components/MyProfile/Modal/FooterModal';
import * as index from 'src/components/MyProfile/Modal/index';
import * as ProfileModal from 'src/components/MyProfile/Modal/ProfileModal';
import * as PromptIdleTimeoutModal from 'src/components/MyProfile/Modal/PromptIdleTimeoutModal';
import * as ReSendModal from 'src/components/MyProfile/Modal/ReSendModal';
import * as SendModal from 'src/components/MyProfile/Modal/SendModal';
import * as SignOutModal from 'src/components/MyProfile/Modal/SignOutModal';
import * as MyProfileBanner from 'src/components/MyProfileBanner/MyProfileBanner';
import * as MyProfileCommunityCard from 'src/components/MyProfileBanner/MyProfileCommunityCard';
import * as ViewSiteDetails from 'src/components/MyProfileQuestionnaire/ViewSiteDetails';
import * as OneAndTwoColumn from 'src/components/OneAndTwoColumn/OneAndTwoColumn';
import * as PreScreenerBanner from 'src/components/PreScreenerBanner/PreScreenerBanner';
import * as PrescreenerFooterBanner from 'src/components/PreScreenerBanner/PrescreenerFooterBanner';
import * as PreScreenerHeaderBanner from 'src/components/PreScreenerBanner/PreScreenerHeaderBanner';
import * as PreScreenerQuestionnaire from 'src/components/PreScreenerQuestionnaire/PreScreenerQuestionnaire';
import * as RegistrationMergeFields from 'src/components/RegistrationWorkflows/RegistrationMergeFields';
import * as RegWorkflowButtons from 'src/components/RegistrationWorkflows/RegWorkflowButtons';
import * as RegWorkflowFactory from 'src/components/RegistrationWorkflows/RegWorkflowFactory';
import * as AboutQuestion from 'src/components/RegistrationWorkflows/AboutYourself/AboutQuestion';
import * as AboutYourself from 'src/components/RegistrationWorkflows/AboutYourself/AboutYourself';
import * as RegistrationDOB from 'src/components/RegistrationWorkflows/AboutYourself/RegistrationDOB';
import * as ConditionsOfInterest from 'src/components/RegistrationWorkflows/ConditionsOfInterest/ConditionsOfInterest';
import * as consentsMedium from 'src/components/RegistrationWorkflows/ContactPreference/consentsMedium';
import * as ContactPreference from 'src/components/RegistrationWorkflows/ContactPreference/ContactPreference';
import * as ContactQuestion from 'src/components/RegistrationWorkflows/ContactPreference/ContactQuestion';
import * as CustomMobileInput from 'src/components/RegistrationWorkflows/ContactPreference/CustomMobileInput';
import * as ExploreProfile from 'src/components/RegistrationWorkflows/ExploreProfile/ExploreProfile';
import * as LocationDetails from 'src/components/RegistrationWorkflows/LocationDetails/LocationDetails';
import * as LocationDetailsQuestion from 'src/components/RegistrationWorkflows/LocationDetails/LocationDetailsQuestion';
import * as PatientEnquiry from 'src/components/RegistrationWorkflows/PatientEnquiry/PatientEnquiry';
import * as PatientEnquiryQuestion from 'src/components/RegistrationWorkflows/PatientEnquiry/PatientEnquiryQuestion';
import * as EmailAutocomplete from 'src/components/RegistrationWorkflows/Registration/EmailAutocomplete';
import * as Registration from 'src/components/RegistrationWorkflows/Registration/Registration';
import * as ThankYou from 'src/components/RegistrationWorkflows/ThankYou/ThankYou';
import * as UpdateEmailDialog from 'src/components/RegistrationWorkflows/ThankYou/UpdateEmailDialog';
import * as ThankYouWrapper from 'src/components/RegistrationWorkflows/ThankYouWrapper/ThankYouWrapper';
import * as ScrollList from 'src/components/ScrollList/ScrollList';
import * as OptionIconButton from 'src/components/ShareAndDownload/OptionIconButton';
import * as ShareAndDownload from 'src/components/ShareAndDownload/ShareAndDownload';
import * as LinkWithModal from 'src/components/shared/LinkWithModal';
import * as CollapseExpandIcon from 'src/components/shared/Accordion/CollapseExpandIcon';
import * as AutocompleteMultiSelectstories from 'src/components/shared/AutocompleteMultiSelect/AutocompleteMultiSelect.stories';
import * as AutocompleteMultiSelect from 'src/components/shared/AutocompleteMultiSelect/AutocompleteMultiSelect';
import * as Breadcrumbs from 'src/components/shared/Breadcrumbs/Breadcrumbs';
import * as HomeIcon from 'src/components/shared/Breadcrumbs/HomeIcon';
import * as ButtonWithIcon from 'src/components/shared/ButtonWithIcon/ButtonWithIcon';
import * as ArticleCard from 'src/components/shared/Carousel/CarouselCard/ArticleCard';
import * as CRQuestionaireCard from 'src/components/shared/Carousel/CarouselCard/CRQuestionaireCard';
import * as TrialCard from 'src/components/shared/Carousel/CarouselCard/TrialCard';
import * as CheckboxWithLabel from 'src/components/shared/CheckboxWithLabel/CheckboxWithLabel';
import * as CheckboxWithLabelContainer from 'src/components/shared/CheckboxWithLabel/CheckboxWithLabelContainer';
import * as CircularProgress from 'src/components/shared/CircularProgress/CircularProgress';
import * as CircularProgressWithHelpText from 'src/components/shared/CircularProgressWithHelpText/CircularProgressWithHelpText';
import * as CommunityCard from 'src/components/shared/CommunityCard/CommunityCard';
import * as ContentBlockWithImage from 'src/components/shared/ContentBlockWithImage/ContentBlockWithImage';
import * as ContentWithHeading from 'src/components/shared/ContentWithHeading/ContentWithHeading';
import * as CRSiteInfoModal from 'src/components/shared/CustomModal/CRSiteInfoModal';
import * as CustomSnackbar from 'src/components/shared/CustomModal/CustomSnackbar';
import * as GeneralModal from 'src/components/shared/CustomModal/GeneralModal';
import * as CustomNextImage from 'src/components/shared/CustomNextImage/CustomNextImage';
import * as Dropdown from 'src/components/shared/Dropdown/Dropdown';
import * as ExitFormDisclaimer from 'src/components/shared/ExitFormDisclaimer/ExitFormDisclaimer';
import * as ConsentMedium from 'src/components/shared/FormElements/ConsentMedium';
import * as FormToolTip from 'src/components/shared/FormElements/FormToolTip';
import * as Stepper from 'src/components/shared/FormElements/Stepper';
import * as AutocompleteInput from 'src/components/shared/FormElements/FormInput/AutocompleteInput';
import * as CustomInput from 'src/components/shared/FormElements/FormInput/CustomInput';
import * as FormInput from 'src/components/shared/FormElements/FormInput/FormInput';
import * as Input from 'src/components/shared/FormElements/FormInput/Input';
import * as InputWrapper from 'src/components/shared/FormElements/FormInput/InputWrapper';
import * as HamburgerIcon from 'src/components/shared/HamburgerIcon/HamburgerIcon';
import * as IconWithDescriptionBlock from 'src/components/shared/IconWithDescriptionBlock/IconWithDescriptionBlock';
import * as IconWithTitle from 'src/components/shared/IconWithTitle/IconWithTitle';
import * as ImageContentCard from 'src/components/shared/ImageContentCard/ImageContentCard';
import * as ImageCtaCard from 'src/components/shared/ImageCtaCard/ImageCtaCard';
import * as InformationalBlock from 'src/components/shared/InformationalBlocks/InformationalBlock';
import * as Loader from 'src/components/shared/Loader/Loader';
import * as LoaderWithText from 'src/components/shared/Loader/LoaderWithText';
import * as NoScriptBanner from 'src/components/shared/NoScriptBanner/NoScriptBanner';
import * as NotificationBanner from 'src/components/shared/NotificationBanner/NotificationBanner';
import * as OneColumnGrid from 'src/components/shared/OneColumnGrid/OneColumnGrid';
import * as PrimaryLinks from 'src/components/shared/PrimaryLinks/PrimaryLinks';
import * as CircularProgressBar from 'src/components/shared/ProgressBar/CircularProgressBar';
import * as ControlledRadioGroup from 'src/components/shared/RadioGroup/ControlledRadioGroup';
import * as RichTextWithPnMergeTag from 'src/components/shared/RichTextWithPnMergeTag/RichTextWithPnMergeTag';
import * as RichTextWithStudyPnMergeTag from 'src/components/shared/RichTextWithStudyPnMergeTag/RichTextWithStudyPnMergeTag';
import * as SecondaryLinks from 'src/components/shared/SecondaryLinks/SecondaryLinks';
import * as ShareToSocialMedia from 'src/components/shared/ShareToSocialMedia/ShareToSocialMedia';
import * as SimplePage from 'src/components/shared/SimplePage/SimplePage';
import * as SocialMediaLinks from 'src/components/shared/SocialMediaLinks/SocialMediaLinks';
import * as Tabs from 'src/components/shared/Tabs/Tabs';
import * as TextBox from 'src/components/shared/TextBox/TextBox';
import * as TextBoxWithLabel from 'src/components/shared/TextBox/TextBoxWithLabel';
import * as TextWithPnMergeTag from 'src/components/shared/TextWithPnMergeTag/TextWithPnMergeTag';
import * as TextWithStudyPnMergeTag from 'src/components/shared/TextWithStudyPnMergeTag/TextWithStudyPnMergeTag';
import * as ToggleSwitch from 'src/components/shared/ToggleSwitch/ToggleSwitch';
import * as ToggleViewPanel from 'src/components/shared/ToggleViewPanel/ToggleViewPanel';
import * as TwoColumnGrid from 'src/components/shared/TwoColumnGrid/TwoColumnGrid';
import * as UIIframe from 'src/components/shared/UIIframe/UIIframe';
import * as UpdateEmail from 'src/components/shared/UpdateEmail/UpdateEmail';
import * as Video from 'src/components/shared/Video/Video';
import * as SingleTrial from 'src/components/SingleTrial/SingleTrial';
import * as PhoneIcon from 'src/components/SiteInfoCard/PhoneIcon';
import * as SiteInfoCard from 'src/components/SiteInfoCard/SiteInfoCard';
import * as SiteLocationRichText from 'src/components/SiteLocationRichText/SiteLocationRichText';
import * as Legend from 'src/components/SiteManagement/Legend';
import * as SimpleBackdrop from 'src/components/SiteManagement/SimpleBackdrop';
import * as TranslateModal from 'src/components/SiteManagement/TranslateModal';
import * as Filters from 'src/components/SiteManagement/Filters/Filters';
import * as FooterActions from 'src/components/SiteManagement/FooterActions/FooterActions';
import * as OptInOption from 'src/components/SiteManagement/Tools/OptInOption';
import * as SitesList from 'src/components/SitesList/SitesList';
import * as SitesMap from 'src/components/SitesMap/SitesMap';
import * as SitesMapListToggle from 'src/components/SitesMapListToggle/SitesMapListToggle';
import * as SiteSelection from 'src/components/SitesSelection/SiteSelection';
import * as AppointmentDateTime from 'src/components/SitesSuccessPage/AppointmentDateTime/AppointmentDateTime';
import * as CardWithIcon from 'src/components/SitesSuccessPage/CardWithIcon/CardWithIcon';
import * as ConfirmationBanner from 'src/components/SitesSuccessPage/ConfirmationBanner/ConfirmationBanner';
import * as HeadingMessage from 'src/components/SitesSuccessPage/HeadingMessage/HeadingMessage';
import * as ShareYourHealthData from 'src/components/SitesSuccessPage/HumanAPI/ShareYourHealthData';
import * as NextStep from 'src/components/SitesSuccessPage/NextStep/NextStep';
import * as NextStepAdvanced from 'src/components/SitesSuccessPage/NextStep/NextStepAdvanced';
import * as OneAndTwoColumnServerSide from 'src/components/SitesSuccessPage/OneAndTwoColumnSSR/OneAndTwoColumnServerSide';
import * as OutComePageBase from 'src/components/SitesSuccessPage/OutComePage/OutComePageBase';
import * as OutComePageContent from 'src/components/SitesSuccessPage/OutComePage/OutComePageContent';
import * as SelectedSitesContainer from 'src/components/SitesSuccessPage/SelectedSitesContainer/SelectedSitesContainer';
import * as SiteInfoBox from 'src/components/SitesSuccessPage/SelectedSitesContainer/SiteInfoBox';
import * as SuccessPageMap from 'src/components/SitesSuccessPage/SelectedSitesContainer/SuccessPageMap';
import * as SuccessPageMergeTag from 'src/components/SitesSuccessPage/SuccessPageMergeTag/SuccessPageMergeTag';
import * as SuccessPageMergeTagText from 'src/components/SitesSuccessPage/SuccessPageMergeTag/SuccessPageMergeTagText';
import * as TextContent from 'src/components/SitesSuccessPage/TextContent/TextContent';
import * as StickyFootermock from 'src/components/StickyFooter/StickyFooter.mock';
import * as StickyFooter from 'src/components/StickyFooter/StickyFooter';
import * as StudyDetail from 'src/components/StudyDetails/StudyDetail';
import * as StudyDetailList from 'src/components/StudyDetails/StudyDetailList';
import * as StudyEnrollment from 'src/components/StudyEnrollment/StudyEnrollment';
import * as StudyEnrollmentContent from 'src/components/StudyEnrollment/StudyEnrollmentContent';
import * as ProgressBar from 'src/components/StudyEnrollment/ProgressBar/ProgressBar';
import * as ProgressBarItem from 'src/components/StudyEnrollment/ProgressBar/ProgressBarItem';
import * as CollapsibleProgressBar from 'src/components/StudyEnrollment/ProgressBar/CollapsibleProgressBar/CollapsibleProgressBar';
import * as StudyInfoCard from 'src/components/StudyInfoCard/StudyInfoCard';
import * as StudyList from 'src/components/StudyList/StudyList';
import * as StudyListing from 'src/components/StudyListing/StudyListing';
import * as StudyListingCard from 'src/components/StudyListing/StudyListingCard';
import * as ProtocolDropdown from 'src/components/StudyLocation/ProtocolDropdown';
import * as SeachZipcode from 'src/components/StudyLocation/SeachZipcode';
import * as StudyLocation from 'src/components/StudyLocation/StudyLocation';
import * as VariationOne from 'src/components/StudyLocation/VariationOne/VariationOne';
import * as VariationTwo from 'src/components/StudyLocation/VariationTwo/VariationTwo';
import * as Marker from 'src/components/StudyMap/Marker';
import * as StudyMap from 'src/components/StudyMap/StudyMap';
import * as StudySwitcher from 'src/components/StudySwitcher/StudySwitcher';
import * as StudySwitchTabList from 'src/components/StudySwitcher/StudySwitchTabList';
import * as Resubscribe from 'src/components/Subscribe/Resubscribe/Resubscribe';
import * as Unsubscribe from 'src/components/Subscribe/Unsubscribe/Unsubscribe';
import * as Support from 'src/components/Support/Support';
import * as SupportForm from 'src/components/Support/SupportForm/SupportForm';
import * as SupportSubpage from 'src/components/Support/SupportSubpage/SupportSubpage';
import * as TechnicalSupport from 'src/components/Support/TechnicalSupport/TechnicalSupport';
import * as TabList from 'src/components/TabList/TabList';
import * as TAHeroBanner from 'src/components/TAHeroBanner/TAHeroBanner';
import * as ThreeStepImage from 'src/components/ThreeStepInformation/ThreeStepImage';
import * as ThreeStepInformation from 'src/components/ThreeStepInformation/ThreeStepInformation';
import * as ThreeStepItem from 'src/components/ThreeStepInformation/ThreeStepItem';
import * as ThreeStepItemVar2 from 'src/components/ThreeStepInformation/ThreeStepItemVar2';
import * as ThreeStepItemVar3 from 'src/components/ThreeStepInformation/ThreeStepItemVar3';
import * as TQPageComponents from 'src/components/TQPage/TQPageComponents';
import * as StudyLocationCard from 'src/components/TrialDetails/StudyLocationCard';
import * as StudyLocationMergeTag from 'src/components/TrialDetails/StudyLocationMergeTag';
import * as TrialDetails from 'src/components/TrialDetails/TrialDetails';
import * as TrialDetailsContent from 'src/components/TrialDetails/TrialDetailsContent';
import * as TrialTitle from 'src/components/TrialDetails/TrialTitle';
import * as ViewTrials from 'src/components/TrialDetails/ViewTrials/ViewTrials';
import * as UnderstandTrials from 'src/components/UnderstandTrials/UnderstandTrials';
import * as VideoPlayer from 'src/components/VideoPlayer/VideoPlayer';
import HCPHeroAndSearch from 'components/CR/HCPHeroAndSearch/HCPHeroAndSearch';
import dynamic from 'next/dynamic';
import * as StudySupport from 'src/components/Support/StudySupport/StudySupport';

const components = new Map();

components.set(
  'AccordionPanel',
  dynamic(() => import('src/components/AccordionPanel/AccordionPanel'))
);
components.set(
  'AddToMyInterest',
  dynamic(() => import('src/components/AddToMyInterest/AddToMyInterest'))
);
components.set('VerificationAlert', VerificationAlert);
components.set('ArticlePage', ArticlePage);
components.set('ArticleCarousel', ArticleCarousel);
components.set('SmallBanner', SmallBanner);
components.set('ButtonGroups', ButtonGroups);
components.set('CarousalList', CarousalList);
components.set('Content', Content);
components.set('Slide', Slide);
components.set('CommunityList', CommunityList);
components.set('ContactInfoContextProvider', ContactInfoContextProvider);
components.set('AdultContactInfo', AdultContactInfo);
components.set('CaregiverContactInfo', CaregiverContactInfo);
components.set('MinorContactInfo', MinorContactInfo);
components.set('ContactInfoHeader', ContactInfoHeader);
components.set('ContactInfoHeaderWithIcon', ContactInfoHeaderWithIcon);
components.set('ContactInfoQuestionnaire', ContactInfoQuestionnaire);
components.set('ContentBlock', ContentBlock);
components.set('ContentWithIconList', ContentWithIconList);
components.set('ContentWithMedia', ContentWithMedia);
components.set(
  'ContentWithOptions',
  dynamic(() => import('src/components/ContentWithOptions/ContentWithOptions'))
);
components.set('CookieBanner', CookieBanner);
components.set('CookieCategoryItem', CookieCategoryItem);
components.set('CookieTable', CookieTable);
components.set('AboutUsWrapper', AboutUsWrapper);
components.set('Pagination', Pagination);
components.set('PersonalizedTrialCardItem', PersonalizedTrialCardItem);
components.set('SearchResultRendering', SearchResultRendering);
components.set(
  'SearchTrialItems',
  dynamic(() => import('src/components/CR/SearchTrialItems'))
);
components.set(
  'SearchTrialFilter',
  dynamic(() => import('src/components/Filter/SearchTrialFilter'))
);
components.set(
  'CountTrial',
  dynamic(() => import('src/components/CR/CountTrial/CountTrial'))
);
components.set(
  'ConnectUs',
  dynamic(() => import('src/components/CR/HCPConnectUs/ConnectUs'))
);
components.set('ConnectUsMergeTag', ConnectUsMergeTag);
components.set('HcpContent', HcpContent);
components.set('HcpContentBlockList', HcpContentBlockList);
components.set('HCPHeroAndSearch', HCPHeroAndSearch);
components.set('HcpTrainingAccess', HcpTrainingAccess);
components.set('HcpTrainingModules', HcpTrainingModules);
components.set('HcpTrainingUsers', HcpTrainingUsers);
components.set('CRHeaderNavigation', CRHeaderNavigation);
components.set('DTPHeroBanner', DTPHeroBanner);
components.set('FeatureHighlights.mock', FeatureHighlightsmock);
components.set('FeatureHighlights', FeatureHighlights);
components.set(
  'HeaderNavigation',
  dynamic(() => import('src/components/DTP/HeaderNavigation/HeaderNavigation'))
);
components.set('Logo', Logo);
components.set('ErrorPage', ErrorPage);
components.set(
  'FAQAccordion',
  dynamic(() => import('src/components/FAQAccordion/FAQAccordion'))
);
components.set('FeaturedTrial', FeaturedTrial);
components.set('FlexiContainer', FlexiContainer);
components.set('Footer', Footer);
components.set(
  'GlossaryContent',
  dynamic(() => import('src/components/Glossary/GlossaryContent'))
);
components.set(
  'HCPHeaderNavigation',
  dynamic(() => import('src/components/HCPHeaderNavigation/HCPHeaderNavigation'))
);
components.set('HcpSubPageBanner', HcpSubPageBanner);
components.set(
  'HeroAndSearch',
  dynamic(() => import('src/components/HeroAndSearch/HeroAndSearch'))
);
components.set('HowToApply', HowToApply);
components.set('InformationalSteps', InformationalSteps);
components.set('MinorDNQ', MinorDNQ);
components.set('MyProfile', MyProfile);
components.set('FooterModal', FooterModal);
components.set('index', index);
components.set('ProfileModal', ProfileModal);
components.set('PromptIdleTimeoutModal', PromptIdleTimeoutModal);
components.set('ReSendModal', ReSendModal);
components.set('SendModal', SendModal);
components.set('SignOutModal', SignOutModal);
components.set('MyProfileBanner', MyProfileBanner);
components.set('MyProfileCommunityCard', MyProfileCommunityCard);
components.set(
  'MyProfileQuestionnaireCarousel',
  dynamic(() => import('src/components/MyProfileQuestionnaire/MyProfileQuestionnaireCarousel'))
);
components.set('ViewSiteDetails', ViewSiteDetails);
components.set('OneAndTwoColumn', OneAndTwoColumn);
components.set(
  'PersonalizedFeaturedTrial',
  dynamic(() => import('src/components/PersonalizedFeaturedTrial/PersonalizedFeaturedTrial'))
);
components.set('PreScreenerBanner', PreScreenerBanner);
components.set('PrescreenerFooterBanner', PrescreenerFooterBanner);
components.set('PreScreenerHeaderBanner', PreScreenerHeaderBanner);
components.set('PreScreenerQuestionnaire', PreScreenerQuestionnaire);
components.set('RegistrationMergeFields', RegistrationMergeFields);
components.set('RegWorkflowButtons', RegWorkflowButtons);
components.set('RegWorkflowFactory', RegWorkflowFactory);
components.set('AboutQuestion', AboutQuestion);
components.set('AboutYourself', AboutYourself);
components.set('RegistrationDOB', RegistrationDOB);
components.set('ConditionsOfInterest', ConditionsOfInterest);
components.set('consentsMedium', consentsMedium);
components.set('ContactPreference', ContactPreference);
components.set('ContactQuestion', ContactQuestion);
components.set('CustomMobileInput', CustomMobileInput);
components.set('ExploreProfile', ExploreProfile);
components.set('LocationDetails', LocationDetails);
components.set('LocationDetailsQuestion', LocationDetailsQuestion);
components.set('PatientEnquiry', PatientEnquiry);
components.set('PatientEnquiryQuestion', PatientEnquiryQuestion);
components.set('EmailAutocomplete', EmailAutocomplete);
components.set('Registration', Registration);
components.set('ThankYou', ThankYou);
components.set('UpdateEmailDialog', UpdateEmailDialog);
components.set('ThankYouWrapper', ThankYouWrapper);
components.set('ScrollList', ScrollList);
components.set('OptionIconButton', OptionIconButton);
components.set('ShareAndDownload', ShareAndDownload);
components.set('LinkWithModal', LinkWithModal);
components.set('CollapseExpandIcon', CollapseExpandIcon);
components.set('AutocompleteMultiSelect.stories', AutocompleteMultiSelectstories);
components.set('AutocompleteMultiSelect', AutocompleteMultiSelect);
components.set('Breadcrumbs', Breadcrumbs);
components.set('HomeIcon', HomeIcon);
components.set('ButtonWithIcon', ButtonWithIcon);
components.set('ArticleCard', ArticleCard);
components.set('CRQuestionaireCard', CRQuestionaireCard);
components.set('TrialCard', TrialCard);
components.set('CheckboxWithLabel', CheckboxWithLabel);
components.set('CheckboxWithLabelContainer', CheckboxWithLabelContainer);
components.set('CircularProgress', CircularProgress);
components.set('CircularProgressWithHelpText', CircularProgressWithHelpText);
components.set('CommunityCard', CommunityCard);
components.set('ContentBlockWithImage', ContentBlockWithImage);
components.set('ContentWithHeading', ContentWithHeading);
components.set('CRSiteInfoModal', CRSiteInfoModal);
components.set('CustomSnackbar', CustomSnackbar);
components.set('GeneralModal', GeneralModal);
components.set('CustomNextImage', CustomNextImage);
components.set('Dropdown', Dropdown);
components.set('ExitFormDisclaimer', ExitFormDisclaimer);
components.set('ConsentMedium', ConsentMedium);
components.set('FormToolTip', FormToolTip);
components.set('Stepper', Stepper);
components.set('AutocompleteInput', AutocompleteInput);
components.set('CustomInput', CustomInput);
components.set('FormInput', FormInput);
components.set('Input', Input);
components.set('InputWrapper', InputWrapper);
components.set('HamburgerIcon', HamburgerIcon);
components.set('IconWithDescriptionBlock', IconWithDescriptionBlock);
components.set('IconWithTitle', IconWithTitle);
components.set('ImageContentCard', ImageContentCard);
components.set('ImageCtaCard', ImageCtaCard);
components.set('InformationalBlock', InformationalBlock);
components.set('Loader', Loader);
components.set('LoaderWithText', LoaderWithText);
components.set('NoScriptBanner', NoScriptBanner);
components.set('NotificationBanner', NotificationBanner);
components.set('OneColumnGrid', OneColumnGrid);
components.set('PrimaryLinks', PrimaryLinks);
components.set('CircularProgressBar', CircularProgressBar);
components.set('ControlledRadioGroup', ControlledRadioGroup);
components.set('RichTextWithPnMergeTag', RichTextWithPnMergeTag);
components.set('RichTextWithStudyPnMergeTag', RichTextWithStudyPnMergeTag);
components.set('SecondaryLinks', SecondaryLinks);
components.set('ShareToSocialMedia', ShareToSocialMedia);
components.set('SimplePage', SimplePage);
components.set('SocialMediaLinks', SocialMediaLinks);
components.set('Tabs', Tabs);
components.set('TextBox', TextBox);
components.set('TextBoxWithLabel', TextBoxWithLabel);
components.set('TextWithPnMergeTag', TextWithPnMergeTag);
components.set('TextWithStudyPnMergeTag', TextWithStudyPnMergeTag);
components.set('ToggleSwitch', ToggleSwitch);
components.set('ToggleViewPanel', ToggleViewPanel);
components.set('TwoColumnGrid', TwoColumnGrid);
components.set('UIIframe', UIIframe);
components.set('UpdateEmail', UpdateEmail);
components.set('Video', Video);
components.set('SingleTrial', SingleTrial);
components.set('PhoneIcon', PhoneIcon);
components.set('SiteInfoCard', SiteInfoCard);
components.set('SiteLocationRichText', SiteLocationRichText);
components.set('Legend', Legend);
components.set('SimpleBackdrop', SimpleBackdrop);
components.set(
  'SiteManagement',
  dynamic(() => import('src/components/SiteManagement/SiteManagement'))
);
components.set(
  'SitesTable',
  dynamic(() => import('src/components/SiteManagement/SitesTable'))
);
components.set('TranslateModal', TranslateModal);
components.set('Filters', Filters);
components.set('FooterActions', FooterActions);
components.set('OptInOption', OptInOption);
components.set('SitesList', SitesList);
components.set('SitesMap', SitesMap);
components.set('SitesMapListToggle', SitesMapListToggle);
components.set('SiteSelection', SiteSelection);
components.set('AppointmentDateTime', AppointmentDateTime);
components.set('CardWithIcon', CardWithIcon);
components.set('ConfirmationBanner', ConfirmationBanner);
components.set('HeadingMessage', HeadingMessage);
components.set('ShareYourHealthData', ShareYourHealthData);
components.set('NextStep', NextStep);
components.set('NextStepAdvanced', NextStepAdvanced);
components.set('OneAndTwoColumnServerSide', OneAndTwoColumnServerSide);
components.set('OutComePageBase', OutComePageBase);
components.set('OutComePageContent', OutComePageContent);
components.set('SelectedSitesContainer', SelectedSitesContainer);
components.set('SiteInfoBox', SiteInfoBox);
components.set('SuccessPageMap', SuccessPageMap);
components.set('SuccessPageMergeTag', SuccessPageMergeTag);
components.set('SuccessPageMergeTagText', SuccessPageMergeTagText);
components.set('TextContent', TextContent);
components.set('StickyFooter.mock', StickyFootermock);
components.set('StickyFooter', StickyFooter);
components.set('StudyDetail', StudyDetail);
components.set('StudyDetailList', StudyDetailList);
components.set('StudyEnrollment', StudyEnrollment);
components.set('StudyEnrollmentContent', StudyEnrollmentContent);
components.set('ProgressBar', ProgressBar);
components.set('ProgressBarItem', ProgressBarItem);
components.set('CollapsibleProgressBar', CollapsibleProgressBar);
components.set('StudyInfoCard', StudyInfoCard);
components.set('StudyList', StudyList);
components.set('StudyListing', StudyListing);
components.set('StudyListingCard', StudyListingCard);
components.set('ProtocolDropdown', ProtocolDropdown);
components.set('SeachZipcode', SeachZipcode);
components.set('StudyLocation', StudyLocation);
components.set('VariationOne', VariationOne);
components.set('VariationTwo', VariationTwo);
components.set('Marker', Marker);
components.set('StudyMap', StudyMap);
components.set('StudySwitcher', StudySwitcher);
components.set('StudySwitchTabList', StudySwitchTabList);
components.set('Resubscribe', Resubscribe);
components.set('Unsubscribe', Unsubscribe);
components.set('Support', Support);
components.set('StudySupport', StudySupport);
components.set('SupportForm', SupportForm);
components.set('SupportSubpage', SupportSubpage);
components.set('TechnicalSupport', TechnicalSupport);
components.set('TabList', TabList);
components.set('TAHeroBanner', TAHeroBanner);
components.set('ThreeStepImage', ThreeStepImage);
components.set('ThreeStepInformation', ThreeStepInformation);
components.set('ThreeStepItem', ThreeStepItem);
components.set('ThreeStepItemVar2', ThreeStepItemVar2);
components.set('ThreeStepItemVar3', ThreeStepItemVar3);
components.set('TQPageComponents', TQPageComponents);
components.set('StudyLocationCard', StudyLocationCard);
components.set(
  'StudyLocationMap',
  dynamic(() => import('src/components/TrialDetails/StudyLocationMap'))
);
components.set('StudyLocationMergeTag', StudyLocationMergeTag);
components.set('TrialDetails', TrialDetails);
components.set('TrialDetailsContent', TrialDetailsContent);
components.set('TrialTitle', TrialTitle);
components.set('ViewTrials', ViewTrials);
components.set(
  'TrialItemsManageProfile',
  dynamic(() => import('src/components/TrialItemsManageProfile/TrialItemsManageProfile'))
);
components.set(
  'TrialSearch',
  dynamic(() => import('src/components/TrialSearch/TrialSearch'))
);
components.set('UnderstandTrials', UnderstandTrials);
components.set('VideoPlayer', VideoPlayer);

components.set(
  'ViewAllSaveTrialItems',
  dynamic(() => import('src/components/ViewAllSaveTrialItems/ViewAllSaveTrialItems'))
);

// Next.js 'dynamic' import and JavaScript 'dynamic' import are different.
// Next.js 'dynamic(...)' returns common 'React.ComponentType' while
// 'import('...')' returns 'Promise' that will resolve module.
// componentModule uses 'import(...)' because primary usage of it to get not only 'React Component' (default export) but all named exports.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#dynamic_imports
// componentFactory uses 'dynamic(...)' because primary usage of it to render 'React Component' (default export).
// See https://nextjs.org/docs/advanced-features/dynamic-import
// At the end you will have single preloaded script for each lazy loading module.
// Editing mode doesn't work well with dynamic components in nextjs: dynamic components are not displayed without refresh after a rendering is added.
// This happens beacuse Sitecore editors simply insert updated HTML generated on server side. This conflicts with nextjs dynamic logic as no HTML gets rendered for dynamic component
// So we use require() to obtain dynamic components in editing mode while preserving dynamic logic for non-editing scenarios
// As we need to be able to seamlessly work with dynamic components in both editing and normal modes, different componentFactory functions will be passed to app

export function componentModule(componentName: string) {
  const component = components.get(componentName);

  // check that component is lazy loading module
  if (!component?.default && component?.module) {
    // return js dynamic import
    return component.module();
  }

  return component;
}

function baseComponentFactory(componentName: string, exportName?: string, isEditing?: boolean) {
  const component = components.get(componentName);

  // check that component should be dynamically imported
  if (component?.element) {
    // return next.js dynamic import
    return component.element(isEditing);
  }

  if (exportName) {
    return component[exportName];
  }

  return component?.Default || component?.default || component;
}

export function componentFactory(componentName: string, exportName?: string) {
  return baseComponentFactory(componentName, exportName, false);
}

export function editingComponentFactory(componentName: string, exportName?: string) {
  return baseComponentFactory(componentName, exportName, true);
}
