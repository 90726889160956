import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import nextStepIcon from '../../../../public/next-step.png';
import { StNextIcon } from '../../shared/NextImage.styles';
import {
  AccessTrainingButton,
  HCPCRGridContainerWithoutBackground,
  HCPWrapper,
} from './HcpTrainingAccess.style';

const HcpTrainingAccess = (props: any) => {
  const { heading, content, link } = props?.fields || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <HCPWrapper id="hcp-wrapper">
      <HCPCRGridContainerWithoutBackground item container sx={{ marginTop: '1rem' }}>
        <Typography variant="h4" sx={{ marginBottom: '0.5rem' }} role="heading" aria-level={1}>
          <Text field={heading} />
        </Typography>
        <div style={{ fontSize: '16px', lineHeight: '24px', color: '#000', marginBottom: '1rem' }}>
          <RichText field={content} />
        </div>
        <AccessTrainingButton
          sx={{ width: isMobile ? '100%' : 'auto' }}
          variant="contained"
          onClick={() => {
            const trainingLink =
              link?.value?.href || 'https://iqviasitetraining.thinkingcap.com/Login/Login.aspx';
            window.open(trainingLink);
          }}
          endIcon={
            <StNextIcon
              suppressHydrationWarning
              sx={{ marginLeft: '0.5rem' }}
              alt="access-training-platform"
              src={nextStepIcon}
              width={24}
              height={24}
              overrideIconColor={theme.palette.white.main}
            />
          }
        >
          {link?.value?.text}
        </AccessTrainingButton>
      </HCPCRGridContainerWithoutBackground>
    </HCPWrapper>
  );
};

export default HcpTrainingAccess;
