import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import pnMergetagReplacer from '../../../lib/utils/pnMergeTagReplacer';
import getCallCenterNumber from '../../../store/selectors/getCallCenterNumber';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

type StTextItemProps = {
  inheritLinkColor?: boolean;
};

export const StText = styled(Text, {
  shouldForwardProp: (prop) => prop !== 'inheritLinkColor',
})<StTextItemProps>(({ inheritLinkColor }) => ({
  '& a': {
    color: inheritLinkColor ? 'inherit' : 'none',
  },
}));

const TextWithPnMergeTag = ({ field, cssClassName, inheritLinkColor }: any) => {
  const { sitecoreContext } = useSitecoreContext();
  const callCenterNumber = getCallCenterNumber(sitecoreContext);
  const [content, setContent] = useState(field);

  useEffect(() => {
    if (callCenterNumber.value) {
      const newContent = pnMergetagReplacer(field?.value, String(callCenterNumber.value), false);
      setContent({ ...content, value: newContent });
    }
  }, [callCenterNumber.value]);

  return (
    <StText
      field={content}
      className={cssClassName ? cssClassName : ''}
      inheritLinkColor={inheritLinkColor ? inheritLinkColor : false}
    ></StText>
  );
};

export default TextWithPnMergeTag;
