import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { JSSLink } from '../../components/shared/Links/JSSLink.styles';

export const ContentBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(1deg, ${theme.palette.themeColor.main} 0%, ${theme.palette.themeColorSecondary.main} 100%);`,
  padding: '1.5rem 1.5rem 2.25rem 1.5rem',
  marginTop: '1.25rem',
  marginBottom: '1.25rem',
  border: `1px solid ${theme.palette.neutral4.main}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,
  '.screener-content': {
    p: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  '@media screen and (min-width:960px) and (max-width:1003px)': {
    fontSize: '14px',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.neutral4.main,
  width: '100%',
  height: '1px',
  borderRadius: '50px',
}));

export const QualifyLink = styled(JSSLink)(({ theme }) => ({
  minWidth: '203px',
  height: '40px',
  borderRadius: '30px',
  padding: '0px',
  [theme.breakpoints.down('sm')]: {
    padding: '8px',
  },
}));
