import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/store';
import { StPrimaryLinks, StPrimaryLinkItem, AppBarButton } from './PrimaryLinks.style';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { updateMyProfileModal } from '../../../features/crProfileSlice';

interface linkItem {
  name: string;
  link: LinkField;
}
type IFooterLinks = Array<linkItem>;

interface IPrimaryLinksProps {
  primaryLinks: IFooterLinks;
  linkFontColor: string;
}
const PrimaryLinks = (props: IPrimaryLinksProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const handleMyProfileClick = () => {
    dispatch(updateMyProfileModal(true));
  };
  return (
    <>
      {props?.primaryLinks &&
        props?.primaryLinks.map((linkItem: linkItem, index: number) => {
          if (linkItem.name === 'manage-my-profile' && !isAuthenticated) {
            return (
              <AppBarButton key={index} onClick={handleMyProfileClick}>
                {linkItem?.link?.value.text || ''}
              </AppBarButton>
            );
          }
          return (
            <StPrimaryLinkItem key={linkItem.name} item>
              <StPrimaryLinks
                id={linkItem.name}
                data-testid={linkItem.name}
                field={linkItem.link}
                linkFontColor={props.linkFontColor}
                key={index}
              />
            </StPrimaryLinkItem>
          );
        })}
    </>
  );
};

export default PrimaryLinks;
