import { getOrCreateEnrollmentId } from '../../lib/utils/enrollmentUtil';
import getReferralId from '../../lib/utils/getReferralId';
import { JOURNEY_STEP } from '../../constants';
import { ISelectedSiteProps } from './SiteSelection.types';

export const getSiteDataPayload = (
  data: ISelectedSiteProps,
  contactInfo: any,
  tenantKey: string,
  enrollmentId: string,
  potentialParticipant_ID: string,
  potentialId: number,
  appointmentStatus: string
): any => {
  const {
    selectedPlace,
    isKIEenabled,
    isLLEnabled,
    isSponserKieEnabled,
    isUserOptedKiElementConsent,
    isUserOptedLifeLinkConsent,
    isSponsorLifeLinkEnabled,
  } = data || {};
  const { selectionType, siteInfo } = selectedPlace || {};
  const { patientId } = contactInfo || {};
  const url = typeof window !== 'undefined' ? window.location.href : null;
  const baseUrl = url ? location.protocol + '//' + location.host : null;
  const {
    siteCode,
    siteAddress,
    siteCity,
    siteCountry,
    siteName,
    contactPhoneNumber,
    siteZipCode,
    siteState,
    warmTransferNumber,
    eConsentUrl,
    piname,
    protocol,
    sponsor,
    studyName,
    projectCode,
    nctid,
    indication,
    isHumanAPIEnabled,
  } = siteInfo;
  return {
    //id: 0, //not needed
    // protocolInfoId: 0, //not needed
    tenantKey,
    enrollmentId,
    siteInfo: {
      siteCode,
      siteAddress,
      siteCity,
      siteCountry,
      siteName,
      contactPhoneNumber,
      siteZipCode,
      siteState,
      warmTransferNumber,
      econsentUrl: eConsentUrl,
      piname,
      isSiteConfirmed: selectionType === 'Confirm' ? true : false,
      protocolInfo: {
        //id: 0, //not needed
        potentialId,
        patientId: patientId, // ui check
        potentialParticipantId: potentialParticipant_ID, //ui check
        protocol: protocol,
        //TODO: these 2 fields will be used in future as per disscussion with backend team
        //keeping it empty string until UI have these
        sponsorId: '', // need details on this
        studyId: '', //need details on this
        studyName: studyName,
        projectCode: projectCode, //
        nctid: nctid,
        sponsor: sponsor,
        indication: indication,
      },
    },
    baseUrl, // required for KIE
    isKiElementEnabled: isKIEenabled, // required for KIE
    isKiElementsSponsorConsentEnabled: isSponserKieEnabled, // required for KIE
    isUserOptedKiElementConsent, // required for KIE
    isLifeLinkEnabled: isLLEnabled,
    isUserOptedLifeLinkConsent,
    isSponsorLifeLinkEnabled, // need to send this value once LL integration id done
    appointmentStatus,
    referralId: getReferralId(getOrCreateEnrollmentId(), siteCode),
    isHumanAPIEnabled: isHumanAPIEnabled || false,
  };
};

export const transformSiteSelectionData = (data: any): any => {
  const { siteInfo, patientReferralStatus, isSiteKiElementsEnabled, isProtocolKiElementsEnabled } =
    data;
  return {
    enrollmentId: data.enrollmentId,
    journeyStep: JOURNEY_STEP.SITESELECTION,
    siteInfo: {
      siteCode: siteInfo?.siteCode ? siteInfo?.siteCode : null,
      siteAddress: siteInfo?.siteAddress ? siteInfo?.siteAddress : null,
      siteCity: siteInfo?.siteCity ? siteInfo?.siteCity : null,
      siteCountry: siteInfo?.siteCountry ? siteInfo?.siteCountry : null,
      siteName: siteInfo?.siteName ? siteInfo?.siteName : null,
      contactPhoneNumber: siteInfo?.contactPhoneNumber ? siteInfo?.contactPhoneNumber : '',
      siteZipCode: siteInfo?.siteZipCode ? siteInfo?.siteZipCode : null,
      siteState: siteInfo?.siteState ? siteInfo?.siteState : null,
      warmTransferNumber: siteInfo?.warmTransferNumber ? siteInfo?.warmTransferNumber : null,
      econsentUrl: data?.eConsentUrl ? data?.eConsentUrl : null,
      piname: data?.piName ? data?.piName : null,
      emailCras: data?.emailCras ? data?.emailCras : null,
      studyStaffEmails: data?.studyStaffEmails ? data?.studyStaffEmails : null,
      status: data?.status ? data?.status : null,
      allowEmails: data?.allowEmails ? data?.allowEmails : false,
      allowSms: data?.allowSms ? data?.allowSms : false,
      latitude: data?.latitude ? data?.latitude : 0,
      longitude: data?.longitude ? data?.longitude : 0,
      radius: data?.siteRadius ? data?.siteRadius : 0,
      isSiteConfirmed: siteInfo?.isSiteConfirmed ? siteInfo?.isSiteConfirmed : false,
      protocolInfo: {
        protocol: siteInfo?.protocolInfo?.protocol ? siteInfo?.protocolInfo?.protocol : null,
        sponsorId: siteInfo?.protocolInfo?.sponsorId ? siteInfo?.protocolInfo?.sponsorId : null,
        studyId: siteInfo?.protocolInfo?.studyId ? siteInfo?.protocolInfo?.studyId : null,
        studyName: siteInfo?.protocolInfo?.studyName ? siteInfo?.protocolInfo?.studyName : null,
        projectCode: siteInfo?.protocolInfo?.projectCode
          ? siteInfo?.protocolInfo?.projectCode
          : null,
        nctid: siteInfo?.protocolInfo?.nctid ? siteInfo?.protocolInfo?.nctid : null,
        sponsor: siteInfo?.protocolInfo?.sponsor ? siteInfo?.protocolInfo?.sponsor : null,
        indication: siteInfo?.protocolInfo?.indication ? siteInfo?.protocolInfo?.indication : null,
        otSponsorId: data?.otSponsorId ? data?.otSponsorId : null,
        protocolMDMID: data?.protocolMDMID ? data?.protocolMDMID : null,
        parentSponsorMDMID: data?.parentSponsorMDMID ? data?.parentSponsorMDMID : null,
        ucpmSponsorName: data?.ucpmSponsorName ? data?.ucpmSponsorName : null,
      },
    },
    isKiElementsSponsorConsentEnabled: data.isKiElementsSponsorConsentEnabled
      ? data.isKiElementsSponsorConsentEnabled
      : false,
    isUserOptedKiElementConsent: data?.isUserOptedKiElementConsent
      ? data?.isUserOptedKiElementConsent
      : null,
    patientReferralStatus,
    isHybridSite: data?.isHybridSite ? data?.isHybridSite : false,
    isProtocolKiElementsEnabled,
    isSiteKiElementsEnabled,
    isHumanApiEnabled: data?.isHumanAPIEnabled ? data?.isHumanAPIEnabled : false,
    isLifeLinkEnabled: data?.isLifeLinkEnabled ? data?.isLifeLinkEnabled : false,
    siteDirectedSchedule: data?.siteDirectedSchedule ? data?.siteDirectedSchedule : false,
    ageCheckForLL: data?.ageCheckForLL ? data?.ageCheckForLL : false,
    maxAgeForLL: data?.maxAgeForLL ? data?.maxAgeForLL : null,
    minAgeForLL: data?.minAgeForLL ? data?.minAgeForLL : null,
    isHoldscheduling: data?.isHoldscheduling ? data?.isHoldscheduling : false,
    isKiElementConsentProvided:
      data?.isUserOptedKiElementConsent && data?.isUserOptedKiElementConsent === 'Yes'
        ? true
        : false,
    isLifeLinkConsentProvided:
      data?.isUserOptedLifeLinkConsent && data?.isUserOptedLifeLinkConsent === 'Yes' ? true : false,
    referralId: getReferralId(getOrCreateEnrollmentId(), siteInfo?.siteCode),
  };
};
