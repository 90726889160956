import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import {
  StHeadingContainer,
  StHeadingText,
  StModalContainerWithModal,
  StmodalContainer,
} from '../../shared/CustomModal/CustomModal.styles';
import { StInputBase } from '../../shared/TextBox/TextBox.style';
import { UIButton } from '../../shared/UIButton.styles';
import { RegisterMergeRichText, RegisterMergeText } from '../RegistrationMergeFields';
//import { EMAIL_SOURCE } from '../../../constants';
import axios from 'axios';

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onConfirm: (email: string) => void;
  updatePopUpEmailLabel: Field<string>;
  updatePopUpSubTitle: Field<string>;
  updatePopUpTitle: Field<string>;
  confirmButtonText: Field<string>;
  cancelButtonnText: Field<string>;
  emailInvalidErrorMessage: Field<string>;
  sameEmailErrorMessage?: Field<string>;
  emailAlreadyExistErrorMessage?: Field<string>;
}
const validateEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,}\s*)$/;
  return emailRegex.test(email);
};

const validateEmailServerSide = async (email: string) => {
  try {
    const validateEmailUrl = `/api/cdp-api?url=/api/contacts/ValidateCrEmailIdOnSave&email=${email}`;
    const response = await axios.get(validateEmailUrl);
    if (response?.data?.isEmailExist) {
      return true;
    }
    return false;
  } catch {
    return true;
  }
};

const UpdateEmailDialog: React.FC<IProps> = (props) => {
  //const { sitecoreContext } = useSitecoreContext();
  //const siteSettings: any = sitecoreContext?.siteSettings;
  //const source = siteSettings?.source?.fields?.name?.value;
  const crEmail = useSelector((state: RootState) => state.registrationDetails.registration.email);
  const myProfileData = useSelector((state: RootState) => state.crProfile.profileData);
  const emailFromProfile = myProfileData?.basicDetails?.email;
  // const eprEmail = useSelector(
  //   (state: RootState) => state?.contactInfoDetails?.contactInfo?.patient?.emailAddress
  // );
  const emailId = crEmail || emailFromProfile;
  const { open, setOpen } = props;
  const confirmText = props.confirmButtonText;
  const cancelText = props.cancelButtonnText;
  const heading = props.updatePopUpTitle;
  const description = props.updatePopUpSubTitle;
  const inputLabel = props.updatePopUpEmailLabel;
  const emailInvalidErrorMessage = props.emailInvalidErrorMessage;
  const sameEmailErrorMessage = props.sameEmailErrorMessage || {
    value: 'Please enter a different email address.',
  };
  const emailExistsErrorMessage = props?.emailAlreadyExistErrorMessage || {
    value: 'Email already exists',
  };

  const updatedEmailRef = React.useRef('');
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isSameEmailId, setIsSameEMailId] = React.useState(false);
  const [isEmailExists, setIsEmailExists] = React.useState(false);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StModalContainerWithModal>
        <StmodalContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <Box>
            <StHeadingContainer>
              <StHeadingText id="modal-modal-title" variant="h6">
                <RegisterMergeText field={heading} />
              </StHeadingText>
            </StHeadingContainer>
            <Box
              sx={{
                width: '100%',
                paddingTop: '1.5rem',
              }}
            >
              <Box>
                <RegisterMergeRichText field={description} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} paddingTop={'2rem'}>
                <RegisterMergeText field={inputLabel} />
                <StInputBase
                  error={!isEmailValid}
                  id="update-email-input"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updatedEmailRef.current = event.target.value;
                    const isProperEmail = validateEmail(updatedEmailRef.current);
                    setIsEmailValid(isProperEmail);
                  }}
                  sx={{
                    width: '75%',
                  }}
                />

                {(!isEmailValid || isSameEmailId || isEmailExists) && (
                  <Typography color={'red'}>
                    <Text
                      field={
                        isSameEmailId
                          ? sameEmailErrorMessage
                          : isEmailExists
                          ? emailExistsErrorMessage
                          : emailInvalidErrorMessage
                      }
                    />
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent={'flex-end'} columnGap={'1rem'}>
            <UIButton
              variant="outlined"
              onClick={() => {
                setIsEmailValid(true);
                setOpen(false);
              }}
              sx={{ paddingBlock: '5px', minWidth: 'unset', width: 'auto' }}
            >
              {cancelText.value}
            </UIButton>
            <UIButton
              variant="contained"
              onClick={async (): Promise<unknown> => {
                if (updatedEmailRef.current) {
                  setIsSameEMailId(false);
                  setIsEmailExists(false);
                  const isProperEmail = validateEmail(updatedEmailRef.current);

                  if (updatedEmailRef.current === emailId) {
                    setIsSameEMailId(true);
                    return false;
                  }
                  const isEmailExists = await validateEmailServerSide(updatedEmailRef.current);
                  if (isEmailExists == true) {
                    setIsEmailExists(true);
                    return;
                  }
                  if (isProperEmail) {
                    props.onConfirm(updatedEmailRef.current);
                    setOpen(false);
                    setIsEmailValid(true);
                  } else {
                    setIsEmailValid(false);
                    return false;
                  }
                }
                return;
              }}
              sx={{ paddingBlock: '5px', minWidth: 'unset', width: 'auto' }}
            >
              {confirmText.value}
            </UIButton>
          </Box>
        </StmodalContainer>
      </StModalContainerWithModal>
    </Modal>
  );
};

export default UpdateEmailDialog;
