import Box from '@mui/material/Box';
import { FeaturedTrialType } from '../../components/shared/Carousel/Carousel.types';
import CarouselWrapper from '../shared/Carousel/CarouselWrapper/CarouselWrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const FeaturedTrial = (props: FeaturedTrialType): JSX.Element => {
  const featuredTrialElement = useRef<HTMLElement>(null);
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state.config.isNotificationBannerOpen
  );
  const theme = useTheme();
  const isBelowDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (featuredTrialElement && featuredTrialElement.current && !isBelowDesktop) {
      const bannerElement = document.getElementById('notification-banner');
      let bannerHeight = 0;
      if (bannerElement) {
        bannerHeight = bannerElement.offsetHeight;
      }
      featuredTrialElement.current.style.marginTop = `${bannerHeight}px`;
    }
  }, [isNotificationBannerOpen]);

  if (!props.fields) {
    return <></>;
  }
  return (
    <Box
      ref={featuredTrialElement}
      id="featured-trial"
      sx={{
        padding: isMobile ? '48px 8px 16px 8px' : '48px 8px',
        height: isMobile
          ? '640px !important'
          : isBelowDesktop
          ? '640px !important'
          : '690px !important',
      }}
    >
      <CarouselWrapper
        {...props}
        identifier="featured-trials-carousal"
        callerComponent="FeaturedTrials"
      />
    </Box>
  );
};

export default FeaturedTrial;
