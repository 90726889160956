import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StContentHeadingBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral2.main,
  padding: '2rem 6rem',
  ul: {
    paddingLeft: '1rem',
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    ':hover': {
      color: theme.palette.primary.dark,
    },
  },
  '.MuiTypography-root': {
    fontWeight: 600,
    color: theme.palette.neutral9.main,
  },
  [theme.breakpoints.down('lg')]: {
    padding: '2rem 3.25rem',
  },
  [theme.breakpoints.down('md')]: {
    '.MuiTypography-root': {
      fontSize: '32px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem',
  },
}));

export const StContentHeadingSubBox = styled(Box)(({}) => ({
  maxWidth: '1365px',
  marginInline: 'auto',
}));
