import { LinkField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { JSSLink } from '../Links/JSSLink.styles';
import { StNextImage } from '../NextImage.styles';

interface SocialMedialinkItem {
  fields: {
    link: LinkField;
    image: ImageField;
  };
  name: string;
}

interface ISocialMediaLinksProps {
  socialMediaIconList: Array<SocialMedialinkItem> | [];
  iconColor?: string;
  hoverColor?: string;
}

export const StSocialMediaIconItem = styled(Grid)(({ theme }) => ({
  height: '24px',
  padding: 0,
  marginLeft: '1rem',
  [theme.breakpoints.down('sm')]: {
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));
const SocialMediaLinks = (props: ISocialMediaLinksProps): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      {props?.socialMediaIconList?.map((socialMediaIcon: SocialMedialinkItem, index: number) => {
        return (
          <StSocialMediaIconItem key={index}>
            <JSSLink
              id={socialMediaIcon.name}
              className={'social-media-link btn'}
              data-testid={socialMediaIcon?.fields?.image?.value?.src}
              field={socialMediaIcon?.fields?.link}
              sx={{
                height: '2rem',
                '&:hover': {
                  borderBottom: `1px dashed ${props?.hoverColor || theme.palette.white.main}`,
                },
              }}
            >
              <StNextImage
                suppressHydrationWarning
                field={socialMediaIcon?.fields?.image}
                height="24"
                width="24"
                overrideIconColor={props?.iconColor || theme.palette.white.main}
              />
            </JSSLink>
          </StSocialMediaIconItem>
        );
      })}
    </>
  );
};

export default SocialMediaLinks;
