/* eslint-disable react-hooks/rules-of-hooks */
import TagManager from 'react-gtm-module';
import { isAcceptCookieOptinAndActive } from './cookieHelpers';

const initializeTagManager = (gtmId: string, isCr = false): void => {
  if ((window as any)?.dataLayerClone) (window as any).dataLayer = (window as any).dataLayerClone;
  if (isCr) TagManager.dataLayer({ dataLayer: { CookieModalAnalytics: 'on' } });
  if (gtmId && !(window as any).dataLayer) TagManager.initialize({ gtmId });
};

const trackEvent = (eventProps?: any, isCr = false): void => {
  if (isAcceptCookieOptinAndActive('Non-Essential Cookies') || isCr) {
    TagManager.dataLayer({
      dataLayer: {
        ...eventProps,
      },
    });
  }
};

export { initializeTagManager, trackEvent };
