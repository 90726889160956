import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
export const LinkTextBox = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const AlertMessageBox = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  lineHeight: theme.spacing(3),
}));

export const AlertInfoBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  position: 'fixed',
  top: '89px',
  left: 0,
  width: '100%',
  zIndex: 5,
}));
