/* eslint-disable react/jsx-key */
import FavoriteIcon from '@mui/icons-material/Favorite';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProfileData } from '../../../src/features/crProfileSlice';
import { hideLoader, showLoader } from '../../../src/features/loaderSlice';
import useSavedTrialsNearestSite from '../../lib/hooks/coveo/useSavedTrialsNearestSite';
import {
  fetchProfileDetailsForCR,
  updateSavedTrialsForMyProfile,
} from '../../lib/utils/axiosCDPFetcher';
import { highlightExcerpt } from '../../lib/utils/coveoHeadless';
import { JSSLink } from '../shared/Links/JSSLink.styles';
import CRModal from './CRModal';
import { StTagInfo } from './SearchTrialItem.style';
import { buildInteractiveResult, Result } from '@coveo/headless';
import { useRouter } from 'next/router';
import headlessEngine from '../../lib/utils/coveo/headlessEngine';
import { Url } from 'url';
import { mergeTag } from '../../lib/utils/common';
import getAgeRange from '../../lib/utils/coveo/getAgeRange';

const KeyMapper: any = {
  Age: 'trialminage',
  Phase: 'trialphase',
  'Participants needed': 'trialenrollment',
  'Est. Completion Date': 'trialcompletiondate',
};

export const StDescription = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'limit',
})<any>(({ theme, limit }) => ({
  lineHeight: '24px',
  color: theme.palette.neutral8.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: limit || 3,
  height: `${limit * 24}px`,
  WebkitBoxOrient: 'vertical',
}));

interface IProps {
  data: { coveoTrialObj: any; fieldsData: any };
  style?: any;
  [key: string]: any;
  modalProps?: any;
  state?: any;
}

export const MODAL_VARIANT = {
  EXIT_DISCLAIMER: 'exit_disclaimer',
};

const getmModalCTAButtonByName = (buttons: any, name: string): any => {
  if (buttons?.length > 0) {
    const item = buttons.find((item: any) => item?.name === name);
    if (item) {
      return {
        buttonVariant: item?.fields?.buttonVariant?.value,
        value: item?.fields?.link?.value?.text,
        anchor: item?.fields?.link,
        name: item?.displayName,
      };
    } else {
      return null;
    }
  }
};

const TrialCardItem = (props: IProps) => {
  const dispatch = useDispatch();
  const { t } = useI18n();
  const theme = useTheme();
  const trialItem = props.data.coveoTrialObj;
  const fieldsData = props.data.fieldsData;
  const customStyle = props.style || {};
  const modalProps = props?.modalProps?.modalProps || props?.modalProps;
  const cancel = getmModalCTAButtonByName(modalProps?.customButtons, 'Cancel');
  const continueBtn = getmModalCTAButtonByName(modalProps?.customButtons, 'Continue');
  const [openModal, setOpenModal] = useState(false);
  const [selectedTrial, setSelectedTrial] = useState<any>(null);
  const getNearestSite = useSavedTrialsNearestSite(props?.state);
  const [updatedModalDescription, setUpdatedModalDescription] = useState({ value: '' });
  const router = useRouter();
  //hardcoded
  const GOOD_MATCH_DISTANCE = 25;

  const isGoodMatchByDistance = useCallback(
    (item: any) => {
      const nearestSite = getNearestSite(item);
      const distance = parseFloat(nearestSite as string);
      return Math.abs(distance) <= GOOD_MATCH_DISTANCE ? true : false;
    },
    [getNearestSite]
  );

  const getSetProfileData = async () => {
    const myProfileVerificationToken = sessionStorage.getItem('myProfileVerificationToken');
    if (myProfileVerificationToken) {
      try {
        const { data } = await fetchProfileDetailsForCR(myProfileVerificationToken);
        if (data) {
          dispatch(setProfileData(data));
        }
      } catch (_e) {}
    }
  };

  const handleSaveAndUnsaveTrial = async () => {
    const payload: any = {
      myProfileToken: sessionStorage.getItem('myProfileVerificationToken'),
      trialId: selectedTrial?.raw?.trialname,
      isSaved: false,
    };
    setSelectedTrial(null);
    try {
      dispatch(showLoader());
      const { data } = await updateSavedTrialsForMyProfile(payload);
      if (data) {
        await getSetProfileData();
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedTrial(null);
  };
  const onCancelModal = () => {
    setOpenModal(false);
    setSelectedTrial(null);
  };

  const handleUnsaveTrialModal = () => {
    const updatedText = mergeTag(modalProps?.description?.value, {
      TrialStudyName: trialItem?.title,
    });
    setUpdatedModalDescription({ value: updatedText });
    setOpenModal(true);
    setSelectedTrial(trialItem);
  };

  const onContinueModal = async () => {
    setOpenModal(false);
    await handleSaveAndUnsaveTrial();
  };

  const onCardLinkClick = (item: Result, isFeatured: boolean) => () => {
    const interactiveResultController = buildInteractiveResult(headlessEngine, {
      options: { result: item },
    });
    interactiveResultController.select();

    // need to send click analytics before open trial page
    setTimeout(() => {
      router.push(
        isFeatured ? (item.raw.featuredeprurl as Url) : (item.clickUri as unknown as Url)
      );
    }, 1000);
  };

  return (
    <Box
      sx={{
        padding: '24px',
        borderRadius: '16px',
        background: '#FFF',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        ...customStyle,
      }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Grid
        item
        container
        sx={{
          flexDirection: 'row',
          marginBottom: '24px',
        }}
      >
        {trialItem?.raw?.trialenrollingstatus === 'Enrolling' &&
          isGoodMatchByDistance(trialItem) && (
            <StTagInfo item sx={{ background: '#077D86' }}>
              {t('goodMatch')}
            </StTagInfo>
          )}
        {JSON.parse(trialItem?.raw?.isvirtual || false) && (
          <StTagInfo item sx={{ background: '#C42EA5' }}>
            {t('virtual')}
          </StTagInfo>
        )}
        {trialItem?.raw?.trialenrollingstatus && (
          <StTagInfo item sx={{ background: '#595959' }}>
            {trialItem?.raw?.trialenrollingstatus}
          </StTagInfo>
        )}
        <Grid item flex={1} textAlign={'right'} alignSelf={'center'}>
          <Tooltip id={trialItem?.UniqueId} title={props?.fields?.saveButtonHoverText?.value}>
            <FavoriteIcon
              sx={{ cursor: 'pointer', color: 'black' }}
              onClick={() => handleUnsaveTrialModal()}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          color: '#444',
          fontFamily: 'Proxima Nova',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '33px',
          marginBottom: '24px',
        }}
      >
        <StDescription
          dangerouslySetInnerHTML={{ __html: highlightExcerpt(trialItem) }}
          limit={2}
        />
      </Grid>
      <Box>
        {fieldsData.map((field: any, index: number) => (
          <Grid
            key={index}
            item
            container
            flexDirection={'row'}
            justifyContent={'space-between'}
            mb={3}
          >
            <Image field={field.icon} />
            <Grid item ml={2}>
              {field.label.value}
            </Grid>
            <Grid item flex={1} textAlign={'right'}>
              {field?.key?.value === 'Nearest Site'
                ? getNearestSite(trialItem) || <span>&#8212;</span>
                : field?.key?.value === 'Age'
                ? getAgeRange(trialItem?.raw?.trialminage, trialItem?.raw?.trialmaxage) || (
                    <span>&#8212;</span>
                  )
                : trialItem?.raw?.[KeyMapper[field.key.value]]}
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          container
          spacing={1.5}
          sx={{
            boxSizing: 'border-box',
          }}
        >
          {trialItem.clickUri && (
            <Grid
              item
              xs={trialItem.raw.featuredeprurl ? 6 : 12}
              lg={trialItem.raw.featuredeprurl ? 6 : 12}
            >
              <JSSLink
                sx={{
                  width: '100%',
                  minWidth: 'initial',
                  cursor: 'pointer',
                  [theme.breakpoints.down(500)]: {
                    padding: '5px',
                  },
                }}
                id={''}
                className={''}
                key={''}
                onClick={onCardLinkClick(trialItem, false)}
                variant="outlined"
                field={{
                  value: {
                    // href: item.clickUri,
                    text: props?.fields?.viewDetailsButton?.value || 'View Details',
                  },
                }}
                aria-label={'text'}
              />
            </Grid>
          )}
          {trialItem.raw.featuredeprurl && (
            <Grid item xs={6} lg={6}>
              <JSSLink
                sx={{
                  width: '100%',
                  minWidth: 'initial',
                  cursor: 'pointer',
                  [theme.breakpoints.down(500)]: {
                    padding: '5px',
                  },
                }}
                id={''}
                className={''}
                key={''}
                onClick={onCardLinkClick(trialItem, true)}
                variant="contained"
                field={{
                  value: {
                    // href: item.raw.featuredeprurl,
                    text: props?.fields?.seeIfYouQualifyButton?.value || 'See if you qualify',
                  },
                }}
                aria-label={'text'}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <CRModal
        open={openModal}
        handleClose={onCloseModal}
        handleCancel={onCancelModal}
        handleContinue={onContinueModal}
        title={modalProps?.title?.value}
        confirmButton={continueBtn}
        cancelButton={cancel}
      >
        <RichText field={updatedModalDescription} />
      </CRModal>
    </Box>
  );
};
export default TrialCardItem;
