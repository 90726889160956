import { styled } from '@mui/material/styles';
import LinkwithModal from '../LinkWithModal';
// import { Link } from '@sitecore-jss/sitecore-jss-nextjs';

// JSS link that looks like a button and can be used for client side transitions between routes
export const JSSLink = styled(LinkwithModal, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'tabIndex',
})<{ variant?: string; tabIndex?: any }>(({ theme, variant, field }) => ({
  display: 'inline-block',
  fontFamily: 'Proxima Nova SemiBold',
  fontSize: '1rem',
  filter:
    variant === 'contained'
      ? 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.50))'
      : 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08))',
  fontStyle: 'normal',
  lineHeight: '2.5rem',
  color: variant === 'contained' ? theme?.palette?.white?.main : theme.palette.primary.main,
  textDecoration: 'none',
  textTransform: 'none',
  borderRadius: variant === 'contained' || variant === 'outlined' ? '50px' : '0px',
  height: 'auto',
  minWidth: variant === 'contained' || variant === 'outlined' ? '205px' : '0px',
  textAlign: 'center',
  padding:
    variant === 'contained' || variant === 'outlined'
      ? ((field as any)?.value?.text as string) && (field as any)?.value?.text?.length > 20
        ? '1px 10px'
        : '1px'
      : '0px',
  border:
    variant === 'contained'
      ? `1px solid ${theme.palette.primary.main}`
      : variant === 'outlined'
      ? `1px solid ${theme.palette.neutral4.main}`
      : 'none',
  background:
    variant === 'contained'
      ? theme.palette.primary.main
      : variant === 'outlined'
      ? theme.palette.secondary.main
      : 'none',
  '&:hover': {
    background:
      variant === 'contained'
        ? theme.palette.primary.dark
        : variant === 'outlined'
        ? theme.palette.secondary.dark
        : 'none',
    textDecoration: 'none',
    border:
      variant === 'contained'
        ? `1px solid ${theme.palette.primary.main}`
        : variant === 'outlined'
        ? `1px solid ${theme.palette.primary.main}`
        : 'none',
    borderBottom:
      variant === 'contained' || variant === 'outlined'
        ? `1px solid ${theme.palette.primary.main}`
        : '1px dashed',
    color: variant === 'contained' ? theme?.palette?.white?.main : theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.5rem',
  },
}));
