import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LinkwithModal from '../LinkWithModal';

export const StSecondaryLinks = styled(LinkwithModal, {
  shouldForwardProp: (prop) => prop !== 'linkFontColor' && prop !== 'hoverColor',
})<{ linkFontColor?: string; hoverColor?: string }>(({ theme, linkFontColor, hoverColor }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px !important',
  textAlign: 'center',
  letterSpacing: '0.5px',
  color: linkFontColor ? linkFontColor : theme.palette.white.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    borderBottom: '1px dashed',
    color: hoverColor || theme.palette.white.main,
  },
  '&:active': {
    fontWeight: 600,
    textDecoration: 'none',
  },
}));

export const StSecondaryLinkItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginBottom: '0.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '0.5rem',
  },
}));

export const StLinkSeprator = styled('span')(({ theme }) => ({
  height: '18px',
  [theme.breakpoints.up('sm')]: {
    marginRight: '0.5rem',
    paddingRight: '0.5rem',
    borderRight: '1px solid #D9D9D9 !important',
  },
}));
