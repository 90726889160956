import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import {
  CarouselTabWrapperType,
  ArticleListType,
  FeaturedTrialItemType,
  CRQuestionaaireType,
  SavedTrialsListItem,
  PersonalizedFeaturedTrialItemType,
} from './Carousel.types';
import ArticleCard from './CarouselCard/ArticleCard';
import TrialCard from './CarouselCard/TrialCard';
import CRQuestionnairCard from './CarouselCard/CRQuestionaireCard';
import TrialCardItem from '../../CR/TrialCardItem';
import PersonalizedTrialCardItem from '../../CR/PersonalizedTrialCardItem';

const CarouselTabWrapper = (props: CarouselTabWrapperType | any): JSX.Element => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        overflowX: 'scroll',
        ...(props?.totalCount === 1 && isTablet && { justifyContent: 'center' }),
      }}
    >
      {props?.callerComponent === 'Articles'
        ? props.data.map((item: ArticleListType, itemIndex: number) => {
            return (
              <ArticleCard
                key={itemIndex}
                data={item}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                totalCount={props.totalCount}
                descriptionWordLimit={props.descriptionWordLimit}
                titleCharacterLimit={props.titleCharacterLimit}
                descriptionLineLimit={props.descriptionLineLimit}
                titleLineLimit={props.titleLineLimit}
              />
            );
          })
        : props?.callerComponent === 'CR-Questionaaire'
        ? props.data.map((item: CRQuestionaaireType | any, itemIndex: number) => {
            return (
              <CRQuestionnairCard
                key={itemIndex}
                data={item}
                dataProps={props?.dataProps}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                totalCount={props.totalCount}
                descriptionWordLimit={props.descriptionWordLimit}
                titleCharacterLimit={props.titleCharacterLimit}
                descriptionLineLimit={props.descriptionLineLimit}
                titleLineLimit={props.titleLineLimit}
              />
            );
          })
        : props?.callerComponent === 'saved-trials'
        ? props.data.map((item: SavedTrialsListItem, itemIndex: number) => {
            return (
              <TrialCardItem
                key={itemIndex}
                data={item}
                style={{ minWidth: '393px', margin: '1rem' }}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                totalCount={props.totalCount}
                descriptionWordLimit={props.descriptionWordLimit}
                titleCharacterLimit={props.titleCharacterLimit}
                descriptionLineLimit={props.descriptionLineLimit}
                titleLineLimit={props.titleLineLimit}
                modalProps={props?.modalProps}
                state={props?.state}
              />
            );
          })
        : props?.callerComponent === 'personalized-featured-trials'
        ? props.data.map((item: PersonalizedFeaturedTrialItemType, itemIndex: number) => {
            return (
              <PersonalizedTrialCardItem
                key={itemIndex}
                data={item}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                totalCount={props.totalCount}
                itemIndex={itemIndex}
                showCount={props.showCount}
                descriptionWordLimit={props.descriptionWordLimit}
                titleCharacterLimit={props.titleCharacterLimit}
                descriptionLineLimit={props.descriptionLineLimit}
                titleLineLimit={props.titleLineLimit}
                findOutMoreText={props.findOutMoreText}
                seeIfYouQualifyText={props.seeIfYouQualifyText}
              />
            );
          })
        : props.data.map((item: FeaturedTrialItemType, itemIndex: number) => {
            return (
              <TrialCard
                key={itemIndex}
                data={item}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                totalCount={props.totalCount}
                itemIndex={itemIndex}
                showCount={props.showCount}
                descriptionWordLimit={props.descriptionWordLimit}
                titleCharacterLimit={props.titleCharacterLimit}
                descriptionLineLimit={props.descriptionLineLimit}
                titleLineLimit={props.titleLineLimit}
                id={'featured-trial'}
              />
            );
          })}
    </Box>
  );
};

export default CarouselTabWrapper;
