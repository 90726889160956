import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ICookieDetails } from '../Cookie.types';
import getDomainName from '../../../lib/utils/getDomainName';

const CookieTable: React.FC<{ cookieList: ICookieDetails[] }> = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const cookieList = Array.isArray(props.cookieList) ? props.cookieList : [];

  const flattenCookieList = (arr: any[]): any[] => {
    const transformedArray: any[] = [];
    arr.forEach((item) => {
      const isUseSiteCoreDomain = item?.fields?.useDomain?.value;
      const isPrefix = item?.fields?.applyPrefix?.value || false;
      const sourceValue = `${isPrefix ? '.' : ''}${
        isUseSiteCoreDomain ? item?.fields?.sourceValue?.value : getDomainName()
      }`;
      transformedArray.push({
        [item?.fields?.namelabel?.value]: item?.fields?.nameValue?.value,
        [item?.fields?.sourceLabel?.value]: sourceValue,
        [item?.fields?.durationLabel?.value]: item?.fields?.durationValue?.value,
        [item?.fields?.descriptionLabel?.value]: item?.fields?.descriptionValue?.value,
      });
    });
    return transformedArray;
  };
  const cookieListForTable = flattenCookieList(cookieList);
  let labels: any[] = [];
  if (cookieListForTable && cookieListForTable.length > 0) {
    labels = Object.keys(cookieListForTable[0]);
  }

  const CookieTableMobile = () => {
    const flattenCookieListForMobile = (arr: any[]): any[] => {
      const transformedArray: any[] = [];
      arr.forEach((item) => {
        Object.keys(item).forEach((key) => {
          transformedArray.push({ name: key, value: item[key] });
        });
      });
      return transformedArray;
    };
    const cookieListForMobile = flattenCookieListForMobile(cookieListForTable);
    return (
      <TableContainer component={Box}>
        <Table>
          <TableBody>
            {cookieListForMobile.map((cookie, index) => {
              const borderBottom = index % 4 === 3 ? '1px solid #0000001F' : '0px !important'; // Apply borderBottom style for every 4th element
              return (
                <TableRow key={index}>
                  <TableCell sx={{ borderBottom }}>
                    <Typography fontWeight="600">{cookie.name}</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom }}>{cookie.value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const CookieTable = () => {
    return (
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              {labels && labels.map((item, index) => <TableCell key={index}>{item}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {cookieListForTable &&
              cookieListForTable.length > 0 &&
              cookieListForTable.map((cookie: any, index: number) => (
                <TableRow key={index}>
                  <>
                    {Object.values(cookie).map((item: string, i) => (
                      <TableCell key={i}>{item}</TableCell>
                    ))}
                  </>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return <>{isTablet ? <CookieTableMobile /> : <CookieTable />}</>;
};

export default CookieTable;
