import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  RichText,
  Text,
  Field,
  ImageField,
  ImageFieldValue,
  NextImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import {
  StHeader,
  StDescription,
  StContentItem,
  StContentListContainer,
  StIcon,
  StContent,
  StContentListContainerLaptop,
  StContentListSubContainer,
} from './FeatureHighlights.style';
import {
  StDTPGridContainer,
  StDTPWrapper,
} from '../../../components/shared/Container/Container.styles';

interface IFeatureHighlightItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    content: Field<string>;
  };
}
interface IFeatureHighlights {
  fields: {
    applyBackground?: Field<boolean>;
    contentList: Array<IFeatureHighlightItem> | [];
    description?: Field<string>;
    sectionIcon: ImageField;
    title: Field<string>;
  };
}
const FeatureHighlights = (props: IFeatureHighlights): JSX.Element => {
  const theme = useTheme();
  const isLaptopView = useMediaQuery(theme.breakpoints.up('lg'));
  if (!props?.fields) return <></>;
  return (
    <StDTPWrapper id="feature-highlights">
      <StDTPGridContainer>
        <StHeader>
          <Text field={props?.fields?.title} />
        </StHeader>
        <StDescription>
          <RichText field={props?.fields?.description} />
        </StDescription>
        <>
          {isLaptopView ? (
            <>
              <StContentListContainerLaptop>
                <StContentListSubContainer>
                  {' '}
                  {props?.fields?.contentList
                    .filter((_, i) => !(i % 2))
                    .map((item) => {
                      return (
                        <StContentItem
                          key={item.id}
                          applyBackground={props?.fields?.applyBackground?.value}
                        >
                          {props?.fields?.applyBackground?.value && (
                            <StIcon>
                              <NextImage field={props?.fields?.sectionIcon as ImageFieldValue} />
                            </StIcon>
                          )}
                          <StContent>
                            <RichText field={item?.fields?.content} />
                          </StContent>
                        </StContentItem>
                      );
                    })}
                </StContentListSubContainer>
                <StContentListSubContainer>
                  {props?.fields?.contentList
                    .filter((_, i) => i % 2)
                    .map((item) => {
                      return (
                        <StContentItem
                          key={item.id}
                          applyBackground={props?.fields?.applyBackground?.value}
                        >
                          {props?.fields?.applyBackground?.value && (
                            <StIcon>
                              <NextImage field={props?.fields?.sectionIcon as ImageFieldValue} />
                            </StIcon>
                          )}
                          <StContent>
                            <RichText field={item?.fields?.content} />
                          </StContent>
                        </StContentItem>
                      );
                    })}
                </StContentListSubContainer>
              </StContentListContainerLaptop>
            </>
          ) : (
            <>
              <StContentListContainer>
                {props?.fields?.contentList.map((item) => {
                  return (
                    <StContentItem
                      key={item.id}
                      applyBackground={props?.fields?.applyBackground?.value}
                    >
                      {props?.fields?.applyBackground?.value && (
                        <StIcon>
                          <NextImage field={props?.fields?.sectionIcon as ImageFieldValue} />
                        </StIcon>
                      )}
                      <StContent>
                        <RichText field={item?.fields?.content} />
                      </StContent>
                    </StContentItem>
                  );
                })}
              </StContentListContainer>
            </>
          )}
        </>
      </StDTPGridContainer>
    </StDTPWrapper>
  );
};

export default FeatureHighlights;
