import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StOneColumnContainer = styled(Grid)(({ theme }) => ({
  margin: '3rem 8rem',
  [theme.breakpoints.down('lg')]: {
    margin: '2.5rem 6rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '2rem 4rem',
  },
  [theme.breakpoints.down(450)]: {
    margin: '2rem 2rem',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1300px',
    margin: '3rem auto',
    padding: '0px 30px',
  },
}));

export const StTwoColumnContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const StLeftColumnContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.themeColor.main,
}));

export const StRightColumnContainer = styled(Grid)(({ theme }) => ({
  paddingTop: '61px',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  [theme.breakpoints.down('lg')]: {
    paddingTop: '2rem',
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));

export const StTypographyTitle = styled(Typography)(() => ({
  textAlign: 'left',
  letterSpacing: '1px',
  lineHeight: '24px',
}));

export const StTypographyDescription = styled(Typography)(() => ({
  textAlign: 'left',
}));
