import * as React from 'react';
import { ComponentProps } from 'lib/component-props';
import { StNextImage } from '../../shared/NextImage.styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Field,
  ImageField,
  RichTextField,
  Text,
  RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export type ISmallBannerProps = ComponentProps & {
  fields: {
    description: RichTextField;
    title: Field<string>;
    titleIcon: ImageField;
    applyMargin?: Field<boolean>;
  };
};

const iconDimentions = {
  desktop: { height: 70, width: 60 },
  tablet: { height: 60, width: 50 },
  mobile: { height: 48, width: 38 },
};

const SmallBanner = (props: ISmallBannerProps): JSX.Element => {
  const theme = useTheme();
  const themeColor = theme?.palette?.themeColor?.main;
  const themeColorSecondary = theme?.palette?.themeLightColor?.main;
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { desktop, mobile, tablet } = iconDimentions;
  if (!props?.fields) return <></>;
  let { height, width } = desktop;
  if (isTablet && !isMobile) {
    height = tablet.height;
    width = tablet.width;
  }
  if (isMobile) {
    height = mobile.height;
    width = mobile.width;
  }

  const applyMargin = props.fields.applyMargin?.value || false;
  const margin = applyMargin ? '3rem 8rem 1rem' : null;
  const marginDownLg = applyMargin ? '2rem 4rem 1rem' : null;
  const marginDownMd = applyMargin ? '2rem 2rem 1rem' : null;

  return (
    <Box
      id={'small-banner'}
      display={'flex'}
      sx={{
        background: themeColorSecondary,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '42px 0px',
        margin,
        [theme.breakpoints.down('lg')]: {
          margin: marginDownLg,
        },
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          margin: marginDownMd,
        },
        [theme.breakpoints.up('xl')]: {
          width: '1300px',
          marginInline: 'auto !important',
        },
      }}
    >
      <Box>
        <StNextImage
          suppressHydrationWarning
          field={props?.fields?.titleIcon}
          width={width}
          height={height}
        />
      </Box>

      <Typography
        variant="h4"
        color={themeColor}
        paddingTop={'8px'}
        sx={{
          textAlign: 'center',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '32px',
        }}
      >
        <Text data-testid={props?.fields?.title} field={props?.fields?.title} />
      </Typography>
      <Box
        sx={{
          paddingTop: '8px',
          color: themeColor,
          textAlign: 'center',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            fontStyle: 'normal',
            lineHeight: '24px',
            paddingInline: '1.5rem',
          },
        }}
      >
        <RichText field={props?.fields?.description} />
      </Box>
    </Box>
  );
};

export default SmallBanner;
