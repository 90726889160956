import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';

import { StToggleSwitch } from './ToggleSwitch.style';
import { ToggleSwitchProps } from './ToggleSwitch.type';
// import ToggleSwitchMock from './ToggleSwitch.mock';

const ToggleSwitch = (props: ToggleSwitchProps): JSX.Element => {
  const theme = useTheme();
  // props = ToggleSwitchMock;
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ width: '358px', margin: '0 auto', position: 'relative' }}
    >
      <Typography
        sx={{
          marginLeft: '0 !important',
          display: props?.isToggled ? 'none' : 'block',
          position: 'absolute',
          zIndex: 1,
          left: '72px',
          color: theme?.palette?.text.primary,
          cursor: 'pointer',
        }}
        onClick={props?.onToggle}
      >
        <Text field={props?.label1} />
      </Typography>
      <Typography
        sx={{
          marginLeft: '0 !important',
          display: props?.isToggled ? 'block' : 'none',
          position: 'absolute',
          zIndex: 1,
          left: '72px',
          color: theme.palette.primary.main,
          cursor: 'pointer',
        }}
        data-testid={'option1Component'}
        onClick={props?.onToggle}
      >
        <Text field={props?.label1} />
      </Typography>
      <StToggleSwitch
        defaultChecked={props?.defaultChecked}
        inputProps={{ ...props?.inputProps, id: 'toggle-switch' }}
        checked={props?.isToggled}
        onChange={props?.onToggle}
        value={'Map selected'}
      />
      <Typography
        sx={{
          marginLeft: '0 !important',
          display: props?.isToggled ? 'none' : 'block',
          position: 'absolute',
          zIndex: 1,
          right: '80px',
          color: theme.palette.primary.main,
          cursor: 'pointer',
        }}
        data-testid={'option2Component'}
        onClick={props?.onToggle}
      >
        <Text field={props?.label2} />
      </Typography>
      <Typography
        sx={{
          marginLeft: '0 !important',
          display: props?.isToggled ? 'block' : 'none',
          position: 'absolute',
          zIndex: 1,
          right: '80px',
          color: theme?.palette?.text.primary,
          cursor: 'pointer',
        }}
        onClick={props?.onToggle}
      >
        <Text field={props?.label2} />
      </Typography>
    </Stack>
  );
};

export default ToggleSwitch;
