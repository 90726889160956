import Box from '@mui/material/Box';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyledLabel, StyledValue } from './StudyDetail.styles';

type StudyDetailList = {
  label: Field<string>;
  value: Field<string>;
};

const StudyDetailList = (props: StudyDetailList): JSX.Element => {
  if (props?.value?.value == '') return <></>;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
      <StyledLabel>
        <Text field={props?.label} />
      </StyledLabel>
      <StyledValue>
        <Text field={props?.value} />
      </StyledValue>
    </Box>
  );
};

export default StudyDetailList;
