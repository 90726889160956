//File for common types
import { Field } from '@sitecore-jss/sitecore-jss-react';

export interface IItemWithString {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    name: Field<string>;
  };
}

export interface IEmailRegex {
  displayName: string;
  fields: {
    key: string;
    value: { value: Field };
  };
  id: string;
  name: string;
  url: string;
}

export interface ISitecoreImage {
  value: { alt: string; height: string; src: string; width: string };
}

export interface IReturnUser {
  enrollmentId: string;
  tenantKey: string;
  isReturningUser: boolean;
  showCustomErrorMessageModel: boolean;
  showPatientExistModel: boolean;
}

export enum SendEmailCategories {
  REGISTRATION_WELCOME = 'Registration-Welcome',
  MANAGE_PROFILE = 'Manage-Profile',
  COMMUNITY_LANDING = 'Community-Landing',
}
