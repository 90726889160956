import React from 'react';

import { StoryFn, Meta } from '@storybook/react';
import AutocompleteMultiSelect from './AutocompleteMultiSelect';

export default {
  /* 👇 The title prop is optional.
   * See https://storybook.js.org/docs/react/configure/overview#configure-story-loading
   * to learn how to generate automatic titles
   */
  title: 'AutocompleteMultiSelect',
  component: AutocompleteMultiSelect,
} as Meta<typeof AutocompleteMultiSelect>;

const Template: StoryFn<typeof AutocompleteMultiSelect> = (args) => (
  <AutocompleteMultiSelect {...args} />
);

export const AutocompleteMultiSelectComponent = Template.bind({});

AutocompleteMultiSelectComponent.args = {
  id: 'conditions-of-interest',
  options: [
    {
      key: 'Allergies',
      value: '54',
      description: 'Allergies',
      communities: [],
      category: 'Allergy/Immunology',
      abbreviation: 'Allergies',
      icon: '',
    },
    {
      key: 'Asthma',
      value: '27',
      description: 'Asthma',
      communities: [
        {
          key: 'Asthma',
          value: '27',
          description: 'Asthma',
        },
      ],
      category: 'Allergy/Immunology',
      abbreviation: 'Asthma',
      icon: '',
    },
    {
      key: 'Lupus',
      value: '33',
      description: 'Lupus',
      communities: [
        {
          key: 'Lupus',
          value: '33',
          description: 'Lupus',
        },
      ],
      category: 'Allergy/Immunology',
      abbreviation: 'Lupus',
      icon: '/-/media/DAP/ClinicalResearch/Communities/ICONS/Lupus.png',
    },
    {
      key: 'Rheumatoid Arthritis',
      value: '148',
      description: 'Rheumatoid Arthritis',
      communities: [
        {
          key: 'Arthritis',
          value: '26',
          description: 'Arthritis',
        },
      ],
      category: 'Allergy/Immunology',
      abbreviation: 'Rheumatoid Arthritis',
      icon: '',
    },
    {
      key: 'Back Pain',
      value: '62',
      description: 'Back Pain',
      communities: [
        {
          key: 'Chronic Pain',
          value: '28',
          description: 'Chronic Pain',
        },
      ],
      category: 'Bone/Joint Issues',
      abbreviation: 'Back Pain',
      icon: '',
    },
    {
      key: 'Restless Leg Syndrome',
      value: '147',
      description: 'Restless Leg Syndrome',
      communities: [],
      category: 'Bone/Joint Issues',
      abbreviation: 'Restless Leg Syndrome',
      icon: '',
    },
    {
      key: 'Chronic Obstructive Pulmonary Disease (COPD)',
      value: '101',
      description: 'Chronic Obstructive Pulmonary Disease (COPD)',
      communities: [
        {
          key: 'Chronic Obstructive Pulmonary Disease (COPD)',
          value: '29',
          description: 'Chronic Obstructive Pulmonary Disease (COPD)',
        },
      ],
      category: 'Breathing/Respiratory',
      abbreviation: 'COPD',
      icon: '',
    },
    {
      key: 'COVID-19 / Coronavirus',
      value: '162',
      description: 'COVID-19/Coronavirus',
      communities: [],
      category: 'Breathing/Respiratory',
      abbreviation: 'COVID-19/Coronavirus',
      icon: '',
    },
    {
      key: 'Respiratory Syncytial Virus',
      value: '167',
      description: 'Respiratory Syncytial Virus',
      communities: [],
      category: 'Breathing/Respiratory',
      abbreviation: 'Respiratory Syncytial Virus',
      icon: '',
    },
    {
      key: 'Brain Cancer',
      value: '67',
      description: 'Brain Cancer',
      communities: [
        {
          key: 'Cancer',
          value: '15',
          description: 'Cancer',
        },
      ],
      category: 'Cancer',
      abbreviation: 'Brain Cancer',
      icon: '',
    },
    {
      key: 'Breast Cancer',
      value: '68',
      description: 'Breast Cancer',
      communities: [
        {
          key: 'Cancer',
          value: '15',
          description: 'Cancer',
        },
      ],
      category: 'Cancer',
      abbreviation: 'Breast Cancer',
      icon: '',
    },
    {
      key: 'Cancer',
      value: '15',
      description: 'Cancer',
      communities: [
        {
          key: 'Cancer',
          value: '15',
          description: 'Cancer',
        },
      ],
      category: 'Cancer',
      abbreviation: 'Cancer',
      icon: '/-/media/DAP/ClinicalResearch/Communities/ICONS/Cancer.png',
    },
    {
      key: 'Leukemia',
      value: '118',
      description: 'Leukemia',
      communities: [
        {
          key: 'Cancer',
          value: '15',
          description: 'Cancer',
        },
      ],
      category: 'Cancer',
      abbreviation: 'Leukemia',
      icon: '',
    },
  ],
  noOptionText: 'no condition avaliable',
  label: '*Please select your condition(s) of  interest',
  placeholder: 'Search and select as many as you’d like',
  value: [],
  onChangeHandler: () => void 0,
};
