import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAboutYourself } from '../../../features/registrationSlice';
import { RootState } from '../../../store/store';
import AboutQuestion from './AboutQuestion';
import RegWorkflowFactory from '../RegWorkflowFactory';
import { RegWorkflowContainer } from '../RegistrationWorkflow.style';
import { setScrollId } from '../../../features/scrollIdSlice';
import { getQuery } from '../../../lib/utils/common';

const AboutYourself = (props: any): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const storedAnswers = useSelector((state: RootState) => state.registrationDetails.aboutYourSelf);

  const [enableNext, setEnableNext] = useState<boolean>(false);
  const [regexError, setRegexError] = useState<any>({ firstName: false, lastName: false });

  useEffect(() => {
    if (
      (props?.fields?.isFirstNameRequired.value && storedAnswers.firstName === '') ||
      (props?.fields?.isLastNameRequired.value && storedAnswers.lastName === '')
    ) {
      setEnableNext(false);
    } else {
      setEnableNext(true);
    }
  }, [storedAnswers]);

  const validateInputs = () => {
    let isValid = false;
    const firstNameRegex = new RegExp(props?.fields?.firstNameRegex?.fields?.value?.value);
    const lastNameRegex = new RegExp(props?.fields?.lastNameRegex?.fields?.value?.value);
    if (
      storedAnswers.firstName !== '' &&
      !firstNameRegex.test(storedAnswers.firstName) &&
      storedAnswers.lastName !== '' &&
      !lastNameRegex.test(storedAnswers.lastName)
    ) {
      isValid = false;
      setRegexError({ firstName: true, lastName: true });
    } else if (storedAnswers.firstName !== '' && !firstNameRegex.test(storedAnswers.firstName)) {
      isValid = false;
      setRegexError({ ...regexError, firstName: true });
    } else if (storedAnswers.lastName !== '' && !lastNameRegex.test(storedAnswers.lastName)) {
      isValid = false;
      setRegexError({ ...regexError, lastName: true });
    } else {
      isValid = true;
      setRegexError({ firstName: false, lastName: false });
    }

    return isValid;
  };

  const handleChange = (event: any, name: string) => {
    dispatch(setAboutYourself({ [name]: event.target.value }));
    const firstNameRegex = new RegExp(props?.fields?.firstNameRegex?.fields?.value?.value);
    const lastNameRegex = new RegExp(props?.fields?.lastNameRegex?.fields?.value?.value);
    if (regexError.firstName && name === 'firstName') {
      if (firstNameRegex.test(event.target.value)) {
        setRegexError({ ...regexError, firstName: false });
      }
    }

    if (regexError.lastName && name === 'lastName') {
      if (lastNameRegex.test(event.target.value)) {
        setRegexError({ ...regexError, lastName: false });
      }
    }
  };

  const handleClick = (event: any, url: any, action: any) => {
    event.preventDefault();
    if (action === 'back') {
      if (url !== '') {
        dispatch(setScrollId('getstarted'));
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      } else {
        history.back();
      }
    } else {
      const isValid = validateInputs();
      if (isValid) {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      }
    }
  };
  if (!props?.fields) return <></>;

  return (
    <RegWorkflowContainer>
      <RegWorkflowFactory
        title={props?.fields?.title}
        currentPage={1}
        totalCount={5}
        buttonsItems={props?.fields?.ctaLinks}
        handleClick={handleClick}
        enableNext={enableNext}
      >
        <AboutQuestion
          {...props.fields}
          handleChange={handleChange}
          regexError={regexError}
          storedAnswers={storedAnswers}
        />
      </RegWorkflowFactory>
    </RegWorkflowContainer>
  );
};

export default AboutYourself;
