import { Field, Text, ImageField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { IconWithTitle } from '../shared/IconWithTitle';
import {
  StContainer,
  StTypography,
  StDivider,
  StInformationContainer,
} from './InformationalSteps.style';
import Grid from '@mui/material/Grid';
import InformationalBlock from '../shared/InformationalBlocks/InformationalBlock';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { StDTPGridContainer } from '../shared/Container/Container.styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type InfoStep = {
  fields: {
    stepNumber: Field<number>;
    stepTitle: Field<string>;
    stepDescription: Field<string>;
  };
};

type InformationalStepsProps = {
  fields: {
    infoStepsList: InfoStep[];
    subheading: Field<string>;
    showHighlightedUnderline: Field<boolean>;
    underlineColor: Field<string>;
    hideStepNumbers: Field<boolean>;
    makeInvertedBackground: Field<boolean>;
    infoStepsVariantType: { value: string };
    title: Field<string>;
    summarySection1?: Field<string>;
    summarySection2?: Field<string>;
    titleIcon: ImageField;
    iconWithTitleVariant: { value: string };
  };
};

const InformationalSteps = (props: InformationalStepsProps): JSX.Element => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  if (!props?.fields) return <></>;
  const makeInvertedBackground = props?.fields?.makeInvertedBackground?.value ? true : false;
  const infoStepVariantType = props?.fields?.infoStepsVariantType?.value
    ? props?.fields?.infoStepsVariantType?.value
    : 'HorizontalVariation1';

  const showUnderlineColor = infoStepVariantType === 'HorizontalVariation4' ? true : false;

  const showTextBottom =
    infoStepVariantType === 'HorizontalVariation4' || infoStepVariantType === 'HorizontalVariation3'
      ? true
      : false;

  const isVertical =
    infoStepVariantType === 'VerticalVariation5' || infoStepVariantType === 'VerticalVariation6'
      ? true
      : false;
  const summarySection1 = props?.fields?.summarySection1;
  const summarySection2 = props?.fields?.summarySection2;
  const alignItems = infoStepVariantType === 'VerticalVariation2' ? 'center' : 'flex-start';

  const calculateBreakpoints = (variant: string) => {
    if (variant === 'VerticalVariation2') {
      return {
        lg: 6,
      };
    } else if (variant === 'HorizontalVariation3' || variant === 'HorizontalVariation4') {
      return {
        lg: 2.4,
      };
    } else if (variant === 'HorizontalVariation1') {
      return {
        lg: 4,
      };
    } else {
      return {
        lg: 12,
      };
    }
  };
  const breakpoints = calculateBreakpoints(infoStepVariantType);

  const IconWithTitleProps = {
    titleIcon: props?.fields?.titleIcon,
    title: props?.fields?.title,
    iconWithTitleVariant: props?.fields?.iconWithTitleVariant.value,
    invertColor: makeInvertedBackground,
  };

  const RenderFooterText = () => {
    return (
      <StDTPGridContainer
        display="flex"
        justifyContent="space-evenly"
        alignItems="start"
        sx={{
          marginTop: '0px',

          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'start',
          },
        }}
      >
        {summarySection1?.value && (
          <Grid
            item
            sx={{
              flex: isTablet ? 1 : 1,
              marginRight: !isTablet ? '1rem' : 0,
              marginBottom: isTablet ? '0.5rem' : 0,
            }}
          >
            <RichText field={summarySection1} />
          </Grid>
        )}
        {summarySection2?.value && (
          <Grid item sx={{ flex: isTablet ? 1 : 2 }}>
            <RichText field={summarySection2} />
          </Grid>
        )}
      </StDTPGridContainer>
    );
  };

  return (
    <StInformationContainer invert={makeInvertedBackground} id={'informational-steps'}>
      <Box
        sx={{
          [theme.breakpoints.up('xl')]: {
            width: '1366px',
            marginInline: 'auto',
          },
        }}
      >
        <StContainer id={'informational-steps-container'}>
          <IconWithTitle {...IconWithTitleProps} />
          {props?.fields?.subheading && (
            <StTypography invert={makeInvertedBackground} sx={{ marginBlock: '1rem' }}>
              <Text field={props?.fields?.subheading} />
            </StTypography>
          )}
          {showUnderlineColor && <StDivider />}
          <Grid container spacing={2} justifyContent="center">
            {props?.fields?.infoStepsList?.map((step, index) => (
              <Grid item key={index} xs={12} sm={12} md={12} lg={breakpoints.lg}>
                <InformationalBlock
                  stepNumber={step?.fields?.stepNumber}
                  stepTitle={step?.fields?.stepTitle}
                  stepDescription={step?.fields?.stepDescription}
                  hideStepNumbers={props?.fields?.hideStepNumbers}
                  infoStepsVariantType={infoStepVariantType}
                  showTextBottom={showTextBottom}
                  alignItems={alignItems}
                  isVertical={isVertical}
                  invert={makeInvertedBackground}
                />
              </Grid>
            ))}
          </Grid>
        </StContainer>
        {(summarySection1?.value || summarySection2?.value) && <RenderFooterText />}
      </Box>
    </StInformationContainer>
  );
};

export default InformationalSteps;
