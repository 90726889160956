import React from 'react';
import Modal from '@mui/material/Modal';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  StModalContainerWithModal,
  StmodalContainer,
  StHeadingContainer,
  StHeadingText,
  StDescriptionText,
  StFooterbuttons,
} from './ExitFormDisclaimer.style';
import { UILink } from '../../shared/Links/UILink.styles';
import { UIButton } from '../../shared/UIButton.styles';
import Image from 'next/image';
import CloseIcon from '../../../../public/close-icon.png';

export interface IModalProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  description: Field<string>;
  title: Field<string>;
  stayButton: LinkField;
  leaveButton: LinkField;
}

const ExitFormDisclaimer = (props: IModalProps) => {
  const { isOpen, onClose, title, description, stayButton, leaveButton } = props;

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StModalContainerWithModal>
          <StmodalContainer>
            <StHeadingContainer>
              <StHeadingText id="modal-modal-title" variant="h6">
                {title?.value}
              </StHeadingText>
              <Image
                tabIndex={0}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') onClose(false);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Space') onClose(false);
                }}
                data-testid="exitform-disclaimer-image"
                alt="close-icon"
                src={CloseIcon}
                onClick={() => onClose(false)}
                height={12}
                width={12}
                style={{ cursor: 'pointer' }}
              />
            </StHeadingContainer>
            <StDescriptionText id="modal-modal-description">{description?.value}</StDescriptionText>
            <StFooterbuttons>
              <UIButton
                className="btn"
                tabIndex={0}
                href={stayButton?.value.href}
                aria-label={leaveButton?.value.text}
              >
                {leaveButton?.value.text}
              </UIButton>
              <UILink
                tabIndex={1}
                data-testid="exitform-disclaimer-stay-btn"
                aria-label={stayButton?.value.text}
                variant="contained"
                onClick={() => onClose(false)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') onClose(false);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Space') onClose(false);
                }}
                target="_blank"
              >
                {stayButton?.value.text}
              </UILink>
            </StFooterbuttons>
          </StmodalContainer>
        </StModalContainerWithModal>
      </Modal>
    </div>
  );
};

export default ExitFormDisclaimer;
