import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export const StModalContainerWithModal = styled(Box)(({ theme }) => {
  return {
    padding: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    background: theme.palette.white.main,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:before': {
      content: '""',
      display: 'block',
      background: `linear-gradient(268.67deg, ${theme.palette.themeColorSecondary.main} -16.55%, ${theme.palette.themeColor.main} 73.37%)`,
      height: '16px',
      borderRadius: '16px 16px 0px 0px',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '&:focus': {
      outline: '0 !important',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '521px !important',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '465px !important',
      maxWidth: '500px !important',
    },
    '@media screen and (max-width:600px)': {
      minWidth: '332px !important',
    },
    '.merge-tag': {
      p: {
        color: '#00839A',
      },
    },
  };
});

export const StmodalContainer = styled(Box)(() => ({
  padding: '24px',
}));

export const StHeadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '@media screen and (max-width:600px)': {
    padding: '1px !important',
  },
}));

export const StHeadingText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '20px',
  color: '#000000',
}));

const CRModal = ({ open, handleClose, title, children }: any) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StModalContainerWithModal>
        <StmodalContainer>
          <StHeadingContainer>
            <Grid>
              <StHeadingText id="modal-modal-title" variant="h6">
                {title}
              </StHeadingText>
            </Grid>
            <IconButton
              sx={{ top: '-16px' }}
              color="inherit"
              aria-label="close modal button"
              edge="start"
              onClick={handleClose}
              data-testid="exitform-disclaimer-image"
            >
              <CloseIcon sx={{ color: theme.palette.neutral11.main }} />
            </IconButton>
          </StHeadingContainer>

          <Grid container>{children}</Grid>
        </StmodalContainer>
      </StModalContainerWithModal>
    </Modal>
  );
};

export default CRModal;
