import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ArticleType } from 'components/shared/Carousel/Carousel.types';
import CarouselWrapper from 'components/shared/Carousel/CarouselWrapper/CarouselWrapper';

const ArticleCarousel = (props: ArticleType): JSX.Element => {
  const theme = useTheme();
  if (!props?.fields) return <></>;
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.white.main,
        maxWidth: '1365px',
        marginInline: 'auto',
      }}
      id="article-carousel"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '48px 41px',
          maxWidth: '1400px',
          marginInline: 'auto',
        }}
      >
        <Typography
          role="heading"
          aria-level={2}
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontFamily: `"Proxima Nova", sans-serif`,
            fontSize: '32px',
            lineHeight: '24px',
            marginBottom: '1rem',
            color: (theme) => theme.palette.common.black,
          }}
        >
          <Text field={props?.fields?.sectionTitle} />
        </Typography>
        <CarouselWrapper {...props} callerComponent="Articles" />
      </Box>
    </Box>
  );
};

export default ArticleCarousel;
