import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Text, NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  StBox,
  StHeadingTypography,
  StRichText,
  CriteriaRichText,
  StTypography,
} from './ViewTrials.styles';
import { mergeTag } from '../../../lib/utils/common';

const ViewTrials: React.FC<any> = (props) => {
  const [inclusionExclusionPostTextWithTag, setInclusionExclusionPostTextWithTag] = useState<
    string | ''
  >('');
  const { sitecoreContext } = useSitecoreContext();
  const route: any = sitecoreContext?.route;
  const aboutStudyIcon = props?.fields?.aboutStudyIcon;
  const aboutStudyText = props?.fields?.aboutStudyText;
  const whoCanTakePartIcon = props?.fields?.whoCanTakePartIcon;
  const whoCanTakePartText = props?.fields?.whoCanTakePartText;
  const inclusionExclusionPreText = props?.fields?.inclusionExclusionPreText;
  const inclusionHeadingText = props?.fields?.inclusionHeadingText;
  const exclusionHeadingText = props?.fields?.exclusionHeadingText;
  const inclusionExclusionPostText = props?.fields?.inclusionExclusionPostText;
  const exclusionCriteria = route?.fields?.exclusionCriteria;
  const inclusionCriteria = route?.fields?.inclusionCriteria;
  const nctid = route?.name;
  const showWhoCanTakePartSection =
    inclusionCriteria?.value !== '' || exclusionCriteria?.value !== '';
  useEffect(() => {
    if (nctid === undefined) return;
    const textWithTag = mergeTag(inclusionExclusionPostText.value, {
      nctid: nctid,
    });

    setInclusionExclusionPostTextWithTag(textWithTag);
  }, [inclusionExclusionPostText.value, nctid]);

  return (
    <Grid sx={{ marginBottom: '1rem' }}>
      <Grid>
        <Grid>
          <StBox>
            {aboutStudyIcon?.value?.src && <NextImage field={aboutStudyIcon} />}
            <StTypography>
              <Text field={aboutStudyText} />
            </StTypography>
          </StBox>
          <StRichText field={route?.fields?.trialDescription} />
        </Grid>
        {showWhoCanTakePartSection && (
          <Grid id="who-can-take-part">
            <StBox>
              {whoCanTakePartIcon?.value?.src && <NextImage field={whoCanTakePartIcon} />}
              <StTypography>
                <Text field={whoCanTakePartText} />
              </StTypography>
            </StBox>
            <Grid sx={{ fontSize: '16px', fontWeight: 600 }}>
              <StRichText field={inclusionExclusionPreText} />
            </Grid>
            {inclusionCriteria?.value !== '' && (
              <Grid id="inclusion-criteria">
                <StBox>
                  <StHeadingTypography>
                    <Text field={inclusionHeadingText} />
                  </StHeadingTypography>
                </StBox>
                <CriteriaRichText field={inclusionCriteria} />
              </Grid>
            )}
            {exclusionCriteria?.value !== '' && (
              <Grid id="exclusion-criteria">
                <StBox>
                  <StHeadingTypography>
                    <Text field={exclusionHeadingText} />
                  </StHeadingTypography>
                </StBox>
                <CriteriaRichText field={exclusionCriteria} />
              </Grid>
            )}
            {nctid && <StRichText field={{ value: inclusionExclusionPostTextWithTag }} />}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ViewTrials;
