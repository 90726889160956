import { ErrorMessage } from '@hookform/error-message';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Image from 'next/image';
import { Controller } from 'react-hook-form';
import { StCheckBoxLabel, StFormLabel, StInputBase } from '../FormElements.styles';
import {
  InputBaseProps,
  InputCheckBoxProps,
  InputLabelProps,
  InputProps,
} from '../FormElements.types';
import FormToolTip from '../FormToolTip';
import { getDialingCodeByISOCode } from '../../../../lib/utils/dialingCodeList';

const InputCheckBox = ({
  isMobileLabel,
  control,
  errors,
  mobileNumberValidationMessageOnSMSConsent,
}: InputCheckBoxProps) => {
  const theme = useTheme();
  return (
    <Controller
      name={'isMobile'}
      control={control}
      rules={{
        validate: async (_value: any, formValues: any) => {
          const isPrimarySMSConsentVisible: any = document.querySelector(
            `input[name="PrimaryConsentMediumSMS"]`
          );
          const isSecondarySMSConsentVisible: any = document.querySelector(
            `input[name="SecondaryConsentMediumSMS"]`
          );
          const isCombinedSMSConsentVisible: any = document.querySelector(
            `input[name="CombinedConsentMediumSMS"]`
          );
          if (
            !!_value === false &&
            ((formValues?.PrimaryConsentMediumSMS &&
              isPrimarySMSConsentVisible?.type === 'checkbox') ||
              (formValues?.SecondaryConsentMediumSMS &&
                isSecondarySMSConsentVisible?.type === 'checkbox') ||
              (formValues?.CombinedConsentMediumSMS &&
                isCombinedSMSConsentVisible?.type === 'checkbox'))
          ) {
            return mobileNumberValidationMessageOnSMSConsent;
          }
          return true;
        },
      }}
      render={({ field: { onChange, value, onBlur, ...rest } }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!value}
                {...rest}
                sx={{
                  padding: '0 2px',
                  alignSelf: 'flex-start',
                  background: '#ffffff',
                  '& .MuiSvgIcon-root': {
                    width: '20px',
                    height: '20px',
                  },
                  color: errors['isMobile'] && `${theme.palette.error.main} !important`,
                }}
              />
            }
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            label={
              <StCheckBoxLabel>
                <Text field={isMobileLabel} />
              </StCheckBoxLabel>
            }
            sx={{
              margin: 0,
            }}
          />
        );
      }}
    />
  );
};

const InputLabel = ({
  name,
  label,
  toolTip,
  showIsMobileOption,
  isMobileLabel,
  control,
  errors,
  mobileNumberValidationMessageOnSMSConsent,
}: InputLabelProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <StFormLabel htmlFor={name}>
      <Text field={label} />
      {toolTip && <FormToolTip value={toolTip} />}
    </StFormLabel>
    {showIsMobileOption && showIsMobileOption.value ? (
      <InputCheckBox
        isMobileLabel={isMobileLabel}
        control={control}
        errors={errors}
        mobileNumberValidationMessageOnSMSConsent={mobileNumberValidationMessageOnSMSConsent}
      />
    ) : (
      ''
    )}
  </Box>
);

const InputBase = ({
  name,
  placeholder,
  onChangeHandler,
  value,
  errors,
  countryFlag,
  countryCode,
  maxLength,
  disabled,
  onBlur,
  showIsMobileOption,
}: InputBaseProps) => (
  <StInputBase
    name={name}
    data-testid={name}
    id={name}
    aria-describedby={placeholder}
    fullWidth
    disabled={disabled}
    placeholder={placeholder}
    value={value}
    onChange={onChangeHandler}
    startAdornment={
      showIsMobileOption && (
        <InputAdornment position="start">
          <Image src={countryFlag} alt="CF" height={15} width={22} />
          {countryCode && (
            <Box sx={{ marginInline: '4px' }}>{getDialingCodeByISOCode(countryCode)}</Box>
          )}
        </InputAdornment>
      )
    }
    inputProps={{
      maxLength: maxLength,
      onBlur: () => {
        onBlur();
      },
    }}
    sx={{
      border: (theme: Theme) =>
        errors && errors[name]
          ? `1px solid ${theme.palette.error.main} !important`
          : `1px solid ${theme.palette.neutral4.main}`,
      padding: '5px 8px !important',
    }}
  />
);

const Input = ({
  name,
  toolTip,
  placeholder,
  label,
  onChangeHandler,
  value,
  errors,
  countryFlag,
  countryCode,
  maxLength,
  disabled,
  onBlur,
  showIsMobileOption,
  isMobileLabel,
  control,
  mobileNumberValidationMessageOnSMSConsent,
}: InputProps) => (
  <FormControl fullWidth>
    <InputLabel
      name={name}
      label={label}
      toolTip={toolTip}
      showIsMobileOption={showIsMobileOption}
      isMobileLabel={isMobileLabel}
      control={control}
      errors={errors}
      mobileNumberValidationMessageOnSMSConsent={mobileNumberValidationMessageOnSMSConsent}
    />
    <InputBase
      placeholder={placeholder}
      onChangeHandler={onChangeHandler}
      value={value}
      countryFlag={countryFlag}
      countryCode={countryCode}
      maxLength={maxLength}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      errors={errors}
      showIsMobileOption={showIsMobileOption}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Box
            data-testid={`${name}-error`}
            sx={{ color: (theme) => theme.palette.error.main, fontSize: '0.8125rem' }}
          >
            {message}
          </Box>
        )}
      />
    )}
    {errors && errors['isMobile'] && showIsMobileOption && (
      <ErrorMessage
        errors={errors}
        name={'isMobile'}
        render={({ message }) => (
          <Box
            data-testid={`${'isMobile'}-error`}
            sx={{ color: (theme) => theme.palette.error.main, fontSize: '0.8125rem' }}
          >
            {message}
          </Box>
        )}
      />
    )}
  </FormControl>
);

export { Input };
