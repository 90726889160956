import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { StNextImage } from '../../../components/shared/NextImage.styles';
import { IContactInfoHeader } from './ContactinfoHeader.types';
import RichTextWithStudyPnMergeTag from '../../shared/RichTextWithStudyPnMergeTag/RichTextWithStudyPnMergeTag';

const ContactInfoHeaderWithIcon = (props: IContactInfoHeader): JSX.Element => {
  const theme = useTheme();
  const cdpState = useSelector((state: RootState) => state.cdpData);
  if (!props?.fields) return <></>;
  if (
    !cdpState?.isDnq &&
    !cdpState?.isNoSitesAvailable &&
    !cdpState?.isNoSitesConvenient &&
    !cdpState?.isNoSitesAvailableMaxCapacity &&
    !cdpState?.isQualified
  ) {
    return <></>;
  }
  const {
    dnqBlock,
    noSitesAvailableBlock,
    noSitesConvenientBlock,
    noSitesAvailableMaxCapBlock,
    qualifiedTextBlock,
  } = props?.fields;

  return (
    <Grid container flexDirection={'column'}>
      {((cdpState?.isDnq && dnqBlock?.fields?.showHeaderIconAndTitle?.value) ||
        (cdpState?.isNoSitesAvailable &&
          noSitesAvailableBlock?.fields?.showHeaderIconAndTitle?.value) ||
        (cdpState?.isNoSitesConvenient &&
          noSitesConvenientBlock?.fields?.showHeaderIconAndTitle?.value) ||
        (cdpState?.isNoSitesAvailableMaxCapacity &&
          noSitesAvailableMaxCapBlock?.fields?.showHeaderIconAndTitle?.value) ||
        (cdpState?.isQualified && qualifiedTextBlock?.fields?.showHeaderIconAndTitle?.value)) && (
        <Grid
          item
          container
          flexDirection={'row'}
          sx={{
            padding: '1.5rem 2rem',
            background: (theme) => theme.palette.themeLightColor.main,
            borderRadius: '0.5rem',
            alignItems: 'center',
          }}
        >
          <Grid item sx={{ flexBasis: '60', marginRight: '1.5rem' }}>
            {cdpState?.isDnq && (
              <StNextImage
                suppressHydrationWarning
                field={dnqBlock?.fields?.headerIcon}
                width={60}
                height={75}
              />
            )}
            {cdpState?.isNoSitesAvailable && (
              <StNextImage
                suppressHydrationWarning
                field={noSitesAvailableBlock?.fields?.headerIcon}
                width={60}
                height={75}
              />
            )}
            {cdpState?.isNoSitesConvenient && (
              <StNextImage
                suppressHydrationWarning
                field={noSitesConvenientBlock?.fields?.headerIcon}
                width={60}
                height={75}
              />
            )}
            {cdpState?.isNoSitesAvailableMaxCapacity && (
              <StNextImage
                suppressHydrationWarning
                field={noSitesAvailableMaxCapBlock?.fields?.headerIcon}
                width={60}
                height={75}
              />
            )}
            {cdpState?.isQualified && (
              <StNextImage
                suppressHydrationWarning
                field={qualifiedTextBlock?.fields?.headerIcon}
                width={60}
                height={75}
              />
            )}
          </Grid>
          <Grid
            item
            sx={{
              flex: 1,
              color: (theme) => theme.palette.themeColor.main,
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '2rem',
            }}
          >
            {cdpState?.isDnq && (
              <RichTextWithStudyPnMergeTag
                field={dnqBlock?.fields?.headerTitle}
                inheritLinkColor={true}
              ></RichTextWithStudyPnMergeTag>
            )}
            {cdpState?.isNoSitesAvailable && (
              <RichTextWithStudyPnMergeTag
                field={noSitesAvailableBlock?.fields?.headerTitle}
                inheritLinkColor={true}
              ></RichTextWithStudyPnMergeTag>
            )}
            {cdpState?.isNoSitesConvenient && (
              <RichTextWithStudyPnMergeTag
                field={noSitesConvenientBlock?.fields?.headerTitle}
                inheritLinkColor={true}
              ></RichTextWithStudyPnMergeTag>
            )}
            {cdpState?.isNoSitesAvailableMaxCapacity && (
              <RichTextWithStudyPnMergeTag
                field={noSitesAvailableMaxCapBlock?.fields?.headerTitle}
                inheritLinkColor={true}
              ></RichTextWithStudyPnMergeTag>
            )}
            {cdpState?.isQualified && (
              <RichTextWithStudyPnMergeTag
                field={qualifiedTextBlock?.fields?.headerTitle}
                inheritLinkColor={true}
              ></RichTextWithStudyPnMergeTag>
            )}
          </Grid>
        </Grid>
      )}
      {((cdpState?.isDnq && dnqBlock?.fields?.showHeaderMessage?.value) ||
        (cdpState?.isNoSitesAvailable && noSitesAvailableBlock?.fields?.showHeaderMessage?.value) ||
        (cdpState?.isNoSitesConvenient &&
          noSitesConvenientBlock?.fields?.showHeaderMessage?.value) ||
        (cdpState?.isNoSitesAvailableMaxCapacity &&
          noSitesAvailableMaxCapBlock?.fields?.showHeaderMessage?.value) ||
        (cdpState?.isQualified && qualifiedTextBlock?.fields?.showHeaderMessage?.value)) && (
        <Grid
          sx={{
            color: (theme) => theme.palette.themeColor.main,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '1.5rem',
            padding: '20px 0',
            [theme.breakpoints.down('md')]: {
              padding: '20px 20px',
            },
          }}
        >
          {cdpState?.isDnq && (
            <RichTextWithStudyPnMergeTag
              field={dnqBlock?.fields?.headerMsg}
              inheritLinkColor={true}
            ></RichTextWithStudyPnMergeTag>
          )}
          {cdpState?.isNoSitesAvailable && (
            <RichTextWithStudyPnMergeTag
              field={noSitesAvailableBlock?.fields?.headerMsg}
              inheritLinkColor={true}
            ></RichTextWithStudyPnMergeTag>
          )}
          {cdpState?.isNoSitesConvenient && (
            <RichTextWithStudyPnMergeTag
              field={noSitesConvenientBlock?.fields?.headerMsg}
              inheritLinkColor={true}
            ></RichTextWithStudyPnMergeTag>
          )}
          {cdpState?.isNoSitesAvailableMaxCapacity && (
            <RichTextWithStudyPnMergeTag
              field={noSitesAvailableMaxCapBlock?.fields?.headerMsg}
              inheritLinkColor={true}
            ></RichTextWithStudyPnMergeTag>
          )}
          {cdpState?.isQualified && (
            <RichTextWithStudyPnMergeTag
              field={qualifiedTextBlock?.fields?.headerMsg}
              inheritLinkColor={true}
            ></RichTextWithStudyPnMergeTag>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ContactInfoHeaderWithIcon;
