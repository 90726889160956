interface Field {
  name: string;
  jsonValue: {
    value: any;
  };
}

interface Community {
  fields: Field[];
}

interface ResultObject {
  name: string;
  [fieldName: string]: any;
}

interface Response {
  data: {
    pageOne: {
      results: Community[];
    };
  };
}

export const getFieldsByNames = (response: Response, fieldNames: string[]): ResultObject[] => {
  const communities = response.data.pageOne.results;
  const resultArray: ResultObject[] = [];

  communities.forEach((community) => {
    const fields = community.fields;
    const resultObject: ResultObject = {
      name: community.fields.find((f) => f.name === 'name').jsonValue.value,
    };

    // Iterate through the specified field names
    fieldNames.forEach((fieldName) => {
      // Find the field with the specified name
      const field = fields.find((field) => field.name === fieldName);

      // If the field is found, add it to the result object
      if (field) {
        resultObject[fieldName] = field.jsonValue.value;
      }
    });

    // Add the result object to the array
    resultArray.push(resultObject);
  });

  return resultArray;
};

export const getFieldsByName = (data: any, fieldName: string): any => {
  return data?.fields?.find((field: any) => field.name === fieldName);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mergeTag = (text: string, dataObject: any): string => {
  const regex = /\[(.*?)\]/g;

  const modifiedText = text.replace(regex, (match, fieldName) => {
    if (dataObject?.hasOwnProperty(fieldName)) {
      return dataObject[fieldName];
    } else {
      return match;
    }
  });

  return modifiedText;
};
