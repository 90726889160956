import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const StExploreYourProfileWrapper = styled(Box)(({ theme }) => ({
  boxShadow: '0px 4px 4px 0px #00000040',
  borderRadius: '8px',
  margin: '1.5rem auto',
  padding: '24px 52.5px',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2.75),
  alignItems: 'flex-start',
  width: '502px',
  maxWidth: '100%',

  [theme.breakpoints.down('md')]: {
    width: '350px',
    maxWidth: '100%',
    padding: '24px 21px',
  },
}));

export const StExploreYourProfileTitle = styled(Typography)(() => ({
  height: '89px',
  alignItems: 'left',
}));

export const StExploreYourProfileStack = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  flexDirection: 'column',
  '& .MuiTypography-subtitle1': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiButton-containedPrimary': {
    minWidth: '212px',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiButton-containedPrimary': {
      minWidth: '192px',
    },
  },

  [theme.breakpoints.down(400)]: {
    '& .MuiButton-containedPrimary': {
      width: '162px',
      textWrap: 'nowrap',
      minWidth: 'auto',
    },
  },
}));
