import React from 'react';
import { RichText, ImageField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StNextImage } from '../NextImage.styles';
import { StContainer } from './IconWithDescriptionBlock.styles';

interface IconWithDescription {
  fields: {
    bulletIcon: ImageField;
    description: RichTextField;
  };
}

type IconWithDescriptionProps = {
  bulletDescriptionList: IconWithDescription[];
  isOneColumn: boolean;
};

const IconWithDescriptionBlock = (props: IconWithDescriptionProps): JSX.Element => {
  return (
    <>
      {props?.bulletDescriptionList &&
        props?.bulletDescriptionList.map((bulletDescriptionSection, index) => (
          <StContainer container key={index}>
            <Grid item xs={1} sm={1} md={0.6} lg={!props?.isOneColumn ? 0.6 : 0.3}>
              <StNextImage
                suppressHydrationWarning
                field={bulletDescriptionSection?.fields?.bulletIcon}
              />
            </Grid>
            <Grid item xs={11} sm={11} md={11.4} lg={!props?.isOneColumn ? 11.4 : 11.7}>
              <Typography variant="description">
                <RichText field={bulletDescriptionSection?.fields?.description} />
              </Typography>
            </Grid>
          </StContainer>
        ))}
    </>
  );
};

export default IconWithDescriptionBlock;
