import { post } from '../lib/utils/axiosCDPFetcher';
import { store } from '../store/store';
import { SCREENER_STATUS } from '../constants';
import { IScreenerOutput } from '../features/screenerOutputSlice';
import { PatientType } from '../lib/type/userJourney.types';
import { getOrCreateEnrollmentId } from '../lib/utils/enrollmentUtil';

//currenty using this for j1 and j2, happy flow
export const checkReturningUser = async (
  isSubmit: boolean,
  tenantKey: string,
  patientType: string,
  email: string,
  returningUserTimeInterval?: string,
  returningUserTimeValue?: string,
  participantFirstName?: string,
  participantLastName?: string,
  participantDateOfBirth?: string,
  isResumeEmailCheck?: boolean,
  isReturnCheck?: boolean,
  pos?: string,
  isJourney3?: boolean,
  guestType?: string
): Promise<any> => {
  const state = store.getState();
  const payload = {
    isSubmit,
    tenantKey,
    currentGuestRef: state?.potentialParticipantDetails?.potentialParticipant_ID,
    currentEnrollment: getOrCreateEnrollmentId(),
    patientType: patientType ? patientType : state?.contactInfoDetails?.patient?.patientType,
    email: email,
    currentScreenerStatus:
      state?.screenerOutput?.pSec || state?.cdpData?.qualifiedProtocol
        ? SCREENER_STATUS.QUALIFIED
        : SCREENER_STATUS.DISQUALIFIED,
    qualifiedProtocol: state?.screenerOutput?.pSec
      ? state?.screenerOutput?.pSec
      : state?.cdpData?.qualifiedProtocol
      ? state?.cdpData?.qualifiedProtocol
      : '',
    participantFirstName: participantFirstName || '',
    participantLastName: participantLastName || '',
    participantDateOfBirth: participantDateOfBirth || '',
    returningUserTimeInterval: returningUserTimeInterval || '',
    returningUserTimeValue: returningUserTimeValue || '',
    isResumeEmailCheck,
    isReturnCheck,
    pos,
    isJourney3,
  };
  try {
    // Make the API call to fetch CDP Data
    const response = await post(
      `/api/contacts/CheckResumingReturningUser?guestType=${guestType || 'customer'}`,
      payload
    );
    return response;
  } catch (_error) {}
};

const getEmailForReturningUser = (state: any) => {
  let email;
  if (state?.contactInfoDetails?.patient?.patientType) {
    email =
      state?.contactInfoDetails?.patient?.patientType === PatientType.ADULT
        ? state?.contactInfoDetails?.patient?.email
        : state?.contactInfoDetails?.patient?.patientType === PatientType.MINOR ||
          state?.contactInfoDetails?.patient?.patientType === PatientType.CAREGIVER
        ? state?.contactInfoDetails?.delegate?.emailAddress
        : '';
  } else {
    email = state?.cdpData?.email || '';
  }
  return email;
};

const getParticipantDetailForReturningUser = (state: any, fieldType: string) => {
  switch (fieldType) {
    case 'firstname':
      return state?.contactInfoDetails?.patient?.firstName
        ? state?.contactInfoDetails?.patient?.firstName
        : state?.cdpData?.participantFirstName
        ? state?.cdpData?.participantFirstName
        : '';
    case 'lastname':
      return state?.contactInfoDetails?.patient?.lastName
        ? state?.contactInfoDetails?.patient?.lastName
        : state?.cdpData?.participantLastName
        ? state?.cdpData?.participantLastName
        : '';
    case 'dob':
      return state?.contactInfoDetails?.patient?.dateofbirth
        ? state?.contactInfoDetails?.patient?.dateofbirth
        : state?.cdpData?.participantDateOfBirth
        ? state?.cdpData?.participantDateOfBirth
        : '';
  }
};

export const checkReturningUserInScreener = async (
  isSubmit: boolean,
  tenantKey: string,
  screenerData: IScreenerOutput,
  returningUserTimeInterval?: string,
  returningUserTimeValue?: string,
  defaultPatientType?: string,
  guestType?: string
): Promise<any> => {
  const state = store.getState();
  const payload = {
    isSubmit,
    tenantKey,
    currentGuestRef: state?.potentialParticipantDetails?.potentialParticipant_ID,
    currentEnrollment: getOrCreateEnrollmentId(),
    patientType: screenerData.patientType || defaultPatientType,
    email: getEmailForReturningUser(state),
    currentScreenerStatus: screenerData?.pSec
      ? SCREENER_STATUS.QUALIFIED
      : SCREENER_STATUS.DISQUALIFIED,
    qualifiedProtocol: screenerData?.pSec ? screenerData?.pSec : '',
    participantFirstName: getParticipantDetailForReturningUser(state, 'firstname'),
    participantLastName: getParticipantDetailForReturningUser(state, 'lastname'),
    participantDateOfBirth: getParticipantDetailForReturningUser(state, 'dob'),
    returningUserTimeInterval,
    returningUserTimeValue,
    isResumeEmailCheck: false,
    isReturnCheck: true,
    isJourney3: true,
    isScreenerSubmit: true,
    pos: '',
  };
  try {
    // Make the API call to fetch CDP Data
    const response = await post(
      `/api/contacts/CheckResumingReturningUser?guestType=${guestType || 'customer'}`,
      payload
    );
    return response;
  } catch (_error) {}
};
