import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { GetServerSideComponentProps, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import CardWithIcon from '../CardWithIcon/CardWithIcon';
import HeadingMessage from '../HeadingMessage/HeadingMessage';
import SelectedSitesContainer from '../SelectedSitesContainer/SelectedSitesContainer';
import { convertCdpComponentData, fetchComponentData } from '../Utils/success-page-utils';
import { StDescription, StHeading, StSubHeading } from './NextStep.styles';
import { NextStepAdvancedSectionsType, NextStepAdvancedType } from './NextStep.type';

const NextStepAdvanced = (props: NextStepAdvancedType): JSX.Element => {
  const { show } = props;
  if (!props?.fields) return <></>;

  let { fields } = props;

  if (!show) {
    return <></>;
  }

  if (Object.keys(fields).length === 0) {
    fields = props.fields;
  }
  const stepCount = props.fields?.nextStepAdvancedSectionsList.length;
  const renderChild = (component: string, componentProps: any) => {
    switch (component) {
      case 'CardWithIcon':
        return <CardWithIcon {...componentProps} />;
      case 'HeadingMessage':
        return <HeadingMessage {...componentProps} />;
      case 'SelectedSitesContainer':
        return <SelectedSitesContainer {...componentProps} />;
      default:
        return <></>;
    }
  };
  return (
    <Box sx={{ marginBlock: '1rem' }}>
      <StHeading>
        <Text field={props.fields.nextStepAdvancedHead} />
      </StHeading>
      <Grid container>
        {props.fields?.nextStepAdvancedSectionsList?.map(
          (data: NextStepAdvancedSectionsType, index: number) => {
            return (
              <Grid item key={index}>
                <StSubHeading>
                  <Text field={data?.fields?.sectionSubheading} />
                </StSubHeading>
                <StDescription>
                  <RichText field={data?.fields?.sectionDescription} />
                </StDescription>
                {renderChild(
                  data?.fields?.relatedComponentName?.value as string,
                  data?.fields?.relatedComponentData[0]
                )}
                <StDescription>
                  <RichText field={data?.fields?.additionalInfo} />
                </StDescription>
                {index !== stepCount - 1 && (
                  <Box sx={{ marginBlock: '2rem' }}>
                    <Divider sx={{ background: `rgba(255, 255, 255, 0.00)` }} />
                  </Box>
                )}
              </Grid>
            );
          }
        )}
      </Grid>
    </Box>
  );
};

export default NextStepAdvanced;

export const getServerSideProps: GetServerSideComponentProps = async (
  context: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return { show: true };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;
  const cdpComponentData = convertCdpComponentData(cdpData.componentData);
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  //Modify component props based on CDP output
  const componentProps = await fetchComponentData(
    cdpComponentData,
    language,
    cdpData.componentsToHide,
    'NextStepAdvanced'
  );

  return { ...componentProps, confirmedsitesData: cdpData.confirmedsitesData };
};
