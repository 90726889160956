import { SearchBox as HeadlessSearchBox, SearchBoxState } from '@coveo/headless';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { FunctionComponent, SyntheticEvent, forwardRef, useEffect, useState } from 'react';
import { AutoCompleteInput } from './HeroAndSearch.styles';
import { isEditorActive, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface SearchBoxProps {
  searchBox: HeadlessSearchBox;
  onChange: any;
  value: any;
  fieldID: string;
  placeholder: string;
  coiInputTextLimit?: number;
  style?: any;
  lengthToSuggestion?: number;
  errors: any;
  setValue: any;
}

const SearchFormInput: FunctionComponent<SearchBoxProps> = forwardRef(
  (
    {
      searchBox,
      onChange,
      fieldID,
      placeholder,
      coiInputTextLimit,
      style,
      lengthToSuggestion,
      errors,
      setValue,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const [state, setState] = useState<SearchBoxState>(searchBox.state);
    const [conditionValue, setConditionValue] = useState('');
    const LENGTH_TO_SUGGESTIONS = lengthToSuggestion || 3;
    //const customStyles = props.style || {};
    const suggestOptions =
      conditionValue.length >= LENGTH_TO_SUGGESTIONS
        ? state.suggestions.map((s) => s.rawValue)
        : [];
    const coiInputTextLimitValue = coiInputTextLimit || 50;
    const { sitecoreContext } = useSitecoreContext();

    const onInputChange = (_: SyntheticEvent<Element, Event>, value: string) => {
      if (value.length > coiInputTextLimitValue) {
        return;
      }
      setConditionValue(value);
      searchBox.updateText(value);
      searchBox.subscribe(() => {
        setState(searchBox.state);
      });
      setValue('hero-and-search-coi', value);
    };

    useEffect(() => {
      if (isEditorActive() || sitecoreContext?.pageState === 'preview') return;
      searchBox.showSuggestions();
      const subscribe = searchBox.subscribe(() => {
        setState(searchBox.state);
      });

      return () => subscribe();
    }, [searchBox]);

    return (
      <AutoCompleteInput
        {...rest}
        options={suggestOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params?.inputProps,
              value: conditionValue,
              'aria-label': 'condition of interest',
              'aria-required': 'true',
            }}
            placeholder={placeholder}
            error={Boolean(errors?.['hero-and-search-coi'])}
            helperText={String((errors && errors?.['hero-and-search-coi']?.message) || '')}
          />
        )}
        size="small"
        freeSolo
        disableClearable
        id={fieldID}
        className="auto-complete-text"
        value={conditionValue}
        onInputChange={onInputChange}
        onChange={onChange}
        style={style}
        sx={{
          width: '100%',
          '& input::placeholder': {
            fontWeight: 400,
            color: `${theme.palette.neutral11.main}`,
            opacity: 1,
            fontSize: '13px',
            lineHeight: '16px',
            fontFamily: 'Proxima Nova',
          },
          '& div, input': {
            color: `${theme.palette.neutral11.main}`,
            opacity: 1,
            borderRadius: '8px',
            fontFamily: 'Proxima Nova',
            fontSize: '14px',
            lineHeight: '24px',
          },
          '& fieldset': {
            borderColor: '#e9e9e9',
          },
        }}
        ref={ref}
      />
    );
  }
);
SearchFormInput.displayName = 'SearchFormInput';

export default SearchFormInput;
