import { GetServerSideComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import OutComePageContent from './OutComePageContent';

const OutComePageBase = (props: any): JSX.Element => {
  const renderingProps = props?.props?.rendering;
  return (
    <OutComePageContent
      hideRightPanel={props?.props?.hideRightPanel}
      renderingProps={renderingProps}
    />
  );
};

export const getServerSideProps: GetServerSideComponentProps = async (
  rendering: any,
  layoutData: any
) => {
  if (layoutData?.sitecore?.context?.pageEditing) {
    return {
      props: {
        rendering: rendering,
        hideRightPanel: false,
      },
    };
  }
  const cdpData = layoutData?.sitecore?.context?.CDP as any;

  const hideRightPanel = cdpData.componentsToHide['ShareYourHealthData'] === 'null' ? true : false;

  const componentProps: any = {};
  componentProps.rendering = rendering;
  componentProps.hideRightPanel = hideRightPanel;

  return {
    props: componentProps,
    // notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default OutComePageBase;
