import axios from 'axios';
import { post } from '../../../lib/utils/axiosFetcher';
import { IPayload, IUpdateEmailPayload } from './UpdateEmail';

export const updateEmailCDP = async (email: string, guestref: string): Promise<any> => {
  try {
    const url = `/api/contacts/UpdateEmail`;
    const cdpRes = await axios.post('/api/cdp-api', {
      url,
      data: { email, guestRef: guestref },
    });
    return cdpRes;
  } catch (error) {
    return error;
  }
};

export const callDapApiSendEmail = async (payload: IPayload): Promise<boolean> => {
  try {
    await post('/Send/AddContactsToCRMailingList', payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const callDapApiSendUpdateEmail = async (payload: IUpdateEmailPayload): Promise<boolean> => {
  try {
    await post('/Send/UpdateCREmail', payload);
    return true;
  } catch (error) {
    return false;
  }
};
