import { useTheme } from '@mui/material/styles';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          borderRadius: '100%',
          boxShadow: `inset 0 0 0 3.2px ${theme.palette.neutral4.main}`,
          backgroundColor: 'transparent',
        }}
        aria-label="Progress Bar"
      />
      {/* <CircularProgress variant="determinate" {...props} /> */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color={theme.palette.themeColor.main}
          sx={{
            fontFamily: `"Proxima Nova", sans-serif`,
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '20px',
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function CircularProgressBar(props: any) {
  const progress = (props?.currentPage / props?.totalCount) * 100;

  return <CircularProgressWithLabel value={progress} />;
}
