import { styled } from '@mui/material/styles';
// UI anchor link that looks like a button. Use it where UI doesn't rely on backend to provide href
export const UILink = styled('a', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'fullWidth',
})<{ variant?: string; fullWidth?: boolean }>(({ theme, variant, fullWidth }) => ({
  display: 'inline-block',
  fontFamily: 'Proxima Nova SemiBold',
  fontSize: '1rem',
  fontStyle: 'normal',
  lineHeight: '2.5rem',
  cursor: 'pointer',
  color: variant === 'contained' ? theme.palette.white.main : theme.palette.primary.main,
  textDecoration: 'none',
  textTransform: 'none',
  borderRadius: variant === 'contained' || variant === 'outlined' ? '50px' : '0px',
  height: 'auto',
  minWidth: fullWidth
    ? '100%'
    : variant === 'contained' || variant === 'outlined'
    ? '205px'
    : '0px',
  textAlign: 'center',
  padding: variant === 'contained' || variant === 'outlined' ? '0px 40px' : '0px',
  border:
    variant === 'contained'
      ? `1px solid ${theme.palette.primary.main}`
      : variant === 'outlined'
      ? `1px solid ${theme.palette.neutral4.main}`
      : 'none',
  background:
    variant === 'contained'
      ? theme.palette.primary.main
      : variant === 'outlined'
      ? theme.palette.secondary.main
      : 'none',
  filter:
    variant === 'contained'
      ? 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.50))'
      : 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08))',
  '&:hover': {
    background:
      variant === 'contained'
        ? theme.palette.primary.dark
        : variant === 'outlined'
        ? theme.palette.secondary.dark
        : 'none',
    textDecoration: 'none',
    border: variant === 'outlined' ? `1px solid ${theme.palette.primary.main}` : 'none',
    borderBottom:
      variant === 'contained' || variant === 'outlined'
        ? `1px solid ${theme.palette.primary.main}`
        : '1px dashed',
    color: variant === 'contained' ? theme.palette.white.main : theme.palette.primary.main,
  },
}));
