import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StyledDivider, StTextContainer } from '../Subscribe.styles';
import { StDTPGridContainer } from '../../shared/Container/Container.styles';
import { SubscribeProps } from '../Subscribe.types';

const Resubscribe = (props: SubscribeProps): JSX.Element => {
  const theme = useTheme();
  if (!props?.fields) return <></>;
  const { heading, content } = props?.fields;

  return (
    <StDTPGridContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap="2rem"
        sx={{ marginBlock: '2rem' }}
      >
        <Typography
          variant="h1"
          data-testid="title"
          textAlign="center"
          sx={{
            display: 'flex',
            color: theme.palette.themeColor.main,
            fontSize: '2rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '2.5rem',
            [theme.breakpoints.down('md')]: {
              fontSize: '2rem',
            },
          }}
        >
          <Text field={heading} />
        </Typography>
        <StyledDivider />
        <StTextContainer>
          <Box
            data-testid="content"
            textAlign="center"
            sx={{
              color: (theme: any) => theme.palette.themeColor.main,
              fontSize: '1.5rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '2rem',
            }}
          >
            <RichText field={content} />
          </Box>
        </StTextContainer>
      </Box>
    </StDTPGridContainer>
  );
};

export default Resubscribe;
