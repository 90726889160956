import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
export const ContactInfoBox = styled(Box)(({ theme }) => ({
  paddingTop: '10px',
  [theme.breakpoints.down('lg')]: {
    padding: '1rem 2rem',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0.5rem 1.2rem',
  },
}));

type StDivProps = {
  isMobile: boolean;
};

export const StFormContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StDivProps>(({ theme, isMobile }) => ({
  color: theme.palette.neutral8.main,
  background: isMobile ? 'none' : theme.palette.neutral0.main,
  border: isMobile ? 'none' : '1px solid #D9D9D9',
  boxShadow: isMobile ? 'none' : '0px 2px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: isMobile ? 'none' : '8px',
  marginTop: '1rem',
  padding: '2rem',
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}));
