import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

export const StScreenerBannerContentWrapper = styled(Box)(({ theme }) => ({
  padding: '25px 6% 0 6%',
  [theme.breakpoints.up('xl')]: {
    padding: '0 30px',
    width: '1300px',
    margin: '0 auto !important',
  },
}));

export const StScreenerBannerFooterWrapper = styled(Box)(({ theme }) => ({
  padding: '0px 6% 50px 6%',
  [theme.breakpoints.up('xl')]: {
    padding: '0 30px !important',
    width: '1300px',
    margin: '0rem auto 4rem !important',
  },
}));

export const PresceenerContainer = styled(Box)(({ theme }) => ({
  margin: '1rem 8rem',
  [theme.breakpoints.down('lg')]: {
    margin: '1rem 6rem',
  },
  [theme.breakpoints.down('md')]: {
    margin: '1rem 4rem',
  },
  [theme.breakpoints.down(450)]: {
    margin: '1rem 2rem',
  },
}));

export const PreScreenerWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    marginInline: 'auto',
  },
}));

export const PreScreenerHeader = styled(Box)(({ theme }) => {
  return {
    color: theme.palette.neutral0.main,
    '& a[href^="tel:"]': {
      color: theme.palette.neutral0.main,
      textDecoration: 'underline dotted',
      '&:hover': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '10px',
    marginLeft: '6.5rem',
    marginRight: '5.5rem',
    paddingBlock: '2rem',
    [theme.breakpoints.down(1220)]: {
      marginLeft: '4rem',
      marginRight: '3rem',
    },
    [theme.breakpoints.down(1150)]: {
      marginLeft: '3rem',
      marginRight: '2rem',
    },
    [theme.breakpoints.down(1080)]: {
      marginInline: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      marginInline: '2rem',
    },
    [theme.breakpoints.down(500)]: {
      marginInline: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.pre-screener-banner__header__title': {
      fontWeight: '500',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.625rem',
        lineHeight: '39px',
      },
    },
    '.pre-screener-banner__header__body': {
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '39px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '24px',
      },
    },
  };
});

export const PreScreenerTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.6rem',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));
export const PreScreenerText = styled(RichText)(() => ({
  fontFamily: 'Proxima Nova Regular',
  '& p': {
    fontFamily: 'Proxima Nova Bold',
    margin: 0,
  },
  '& p a': {
    textUnderlineOffset: '8px',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.themeColorSecondary.main,
  width: '100px',
  height: '4px',
  borderRadius: '50px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  marginBlock: '0.2rem',
}));

export const PreScreenerFooter = styled(Box)(({ theme }) => ({
  background: theme.palette.themeLightColor.main,
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  columnGap: '32px',
  padding: '32px 14%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    rowGap: '20px',
    padding: '24px',
  },
}));
