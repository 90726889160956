import Box from '@mui/material/Box';
import ControlledRadioGroup from 'components/shared/RadioGroup/ControlledRadioGroup';
import TextBoxWithLabel from 'components/shared/TextBox/TextBoxWithLabel';

const LocationDetailsQuestions = (props: any): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '1rem',
      }}
    >
      <TextBoxWithLabel
        label={props?.zipcodeLabel}
        handleChange={props?.handleChange}
        placeholder={props?.zipcodePlaceholder?.value}
        required={props?.isZipcodeRequired.value}
        requiredMessage={props?.errorMessage}
        errors={props?.errors?.zipcode}
        value={props?.storedAnswers?.zipcode != '' ? props.storedAnswers.zipcode : ''}
        regexMessage={props?.errorMessage}
        regexError={props?.errors?.zipcode}
        name="zipcode"
        id="zipcode"
      />

      <ControlledRadioGroup
        id="distanceCheckbox"
        label={props?.distanceLabel?.value}
        options={props?.distanceOptions}
        ariaLabelledby={props?.distanceLabel?.value}
        defaultValue={props?.storedAnswers?.travelDistance || ''}
        handleChange={props?.handleCheckBox}
      />

      <ControlledRadioGroup
        id="homeTrialCheckbox"
        label={props?.homeTrailsConsentLabel?.value}
        options={props?.homeTrailsConsentOptions}
        ariaLabelledby={props?.homeTrailsConsentLabel?.value}
        defaultValue={props?.storedAnswers?.homeTrial || ''}
        handleChange={props?.handleCheckBox}
      />
    </Box>
  );
};

export default LocationDetailsQuestions;
