import { Field, ImageField } from '@sitecore-jss/sitecore-jss-react';
import { IconWithTitle } from '../shared/IconWithTitle';
import { OneColumnGrid } from '../shared/OneColumnGrid';
import { TwoColumnGrid } from '../shared/TwoColumnGrid';
import { ComponentProps } from 'lib/component-props';
import {
  CommunityContainer,
  StDTPGridContainer,
  StDTPWrapper,
} from '../shared/Container/Container.styles';

type TextDetails = ComponentProps & {
  templateName: string;
  fields: {
    heading: Field<string>;
    content: Field<string>;
  };
};

type ImageDetails = TextDetails & {
  fields: {
    imageContent?: ImageField;
  };
};

type VideoDetails = TextDetails & {
  fields: {
    videoUrl?: Field<string>;
    isAutoplay?: Field<boolean>;
    isMuted?: Field<boolean>;
  };
};

type ContentDetails = TextDetails | ImageDetails | VideoDetails;

interface IOneAndTwoColumnProps {
  fields: {
    screenRatio: { value: string };
    contentList: ContentDetails[];
    title: Field<string>;
    titleIcon: ImageField;
    iconWithTitleVariant: { value: string };
    iconMobile?: ImageField;
    iconTablet?: ImageField;
    iconDesktop?: ImageField;
  };
}

const OneAndTwoColumn = (props: IOneAndTwoColumnProps): JSX.Element => {
  if (!props?.fields) return <></>;
  const titleIconSrc = props?.fields?.titleIcon?.value?.src;
  const IconWithTitleProps = {
    ...(titleIconSrc && { titleIcon: props?.fields?.titleIcon }),
    title: props.fields.title,
    iconWithTitleVariant: props.fields.iconWithTitleVariant.value,
    iconMobile: props.fields.iconMobile,
    iconTablet: props.fields.iconTablet,
    iconDesktop: props.fields.iconDesktop,
  };

  const isSingleColumn = props?.fields?.contentList.length > 1 ? false : true;

  return (
    <StDTPWrapper id={'about-study'}>
      <StDTPGridContainer>
        <IconWithTitle {...IconWithTitleProps} />
        <CommunityContainer>
          {isSingleColumn ? (
            <OneColumnGrid {...props.fields?.contentList[0]} isSingleColumn={isSingleColumn} />
          ) : (
            <TwoColumnGrid fields={props.fields} isSingleColumn={isSingleColumn} />
          )}
        </CommunityContainer>
      </StDTPGridContainer>
    </StDTPWrapper>
  );
};
export default OneAndTwoColumn;
