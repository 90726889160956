import { useEffect, useState, useMemo } from 'react';
import {
  loadAdvancedSearchQueryActions,
  buildResultList,
  buildResultsPerPage,
  ResultListState,
  ResultList,
  loadSearchActions,
  loadSearchAnalyticsActions,
} from '@coveo/headless';
import locationEngine from '../../../lib/utils/coveo/lacationEngine';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { getStateFromZipcode } from '../../../lib/utils/coveoHeadless';
import { isEditorActive, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const useSavedTrialsNearestSite = (
  state: ResultListState | undefined
): ((item: any) => string | null) => {
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [locationResults, setLocationResults] = useState<ResultListState>();
  const userProfile = useSelector((state: RootState) => state.crProfile);
  const profileDetails = userProfile?.profileData?.basicDetails;
  const isProfile = Object.keys(userProfile.profileData).length !== 0;
  const [locationStateByZipCode, setLocationStateByZipCode] = useState<{
    latitude?: number;
    longitude?: number;
  }>();
  const { sitecoreContext } = useSitecoreContext();
  const fetchLocationData = async () => {
    const zipCodeValue = isProfile && profileDetails?.zipCode ? profileDetails?.zipCode : '';
    if (!zipCodeValue) return;

    try {
      const stateFromZipCode: any = await getStateFromZipcode(zipCodeValue);
      if (stateFromZipCode) {
        setLocationStateByZipCode({
          latitude: stateFromZipCode.latitude,
          longitude: stateFromZipCode.longitude,
        });
      }
    } catch (_error) {
      setLocationStateByZipCode({
        latitude: undefined,
        longitude: undefined,
      });
    }
  };

  useEffect(() => {
    const checkGoogleMaps = () => {
      if (window.google) {
        setIsGoogleMapsLoaded(true);
      } else {
        setTimeout(checkGoogleMaps, 100);
      }
    };

    checkGoogleMaps();
  }, []);

  useEffect(() => {
    if (isGoogleMapsLoaded) {
      fetchLocationData();
    }
  }, [isGoogleMapsLoaded, isProfile, profileDetails?.zipCode, userProfile.profileData]);

  useEffect(() => {
    if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
      return;
    }
    if (typeof window === 'undefined') return;
    if (locationStateByZipCode?.latitude && locationStateByZipCode?.longitude && state?.results) {
      const trialOrgStudyIDs = state?.results
        .map((result) => `"${result.raw.trialorgstudyid}"`)
        .join(' OR ');

      const searchQueryActionCreators = loadAdvancedSearchQueryActions(locationEngine);
      const trialLocationGovSource =
        process.env.NEXT_PUBLIC_COVEO_CTGOV_LOCATION_SOURCE || 'Trial Location Gov Dev';
      const trialLocationSource =
        process.env.NEXT_PUBLIC_COVEO_CTMS_LOCATION_SOURCE || 'Trial Locations Dev';
      const searchQueryAction = searchQueryActionCreators.registerAdvancedSearchQueries({
        aq: `@source=("${trialLocationSource}" OR "${trialLocationGovSource}") @documenttype=Location${
          trialOrgStudyIDs?.length ? ` @trialorgstudyid=(${trialOrgStudyIDs})` : ''
        } $qf(function:'dist(@triallatitude, @triallongitude, ${
          locationStateByZipCode?.latitude
        }, ${
          locationStateByZipCode?.longitude
        })*0.000621371', fieldName: 'distance')$sort(criteria: 'fieldascending', field: '@distance')`,
      });

      locationEngine.dispatch(searchQueryAction);
      const { executeSearch } = loadSearchActions(locationEngine);
      const { logInterfaceLoad } = loadSearchAnalyticsActions(locationEngine);
      locationEngine.dispatch(executeSearch(logInterfaceLoad()));
    }
  }, [locationStateByZipCode, state?.results]);

  useEffect(() => {
    const resultList: ResultList = buildResultList(locationEngine, {
      options: {
        fieldsToInclude: ['trialorgstudyid', 'distance'],
      },
    });

    setLocationResults(resultList.state);

    resultList.subscribe(() => {
      setLocationResults(resultList.state);
    });
  }, []);

  useEffect(() => {
    buildResultsPerPage(locationEngine, {
      initialState: { numberOfResults: 100000 },
    });
  }, []);

  const nearestSitesData = useMemo(() => {
    return state?.results.map((result: any) => {
      const orgStudyID: string = result.raw.trialorgstudyid;
      const filteredLocations = locationResults?.results?.filter(
        (result: any) => orgStudyID === result.raw.trialorgstudyid
      );

      return { postID: orgStudyID, locations: filteredLocations };
    });
  }, [state?.results, locationResults?.results]);

  const getNearestSite = (item: any): string | null => {
    const itemOrgStudyID: string = item.raw.trialorgstudyid;
    const itemLocations = nearestSitesData?.find(
      (site: any) => itemOrgStudyID === site.postID
    )?.locations;

    if (itemLocations?.length === 0) return null;

    const itemDistances = itemLocations?.map((location: any) => location.raw.distance?.toFixed(1));
    const nearestSiteDistance = itemDistances && itemDistances[0];

    return nearestSiteDistance ? `${nearestSiteDistance} miles` : null;
  };

  return getNearestSite;
};

export default useSavedTrialsNearestSite;
