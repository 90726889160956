import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';

type ControlledRadioGroupProps = {
  label?: string;
  id: string;
  ariaLabelledby?: string;
  defaultValue?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  options?: {
    name: string;
    fields: {
      name: {
        value: string;
      };
    };
  }[];
};

const ControlledRadioGroup = (props: ControlledRadioGroupProps): JSX.Element => {
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel
        sx={{
          paddingBottom: '10px',
          '&.MuiFormLabel-root': {
            color: theme.palette.neutral8.main,
          },
        }}
      >
        {props?.label}
      </FormLabel>
      <RadioGroup
        aria-labelledby={`${props?.ariaLabelledby}-group`}
        defaultValue={props?.defaultValue}
        onChange={(e) => props?.handleChange(e, props?.id)}
      >
        {props?.options?.map((item: any, itemIndex: number) => {
          return (
            <FormControlLabel
              key={itemIndex}
              value={item?.fields?.name?.value}
              control={<Radio id={item?.fields?.name?.value} />}
              label={item?.fields?.name?.value}
              name={item?.fields?.name?.value}
              sx={{
                color: theme.palette.neutral8.main,
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default ControlledRadioGroup;
