import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRef } from 'react';
import IconMarker from '../../../../public/icon-marker.svg';
import { SuccessPageMapProps } from './SelectedSitesContainer.type';

const SuccessPageMap = (props: SuccessPageMapProps): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const containerStyle = {
    width: '100%',
    height: `100%`,
    borderBottomRightRadius: isDesktop ? '0.5rem' : 0,
    borderTopRightRadius: isDesktop ? '0.5rem' : 0,
  };

  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;

  let isLoaded = false;
  const lng =
    sitecoreContext?.site?.name === 'ClinicalResearch' ? 'en' : sitecoreContext?.language || 'en';

  try {
    const loaderObj = useJsApiLoader({
      id: 'google-map-script',
      language: lng,
      googleMapsApiKey: atob(siteSettings?.googleMapsAPIKey?.value || ''),
    });

    isLoaded = loaderObj.isLoaded;
  } catch (_error) {}

  const center = useRef({
    lat: props?.confirmedsitesData?.latitude || 58.3,
    lng: props?.confirmedsitesData?.longitude || -134.43,
  });

  return (
    <Box sx={{ height: '100%' }}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center.current}
          zoom={10}
          options={{
            mapTypeControl: false,
            gestureHandling: 'none',
            zoomControl: true,
          }}
        >
          <MarkerF
            position={{
              lat: props?.confirmedsitesData?.latitude,
              lng: props?.confirmedsitesData?.longitude,
            }}
            icon={props?.markerUrl ? props?.markerUrl : <IconMarker></IconMarker>}
          />
        </GoogleMap>
      )}
    </Box>
  );
};
export default SuccessPageMap;
