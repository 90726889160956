import { RichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';

const replaceValue = (originaValue: string, lookupObj: Record<string, string>): string => {
  const tags = Object.keys(lookupObj);
  let replacedValue = originaValue;
  tags.forEach((tag) => {
    const tagValue = lookupObj[tag] || '';
    replacedValue = replacedValue.replace(new RegExp(`\\[${tag}\\]`, 'g'), tagValue as string);
  });

  return replacedValue;
};

export const StudyLocationMergeTag: React.FC<{
  field: RichTextField;
  studyDetails: any;
  className?: string;
}> = (props) => {
  const lookupObj = {
    siteAddress: props?.studyDetails?.raw?.locationaddress || '',
    siteCity: props?.studyDetails?.raw?.locationcity || '',
    siteState: props?.studyDetails?.raw?.locationstate || '',
    siteCountry: props?.studyDetails?.raw?.locationcountry || '',
    siteZipCode: props?.studyDetails?.raw?.locationzip || '',
  };
  const newValue = replaceValue(String(props.field.value), lookupObj);
  return (
    <RichText
      field={{ value: newValue, editable: props.field.editable }}
      className={props.className}
    />
  );
};
