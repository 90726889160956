import { FacetValue, Result, HighlightUtils } from '@coveo/headless';

export const findClosestGreaterFacet = (number: number, facetValues: FacetValue[]): FacetValue => {
  const filteredArr = facetValues.filter((item) => parseInt(item.value) >= number);
  filteredArr.sort((a, b) => parseInt(a.value) - parseInt(b.value));
  return filteredArr[0];
};

export const dayOfBirthToAge = (dayOfBirth: string): string | null => {
  if (dayOfBirth?.length < 4) return null;
  return String(new Date().getFullYear() - Number(dayOfBirth));
};

export const highlightExcerpt = (item: Result): string => {
  return HighlightUtils.highlightString({
    content: item.title,
    highlights: item.titleHighlights,
    openingDelimiter:
      '<span style="color: white; background-color: gray; padding: 0px 3px; font-weight: bold">',
    closingDelimiter: '</span>',
  });
};

export const sortByGoodMatch = (
  a: any,
  b: any,
  isGoodMatchByDistance: (item: any) => boolean
): number => {
  const goodA = isGoodMatchByDistance(a) && a.raw.trialenrollingstatus === 'Enrolling';
  const goodB = isGoodMatchByDistance(b) && b.raw.trialenrollingstatus === 'Enrolling';

  if (goodA && !goodB) return -1;
  if (!goodA && goodB) return 1;
  return 0;
};

export const getStateFromZipcode = async (zipCode: string) => {
  return new Promise((resolve, reject) => {
    if (!window.google) {
      reject(false);
      return;
    }

    const geoCoder = new window.google.maps.Geocoder();
    geoCoder.geocode(
      { address: zipCode, componentRestrictions: { country: 'US' } },
      (results: any, status: string) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          if (results[0]?.types?.includes('postal_code')) {
            resolve({
              latitude: results[0]?.geometry?.location.lat() || null,
              longitude: results[0]?.geometry?.location.lng() || null,
            });
          } else {
            reject(false);
          }
        } else {
          reject(false);
        }
      }
    );
  });
};
