import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import TextBox from 'components/shared/TextBox/TextBox';

const AboutQuestion = (props: any): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(510));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Typography sx={{ color: '#444444' }}>
        <Text field={props?.formTitle} />
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={isMobile ? 12 : 6}>
          <TextBox
            onChangeHandler={props?.handleChange}
            placeholder={props?.firstNameLabel?.value}
            required={props?.isFirstNameRequired.value}
            requiredMessage={props?.firstNameRequiredMessage}
            regexMessage={props?.firstNameInvalidMessage}
            regexError={props?.regexError?.firstName}
            value={props?.storedAnswers?.firstName !== '' ? props.storedAnswers.firstName : ''}
            name="firstName"
            id="first-name"
            maxLength={props?.firstNameLength?.value}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <TextBox
            onChangeHandler={props?.handleChange}
            placeholder={props?.lastNameLabel?.value}
            required={props?.isLastNameRequired.value}
            requiredMessage={props?.lastNameRequiredMessage}
            regexMessage={props?.lastNameInvalidMessage}
            regexError={props?.regexError?.lastName}
            value={props?.storedAnswers?.lastName !== '' ? props.storedAnswers.lastName : ''}
            name="lastName"
            id="last-name"
            maxLength={props?.lastNameLength?.value}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutQuestion;
