import { styled } from '@mui/material/styles';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import Image from 'next/image';
import { hexToCSSFilter } from 'hex-to-css-filter';

// default configuration for hex to css filter
const config = {
  acceptanceLossPercentage: 1,
};

type StNextImageProps = {
  invertColor?: boolean;
  overrideIconColor?: string;
};

const getIconColor = function (invertColor: boolean, overrideIconColor: string, theme: any) {
  if (invertColor) {
    return theme.palette.whiteColorFilter.main;
  } else if (!!overrideIconColor) {
    return `brightness(0) saturate(100%) ${hexToCSSFilter(overrideIconColor, config).filter}`;
  } else {
    return theme.palette.themeColorFilter.main;
  }
};

export const StNextImage = styled(NextImage, {
  shouldForwardProp: (prop) => prop !== 'invertColor' && prop !== 'overrideIconColor',
})<StNextImageProps>(({ theme, invertColor, overrideIconColor }) => ({
  filter: getIconColor(invertColor!, overrideIconColor!, theme),
}));

export const StNextIcon = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'invertColor' && prop !== 'overrideIconColor',
})<StNextImageProps>(({ theme, invertColor, overrideIconColor }) => ({
  filter: getIconColor(invertColor!, overrideIconColor!, theme),
}));
