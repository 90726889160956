import { getCookie } from 'cookies-next';
import { post } from '../utils/axiosFetcher';

export const DataOrigin = {
  EPR: 'ePR',
  TAEPR: 'TAePR',
  CR_REGISTRATION: 'crRegistration',
  CR_MY_PROFILE: 'crMyprofile',
  RESUBSCRIBE: 'reSubscribe',
  UNSUBSCRIBE: 'unSubscribe',
  SITEUPDATEJOB: 'siteUpdateJob',
  TA_CR_REGISTRATION: 'ta-crRegistration',
  TA_CR_UPDATE: 'ta-crUpdate',
};
export const ClinicalReasearch = 'CR';
export const crContextSettingsId = '{031D0BF6-F866-4B44-B2DC-199FA3E27C95}';
export const siteRootPath = '{33E0826A-F8F6-5961-A24A-2F4CC7FA946E}';
export const contextSettingsId = '{031D0BF6-F866-4B44-B2DC-199FA3E27C95}';
export const globalSettingsTemplateList = {
  '0B5A09AFF9DF4F5EB9CC7A2ED6E0E938': 'siteSettings',
};

const getEnrollmentId = (dataOrigin: string) => {
  if (
    [
      DataOrigin.CR_REGISTRATION,
      DataOrigin.CR_MY_PROFILE,
      DataOrigin.TA_CR_REGISTRATION,
      DataOrigin.TA_CR_UPDATE,
    ].includes(dataOrigin)
  ) {
    return '';
  }
  return getCookie('enrollmentID') || '';
};
export const updateUCPMJobRecordsForCR = async (data: any) => {
  const {
    otSponsorId,
    parentSponsorMDMID,
    protocolMDMID,
    sponsor,
    iqviaSponsorId,
    dataOrigin,
    baseUrl,
  } = data;
  const payload: any = {
    otSponsorId: otSponsorId,
    parentSponsorMDMID: parentSponsorMDMID,
    protocolMDMID: protocolMDMID,
    sponsor: sponsor,
    iqviaSponsorId: iqviaSponsorId,
    guestRef: getCookie('bx_guest_ref'), //getCookie('bx_guest_ref'), as per discussion with Manish, this only needed for CR case
    dataOrigin: dataOrigin,
    UCPMStudyName: '',
    integrationAttemptHistory: {
      baseUrl: baseUrl,
      isHumanApienabled: false, //this field is not needed for now by UCPM
      tenantKey: '',
      enrollmentId: getEnrollmentId(dataOrigin),
    },
  };
  return await post(`/UCPM/AddUCPMJobRecord`, payload);
};
