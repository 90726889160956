import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export const ContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  padding: '1.5rem',
  marginTop: '1.25rem',
  marginBottom: '1.25rem',
  border: `1px solid ${theme.palette.neutral4.main}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.neutral4.main,
  width: '100%',
  height: '1px',
  borderRadius: '50px',
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  fontFamily: 'Proxima Nova',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  textAlign: 'start',
}));

export const StyledValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontFamily: 'Proxima Nova',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '1.5rem',
  textAlign: 'start',
}));
