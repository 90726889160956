import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '../CircularProgress/CircularProgress';

interface IProps {
  text?: string;
}
const CircularProgressWithHelpText = (props: IProps) => {
  return (
    <>
      <Box display="flex" flexDirection="column" rowGap="0.5rem" alignItems="center">
        <Box>
          <CircularProgress />
        </Box>
        {props.text && <Typography variant="body">{props.text}</Typography>}
      </Box>
    </>
  );
};

export default CircularProgressWithHelpText;
