/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import { ALERT_STATUSES } from '../../components/ContactInfo/constants';

export const stateAndZipCodeValidator = async (zipCode: string, state: string) => {
  return new Promise((reslove, reject) => {
    const geoCoder = new window.google.maps.Geocoder();
    geoCoder.geocode({ address: zipCode }, (results: any, status: string) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const place = results[0]['address_components'].find(
          (item: any) => item.long_name.toLowerCase() === state.toLowerCase()
        );
        reslove(place);
      } else {
        reject(false);
      }
    });
  });
};

export const getStateFromZipcode = async (zipCode: string) => {
  return new Promise((reslove, reject) => {
    const geoCoder = new window.google.maps.Geocoder();
    geoCoder.geocode({ address: zipCode }, (results: any, status: string) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const place = results[0]['address_components'].find((item: any) =>
          item.types.includes('administrative_area_level_1')
        );
        reslove(place?.long_name);
      } else {
        reject(false);
      }
    });
  });
};

export const validateAddresWithZipcode = async (
  address: string,
  zipcode: string,
  zipcodeType: string
) => {
  return new Promise((resolve, reject) => {
    const geoCoder = new window.google.maps.Geocoder();
    geoCoder.geocode({ address: address }, (results: any, status: string) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const place = results[0]['address_components'].find((item: any) =>
          item.types.includes(zipcodeType)
        );
        let isValid = false;
        if (place?.long_name === zipcode) {
          isValid = true;
        }
        resolve(isValid);
      } else {
        reject(false);
      }
    });
  });
};

export const validateAddresWithState = async (
  address: string,
  state: string,
  stateTypeConfig: any[]
) => {
  return new Promise((resolve, reject) => {
    const geoCoder = new window.google.maps.Geocoder();
    geoCoder.geocode({ address: address }, (results: any, status: string) => {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        let statePopulated = false;
        let place: any = '';

        stateTypeConfig.map((stateType: any) => {
          if (statePopulated) {
            return;
          } else {
            results[0]['address_components'].map((item: any) => {
              if (item.types.includes(stateType.fields.name.value)) {
                place = item.long_name;
                statePopulated = true;
                return;
              }
            });
          }
        });

        let isValid = false;

        if (place === state) {
          isValid = true;
        }
        resolve(isValid);
      } else {
        reject(false);
      }
    });
  });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAlertStatusIcon = (status: string) => {
  return ALERT_STATUSES[status.toLowerCase()]?.icon;
};
