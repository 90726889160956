import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { IconContainer } from './AboutUsWrapper.styles';

const AboutUsWrapper = (props: any): JSX.Element => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          width: '1365px',
          minWidth: '100%',
          display: 'flex',
          flexDirection: isTablet ? 'column' : 'row',
        }}
      >
        <Box sx={{ width: { xs: '100%', lg: '509px' }, minWidth: { xs: '100%', md: '100%' } }}>
          <Placeholder name="jss-content" rendering={props.rendering} />
          <IconContainer>
            <Placeholder name="jss-share" rendering={props.rendering} />
          </IconContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUsWrapper;
