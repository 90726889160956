import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Typography from '@mui/material/Typography';
import { StThreeStepItemVar1 } from './ThreeStepInformation.style';
import { IThreeStepItemProps } from './ThreeStepInformation.types';
import ThreeStepImage from './ThreeStepImage';
import Box from '@mui/material/Box';

const ThreeStepItem: React.FC<IThreeStepItemProps> = (props) => {
  return (
    <StThreeStepItemVar1>
      <Typography
        variant="h3"
        sx={{
          marginBottom: '1rem',
        }}
      >
        <Text field={props.fields?.stepNumber} />
      </Typography>
      <ThreeStepImage field={props.fields?.icon} />
      <Box marginTop="1rem">
        <RichText field={props.fields?.description} />
      </Box>
    </StThreeStepItemVar1>
  );
};

export default ThreeStepItem;
