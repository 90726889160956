import {
  default as MuiCircularProgress,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

const CircularProgress = (props: CircularProgressProps) => {
  return (
    <MuiCircularProgress
      size={'3.7rem'}
      sx={{
        borderRadius: '100%',
        boxShadow: (theme) => `inset 0 0 0 3.2px ${theme.palette.neutral4.main}`,
        backgroundColor: 'transparent',
      }}
      {...props}
    />
  );
};

export default CircularProgress;
