import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { JSSLink } from '../shared/Links/JSSLink.styles';
export const StBannerWrapper = styled(Grid)(({}) => ({
  position: 'relative',
  minHeight: '542px',
}));

export const StBannerContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  backgroundSize: 'cover',
  minHeight: '475px',
  paddingBlock: '60px',
  paddingLeft: '6.5rem',
  paddingRight: '5.5rem',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    marginInline: 'auto',
  },
  [theme.breakpoints.down(1150)]: {
    paddingLeft: '4rem',
    paddingRight: '3rem',
  },
}));

export const StBannerContent = styled(Grid)(({ theme }) => ({
  maxWidth: '450px',
  marginBottom: '10px',
  zIndex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '310px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '325px',
  },
  [theme.breakpoints.up(1035)]: {
    maxWidth: '375px',
  },
  [theme.breakpoints.up(1055)]: {
    maxWidth: '380px',
  },
  [theme.breakpoints.up(1200)]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.up(1200)]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.up(2000)]: {
    maxWidth: '390px',
  },
  [theme.breakpoints.up(1300)]: {
    marginLeft: theme.spacing(6),
  },
}));

export const StRichTextGridItem = styled(Grid)(({ theme }) => ({
  color: theme.palette.neutral8.main,
  margin: '0px 0px',
  marginBottom: '0',
  fontSize: '1.25rem',
  lineHeight: '2.18rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
    lineHeight: '1.687rem',
  },
}));

export const StSearchBoxWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    marginTop: '22px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  '.MuiFormHelperText-root': {
    margin: '2px 0px 5px 0px',
  },
}));

export const StSearchBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  padding: '30px',
  minHeight: '226px',
  width: '442px',
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    a: {
      marginTop: '6px',
      fontWeight: 500,
    },
  },
  [theme.breakpoints.down('lg')]: {
    form: {
      minHeight: '176px',
    },
  },
  '@media screen and (max-width:500px)': {
    width: '100%',
    paddingBlock: theme.spacing(3),
    paddingInline: '22px',
  },
}));

export const StSearchButton = styled(JSSLink)(({}) => ({
  minWidth: 'auto !important',
  // padding: '5px 17px',
  width: '100%',
}));

export const AutoCompleteInput = styled(Autocomplete)(({}) => ({
  '&.MuiInputBase-root .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

export const InputTextField = styled(TextField)(({ theme }) => ({
  '& .css-1h58qzp-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  '& div': {
    borderRadius: '8px',
    // '& input': {
    //   color: theme.palette.neutral8.main,
    // },
    '& fieldset': {
      borderColor: '#e9e9e9',
    },
  },
  '& input::placeholder': {
    fontWeight: 400,
    color: `${theme.palette.neutral11.main}`,
    opacity: 1,
    fontSize: '13px',
    lineHeight: '16px',
    fontFamily: 'Proxima Nova',
  },
  '& div, input': {
    color: `${theme.palette.neutral11.main}`,
    borderRadius: '8px',
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    lineHeight: '24px',
  },
}));
