const getAgeRange = (minAge?: string, maxAge?: string) => {
  if (minAge && maxAge) {
    return `${minAge} - ${maxAge}`;
  } else if (minAge) {
    return `${minAge}+`;
  } else if (maxAge) {
    return `< ${maxAge}`;
  } else {
    return '';
  }
};

export default getAgeRange;
