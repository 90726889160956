import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNextIcon } from 'components/shared/NextImage.styles';
import MyProfileCollapse from '../../../public/MyProfileChevronDown.svg';
import MyProfileExpand from '../../../public/MyprofileChevronRight.svg';

const MyProfileHeader = (props: any): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '1.5rem',
        color: theme.palette.neutral8.main,
      }}
      id="my-profile-header"
    >
      <Box sx={{ flex: 2 }}>
        <Box
          sx={{
            fontSize: '2rem',
            fontWeight: 600,
            lineHeight: '1.5rem',
            fontFamily: 'Proxima Nova',
          }}
        >
          <Text field={props?.fields?.heading} />
        </Box>
        <Box sx={{ fontSize: '1.5rem', fontWeight: 500, lineHeight: '1.5rem' }}>
          <RichText field={props?.fields?.content} />
        </Box>
      </Box>
      {!props.showForm ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            minWidth: '50px',
            cursor: 'pointer',
          }}
          onClick={() => props.handleEdit(true)}
        >
          {/* <NextImage field={props?.fields?.editIcon} width={43} height={43} /> */}
          <StNextIcon
            suppressHydrationWarning
            alt="my-profile-expand"
            src={MyProfileExpand}
            width={43}
            height={43}
            overrideIconColor={theme.palette.primary.main}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            minWidth: '50px',
            cursor: 'pointer',
          }}
          onClick={() => props.handleEdit(false)}
        >
          <StNextIcon
            suppressHydrationWarning
            alt="my-profile-collapse"
            src={MyProfileCollapse}
            width={43}
            height={43}
            overrideIconColor={theme.palette.primary.main}
          />
        </Box>
      )}
    </Box>
  );
};

export default MyProfileHeader;
