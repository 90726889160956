import { useEffect, useState } from 'react';

type IDisclaimerModalProps = {
  open: boolean;
  openModal: (param: string | null) => void;
  closeModal: () => void;
  link: string | null;
};

export const useDisclaimerModal = (isDisclaimerEnable: boolean): IDisclaimerModalProps => {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState<string | null>('');
  const openModal = (param: string | null) => {
    setOpen(true);
    setLink(param);
  };
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isDisclaimerEnable) {
      const anchors = Array?.from(document.getElementsByClassName('open-disclaimer'));
      anchors &&
        anchors?.forEach((anchor) => {
          anchor.addEventListener('click', function (e) {
            const linkName = anchor.getAttribute('href');
            openModal(linkName);
            e.preventDefault();
          });
          return () => {
            anchor.removeEventListener('click', function (e) {
              const linkName = anchor.getAttribute('href');
              openModal(linkName);
              e.preventDefault();
            });
          };
        });
    }
  });

  return { open, openModal, closeModal, link };
};

export default useDisclaimerModal;
