import { styled } from '@mui/material/styles';
import { default as MuiButton } from '@mui/material/Button';

// MUI styled buttons
export const UIButton = styled(MuiButton)(({ theme, variant }) => ({
  fontSize: '1rem',
  fontStyle: 'normal',
  fontFamily: 'Proxima Nova SemiBold',
  textTransform: 'none',
  borderRadius: variant === 'contained' || variant === 'outlined' ? '50px' : '0px',
  height: 'auto',
  minWidth: variant === 'contained' || variant === 'outlined' ? '205px' : '0px !important',
  padding: variant === 'contained' || variant === 'outlined' ? '0px 40px' : '0px',
  filter:
    variant === 'contained'
      ? 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.50))'
      : 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08))',
  border:
    variant === 'contained'
      ? `1px solid ${theme.palette.primary.main}`
      : variant === 'outlined'
      ? `1px solid ${theme.palette.neutral4.main}`
      : 'none',
  background:
    variant === 'contained'
      ? theme.palette.primary.main
      : variant === 'outlined'
      ? theme.palette.secondary.main
      : 'none',
  '&:hover': {
    background:
      variant === 'contained'
        ? theme.palette.primary.dark
        : variant === 'outlined'
        ? theme.palette.secondary.dark
        : 'none',
    borderBottom:
      variant === 'contained' || variant === 'outlined'
        ? `1px solid ${theme.palette.primary.main}`
        : '1px dashed',
    color: variant === 'contained' ? theme.palette.white.main : theme.palette.primary.main,
  },
  '&:disabled': {
    background:
      variant === 'contained'
        ? theme.palette.primary.dark
        : variant === 'outlined'
        ? theme.palette.secondary.dark
        : 'none',
    opacity: 0.6,
    borderBottom:
      variant === 'contained' || variant === 'outlined'
        ? `1px solid ${theme.palette.primary.main}`
        : '1px dashed',
    color: variant === 'contained' ? theme.palette.white.main : theme.palette.primary.main,
  },
}));
