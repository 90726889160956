/**
 * Returns the current timezone offset in a string format.
 * @returns {string} The timezone offset in the format 'UTC±HH'.
 */
const getTimezoneOffset = (): string => {
  let utcOffsetString = 'UTC';
  const currentYear = new Date().getFullYear();
  const userDate = new Date(currentYear, 0, 1);
  let userOffset = userDate.getTimezoneOffset();
  // Convert the offset to positive
  userOffset = -userOffset;
  const hours = Math.floor(userOffset / 60);
  utcOffsetString += (hours >= 0 ? '+' : '-') + Math.abs(hours).toString().padStart(2, '0');
  //   Incase minutes are included in future, use the below format
  //   let minutes = userOffset % 60; //
  //   utcOffsetString += (hours >= 0 ? "+" : "-") + Math.abs(hours).toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0");
  return utcOffsetString;
};

const findTimeZoneInOptions = (
  timezone: string,
  options: Array<{ key: { value: string } }>
): string => {
  if (Array.isArray(options) && options.length > 0) {
    const result = options && options.find((item) => item.key.value.includes(timezone));
    return result?.key?.value || '';
  }
  return '';
};

const getDefaultTimeZone = (options: any, existingValues: any = {}): string => {
  const userTimeZone = getTimezoneOffset();
  const timezone = findTimeZoneInOptions(userTimeZone, options);
  return existingValues['UserTimeZone'] ? existingValues['UserTimeZone'] : timezone;
};

export { getTimezoneOffset, findTimeZoneInOptions, getDefaultTimeZone };
