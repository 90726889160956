import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StHcpMainContainer = styled(Box)(() => ({
  position: 'relative',
  marginBottom: '6px',
  overflow: 'hidden',
}));

export const StBannerContainer = styled(Box)(({ theme }) => ({
  borderRadius: '0px',
  width: '100%',
  height: '457px',
  margin: '0px',
  marginInline: 'auto',
  gap: theme.spacing(0),
  display: 'flex',
  justifyContent: 'center',
  background: `linear-gradient(0deg, ${theme.palette.primaryOne.dark} 4.5%, ${theme.palette.primaryOne.light} 100%)`,
  overflow: 'hidden',
  [theme.breakpoints.down(1150)]: {
    height: '268px',
  },
  [theme.breakpoints.down('md')]: {
    height: '188px',
    background: 'initial',
  },
}));

export const StSubBannerContainer = styled(Box)(({ theme }) => ({
  borderRadius: '0px',
  width: '100%',
  margin: '0px',
  marginInline: 'auto',
  gap: theme.spacing(0),
  display: 'flex',
  maxWidth: '1305px',
  justifyContent: 'center',
}));

export const StOverlayBannerContainer = styled(Box)(({ theme }) => ({
  borderRadius: '0px',
  width: '100%',
  height: '457px',
  margin: '0px',
  gap: theme.spacing(0),
  display: 'flex',
  justifyContent: 'center',
  background: `linear-gradient(0deg, ${theme.palette.primaryOne.dark} 4.5%, ${theme.palette.primaryOne.light} 100%)`,
  overflow: 'hidden',
  [theme.breakpoints.down(1150)]: {
    height: '355px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '457px',
  },
}));

export const StHcpHeadingWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBlock: '26px',
  paddingInline: '4rem',
  marginInline: 0,
  opacity: 0.95,
  background: 'rgba(255, 255, 255, 0.95)',
  [theme.breakpoints.only('lg')]: {
    paddingInline: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '31px',
    paddingBottom: '22px',
    lineHeight: 'normal',
    paddingInline: '2rem',
  },
  [theme.breakpoints.only('md')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down(620)]: {
    paddingInline: '2rem',
    paddingBlock: '0.5rem',
    position: 'absolute',
    marginInline: 0,
    bottom: '0px',
  },
}));

export const StHcpOverlayHeadingWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBlock: '26px',
  paddingInline: '4rem',
  marginInline: '0',
  opacity: 0.95,
  background: 'rgba(255, 255, 255, 0.95)',
  [theme.breakpoints.only('lg')]: {
    paddingInline: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '31px',
    paddingBottom: '22px',
    lineHeight: 'normal',
    paddingInline: '2rem',
  },
  [theme.breakpoints.only('md')]: {
    paddingInline: '2rem',
  },
  [theme.breakpoints.down(620)]: {
    padding: '2rem',
    position: 'absolute',
    marginInline: 0,
    bottom: '0px',
    background: 'transparent',
  },
}));

type StBoxProps = {
  isMobile: boolean;
  isTextOverlapInMob: boolean;
};

export const StHcpHeadingBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTextOverlapInMob',
})<StBoxProps>(({ theme, isMobile, isTextOverlapInMob }) => ({
  fontWeight: 600,
  fontFamily: 'Proxima Nova Regular',
  fontSize: '32px',
  lineHeight: isTextOverlapInMob ? '45px' : '38px',
  maxWidth: '1260px',
  marginInline: 'auto',
  color: isMobile && isTextOverlapInMob ? theme.palette.white.main : theme.palette.common.black,
  [theme.breakpoints.down('lg')]: {
    fontSize: '24px',
  },
}));

export const StHcpSubHeadingBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StBoxProps>(({ theme, isMobile, isTextOverlapInMob }) => ({
  fontWeight: 400,
  fontFamily: 'Proxima Nova Regular',
  fontSize: '16px',
  lineHeight: '24px',
  paddingTop: '8px',
  color: isMobile && isTextOverlapInMob ? theme.palette.white.main : theme.palette.common.black,
  maxWidth: '1308px',
  marginInline: 'auto',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
}));

export const StHcpImageWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '16px 16px 0 0',
  position: 'absolute',
  top: 19,
  width: '100%',
  paddingInline: '24px',
  color: theme.palette.common.black,
  maxWidth: '1305px',
  marginInline: 'auto',
  [theme.breakpoints.down('lg')]: {
    top: 12,
    paddingInline: '16px',
  },
  [theme.breakpoints.down(620)]: {
    top: 0,
    padding: 0,
  },

  '& img': { width: '100%' },
}));

export const StHcpOverlayImageWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '16px 16px 0 0',
  position: 'absolute',
  top: 19,
  width: '100%',
  paddingInline: '24px',
  color: theme.palette.common.black,
  maxWidth: '1305px',
  marginInline: 'auto',

  [theme.breakpoints.down('lg')]: {
    top: 12,
    paddingInline: '16px',
  },
  [theme.breakpoints.down(620)]: {
    top: 0,
    padding: 0,
  },

  '& img': { width: '100%', height: '100%', maxWidth: '1305px' },
}));
