/**
 * Formats a phone number string using a given format string.
 * @param format The format string to use for formatting the phone number.
 *  Use "#" to indicate where digits from the phone number should be inserted.
 *  Use "-" to include hyphens in the formatted number.
 * @param phoneNumber The phone number string to format.
 * @returns The formatted phone number string.
 */
function formatPhoneNumber(format: string, phoneNumber: string): string {
  if (phoneNumber?.length < format?.split('#').length - 1) {
    return '';
  }
  let formattedNumber = '';
  let phoneNumberIndex = 0;
  for (let i = 0; i < format.length; i++) {
    if (format[i] === '#') {
      formattedNumber += phoneNumber[phoneNumberIndex];
      phoneNumberIndex++;
    } else {
      formattedNumber += format[i];
    }
  }
  return formattedNumber;
}

export default formatPhoneNumber;
