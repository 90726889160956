import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SiteInfoCard from '../SiteInfoCard/SiteInfoCard';
import { TextField, RichTextField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect } from 'react';
import { CDP_EVENTS } from '../../constants';
import * as cdpService from '../../services/CdpService';

interface ISiteListProps {
  siteDetails: any;
  buttonText: TextField;
  siteAddressFormat: RichTextField;
  cardButtonAriaLabel?: string;
  mapIcon: ImageField;
  phoneIcon: ImageField;
  isError: boolean;
  virtualIcon: ImageField;
  virtualMapIcon: ImageField;
  virutalSiteText: TextField;
}
const SitesList: React.FC<ISiteListProps> = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const noSitesAvailableCDPEvent = () => {
    const extensionData = {};
    cdpService.event(CDP_EVENTS.SITE_SELECTION_NO_SITE_AVAILABLE, extensionData);
  };

  useEffect(() => {
    if (props?.siteDetails?.length === 0) {
      noSitesAvailableCDPEvent();
    }
  }, [props]);

  const calculateDynamicHeight = () => {
    if (!isDesktop) {
      const cardRows = Math.round(props?.siteDetails?.length / 2);
      const PADDING = 64;
      return `${cardRows * 196 + PADDING}px`;
    } else {
      return '524px';
    }
  };
  return (
    <Box
      id="sites-list"
      sx={{
        padding: isDesktop ? '13px 5px 13px 12px' : '0px 16px 0px 32px',
      }}
    >
      <Grid
        container
        sx={{
          overflowY: 'auto',
          height: calculateDynamicHeight(),
          maxHeight: '524px',
          padding: isDesktop ? '0 7px 0 0' : '0 16px 0 0',
        }}
        justifyContent="flex-start"
        spacing={isDesktop ? 0 : 1.2}
        rowGap={isDesktop ? 2 : 2}
      >
        {props.isError && <p>No sites available</p>}
        {!props.isError &&
          Array.isArray(props.siteDetails) &&
          props.siteDetails.length > 0 &&
          props.siteDetails.map((siteItem, index) => {
            return (
              <Grid item key={index} sx={{ width: '100%' }} xs={isDesktop || isMobile ? 12 : 6}>
                <SiteInfoCard
                  siteInfo={siteItem}
                  buttonText={props.buttonText}
                  siteAddressFormat={props.siteAddressFormat}
                  cardButtonAriaLabel={props.cardButtonAriaLabel}
                  mapIcon={props?.mapIcon}
                  phoneIcon={props?.phoneIcon}
                  virtualIcon={props?.virtualIcon}
                  virtualMapIcon={props?.virtualMapIcon}
                  virutalSiteText={props?.virutalSiteText}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
export default SitesList;
