import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  StCRQuestionnairTagText,
  StQuestionairContainer,
  StTagSubTitle,
  StTagText,
  StTagTitle,
} from '../Carousel.styles';
import { UIButton } from '../../../../components/shared/UIButton.styles';
import CRSiteInfoModal from '../../../../components/shared/CustomModal/CRSiteInfoModal';
import ViewSiteDetails from '../../../../components/MyProfileQuestionnaire/ViewSiteDetails';
import { CardContentBox } from '../../../../components/MyProfileQuestionnaire/MyProfileQuestionnaire.style';
import { STUDY_STATUS } from '../../../../components/ContactInfo/constants';

const CRQuestionnairCard = (props: any): JSX.Element => {
  const theme = useTheme();
  const router = useRouter();
  const unMatchColor = theme.palette.neutral7.main;
  const matchColor = theme.palette?.teal?.main;
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const fields = props?.dataProps?.fields || {};
  const cardData = props?.data || props?.data?.fields || {};
  const publishDatePrefix =
    cardData?.studyStatus?.toLowerCase() === fields?.inCompleteText?.value?.toLowerCase()
      ? fields?.attemptedOnText?.value
      : fields?.completedOnText?.value;
  const publishDate = cardData?.studyCompletionDate;
  const isMatchStudy = cardData?.studyStatus === fields?.matchedText?.value;
  const actionButtonName = isMatchStudy
    ? fields?.siteInfoText?.value
    : fields?.viewDetailText?.value;

  const getCardWidth = () => {
    if (props?.isMobile || props?.isTablet) {
      return 306;
    }
    return 272;
  };

  const getCardMaxWidth = () => {
    if (props?.isMobile || props?.isTablet) {
      return 306;
    }
    return 272;
  };

  const modalStyles: React.CSSProperties = {
    top: '50%',
  };

  const LoadDetailModal = () => {
    return (
      <CRSiteInfoModal
        open={loadingModalOpen}
        heading={fields?.siteModalSectionHeading?.value}
        style={modalStyles}
        showActionButton
        onActionButtonClick={() => setLoadingModalOpen(false)}
        onClose={() => setLoadingModalOpen(false)}
        variant={'text'}
        actionButtonText={fields?.closeLabel?.value}
      >
        <ViewSiteDetails
          cardTitle={fields?.siteInfoText?.value}
          siteInfo={cardData?.studySiteInfo}
          mapsLink={fields?.mapsLink?.value}
          siteAddressFormat={fields?.siteAddressFormat?.value}
          icons={{
            hospitalIcon: fields?.hospitalIcon?.value,
            locationPinIcon: fields?.locationPinIcon?.value,
            phoneIcon: fields?.phoneIcon?.value,
          }}
        />
      </CRSiteInfoModal>
    );
  };
  const onActionButtonClick = () => {
    if (isMatchStudy) return setLoadingModalOpen(true);
    router.push(`/${cardData?.studyLink}`);
  };
  const fetchStatusName = (status: string) => {
    return status === STUDY_STATUS.MATCHED
      ? fields?.matchedText?.value
      : status === STUDY_STATUS.INCOMPLETE
      ? fields?.inCompleteText?.value
      : fields?.unMatchedText?.value;
  };

  return (
    <StQuestionairContainer
      sx={{
        minWidth: getCardWidth(),
        maxWidth: getCardMaxWidth(),
        marginInline: props?.isTablet || props?.isMobile ? 'none' : 'auto',
        margin: props?.isTablet || props?.isMobile ? '1.4rem' : 'none',
        borderRadius: '0.6rem',
        boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
        padding: '24px 0px',
        height: '217px',
      }}
    >
      <CardContentBox>
        <StCRQuestionnairTagText
          sx={{
            background: isMatchStudy ? matchColor : unMatchColor,
          }}
        >
          <StTagText>
            <Text field={{ value: fetchStatusName(cardData?.studyStatus) }} />
          </StTagText>
        </StCRQuestionnairTagText>

        <StTagTitle sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Text field={{ value: cardData?.studyName }} />
        </StTagTitle>
        <StTagSubTitle
          sx={{
            marginBottom: '0.5rem',
            display: 'flex',
          }}
        >
          <Text field={{ value: `${publishDatePrefix} ${publishDate}` }} />
        </StTagSubTitle>
        <UIButton
          variant={isMatchStudy ? 'contained' : 'outlined'}
          sx={{ minWidth: 'unset', p: '5px 24px' }}
          onClick={onActionButtonClick}
        >
          {actionButtonName}
        </UIButton>
      </CardContentBox>
      <LoadDetailModal />
    </StQuestionairContainer>
  );
};

export default CRQuestionnairCard;
