/* eslint-disable react/jsx-key */
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import StudyListingCard from './StudyListingCard';
import {
  STCRWrapper,
  StCRGridContainerWithoutBackground,
} from '../shared/Container/Container.styles';
import {
  SLBannerHeaderText,
  SLBannerWrapper,
  SLMainBoxContainer,
  SLTextField,
} from './StudyListing.style';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';

interface IStudyListingProps {
  [key: string]: any;
}

const StudyListing: React.FC<IStudyListingProps> = (props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [studyEprList, setStudyEprList] = useState<any>([]);

  useEffect(() => {
    if (searchValue) {
      const list = props?.fields?.studyEprList || [];
      const filterValue = list?.filter((a: any) =>
        a?.fields?.studyCondition?.fields?.description?.value
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
      );
      setStudyEprList(filterValue);
    } else {
      setStudyEprList(props?.fields?.studyEprList);
    }
  }, [searchValue]);

  return (
    <SLMainBoxContainer>
      {props?.fields?.isSearchBoxVisible?.value && (
        <SLBannerWrapper>
          <STCRWrapper>
            <StCRGridContainerWithoutBackground sx={{ marginBlock: '1rem' }}>
              <Grid container>
                <Grid item xs={12}>
                  <SLBannerHeaderText gutterBottom variant="h1" data-testid="hero-and-search-title">
                    <Text field={props?.fields?.searchSectionHeading}></Text>
                  </SLBannerHeaderText>
                </Grid>
                <Grid item xs={12}>
                  <SLTextField
                    variant="standard"
                    margin="none"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e?.target?.value)}
                    placeholder={props?.fields?.searchPlaceholderLabel?.value}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ border: 'none' }}>
                          <SearchIcon style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
            </StCRGridContainerWithoutBackground>
          </STCRWrapper>
        </SLBannerWrapper>
      )}
      <STCRWrapper>
        <StCRGridContainerWithoutBackground sx={{ marginBlock: '2rem' }}>
          <Grid container rowGap={7} columnSpacing={6}>
            {studyEprList?.map((a: any) => (
              <Grid item xs={12} md={6} lg={4}>
                <StudyListingCard
                  {...a}
                  descriptionWordLimit={props?.fields?.descriptionWordLimit?.value || 30}
                  titleCharacterLimit={props?.fields?.titleCharacterLimit?.value || 30}
                  titleLineLimit={props?.fields?.titleLineLimit?.value || 2}
                  descriptionLineLimit={props?.fields?.descriptionLineLimit?.value || 3}
                />
              </Grid>
            ))}
          </Grid>
        </StCRGridContainerWithoutBackground>
      </STCRWrapper>
    </SLMainBoxContainer>
  );
};

export default StudyListing;
