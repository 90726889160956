import { RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import pnMergetagReplacer from '../../../lib/utils/pnMergeTagReplacer';
import studyConditionMergeTagReplacer from '../../../lib/utils/studyConditionMergeTagReplacer';
import getCallCenterNumber from '../../../store/selectors/getCallCenterNumber';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

type StRichTextItemProps = {
  inheritLinkColor?: boolean;
};

export const StRichText = styled(RichText, {
  shouldForwardProp: (prop) => prop !== 'inheritLinkColor',
})<StRichTextItemProps>(({ inheritLinkColor }) => ({
  '& a': {
    color: inheritLinkColor ? 'inherit' : 'none',
  },
}));

const RichTextWithStudyPnMergeTag = ({ field, cssClassName, inheritLinkColor }: any) => {
  const { sitecoreContext } = useSitecoreContext();
  const callCenterNumber = getCallCenterNumber(sitecoreContext);
  const siteSettings: any = sitecoreContext?.siteSettings;
  const primaryStudyName = siteSettings?.studyName?.value;
  const primaryConditionName = siteSettings?.projectTherapeuticArea?.fields?.description?.value;
  const [content, setContent] = useState(field);

  useEffect(() => {
    if (callCenterNumber?.value || primaryStudyName || primaryConditionName) {
      const newContent = pnMergetagReplacer(field?.value, String(callCenterNumber?.value), true);
      const newContentWithStudyCondition = studyConditionMergeTagReplacer(
        newContent,
        primaryStudyName,
        primaryConditionName
      );
      setContent({ ...content, value: newContentWithStudyCondition });
    }
  }, [callCenterNumber?.value, primaryStudyName, primaryConditionName]);

  return (
    <StRichText
      field={content}
      className={cssClassName ? cssClassName : ''}
      inheritLinkColor={inheritLinkColor ? inheritLinkColor : false}
    ></StRichText>
  );
};

export default RichTextWithStudyPnMergeTag;
