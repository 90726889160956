import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PaginationItem from '@mui/material/PaginationItem';

export const StButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: '1rem',
}));

export const StPaginationItem = styled(PaginationItem)(({ theme }) => ({
  color: `${theme.palette.black.main} !important`,
}));
