import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import lowerCase from 'lodash/lowerCase';
import { ComponentProps } from 'lib/component-props';
import { ButtonType, IButton } from '../../lib/type/Button';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { StButtonStack, StLinkButton } from './ButtonGroups.styles';

type ButtonProps = ComponentProps & {
  fields: {
    ctaButtons: IButton[];
  };
};

const fallbackLink: LinkField = {
  value: {
    href: '',
    text: '',
  },
};

const Buttons = (props: ButtonProps): JSX.Element => {
  if (!props?.fields) return <></>;
  const { fields } = props;

  return (
    <Box
      display={'flex'}
      sx={{
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Grid>
        <StButtonStack spacing={2}>
          {fields?.ctaButtons.map((ctaButton: IButton) => {
            const btnType: string = ctaButton?.fields?.buttonVariant?.value || ButtonType.CONTAINED;
            if (lowerCase(btnType) === ButtonType.CONTAINED) {
              return (
                <StLinkButton
                  key={ctaButton?.id}
                  variant="contained"
                  field={ctaButton?.fields?.link || fallbackLink}
                />
              );
            } else if (btnType === ButtonType.OUTLINED) {
              return (
                <StLinkButton
                  key={ctaButton?.id}
                  variant="outlined"
                  field={ctaButton?.fields?.link || fallbackLink}
                />
              );
            }
            return (
              <StLinkButton key={ctaButton?.id} field={ctaButton?.fields?.link || fallbackLink} />
            );
          })}
        </StButtonStack>
      </Grid>
    </Box>
  );
};

export default Buttons;
