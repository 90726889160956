import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
  margin: '2rem 8rem',
  [theme.breakpoints.down('lg')]: {
    margin: '1rem 4rem',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    margin: '1rem 2rem',
    ['& .contentblock-content']: {
      textAlign: 'left',
      marginTop: '16px',
    },
  },
  [theme.breakpoints.up('xl')]: {
    width: '1300px',
    marginInline: 'auto !important',
  },
}));
