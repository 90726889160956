import { styled } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type StBoxProps = {
  singleline: boolean;
};

type StTypographyProps = {
  singleline: boolean;
  invertColor: boolean;
};

export const StTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'singleline' && prop !== 'invertColor',
})<StTypographyProps>(({ theme, singleline, invertColor }) => ({
  color: invertColor ? theme.palette.white.main : theme.palette.themeColor.main,
  textAlign: 'center',
  marginLeft: singleline ? '1rem' : 0,
  marginTop: singleline ? 0 : '1rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: singleline ? 0 : '1rem',
  },
  // [singleline ? theme.breakpoints.down(480) : theme.breakpoints.down(300)]: {
  //   fontSize: '1rem',
  //   fontFamily: `"Proxima Nova Semibold", sans-serif`,
  //   lineHeight: '1.25rem',
  //   fontWeight: 500,
  // },
}));

export const StBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'singleline',
})<StBoxProps>(({ singleline }) => ({
  display: 'flex',
  flexDirection: singleline ? 'row' : 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem',
}));
