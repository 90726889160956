// Third party imports
import {
  LinkField,
  Text,
  ImageField,
  Field,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import {
  StstickyBox,
  StStickyContainer,
  StButtonsContainer,
  StCtaButtons,
  StMoveTotopLink,
  StFooterText,
  StUpArrowImg,
  StJSSLink,
  StMoveText,
} from './StickyFooter.styles';
import useStickyScroll from '../../lib/hooks/useStickyScroll';
import Box from '@mui/material/Box';
import { trackEvent } from '../../lib/utils/gtmService';
import MoveTopIcon from '../../../public/move-top.png';
import { ButtonType } from '../../lib/type/Button';
import { CR_SITE } from '../../lib/constant';

interface CtaButtonItem {
  fields: {
    buttonVariant?: Field<string>;
    link: LinkField;
    image?: ImageField;
    ariaLabel?: Field<string>;
  };
}

export interface IStickyData {
  ctaAllignment: Field<string>;
  ctaButtons: Array<CtaButtonItem>;
  footerText: Field<string>;
  scrollToTopText: Field<string>;
  enableAutoShowMobile: Field<boolean>;
}

interface StickyFooterProps {
  stickyData: IStickyData;
}

const StickyFooter = (props: StickyFooterProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const router = useRouter();
  const theme = useTheme();
  const siteName = sitecoreContext?.site?.name;
  const enableAutoShowMobile = props?.stickyData?.enableAutoShowMobile?.value;

  const { scrolling, scrollThreshold } = useStickyScroll(router, enableAutoShowMobile);
  const isLaptopView = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  if (!props?.stickyData) return <></>;
  const { stickyData } = props;
  const { ctaAllignment, footerText, ctaButtons, scrollToTopText } = stickyData;
  const moveToTop = () => {
    window.scrollTo(0, 0);
  };
  const scrollToSection = (e: any, item: any) => {
    e.preventDefault(); // Removed as internal links were not working
    trackEvent(
      {
        event: 'Sticky CTA Clicked',
      },
      siteName === CR_SITE ? true : false
    );
    try {
      if (item) {
        const itemId = item?.fields?.link?.value?.anchor;
        const targetElement = document.getElementById(itemId);
        if (targetElement) {
          window.scroll({
            top: targetElement.offsetTop,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    } catch (_error) {}
  };

  const alignment = ctaAllignment?.value;
  const numberOfCTA = ctaButtons?.length;

  const renderFooterText = () => {
    if (
      (alignment === 'Right' && isLaptopView && numberOfCTA >= 1) ||
      (alignment === 'Right' && !isLaptopView && numberOfCTA === 1)
    ) {
      return (
        <StFooterText>
          <Text field={footerText} />
        </StFooterText>
      );
    } else {
      return;
    }
  };

  const buttonAlignment = () => {
    if (
      (alignment === 'Right' && isLaptopView && numberOfCTA >= 1) ||
      (alignment === 'Right' && !isLaptopView && numberOfCTA === 1)
    ) {
      return 'space-between';
    } else if (alignment === 'Right' && !isLaptopView && numberOfCTA === 2) {
      return 'center';
    } else {
      return 'center';
    }
  };

  if (!scrolling) {
    return <></>;
  }

  return (
    <StstickyBox data-testid="stickyFooter">
      <StStickyContainer data-testid="stickyContainer">
        {((alignment === 'Right' && !isLaptopView && numberOfCTA > 1) ||
          alignment === 'Center') && <Box sx={{ display: 'flex', width: '107.6px' }}></Box>}
        <StButtonsContainer data-testid="first" sx={{ justifyContent: buttonAlignment() }}>
          {renderFooterText()}
          <StCtaButtons data-testid="second" alignment={alignment}>
            {ctaButtons?.map((button, i) => {
              if (button?.fields?.buttonVariant?.value === ButtonType.CONTAINED) {
                return (
                  <Box
                    onClick={(e) => scrollToSection(e, button)}
                    key={i}
                    sx={{ ...(isMobileView && { width: '100%' }) }}
                  >
                    <StJSSLink
                      className={'sticky-footer-btn btn'}
                      variant={button?.fields?.buttonVariant?.value}
                      aria-label={button?.fields?.ariaLabel?.value}
                      field={button?.fields?.link}
                      alignment={alignment}
                      numberOfCTA={numberOfCTA}
                    />
                  </Box>
                );
              } else {
                return (
                  <StJSSLink
                    className={'sticky-footer-btn btn'}
                    key={i}
                    variant={button?.fields?.buttonVariant?.value}
                    aria-label={button?.fields?.ariaLabel?.value}
                    field={button?.fields?.link}
                    alignment={alignment}
                    numberOfCTA={numberOfCTA}
                  />
                );
              }
            })}
          </StCtaButtons>
        </StButtonsContainer>
        {scrollThreshold && (
          <StMoveTotopLink onClick={moveToTop} data-testid="moveToTop">
            <StUpArrowImg
              alt="up-arrow"
              src={MoveTopIcon?.src || MoveTopIcon}
              height={8}
              width={8}
            />
            <StMoveText>{scrollToTopText?.value}</StMoveText>
          </StMoveTotopLink>
        )}
      </StStickyContainer>
    </StstickyBox>
  );
};

export default StickyFooter;
