import { ErrorMessage } from '@hookform/error-message';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Image from 'next/image';
import { Controller } from 'react-hook-form';
import { StCheckBoxLabel, StFormLabel, StInputBase } from '../FormElements.styles';
import {
  InputBaseProps,
  InputCheckBoxProps,
  InputLabelProps,
  InputProps,
} from '../FormElements.types';
import FormToolTip from '../FormToolTip';
import { getDialingCodeByISOCode } from '../../../../lib/utils/dialingCodeList';

const InputCheckBox = ({ isMobileLabel, control }: InputCheckBoxProps) => (
  <Controller
    name={'isMobile'}
    control={control}
    render={({ field: { onChange, value, onBlur } }) => (
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              padding: '0 2px',
              alignSelf: 'flex-start',
              background: '#ffffff',
              '& .MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
              },
            }}
          />
        }
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        label={
          <StCheckBoxLabel>
            <Text field={isMobileLabel} />
          </StCheckBoxLabel>
        }
        sx={{
          margin: 0,
        }}
      />
    )}
  />
);

const InputLabel = ({
  name,
  label,
  toolTip,
  showIsMobileOption,
  isMobileLabel,
  control,
}: InputLabelProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <StFormLabel htmlFor={name}>
      <Text field={label} />
      {toolTip && <FormToolTip value={toolTip} />}
    </StFormLabel>
    {showIsMobileOption && showIsMobileOption.value ? (
      <InputCheckBox isMobileLabel={isMobileLabel} control={control} />
    ) : (
      ''
    )}
  </Box>
);

const InputBase = ({
  name,
  placeholder,
  onChangeHandler,
  value,
  errors,
  countryFlag,
  countryCode,
  maxLength,
  disabled,
  showIsMobileOption,
  width,
}: InputBaseProps) => (
  <StInputBase
    name={name}
    data-testid={name}
    id={name}
    aria-describedby={placeholder}
    disabled={disabled}
    placeholder={placeholder}
    value={value}
    onChange={onChangeHandler}
    startAdornment={
      showIsMobileOption && (
        <InputAdornment position="start">
          <Image src={countryFlag} alt="CF" height={15} width={22} />
          {countryCode && (
            <Box sx={{ marginInline: '4px' }}>{getDialingCodeByISOCode(countryCode)}</Box>
          )}
        </InputAdornment>
      )
    }
    inputProps={{
      maxLength: maxLength,
    }}
    sx={{
      border: (theme: Theme) =>
        errors && errors[name]
          ? `1px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.neutral4.main}`,
      padding: '5px 8px !important',
      width: width,
    }}
  />
);

const CustomInput = ({
  name,
  toolTip,
  placeholder,
  label,
  onChangeHandler,
  value,
  errors,
  countryFlag,
  countryCode,
  maxLength,
  disabled,
  onBlur,
  showIsMobileOption,
  isMobileLabel,
  control,
  width,
}: InputProps) => (
  <FormControl>
    <InputLabel
      name={name}
      label={label}
      toolTip={toolTip}
      showIsMobileOption={showIsMobileOption}
      isMobileLabel={isMobileLabel}
      control={control}
    />
    <InputBase
      placeholder={placeholder}
      onChangeHandler={onChangeHandler}
      value={value}
      countryFlag={countryFlag}
      countryCode={countryCode}
      maxLength={maxLength}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      errors={errors}
      showIsMobileOption={showIsMobileOption}
      width={width}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Box sx={{ color: (theme) => theme.palette.error.main }}>{message}</Box>
        )}
      />
    )}
  </FormControl>
);

export { CustomInput };
