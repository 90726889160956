import { ImageField } from '@sitecore-jss/sitecore-jss-react';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';

const ThreeStepImage: React.FC<{ field: ImageField | undefined }> = (props) => {
  if (!props.field?.value?.src) {
    return <></>;
  }
  return <NextImage field={props.field} />;
};

export default ThreeStepImage;
