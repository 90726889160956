import { buildSearchEngine, getOrganizationEndpoints, SearchEngine } from '@coveo/headless';

// eslint-disable-next-line @typescript-eslint/ban-types
const headlessEngine: SearchEngine<{}> = buildSearchEngine({
  configuration: {
    organizationId:
      process.env.NEXT_PUBLIC_COVEO_ORG_ID || 'iqviafnaquintilesimsnonproduction12c6jth8d',
    accessToken:
      process.env.NEXT_PUBLIC_COVEO_ACCESSTOKEN || 'xx7f5138ce-1ddd-4e63-a922-0730d9795d4b',
    organizationEndpoints: getOrganizationEndpoints(
      process.env.NEXT_PUBLIC_COVEO_ORG_ID || 'iqviafnaquintilesimsnonproduction12c6jth8d'
    ),
    search: {
      searchHub: process.env.NEXT_PUBLIC_COVEO_TRIAL_SEARCHHUB || 'IQVIA Find Trials CM',
    },
    analytics: {
      originLevel3:
        typeof window !== 'undefined'
          ? document.referrer || '/find-trials/search-result'
          : '/find-trials/search-result',
    },
  },
});

export default headlessEngine;
