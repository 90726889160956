import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import TrialDetailsContent from './TrialDetailsContent';

const TrialDetails = (props: ComponentProps): JSX.Element => {
  const JssTrialTitle = () => <Placeholder name="jss-trial-title" rendering={props.rendering} />;
  const JssTrialLeft = () => <Placeholder name="jss-trial-left" rendering={props.rendering} />;
  const JssTrialRight = () => <Placeholder name="jss-trial-right" rendering={props.rendering} />;
  return (
    <TrialDetailsContent
      JssTrialLeft={JssTrialLeft}
      JssTrialRight={JssTrialRight}
      JssTrialTitle={JssTrialTitle}
    />
  );
};
export default TrialDetails;
