import { SitecoreContextValue } from '@sitecore-jss/sitecore-jss-react';
import { TextField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import formatPhoneNumber from '../../lib/utils/formatNumber';
const isBrowser = () => typeof window !== 'undefined';
export interface ICallCenterContext {
  callCenterDefaultValue?: TextField;
  callCenterLength?: TextField;
  callCenterPrefixDailingcode?: TextField;
  callCenterAriaLabel?: TextField;
  callCenterFormat?: TextField;
  callCenterIcon?: ImageField;
}

interface IStcoreContextCallCenter extends SitecoreContextValue {
  callcenter?: ICallCenterContext;
}

/**
 * Retrieves and formats a phone number.
 * The function looks for a phone number in the following sources, in order of priority:
 * 1. Directly from URL query params `pn`
 * 2. From Sitecore context
 * @param {RootState} state - The root state object, possibly containing a `queryParams` object with a phone number `pn` property.
 * @param {IStcoreContextCallCenter} sitecoreContext - The Sitecore context object, possibly containing call center context information.
 * @returns {TextField} The formatted phone number or default value from `sitecoreContext`.
 */
const getCallCenterNumber = (sitecoreContext: IStcoreContextCallCenter): TextField => {
  let finalPhoneNo: TextField = { value: '' };
  const unFormattedPhoneNo: TextField = { value: '' };
  if (!sitecoreContext) {
    return { value: '' };
  }
  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    const pnFromURL = urlParams.get('pn');
    if (pnFromURL && typeof pnFromURL === 'string') {
      unFormattedPhoneNo.value = pnFromURL;
    }
    // verify unFormattedPhoneNo.value is number
    const isNumber = !isNaN(Number(unFormattedPhoneNo?.value));
    if (unFormattedPhoneNo.value && isNumber) {
      // callCenterLength equality Check
      const callCenterLength = Number(sitecoreContext?.callcenter?.callCenterLength?.value) || 0;
      const unFormattedPhoneNoLength = String(unFormattedPhoneNo.value).length;
      if (
        callCenterLength &&
        !isNaN(callCenterLength) &&
        callCenterLength > 0 &&
        unFormattedPhoneNoLength === callCenterLength
      ) {
        // Format Phone number
        const format = sitecoreContext?.callcenter?.callCenterFormat?.value || '';
        const countryCode = sitecoreContext?.callcenter?.callCenterPrefixDailingcode?.value || '';
        const formattedPn = formatPhoneNumber(String(format), String(unFormattedPhoneNo.value));
        if (formattedPn) {
          // Prefix country code
          const pnWithCountryCode = `${countryCode}${formattedPn}`;
          finalPhoneNo.value = pnWithCountryCode;
        }
      }
    }
    if (!finalPhoneNo.value) {
      // Set the default call center field from Sitecore
      const callCenterDefaultValue = sitecoreContext?.callcenter?.callCenterDefaultValue;
      if (callCenterDefaultValue?.value) {
        finalPhoneNo = callCenterDefaultValue;
      }
    }
  }
  return finalPhoneNo;
};
export default getCallCenterNumber;
