import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const PhoneIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.1138 13.7831C18.1254 13.7948 21.7347 17.4048 21.7347 17.4048C22.7855 19.009 20.4597 21.0524 19.2071 21.7066C17.8038 22.4399 15.7704 21.6424 14.4254 21.0441C12.0212 19.9757 9.9353 18.3707 8.08444 16.5198C6.01273 14.4639 4.15353 12.2672 2.95684 9.57468C2.3585 8.22882 1.56099 6.19629 2.2935 4.79293C2.94768 3.53957 4.99188 1.21453 6.59608 2.26538L10.217 5.88628C11.577 7.2463 8.79028 8.90633 8.78945 10.0747C8.78862 10.858 9.23196 11.5647 9.6328 12.2056C10.1086 12.9647 10.7287 13.6281 11.4562 14.1498C12.1453 14.6448 13.0487 15.2123 13.9262 15.2114C15.0937 15.2106 16.7538 12.4231 18.1138 13.7831Z" />
    </SvgIcon>
  );
};

export default PhoneIcon;
