import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StImageCtaCardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 0px 20px 0px',
  width: '245px',
  height: '349px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    height: '309px',
  },
}));
