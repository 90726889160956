import CheckboxWithLabelContainer from 'components/shared/CheckboxWithLabel/CheckboxWithLabelContainer';

const PatientEnquiryQuestion = (props: any): JSX.Element => {
  return (
    <CheckboxWithLabelContainer
      label={props?.sectionTitle}
      options={props?.patientEnquiries}
      handleChange={props?.handleChange}
      storedAnswers={props?.storedAnswers}
    />
  );
};

export default PatientEnquiryQuestion;
