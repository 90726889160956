import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const StNoScriptBannerContainer = styled(Grid)(({ theme }) => ({
  p: {
    color: theme?.palette?.neutral0.main,
    fontWeight: '600',
    fontSize: '1rem',
  },
  '.typo': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default StNoScriptBannerContainer;
