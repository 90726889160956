import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import { bgcolor } from '@mui/system';
//import { bgcolor, color } from '@mui/system';

export const SupportContentContainer = styled(Grid)(({ theme }) => ({
  margin: '51px auto',
  padding: '0 30px',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    padding: '0',
    margin: '4rem auto !important',
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    margin: '1rem auto !important',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 30px',
    width: '900px',
    margin: '4rem auto !important',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0',
    margin: '1rem auto !important',
  },
}));

export const SupportContentItem = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  [theme.breakpoints.down('lg')]: {
    margin: '0 auto',
    maxWidth: '400px',
    padding: 0,
    paddingInline: '1rem',
  },
}));

export const SupportHeader = styled(Box)(({ theme }) => {
  return {
    color: theme.palette.neutral0.main,
    '& a[href^="tel:"]': {
      color: theme.palette.neutral0.main,
      textDecoration: 'underline dotted',
      '&:hover': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '10px',
    padding: '35px 6% 35px 6%',
    [theme.breakpoints.down('md')]: {
      padding: '35px 35px',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '35px 30px !important',
      width: '1300px',
      margin: '0 auto !important',
    },
  };
});

export const SupportTitle = styled(Box)(({}) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.6rem',
  alignItems: 'center',
  textAlign: 'center',
}));

export const StSupportFormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '140px',
  rowGap: '1.2rem',
  '.MuiInputBase-root': {
    background: theme.palette.neutral0.main,
    borderRadius: '8px',
  },
  '.MuiInputBase-root fieldset': {
    border: '1px solid #E9E9E9',
  },
  '.MuiSelect-select': {
    color: '#757575',
  },
  '::placeholder': {
    color: '#757575',
    opacity: '1',
  },
  '.MuiInputBase-inputMultiline::placeholder': {
    color: '#000',
  },

  '.submit-button': {
    padding: '0.3rem',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '150px',
    },
  },
  '.MuiInputBase-root.Mui-disabled': {
    background: theme.palette.neutral2.main,
  },
  '.Mui-disabled': {
    WebkitTextFillColor: `${theme.palette.neutral9.main} !important`,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
}));
