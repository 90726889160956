export const stickyData = {
  stickyData: {
    ctaAllignment: {
      value: 'Center',
    },
    ctaButtons: [
      {
        id: '581a419f-f7e6-469a-bf55-4a4fd7f7aa42',
        url: '/ContextSettings/Sticky-Footer/Sticky-Footer-Item/Learn-More',
        name: 'Learn More',
        displayName: 'Learn More',
        fields: {
          buttonVariant: {
            value: 'contained',
          },
          link: {
            value: {
              href: 'https://www.google.com',
              text: 'Learn more',
              linktype: 'external',
              url: 'https://www.google.com',
              anchor: '',
              target: '_blank',
            },
          },
        },
      },
      {
        id: '0c03361d-0f70-40ba-93be-4bf1133085bb',
        url: '/ContextSettings/Sticky-Footer/Sticky-Footer-Item/See-if-you-Qualify',
        name: 'See if you Qualify',
        displayName: 'See if you Qualify',
        fields: {
          buttonVariant: {
            value: 'outlined',
          },
          link: {
            value: {
              href: 'https://www.google.com',
              text: 'See if you qualify',
              linktype: 'external',
              url: 'https://www.google.com',
              anchor: '',
              target: '_blank',
            },
          },
        },
      },
    ],
    footerText: {
      value: 'Are you interested in participating in this study?',
    },
    scrollToTopText: {
      value: 'Back to top',
    },
    enableAutoShowMobile: {
      value: true,
    },
  },
};
