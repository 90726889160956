import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Field, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Container } from './ContentBlock.styles';
import TextWithStudyPnMergeTag from '../shared/TextWithStudyPnMergeTag/TextWithStudyPnMergeTag';
import RichTextWithStudyPnMergeTag from '../shared/RichTextWithStudyPnMergeTag/RichTextWithStudyPnMergeTag';

export type ContentBlockProps = ComponentProps & {
  fields?: {
    heading?: TextField;
    content?: Field<string>;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */

const ContentBlock = (props: ContentBlockProps): JSX.Element => {
  if (!props?.fields) return <></>;
  const { fields } = props;
  const heading = fields?.heading;
  const content = fields?.content;

  return (
    <>
      {heading?.value || content?.value ? (
        <Container>
          <Box>
            {heading?.value && (
              <Typography
                variant="h4"
                fontWeight={'bold'}
                color={(theme: Theme) => theme?.palette?.themeColor?.main}
                gutterBottom
                fontSize="20px"
                lineHeight="32px"
              >
                <TextWithStudyPnMergeTag field={heading} inheritLinkColor={false} />
              </Typography>
            )}
            {content?.value && (
              <RichTextWithStudyPnMergeTag
                field={content}
                cssClassName={'contentblock-content'}
                inheritLinkColor={false}
              ></RichTextWithStudyPnMergeTag>
            )}
          </Box>
        </Container>
      ) : null}
    </>
  );
};

export default ContentBlock;
