// hooks/useGeolocation.ts
import { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { setGeolocation, setGoogleScriptLoaded } from '../../features/geolocationSlice';
import { useDispatch } from 'react-redux';

export interface Location {
  latitude: number;
  longitude: number;
  state: string | null;
  city: string | null;
  zipcode: string | null;
}

interface GeolocationHookResult {
  location: Location | null;
  isLoaded: boolean;
  error: string | null;
}

const useGeolocation = (): GeolocationHookResult => {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const [libraries] = useState<
    ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]
  >(['places']);
  const lng =
    sitecoreContext?.site?.name === 'ClinicalResearch' ? 'en' : sitecoreContext?.language || 'en';

  let isLoaded = false;

  try {
    const loaderObj = useLoadScript({
      googleMapsApiKey: atob(siteSettings?.googleMapsAPIKey?.value || ''),
      libraries: libraries,
      language: lng,
    });
    isLoaded = loaderObj.isLoaded;
  } catch (_error) {}

  const dispatch = useDispatch();

  useEffect(() => {
    // Check if geolocation is available in the browser
    if (!navigator.geolocation || isEditorActive()) {
      setError('Geolocation is not available in this browser.');
      return;
    }

    if (isLoaded) {
      //Update to redux when google scripts are loaded
      dispatch(setGoogleScriptLoaded());
      // Request geolocation permission
      true &&
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            // Use Google Maps Geocoding API from the 'places' library
            const geocoder = new google.maps.Geocoder();
            const latLng = new google.maps.LatLng(latitude, longitude);

            geocoder.geocode({ location: latLng }, (results: any, status: string) => {
              if (status === 'OK' && results.length > 0) {
                const addressComponents = results[0].address_components;
                const state = addressComponents.find((component: any) =>
                  component.types.includes('administrative_area_level_1')
                )?.long_name;
                const city = addressComponents.find((component: any) =>
                  component.types.includes('locality')
                )?.long_name;
                const zipcode = addressComponents.find((component: any) =>
                  component.types.includes('postal_code')
                )?.long_name;
                setLocation({
                  latitude,
                  longitude,
                  state: state || null,
                  city: city || null,
                  zipcode: zipcode || null,
                });
              } else {
                setError('Geocoding request failed.');
              }
            });
          },
          (error) => {
            setError('Error getting geolocation: ' + error.message);
          }
        );
    }
  }, [isLoaded]);

  useEffect(() => {
    if (location) {
      dispatch(
        setGeolocation({ location: { ...location, zipcode: location?.zipcode }, error: null })
      );
    } else {
      dispatch(setGeolocation({ location: null, error: error }));
    }
  }, [location, error, dispatch]);

  return { location, isLoaded, error };
};

export default useGeolocation;
