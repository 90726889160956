import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { JSSLink } from '../../shared/Links/JSSLink.styles';
import { HeroBannerType } from './DTPHeroBanner.type';

export const StBannerWrapper = styled(Grid)(({}) => ({
  position: 'relative',
}));

export const StBannerContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string; gridBackground?: string | null }>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: variant === HeroBannerType.V4 ? 'row' : 'column',
  justifyContent: variant === HeroBannerType.V4 ? 'space-between' : 'flex-end',
  alignItems: variant === HeroBannerType.V4 ? 'flex-end' : 'flex-start',
  flexWrap: 'nowrap',
  backgroundSize: 'cover',
  minHeight: '475px',
  paddingBlock: '70px',
  paddingLeft: '6.5rem',
  paddingRight: '5.5rem',
  [theme.breakpoints.down(1220)]: {
    paddingLeft: '4rem',
    paddingRight: '3rem',
  },
  [theme.breakpoints.down(1150)]: {
    paddingLeft: '3rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.down(1080)]: {
    padding: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '2rem',
    width: '100vw',
  },
  [theme.breakpoints.up('xl')]: {
    width: '1366px',
    margin: '0 auto !important',
  },
}));

export const StBannerContent = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  color: theme.palette.white.main,
}));

export const StRichTextGridItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: string }>(({ theme, variant }) => ({
  color: theme.palette.white.main,
  maxWidth: variant === HeroBannerType.V4 ? '340px !important' : '100% !important',
  p: {
    margin: '0px 0px',
    marginBottom: variant === HeroBannerType.V4 ? '0' : '1.5rem',
    fontSize: '1.5rem',
    fontFamily: 'Proxima Nova SemiBold',
    lineHeight: '2.25rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
    strong: {
      display: 'block',
      maxWidth: '582px',
    },
    ul: {
      paddingLeft: '20px',
      li: {
        margin: '5px -10px !important',
        fontSize: '1.25rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.25rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '1rem',
        },
      },
    },
  },
  '.note': {
    margin: '10px 0px 5px 0px',
    fontSize: '1.25rem',
    lineHeight: '2.25rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
}));

export const StButtonStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'spacing',
})<{ variant?: string; spacing: number }>(({ theme, spacing }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    'a:nth-of-type(2)': {
      marginLeft: theme.spacing(spacing),
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
    a: {
      width: '100%',
      padding: '8px',
    },
    'a:nth-of-type(2)': {
      marginLeft: theme.spacing(spacing),
    },
  },
  '@media screen and (max-width:600px)': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    a: {
      width: '100%',
    },
    'a:nth-of-type(2)': {
      marginLeft: 0,
      marginTop: '16px',
    },
  },
}));

export const StBigLinkButton = styled(JSSLink)(({ theme, variant }) => ({
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  borderRadius: '50px',
  height: 'auto',
  minWidth: 300,
  color: variant === 'contained' ? theme.palette.white.main : theme.palette.primary.main,
  padding: '19px 40px',
  backgroundColor:
    variant === 'contained'
      ? theme.palette.primary.main
      : variant === 'outlined'
      ? theme.palette.secondary.main
      : 'none',
  '&:hover': {
    backgroundColor:
      variant === 'contained'
        ? theme.palette.primary.dark
        : variant === 'outlined'
        ? theme.palette.secondary.dark
        : 'none',
    textDecoration: 'none',
    color: variant === 'contained' ? theme.palette.white.main : theme.palette.primary.main,
  },
  span: {
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
  },
  '@media screen and (max-width:670px)': {
    minWidth: 205,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    lineHeight: '1rem',
  },
}));

export const StOrText = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<{ spacing: number }>(({ theme, spacing }) => ({
  fontSize: '20px',
  lineHeight: '2rem',
  fontWeight: 600,
  fontStyle: 'normal',
  color: theme.palette.white.main,
  marginLeft: theme.spacing(spacing),
  alignSelf: 'center',
}));
