import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { UIButton } from '../../components/shared/UIButton.styles';
import { UILink } from '../../components/shared/Links/UILink.styles';
import { ComponentProps } from '../../lib/component-props';
import isEmpty from 'lodash/isEmpty';
// import CloseIcon from '../../../../public/close-icon.png';

export const StModalContainerWithModal = styled(Box)(({ theme }) => {
  return {
    padding: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    background: theme.palette.white.main,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:before': {
      content: '""',
      display: 'block',
      background: `linear-gradient(268.67deg, ${theme.palette.themeColorSecondary.main} -16.55%, ${theme.palette.themeColor.main} 73.37%)`,
      height: '16px',
      borderRadius: '16px 16px 0px 0px',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '&:focus': {
      outline: '0 !important',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '521px !important',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '465px !important',
      maxWidth: '500px !important',
    },
    '@media screen and (max-width:600px)': {
      minWidth: '332px !important',
    },
    [theme.breakpoints.down(325)]: {
      minWidth: '85% !important',
    },
    '.merge-tag': {
      p: {
        color: '#00839A',
      },
    },
  };
});

export const StmodalContainer = styled(Box)(() => ({
  padding: '24px',
}));

export const StHeadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '@media screen and (max-width:600px)': {
    padding: '1px !important',
  },
}));

export const StHeadingText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '20px',
  color: '#000000',
}));

export const StFooterbuttons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  a: {
    padding: '12px 0px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    minWidth: '110px',
    marginLeft: '32px',
  },
}));

interface IButtonProps {
  name: string;
}

interface CRModalProps {
  open: boolean;
  handleClose: () => void;
  handleContinue?: () => void;
  handleCancel?: () => void;
  handleActionClick?: (e: any) => void;
  title: string;
  children: any;
  confirmButton?: IButtonProps;
  cancelButton?: IButtonProps;
  ctaButtons?: ComponentProps[];
}
const CRModal = ({
  open,
  handleClose,
  handleContinue,
  handleCancel,
  handleActionClick,
  title,
  children,
  confirmButton,
  cancelButton,
  ctaButtons,
}: CRModalProps): React.ReactElement => {
  return (
    <Modal open={open} aria-labelledby={title}>
      <StModalContainerWithModal>
        <StmodalContainer>
          <StHeadingContainer>
            <Grid>
              <StHeadingText id="modal-modal-title" variant="h6">
                {title}
              </StHeadingText>
            </Grid>
            <IconButton
              sx={{ top: '-16px' }}
              color="inherit"
              aria-label="close modal button"
              edge="start"
              onClick={handleClose}
              onKeyUp={(e) => {
                if (e.key === 'Enter') handleClose();
              }}
              onKeyDown={(e) => {
                if (e.code === 'Space') handleClose();
              }}
              data-testid="cr-myprofile"
            >
              <CloseIcon />
            </IconButton>
          </StHeadingContainer>

          <Grid container>{children}</Grid>
          <br></br>
          <StFooterbuttons>
            {cancelButton && (
              <UILink href={'#'} onClick={handleCancel} variant={'outline'}>
                {cancelButton?.name}
              </UILink>
            )}

            {confirmButton && (
              <UIButton
                variant="contained"
                sx={{ padding: '', minWidth: 'unset', width: 'auto' }}
                onClick={handleContinue}
              >
                {confirmButton?.name}
              </UIButton>
            )}
            {!isEmpty(ctaButtons) && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {ctaButtons?.map((a: any, i: number) => (
                  <UILink
                    key={a?.name}
                    href={'#'}
                    onClick={(e) => handleActionClick(e)}
                    variant={'outline'}
                    id={(a?.fields?.link?.value?.text || a?.name) as string}
                    sx={{
                      textAlign: 'end',
                      ...(i === 0 && { marginLeft: '0px !important', textAlign: 'start' }),
                    }}
                  >
                    {a?.fields?.link?.value?.text || a?.name}
                  </UILink>
                ))}
              </Box>
            )}
          </StFooterbuttons>
        </StmodalContainer>
      </StModalContainerWithModal>
    </Modal>
  );
};

export default CRModal;
