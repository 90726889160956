import { ErrorMessage } from '@hookform/error-message';
import Box from '@mui/material/Box';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { stateAndZipCodeValidator } from '../../lib/utils/contactForm';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateZipCodeDetails } from '../../features/contactInfoSlice';
import { RootState } from '../../store/store';
import ConsentMedium from '../shared/FormElements/ConsentMedium';
import DOB from '../shared/FormElements/DOB';
import FormDropDown from '../shared/FormElements/DropDown';
import { FormInput } from '../shared/FormElements/FormInput/FormInput';
import { FormInputRadio } from '../shared/FormElements/Radio';
import {
  clientSideDefaultMaxLength,
  // clientSideDefaultRegex,
  contactFormFieldKeys,
  formFieldType,
  SITECORE_ADDRESS_KEYS,
  SITECORE_ADULT_FORM_KEYS,
  SITECORE_CAREGIVER_FORM_KEYS,
  SITECORE_MINOR_FORM_KEYS,
  SITECORE_STATE_KEYS,
  SITECORE_ZIPCODE_KEYS,
  ZIPCODE_STATE_MAPPING,
} from './constants';
import { PreferredContactTimeListItem, SitecoreGenericFieldInterface } from './ContactInfo.types';
import { ContactInfoFormContext } from './ContactInfoContextProvider';
import AutocompleteMultiSelect from '../../components/shared/AutocompleteMultiSelect/AutocompleteMultiSelect';
import { setSelectedCOIList } from '../../features/registrationSlice';
import { COIItemInterface } from '../../components/shared/AutocompleteMultiSelect/AutocompleteMultiSelect.types';

type FormFactoryProps = {
  formFields: SitecoreGenericFieldInterface;
};
type RenderComponentsProps = {
  type: string;
  formElements: SitecoreGenericFieldInterface;
};

const RenderComponents = ({ type, formElements }: RenderComponentsProps) => {
  const stateList = useSelector((state: RootState) => state.contactInfoDetails.stateList);
  const timeZoneList = useSelector((state: RootState) => state.contactInfoDetails.timeZones);
  const countryFlag = useSelector((state: RootState) => state.selectedLanguage.flagIcon);
  const countryCode = useSelector((state: RootState) => state.selectedLanguage.countryISO2);
  const formatedCOIList = useSelector(
    (state: RootState) => state.contactInfoDetails.conditionOfIntrestList
  );
  const selectedCOIList = useSelector(
    (state: RootState) => state.registrationDetails.selectedCOIList
  );

  const contactInfoElements = useSelector(
    (state: RootState) => state.contactInfoDetails.stateZipcodeInfo
  );
  // const geolocation = useSelector((state: RootState) => state.geolocation);
  const {
    formState: { errors },
    control,
    getValues,
    clearErrors,
    setError,
    setValue,
    trigger,
  } = useContext<any>(ContactInfoFormContext);
  const dispatch = useDispatch();

  const { sitecoreContext } = useSitecoreContext();

  const siteSettings: any = sitecoreContext?.siteSettings;
  useEffect(() => {
    /** This if else code set the current zipcode element in the context */
    async function setData() {
      if (formElements?.key?.value === SITECORE_ADULT_FORM_KEYS.ZIPCODE) {
        const key = ZIPCODE_STATE_MAPPING[formElements.key.value];
        dispatch(updateStateZipCodeDetails({ [key]: formElements }));
      } else if (formElements?.key?.value === SITECORE_MINOR_FORM_KEYS.PARTICIPANT_ZIPCODE) {
        const key = ZIPCODE_STATE_MAPPING[formElements.key.value];
        dispatch(updateStateZipCodeDetails({ [key]: formElements }));
      } else if (formElements?.key?.value === SITECORE_MINOR_FORM_KEYS.PARENT_ZIPCODE) {
        const key = ZIPCODE_STATE_MAPPING[formElements.key.value];
        dispatch(updateStateZipCodeDetails({ [key]: formElements }));
      } else if (formElements?.key?.value === SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ZIPCODE) {
        const key = ZIPCODE_STATE_MAPPING[formElements.key.value];
        dispatch(updateStateZipCodeDetails({ [key]: formElements }));
      }
      return true;
    }
    setData();
  }, [dispatch, formElements, type]);

  const getDropdownValues = (fieldName: SitecoreGenericFieldInterface) => {
    if (fieldName?.key?.value === contactFormFieldKeys.CONDITION_OF_INTEREST) {
      return [...formatedCOIList];
    } else if (contactFormFieldKeys?.STATE_KEYS.indexOf(fieldName?.key?.value) >= 0) {
      return stateList;
    } else if (fieldName?.key?.value === contactFormFieldKeys?.USER_TIME_ZONE) {
      return timeZoneList;
    } else if (fieldName?.key?.value === contactFormFieldKeys?.PREFERRED_CONTACT_TIME) {
      const contactTime = fieldName?.preferredContactTimeList;
      let options: any = [];
      if (contactTime && contactTime.length > 0) {
        contactTime?.map((item: PreferredContactTimeListItem) => {
          const option = {
            label: item?.fields?.label?.value,
            value: item?.fields?.value?.value,
          };
          options.push(option);
        });
      } else {
        options = [];
      }
      return options;
    } else {
      return stateList;
    }
    return [];
  };

  const getRadioValues = (formValues: SitecoreGenericFieldInterface) => {
    const gender = formValues?.Gender;
    const options: any = [];
    if (gender && gender.length > 0) {
      gender.map((item: any) => {
        const value = item?.fields?.value.value;
        const label = item?.fields?.label?.value ? item.fields.label : { value };
        const key = item?.fields?.key?.value;
        const option = {
          label,
          value,
          key,
        };
        options.push(option);
      });
    }
    return options;
  };

  const getMaxLength = (formElements: SitecoreGenericFieldInterface) => {
    if (formElements.maxLength && formElements?.maxLength?.value != '') {
      return formElements.maxLength.value;
    } else {
      const maxLengthValue =
        clientSideDefaultMaxLength[
          formElements?.key?.value as keyof typeof clientSideDefaultMaxLength
        ];
      return maxLengthValue;
    }
  };

  const enableAutocomplete = (key: string) => {
    return SITECORE_ADDRESS_KEYS.includes(key) &&
      (siteSettings?.enableGoogleAutoCompleteAddress?.value ||
        siteSettings?.enableAutoComplete?.value)
      ? true
      : false;
  };

  switch (type) {
    case formFieldType.DROPDOWN:
      if (formElements?.key?.value === contactFormFieldKeys?.CONDITION_OF_INTEREST) {
        return (
          <AutocompleteMultiSelect
            id={'conditions-of-interest'}
            isContactInfo={true}
            options={getDropdownValues(formElements)}
            noOptionText={(formElements as any)?.noOptionText?.value}
            label={formElements?.label?.value}
            placeholder={formElements?.placeholderLabel?.value}
            value={selectedCOIList}
            onChangeHandler={(newValue: COIItemInterface[]) => {
              dispatch(setSelectedCOIList(newValue));
            }}
          />
        );
      } else {
        return (
          <FormDropDown
            regexValidationMessage={formElements.regexValidationMessage}
            requiredFieldMessage={formElements.requiredFieldMessage}
            type="string"
            placeholder={formElements?.placeholderLabel}
            isRequired={formElements.isRequired}
            label={formElements.label}
            name={formElements.key}
            toolTip={formElements?.toolTip?.value}
            errors={errors}
            control={control}
            setValue={setValue}
            rules={{
              required: formElements?.isRequired?.value
                ? formElements?.requiredFieldMessage?.value
                : ``,
              validate: async () => {
                const zipCodeFieldState = contactInfoElements[formElements.key.value];
                if (
                  SITECORE_STATE_KEYS.includes(formElements.key.value) &&
                  getValues(formElements?.key?.value) !== undefined &&
                  getValues(zipCodeFieldState?.key?.value) !== undefined &&
                  zipCodeFieldState?.enableStateZipcodeValidation?.value
                ) {
                  try {
                    const response = await stateAndZipCodeValidator(
                      getValues(zipCodeFieldState?.key?.value), // zipCodeField.key.value return a key of the zipcode field
                      getValues(formElements.key.value) // formElements.key.value return current element key
                    );
                    if (response === undefined) {
                      setError(zipCodeFieldState?.key?.value, {
                        type: 'custom',
                        message: zipCodeFieldState.stateZipcodeValidationErrorMessage.value,
                      });
                    } else {
                      clearErrors(zipCodeFieldState?.key?.value);
                    }
                  } catch (error) {
                    setError(zipCodeFieldState?.key?.value, {
                      type: 'custom',
                      message: zipCodeFieldState.stateZipcodeValidationErrorMessage.value,
                    });
                  }
                }
              },
            }}
            options={getDropdownValues(formElements)}
            showInputLabel={true}
            renderError={(name: string) => (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <Box sx={{ color: (theme) => theme.palette.error.main }}>{message}</Box>
                )}
              />
            )}
          />
        );
      }
    case formFieldType.TEXTBOX:
      return (
        <FormInput
          label={formElements.label}
          name={formElements.key.value}
          toolTip={formElements?.toolTip?.value}
          placeholder={formElements?.placeholderLabel?.value}
          control={control}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
          showIsMobileOption={formElements.showIsMobileOption}
          isMobileLabel={formElements.isMobileLabel}
          enableAutoComplete={enableAutocomplete(formElements.key.value)}
          addressZipcodeValidationErrorMessage={formElements?.addressZipcodeValidationErrorMessage}
          setError={setError}
          rules={{
            required: formElements?.isRequired?.value
              ? formElements?.requiredFieldMessage?.value
              : ``,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            validate: async () => {
              const stateKey = ZIPCODE_STATE_MAPPING[formElements?.key?.value];
              const zipCodeFieldState = contactInfoElements[stateKey];
              if (
                zipCodeFieldState !== undefined &&
                getValues(ZIPCODE_STATE_MAPPING[formElements.key.value]) !== undefined &&
                SITECORE_ZIPCODE_KEYS.includes(formElements.key.value) &&
                zipCodeFieldState.enableStateZipcodeValidation.value
              ) {
                try {
                  const response = await stateAndZipCodeValidator(
                    getValues(formElements.key.value),
                    getValues(ZIPCODE_STATE_MAPPING[formElements.key.value])
                  );
                  if (response === undefined) {
                    return zipCodeFieldState.stateZipcodeValidationErrorMessage.value;
                  } else {
                    clearErrors(zipCodeFieldState.key.value);
                  }
                } catch (error) {
                  return zipCodeFieldState.stateZipcodeValidationErrorMessage.value;
                }
              } else if (formElements?.regex && formElements?.regex?.fields?.value) {
                const regEx = RegExp(formElements?.regex?.fields?.value?.value);
                const fieldValue = getValues(formElements.key.value);
                return regEx.test(fieldValue) ? true : formElements?.invalidInputMessage?.value;
              }
            },
          }}
          countryFlag={countryFlag}
          countryCode={countryCode}
          maxLength={getMaxLength(formElements)}
          prePopulateFromScreener={formElements?.prePopulateFromScreener}
          isReadOnly={formElements?.isReadOnly}
          mobileNumberValidationMessageOnSMSConsent={
            formElements?.mobileNumberValidationMessageOnSMSConsent?.value
          }
        />
      );
    case formFieldType.CHECKBOX:
      return (
        <ConsentMedium
          type={formElements?.type}
          consentMediumList={formElements?.consentMediumList}
          consentSubTitle={formElements?.consentSubTitle}
          consentTitle={formElements?.consentTitle}
          isMandatory={formElements?.isMandatory}
          errors={errors}
          name={formElements.key}
          control={control}
          clearErrors={clearErrors}
          setError={setError}
          setValue={setValue}
          rules={{
            required: {
              value: formElements?.isRequired?.value,
              message: formElements?.requiredFieldMessage?.value || 'This is required field',
            },
          }}
          renderError={(name: string) => (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <Box sx={{ color: (theme) => theme.palette.error.main }}>{message}</Box>
              )}
            />
          )}
          formElements={formElements}
        />
      );
    case formFieldType.DOB:
      return (
        <DOB
          DOB={formElements?.DOB}
          toolTip={formElements?.toolTip}
          label={formElements?.label}
          isRequired={formElements?.isRequired}
          requiredFieldMessage={formElements?.requiredFieldMessage}
          invalidInputMessage={formElements?.invalidInputMessage}
          type={formElements?.type}
          regexValidationMessage={formElements?.regexValidationMessage}
          errors={errors}
          control={control}
          getValues={getValues}
          clearErrors={clearErrors}
          setError={setError}
          DisqualifiedAgeRangeErrorMessage={formElements?.DisqualifiedAgeRangeErrorMessage}
          DisqualifiedMaxAge={formElements?.DisqualifiedMaxAge}
          DisqualifiedMinAge={formElements?.DisqualifiedMinAge}
          QualifiedAgeRangeErrorMessage={formElements?.QualifiedAgeRangeErrorMessage}
          QualifiedMaxAge={formElements?.QualifiedMaxAge}
          QualifiedMinAge={formElements?.QualifiedMinAge}
          prePopulateFromScreener={formElements?.prePopulateFromScreener}
          isReadOnly={formElements?.isReadOnly}
        />
      );
    case formFieldType.RADIOBUTTON:
      return (
        <FormInputRadio
          options={getRadioValues(formElements)}
          errors={errors}
          toolTip={formElements?.toolTip?.value}
          control={control}
          name={formElements.key.value}
          label={formElements.label}
          prePopulateFromScreener={formElements?.prePopulateFromScreener}
          isReadOnly={formElements?.isReadOnly}
          rules={{
            required: formElements?.isRequired?.value
              ? formElements?.requiredFieldMessage?.value
              : ``,
          }}
        />
      );
    default:
      return <></>;
  }
};

const FormFactory = (props: FormFactoryProps): JSX.Element => {
  const formElements = props?.formFields;
  return <RenderComponents type={formElements?.type?.value} formElements={formElements} />;
};

export default FormFactory;
