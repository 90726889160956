import { LinkField, Field } from '@sitecore-jss/sitecore-jss-nextjs';

export type IButton = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    link?: LinkField;
    screenerCtaLink?: LinkField;
    questionnaireCtaLink?: LinkField;
    buttonVariant?: {
      value: string;
    };
    ariaLabel?: Field<string>;
    callCenterCTAButton?: Field<boolean>;
  };
};

export enum ButtonType {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text',
}
