import { RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextWithPnMergeTag from '../../shared/RichTextWithPnMergeTag/RichTextWithPnMergeTag';
import React from 'react';

interface IProps {
  mergeTagField: RichTextField;
  mergeTagObj: any;
  style?: React.CSSProperties;
  inheritLinkColor?: boolean;
}
const SuccessPageMergeTag: React.FC<IProps> = (props) => {
  const style = props.style || {};
  const RTFromSiteCore: RichTextField = props.mergeTagField || { value: '' };
  const mergeTagObj = {
    ...props?.mergeTagObj,
    ...props?.mergeTagObj?.confirmedsitesData,
    ...props?.mergeTagObj?.confirmedProtocolInfo,
  };
  let newValue = '';

  if (mergeTagObj && Object.keys(mergeTagObj).length > 0) {
    // Get the mergeTags out from the RTFromSiteCore.value and store it in an array
    const originaValue: string = RTFromSiteCore.value || '';
    const mergeTagsInText: string[] = (originaValue.match(/\[(.*?)\]/g) || []).map((tag) =>
      tag.slice(1, -1)
    );

    const replaceMergeTags = (): string => {
      let replacedValue = originaValue;
      mergeTagsInText.forEach((tag) => {
        const tagValue = mergeTagObj[tag as keyof any] || '';
        replacedValue = replacedValue.replace(new RegExp(`\\[${tag}\\]`, 'g'), tagValue as string);
      });
      return replacedValue;
    };

    newValue = replaceMergeTags();
  } else {
    newValue = props?.mergeTagField?.value as string;
  }

  return (
    <RichTextWithPnMergeTag
      data-testid="merge-tag"
      style={style}
      field={{ ...RTFromSiteCore, value: newValue }}
      inheritLinkColor={props?.inheritLinkColor}
    />
  );
};

export default SuccessPageMergeTag;
