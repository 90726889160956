import { buildContext, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import headlessEngine from './headlessEngine';

export const setContext = (profileContextData?: { [key: string]: string | number }): null => {
  const { executeSearch } = loadSearchActions(headlessEngine);
  const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngine);
  headlessEngine.dispatch(executeSearch(logInterfaceLoad()));

  const context = buildContext(headlessEngine!);

  context.set({
    ...profileContextData,
    language: 'en',
  });

  return null;
};
