import { useEffect } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { post } from '../../lib/utils/axiosFetcher';
import { updateQueryParamList } from '../../features/configSlice';

const addOrUpdateQueryParams = async (
  queryParams: any,
  enrollmentId: string,
  tenantKey: string,
  potentialId: string
) => {
  const url = '/Participant/AddOrUpdateQueryParams';
  const data = {
    queryParams: queryParams,
    enrollmentId: enrollmentId,
    tenantKey: tenantKey,
    potentialId: potentialId,
  };
  try {
    await post(url, data);
  } catch (_e) {}
};

const useQueryParam = () => {
  const router: NextRouter = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const queryParams = useSelector((state: RootState) => state.config.queryParamList);
  const potentialParticipant = useSelector((state: RootState) => state.potentialParticipantDetails);
  const { enrollmentId, tenantKey, potentialId } = potentialParticipant;
  const retainQueryParamList = siteSettings?.RetainQueryParamList?.value?.split('&');

  const dispatch = useDispatch();
  const { query } = router;
  useEffect(() => {
    const result: any = {};
    if (retainQueryParamList && retainQueryParamList.length > 0) {
      retainQueryParamList.forEach((key: string) => {
        if (query.hasOwnProperty(key)) {
          result[key] = query[key];
        }
      });

      const paramsList = Object.entries(result).map(([queryParamKey, queryParamValue]) => ({
        queryParamKey,
        queryParamValue,
      }));
      dispatch(updateQueryParamList(paramsList));
    }
  }, [query]);

  useEffect(() => {
    let atid;
    if (query.hasOwnProperty('atid') && query['atid'] !== '') {
      atid = query['atid'];
    } else if (siteSettings?.aTIdDefaultValue?.value !== '') {
      atid = siteSettings?.aTIdDefaultValue?.value;
    }

    if (atid !== '' && enrollmentId && potentialId) {
      const params = [{ queryParamKey: 'atid', queryParamValue: atid }];
      addOrUpdateQueryParams(params, enrollmentId, tenantKey, potentialId);
    }
  }, [query, enrollmentId, potentialId]);

  useEffect(() => {
    if (queryParams.length > 0) {
      if (enrollmentId && potentialId) {
        addOrUpdateQueryParams(queryParams, enrollmentId, tenantKey, potentialId);
      }
    }
  }, [queryParams, enrollmentId, potentialId]);
};

export default useQueryParam;
