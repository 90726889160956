import { Controller } from 'react-hook-form';
import { InputFormProps } from '../FormElements.types';
import { InputWrapper } from './InputWrapper';

const FormInput = ({
  name,
  toolTip,
  placeholder,
  label,
  control,
  rules,
  errors,
  countryFlag,
  countryCode,
  maxLength,
  showIsMobileOption,
  isMobileLabel,
  enableAutoComplete,
  setValue,
  trigger,
  prePopulateFromScreener,
  isReadOnly,
  setError,
  addressZipcodeValidationErrorMessage,
  mobileNumberValidationMessageOnSMSConsent,
}: InputFormProps): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={''}
      render={({ field: { onChange, value, onBlur } }) => (
        <InputWrapper
          onChangeHandler={onChange}
          value={value}
          label={label}
          name={name}
          setValue={setValue}
          trigger={trigger}
          onBlur={onBlur}
          setError={setError}
          toolTip={toolTip}
          placeholder={placeholder}
          errors={errors}
          countryFlag={countryFlag}
          countryCode={countryCode}
          maxLength={maxLength}
          isMobileLabel={isMobileLabel}
          showIsMobileOption={showIsMobileOption}
          enableAutoComplete={enableAutoComplete}
          control={control}
          prePopulateFromScreener={prePopulateFromScreener}
          isReadOnly={isReadOnly}
          addressZipcodeValidationErrorMessage={addressZipcodeValidationErrorMessage}
          mobileNumberValidationMessageOnSMSConsent={mobileNumberValidationMessageOnSMSConsent}
        />
      )}
    />
  );
};

export { FormInput };
