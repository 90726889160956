import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Text, Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNumber } from './InformationalBlock.style';
import useMediaQuery from '@mui/material/useMediaQuery';

type InformationalBlockProps = {
  stepNumber: Field<number>;
  stepTitle: Field<string>;
  stepDescription: Field<string>;
  hideStepNumbers: Field<boolean>;
  infoStepsVariantType: string;
  showTextBottom: boolean;
  alignItems: string;
  isVertical: boolean;
  invert: boolean;
};

const InformationalBlock = (props: InformationalBlockProps): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const direction = props?.showTextBottom && !matches ? 'column' : 'row';
  const isVertical = props?.isVertical;
  const invert = props?.invert;

  return (
    <Grid
      container
      flexDirection={direction}
      alignItems={props?.alignItems}
      justifyContent="center"
    >
      <Grid item xs={1.5} sm={1.5} md={1.5} lg={isVertical ? 1.5 : 3}>
        <StNumber invert={invert}>
          {props?.hideStepNumbers?.value === false && <Text field={props?.stepNumber}></Text>}
        </StNumber>
      </Grid>
      <Grid item xs={10.5} sm={10.5} md={10.5} lg={isVertical ? 10.5 : 9}>
        <Box>
          {props?.stepTitle?.value && (
            <Typography
              variant="h3"
              color={invert ? theme.palette.white.main : theme.palette.themeColor.main}
            >
              <Text field={props?.stepTitle} />
            </Typography>
          )}

          <Typography
            variant="description"
            color={invert ? theme.palette.white.main : theme.palette.text.primary}
          >
            <RichText field={props?.stepDescription} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InformationalBlock;
